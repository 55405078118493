import { Col, Empty, List, Row } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeOccupancyRates, changeTypesOfDay } from '../../../store/actions/configurations';
import { changeTemplates } from '../../../store/actions/planning';
import Network from '../../../utils/network';
import { PlanningOccupancyRate, PlanningTemplate, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { alert, convertNetworkEventsToPlanningEventsV2, convertNetworkOccupancyRatesToPlanningOccupancyRates, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';
import TypeOfDayItem from './typeOfDayItem';

interface IProps {
    typesOfDay: TypeOfDay[];
}

type Props = IProps & ConfigurationsDispatchProps & PlanningDispatchProps & IntlProps;

interface State {
    createTypeOfDay: TypeOfDay | undefined;
}

/**
 * Component for the types of day tab in the configurations page
 */
class TypesOfDayTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            createTypeOfDay: undefined,
        };
    }

    /**
     * Refresh the types of day
     * @param message a message to display as a success message - optional
     */
    refresh = (message?: string, forceReload = false) => {
        const { intl } = this.props;
        // get all types of day
        Network.getTypeOfDay().then(
            response => {
                this.props.changeTypesOfDay!(response);
                this.setState({ createTypeOfDay: undefined });
                if (message) alert(message, "success");
            },
            () => alert(intl.formatMessage({ defaultMessage: 'An error occurred while loading the day types' }), "warning"),
        );
        if (forceReload) {
            Network.getOccupancyRates().then(
                response => {
                    const occupancyRates = convertNetworkOccupancyRatesToPlanningOccupancyRates(response);
                    this.props.changeOccupancyRates!(occupancyRates);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the missions' }), "warning");
                },
            );

            Network.getTemplates().then(
                response => {
                    if (response.error) showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the models' }), "warning");
                    else {
                        this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
                    }
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the models' }), "warning")
            );
        }
    };

    /**
     * Check if the add type of day button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddTypeOfDay = (): boolean => this.state.createTypeOfDay === undefined;

    /**
     * Add a type of day
     */
    addTypeOfDay = () => {
        const createTypeOfDay: TypeOfDay = {
            title: "",
            inReports: true,
            countAsWorktime: true,
            canBeBadged: true

        };
        this.setState({ createTypeOfDay });
    };

    /**
     * Render a type of day (a list item)
     * @param typeOfDay the item to render
     * @returns the component to render
     */
    renderTypeOfDay = (typeOfDay: TypeOfDay): React.ReactNode => {
        return <TypeOfDayItem typeOfDay={typeOfDay} refresh={this.refresh} />;
    };

    render() {
        const { createTypeOfDay } = this.state;
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }}>
                    <Card className="types-of-day-card" icon={<FAIcon prefix='fad' name='calendar-days' />} title={<FormattedMessage defaultMessage={'Day types'} />} headerElements={[
                        this.showAddTypeOfDay() &&
                        <CircleButton
                            key="type_of_day_add_button"
                            style={{ marginRight: '8px' }}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Add a day type' })}
                            onClick={this.addTypeOfDay}
                            small
                            icon={<FAIcon prefix='far' name='plus' />}
                            placement="right" />
                    ]}>
                        <List
                            className="types-of-day-list"
                            dataSource={createTypeOfDay ? this.props.typesOfDay.concat([createTypeOfDay]) : this.props.typesOfDay}
                            renderItem={this.renderTypeOfDay}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No type of day'} />} /> }}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfDay: state.configurations.typesOfDay,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TypesOfDayTab));