import { Button, Card, Divider, message, Popconfirm, Popover, Space, Tooltip } from "antd";
import { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Network from "../../../../utils/network";
import { IUserExportHoursHotelaUserData } from "../../../../utils/types/networkTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { showNotification } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import CircleButton from "../../../common/fields/circleButton";
import ExportHotelaResult from "./exportHotelaResult";

interface Props {
    selectedDate: Moment;
    selectedUserId: number;
    loading: boolean;
    userIds: number[];
    waitAggregated: boolean;
    waitTimeclock: boolean;
    setLoading: (loading: boolean) => void;
    loadUsers: () => void;
    reset: () => void;
}

const HotelaExport = (props: Props) => {
    const [userData, setUserData] = useState<IUserExportHoursHotelaUserData | undefined>(undefined);
    const [generateLoading, setGenerateLoading] = useState(false);
    const [isAuxiliary, setIsAuxiliary] = useState(false);
    const [monthId, setMonthId] = useState(-1);

    const intl = useIntl();
    const width = useSelector((state: ApplicationState) => state.window.width);

    const selectedUseIdRef = useRef(props.selectedUserId);

    const generatePreview = useCallback(() => {
        selectedUseIdRef.current = props.selectedUserId;
        const year = parseInt(props.selectedDate.format('YYYY'));
        const month = parseInt(props.selectedDate.format('MM'));
        if (props.selectedUserId === -1) {
            showNotification(intl.formatMessage({ defaultMessage: 'Please select an user' }), 'warn');
            return;
        }

        setGenerateLoading(true);
        Network.getHotelaUserData(year, month, props.selectedUserId).then(
            (response) => {
                setUserData(response.data.userData);
                setIsAuxiliary(response.data.isAuxiliary);
                setMonthId(response.data.userData.id);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the preview' }), 'error');
            }
        ).finally(() => setGenerateLoading(false));
    }, [intl, props.selectedDate, props.selectedUserId]);

    const cancelValidation = useCallback(() => {
        props.setLoading(true);
        Network.cancelHotelaUserData(monthId).then(
            () => {
                message.destroy('confirm-hotela-validation');
                message.success({
                    content: intl.formatMessage({ defaultMessage: 'Data sending canceled' }),
                    duration: 2,
                });
                props.loadUsers();
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), 'error');
            }
        ).finally(() => props.setLoading(false));
    }, [intl, monthId, props]);

    const validateData = useCallback(() => {
        setGenerateLoading(true);
        props.setLoading(true);
        Network.validateHotelaUserData(monthId).then(
            () => {
                message.success({
                    content: <>{intl.formatMessage({ defaultMessage: 'Sending data to Hotela...' })} <Button onClick={cancelValidation} type='link'>{intl.formatMessage({ defaultMessage: 'Cancel' })}</Button></>,
                    duration: 5,
                    key: 'confirm-hotela-validation',
                    className: 'hotela-confirm-message'
                });
                setUserData(undefined);
                props.reset();
            },
            (error) => {
                const json = JSON.parse(error.message);

                switch (json.code) {
                    case 'integrations-still-waiting-aggregated-confirmation-603':
                        showNotification(intl.formatMessage({ defaultMessage: 'Cannot validate the hours' }), 'error', intl.formatMessage({ defaultMessage: 'All events are not validated in the reports yet' }));
                        break;
                    default:
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), 'error');
                }
            }
        ).finally(() => { setGenerateLoading(false); props.setLoading(false); });
    }, [intl, cancelValidation, monthId, props]);

    useEffect(() => {
        setUserData(undefined);
        if (props.selectedUserId !== -1 && props.selectedUserId !== selectedUseIdRef.current)
            generatePreview();
    }, [generatePreview, props.selectedUserId]);

    useEffect(() => {
        if (props.selectedUserId === -1)
            selectedUseIdRef.current = -1;
        setUserData(undefined);
        setIsAuxiliary(false);
    }, [props.selectedUserId, props.userIds]);

    useEffect(() => {
        return () => {
            message.destroy('confirm-hotela-validation');
        };
    }, []);

    return (
        <Card
            size='small'
            title={
                <Space>
                    <FormattedMessage defaultMessage={'Data preview'} />
                    <Divider type='vertical' />
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='rotate' />}
                        onClick={generatePreview}
                        loading={generateLoading}
                        disabled={userData === undefined}
                    />
                    {
                        isAuxiliary &&
                        <Tooltip
                            title={<FormattedMessage defaultMessage={'This user is an auxiliary employee and therefor exporting his data may break on Hotela.'} />}
                        >
                            <FAIcon name='triangle-exclamation' prefix='far' color='red' style={{ marginLeft: 10 }} />
                        </Tooltip>
                    }
                </Space>
            }
            extra={[
                <Space key='export-hours-filter' >
                    {
                        props.waitAggregated || props.waitTimeclock ?
                            <Popover
                                title={<FormattedMessage defaultMessage={'Unable to validate'} />}
                                content={
                                    <>
                                        {
                                            props.waitAggregated &&
                                            <FormattedMessage defaultMessage={'Awaiting hours validations in reports'} />
                                        }
                                        {
                                            props.waitTimeclock &&
                                            <FormattedMessage defaultMessage={'Awaiting timeclock validation'} />
                                        }
                                    </>
                                }
                                placement='left'
                            >
                                <FAIcon name='circle-info' prefix='far' />
                            </Popover>
                            :
                            undefined
                    }
                    <Popconfirm
                        title={
                            <>
                                <p><strong><FormattedMessage defaultMessage={'Hours validation'} /></strong></p>
                                <br />
                                <p><FormattedMessage defaultMessage={"This action will validate and send the data to Hotela. Te user's planning will be blocked for the selected month."} /></p>
                                <br />
                                <p><FormattedMessage defaultMessage={'Do you want to continue?'} /></p>
                            </>
                        }
                        onConfirm={() => validateData()}
                        okText={<FormattedMessage defaultMessage={'Validate and send'} />}
                        disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                        placement='left'
                    >
                        {
                            width > 620 ?
                                <Button
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                >
                                    <FormattedMessage defaultMessage={'Validate and send to Hotela'} />
                                </Button>
                                :
                                <CircleButton
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                    small
                                    icon={<FAIcon name='check' prefix='far' />}
                                />
                        }

                    </Popconfirm>
                </Space>
            ]}
        >
            <ExportHotelaResult loading={generateLoading} userData={userData} />
        </Card>
    );
};

export default HotelaExport;