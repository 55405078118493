import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ReloadTimes } from "../../utils/constants";
import Network from "../../utils/network";
import { ApplicationState, NewsState, StatusType } from "../../utils/types/storeTypes";
import { isDeprecated } from "../../utils/utils";

export const initialState: NewsState = {
    news: {
        status: StatusType.NONE
    },
};

const getLang = () => {
    const splitted_pathname = window.location.pathname.split('/');
    return (['fr', 'en', 'de'].includes(splitted_pathname[1])) ? splitted_pathname[1] : 'fr';
};

//* Details
export const newsFetched = createAsyncThunk(
    'news/fetched', async () => {
        const response = await Network.getAdminNews(getLang());
        return { updatedAt: moment().format(), data: response.data };
    },
    {
        condition: (arg: { forceReload: boolean; }, thunkAPI) => {
            const { news, user } = thunkAPI.getState() as ApplicationState;
            return news.news.data && (arg.forceReload || isDeprecated(news.news.updatedAt ? moment(news.news.updatedAt) : undefined, ReloadTimes.FAST_RELOAD) && (user.currentUser && [2, 3].includes(user.currentUser.role)));
        },
    });

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        reset: () => {
            return {
                ...initialState,
            };
        },
        resetNewsCount: (state) => {
            return {
                ...state,
                news: {
                    ...state.news,
                    data: {
                        ...state.news.data,
                        count: 0,
                        news: state.news.data?.news || []
                    }
                }
            };
        }
    },
    extraReducers(builder) {
        //* Enabled integrations *//
        builder.addCase(newsFetched.fulfilled, (state, action) => {
            state.news = {
                ...state.news,
                data: {
                    ...state.news.data,
                    count: action.payload.data.count,
                    news: action.payload.data.news
                },
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(newsFetched.pending, (state) => {
            state.news = {
                ...state.news,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(newsFetched.rejected, (state) => {
            state.news = {
                ...state.news,
                status: StatusType.REJECTED,
            };
        });
    },
});

export const { reset, resetNewsCount } = newsSlice.actions;

export default newsSlice.reducer;