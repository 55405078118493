import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeProject, changeStaffTypes, changeTypesOfDay, changeTypesOfDayOff, loadDepartments } from '../../store/actions/configurations';
import { loadPois } from '../../store/actions/location';
import { changeSettings, changeTemplates } from '../../store/actions/planning';
import { changeGroups, changeUsers } from '../../store/actions/teamManagement';
import { changeCompany, getCurrentUser } from '../../store/actions/user';
import Network from '../../utils/network';
import { Company, DONE_TEXT, Group, LoadingGroup, LoadingGroups, RouterProps, StaffType, User } from '../../utils/types/generalTypes';
import { PlanningSettings, PlanningTemplate, Project, TypeOfDay, TypeOfDayOff } from '../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { convertNetworkEventsToPlanningEventsV2, convertNetworkSettingsToPlanningSettings } from '../../utils/utils';

import { FormattedMessage, injectIntl } from 'react-intl';
import logo from '../../images/logo/logo_sunkhronos_minimized.png';
import { enabledIntegrationsFetched } from '../../store/features/integrationsSlice';
import { newsFetched } from '../../store/features/newsSlice';
import '../../styles/splashScreen.css';
import { getRandomItem } from '../../utils/array_utils';
import { IntlProps } from '../app/LanguageProvider';
import LoadingSplashScreen from './loadingSplashScreen';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, RouterProps, IntlProps {
    children?: React.ReactNode;
}

interface State {
    loadingGroups: LoadingGroups;
    ready: boolean;
}

const TIMEOUT_AFTER_DONE_LOADING = 500;

class SplashScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingGroups: this.generateLoadingGroups([
                {
                    label: <><FormattedMessage defaultMessage={'the company'} description={'SplashScreen'} /> {getRandomItem(["📋", "🏭", "🗂️", "🏢"])}</>,
                    steps: [
                        this.loadCurrentUser,
                        this.loadCompanyDetails,
                        this.loadIntegrations
                    ]
                },
                {
                    label: <><FormattedMessage defaultMessage={'the team'} description={'SplashScreen'} /> {getRandomItem(["👨🏻‍💼", "👩🏻‍💼", "👩🏽‍💼", "👨🏽‍💼", "🧑🏼‍💼"])}</>,
                    steps: [
                        this.loadUsers,
                        this.loadGroups,
                        this.loadNews,
                    ]
                },
                {
                    label: <><FormattedMessage defaultMessage={'the settings'} description={'SplashScreen'} /> {'🛠️'}</>,
                    steps: [
                        this.loadDepartments,
                        this.loadTypesOfDays,
                        this.loadTypesOfDaysOff,
                        this.loadSettings,
                    ]
                },
                {
                    label: <><FormattedMessage defaultMessage={'the datas'} description={'SplashScreen'} /> {'🖥️'}</>,
                    steps: [
                        this.loadProjects,
                        this.loadPois,
                        this.loadTemplates,
                        this.loadStaffTypes
                    ]
                }
            ]),
            ready: false,
        };
    }

    // #region Fixtures and utils
    generateLoadingGroups = (groups: Omit<Omit<LoadingGroup, 'status'>, 'key'>[]): LoadingGroups => {
        const groupsFull: LoadingGroups = groups.map((g, i) => ({ ...g, status: 'NONE', key: `loading-group-${i}` }));

        const emoji = getRandomItem(["🏆", "🥇", "🏁"]);

        return [
            ...groupsFull,
            {
                key: DONE_TEXT,
                label: <><FormattedMessage defaultMessage={'complete'} description={'SplashScreen'} /> {emoji}</>,
                status: 'NONE',
                steps: [async () => { setTimeout(this.ready, TIMEOUT_AFTER_DONE_LOADING); return; }]
            }
        ];
    };

    componentDidMount() {
        this.loadNextGroup();
    }

    componentDidUpdate() {
        this.loadNextGroup();
    }

    loadNextGroup = () => {
        for (const group of this.state.loadingGroups) {
            if (group.status === 'PENDING')
                break;
            if (group.status === 'NONE') {
                this.loadGroup(group);
                break;
            }
        }
    };

    changeGroupState = (group: LoadingGroup, state: LoadingGroup['status']) => {
        this.setState((prevState) => ({
            loadingGroups: [
                ...prevState.loadingGroups.map(g => ({
                    ...g,
                    status: g.key !== group.key ? g.status : state
                })),
            ],
        }));
    };

    loadGroup = async (group: LoadingGroup): Promise<void> => {
        this.changeGroupState(group, 'PENDING');

        // This will resolve all promise simultaneously. Faster
        // await Promise.all(group.steps) 

        // This will wait for each request to finish before moving onto the next one. Slower
        for (const step of group.steps)
            await step();


        this.changeGroupState(group, 'LOADED');
    };

    ready = () => this.setState({ ready: true });
    // #endregion

    // #region Loaders
    loadCurrentUser = async () => this.props.getCurrentUser();
    loadDepartments = async () => this.props.loadDepartments();

    loadCompanyDetails = async () => await Network.getCompanyDetails().then(response => this.props.changeCompany(response));

    loadIntegrations = async () => await this.props.enabledIntegrationsFetched();

    loadUsers = async () => await Network.getAllUsers().then(response => this.props.changeUsers!(response));

    loadGroups = async () => await Network.getGroups().then(response => this.props.changeGroups(response));

    loadProjects = async () => await Network.getProjects().then((response) => this.props.changeProject(response));

    loadSettings = async () => await Network.getSettings().then(response => this.props.changeSettings(convertNetworkSettingsToPlanningSettings(response)));

    loadTypesOfDays = async () => await Network.getTypeOfDay().then(response => this.props.changeTypesOfDay(response));

    loadTypesOfDaysOff = async () => await Network.getTypeOfDayOff().then(response => this.props.changeTypesOfDayOff(response));

    loadNews = async () => await this.props.newsFetched();

    loadTemplates = async () => Network.getTemplates().then(
        response => {
            if (response.error) {
                console.error("Problem template");
            } else {
                this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
            }
        });

    loadStaffTypes = async () => Network.getStaffType().then(response => this.props.changeStaffTypes(response));

    loadPois = async () => this.props.loadPois();
    // #endregion

    // #region Render
    render() {
        return (
            this.state.ready ?
                this.props.children
                :
                <>
                    {
                        this.state.loadingGroups.filter(lg => lg.status === 'NONE').length !== this.state.loadingGroups.length ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '30px', height: '100%' }}>
                                <img src={logo} style={{ maxWidth: '400px' }} alt="Sunkhronos logo" />
                                <LoadingSplashScreen dataLoading={this.state.loadingGroups.filter(lg => lg.status === 'LOADED' || lg.key === DONE_TEXT).length === this.state.loadingGroups.length ? DONE_TEXT : this.state.loadingGroups.find(g => g.status === 'PENDING')?.key} dataToLoad={this.state.loadingGroups} loadingGroups={this.state.loadingGroups} />
                            </div>
                            :
                            <></>
                    }
                </>
        );
    }
    // #endregion
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
    changeTemplates: (a: PlanningTemplate[]) => dispatch(changeTemplates(a)),
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
    changeStaffTypes: (s: StaffType[]) => dispatch(changeStaffTypes(s)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDayOff[]) => dispatch(changeTypesOfDayOff(t)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
    enabledIntegrationsFetched: () => dispatch(enabledIntegrationsFetched({})),
    newsFetched: () => dispatch(newsFetched({ forceReload: true }))
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    pois: state.location.pois,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(injectIntl(SplashScreen)));

