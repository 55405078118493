import { Space, Spin, Tabs } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Rules } from '../../../rbacRules';
import { changeGroup, changeGroupsUsers, resetAllAppartGroups } from '../../../store/actions/teamManagement';
import Network from '../../../utils/network';
import { Group, GroupUser, RouterProps, User, UserSummary } from '../../../utils/types/generalTypes';
import { PlanningExclusion, PlanningPeriod } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { alert, convertNetworkExclusionsToPlanningExclusions, convertNetworkPeriodsToPlanningPeriods, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import Can from '../../common/general/can';
import Container from '../../common/navigations/container';
import EditGroupTab from './editGroupTab';

//Keys for the different tabs
enum TabKeys {
    Informations = "i",
    Periods = "p",
    Exclusions = "e",
}

//Links for the different tabs
enum TabLink {
    Informations = "informations",
    Periods = "periods",
    Exclusions = "exclusions",
}

interface IProps {
    group: Group | undefined;
    currentUser?: User;
    groupUsers: UserSummary[] | undefined;
    periods: PlanningPeriod[] | undefined;
    isSmartphone: boolean;
}

type Props = IProps & RouterProps & TeamManagementDispatchProps & IntlProps;

interface State {
    groupId: number;
    periods: PlanningPeriod[] | undefined;
    exclusions: PlanningExclusion[] | undefined;
    periodsLoading: boolean;
    exclusionsLoading: boolean;
}

/**
 * Page for group details
 */
class GroupDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let groupId = parseInt(this.props.location.search.replace("?id=", ""));

        if (isNaN(groupId)) {
            const index = this.props.location.pathname.indexOf("?id=") + 4;
            groupId = parseInt(this.props.location.pathname.slice(index));
        }

        this.state = {
            groupId,
            periods: undefined,
            exclusions: undefined,
            periodsLoading: false,
            exclusionsLoading: false,
        };
    }

    componentDidMount() {
        //redirect if no group id was found
        if (isNaN(this.state.groupId)) this.props.history.push(`/${this.props.match.params.lang}/team-management/list`);

        //get the current group and it's periods and exclusions
        this.refresh();
        this.refreshGroupUsers();
        this.setState({ periodsLoading: true, exclusionsLoading: true });
        this.refreshPeriods(() => this.setState({ periodsLoading: false }), () => this.setState({ periodsLoading: false }));
        this.refreshExclusions(() => this.setState({ exclusionsLoading: false }), () => this.setState({ exclusionsLoading: false }));
    }

    componentWillUnmount() {
        //reset team management stored value
        // this.props.reset!();
    }

    /**
     * Get the current group
     * @param message a message to display as a success message - optional
     */
    refresh = (message?: string) => {
        Network.getGroups(this.state.groupId).then(
            response => {
                if (response.length > 0) {
                    this.props.changeGroup!(response[0]);
                    if (message) alert(message, "success");
                } else this.props.history.push(`/${this.props.match.params.lang}/team-management/list`);
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the group' }), "warning")
        );
    };

    /**
     * Refresh group's users
     */
    refreshGroupUsers = () => {
        Network.getGroupUsers(this.state.groupId).then(
            response => {
                const groupUsers: UserSummary[] = response.map((g: GroupUser) => {
                    return {
                        id: g.user?.id,
                        username: g.user?.username,
                        first_name: g.user?.first_name,
                        last_name: g.user?.last_name,
                        image: g.user?.image,
                        email: g.user?.email,
                        role: g.user?.role,
                        is_admin: g.is_admin,
                    };
                });
                this.props.changeGroupsUsers!(groupUsers);
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
        );
    };

    /**
     * Refresh the periods
     * @param successCallback a callback function in case of successful network refresh call - optional
     * @param failedCallback a callback function in case of failed network refresh call - optional
     */
    refreshPeriods = (successCallback?: () => void, failedCallback?: () => void): void => {
        //get current group's periods
        if (this.state.groupId !== undefined && this.state.groupId !== null) {
            Network.getPeriods(undefined, this.state.groupId).then(
                response => {
                    this.setState({ periods: convertNetworkPeriodsToPlanningPeriods(response) });
                    if (successCallback) successCallback();
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning");
                    if (failedCallback) failedCallback();
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning");
        }
    };

    /**
     * Refresh the exclusions
     * @param successCallback a callback function in case of successful network refresh call - optional
     * @param failedCallback a callback function in case of failed network refresh call - optional
     */
    refreshExclusions = (successCallback?: () => void, failedCallback?: () => void) => {
        //get current user's exclusions
        if (!this.state.groupId !== undefined && this.state.groupId !== null) {
            Network.getExclusions(undefined, this.state.groupId).then(
                response => {
                    this.setState({ exclusions: convertNetworkExclusionsToPlanningExclusions(response) });
                    if (successCallback) successCallback();
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the exclusions' }), "warning");
                    if (failedCallback) failedCallback();
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the exclusions' }), "warning");
        }
    };

    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        const { tab } = this.props.match.params;
        if (tab) {
            switch (tab) {
                case TabLink.Periods:
                    return TabKeys.Periods;
                case TabLink.Exclusions:
                    return TabKeys.Exclusions;
                default:
                    return TabKeys.Informations;
            }
        } else {
            return TabKeys.Informations;
        }
    };

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (key) {
            case TabKeys.Periods:
                link = TabLink.Periods;
                break;
            case TabKeys.Exclusions:
                link = TabLink.Exclusions;
                break;
            default:
                link = TabLink.Informations;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/team-management/group-details/${link}?id=${this.state.groupId}`);
    };

    /**
     * Called when the group users is edited
     */
    onEdit = () => {
        this.refresh();
        this.refreshGroupUsers();
    };

    render() {
        const { group, intl } = this.props;

        const currentTab = this.getCurrentTab();
        return (
            <Can rule={Rules.TeamManagement.Visit} redirect="/dashboard">
                <Container breadcrumb={[
                    { title: intl.formatMessage({ defaultMessage: 'Team management' }), link: "/team-management/list" },
                    { title: intl.formatMessage({ defaultMessage: 'Groups' }), link: "/team-management/list/groups" },
                    { title: group?.id ? `${group.name}` : intl.formatMessage({ defaultMessage: 'No group' }), link: `/team-management/group-details/informations?id=${this.state.groupId}` }
                ]}>
                    <div className="team-parent">
                        {
                            this.props.group ?
                                <Tabs
                                    tabBarExtraContent={this.props.isSmartphone ? undefined :
                                        {
                                            left: (
                                                <div style={{ marginRight: '10px' }}>
                                                    <FAIcon prefix='fad' name='arrow-left' style={{ cursor: 'pointer' }} onClick={() => this.props.history.replace(`/${this.props.match.params.lang}/team-management/list/groups`)} />
                                                </div>
                                            ),
                                        }
                                    }
                                    animated onChange={this.onChangeTab} activeKey={currentTab}>
                                    <Tabs.TabPane key={TabKeys.Informations} tab={
                                        <Space>
                                            <FAIcon prefix='fad' name='list-ul' />
                                            <FormattedMessage defaultMessage={'Informations'} />
                                        </Space>
                                    }>
                                        <EditGroupTab onEdit={this.onEdit} />
                                    </Tabs.TabPane>
                                </Tabs>
                                :
                                <Spin className="team-loading-spin" size="large" />
                        }
                    </div>
                </Container>
            </Can >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeGroup: (g: Group) => dispatch(changeGroup(g)),
    changeGroupsUsers: (u: UserSummary[] | undefined) => dispatch(changeGroupsUsers(u)),
    reset: () => dispatch(resetAllAppartGroups()),
});

const mapStateToProps = (state: ApplicationState) => ({
    group: state.teamManagement.group,
    groupUsers: state.teamManagement.groupsUsers,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GroupDetails));
