import { Button, Card, Checkbox, Col, DatePicker, Input, InputNumber, List, Modal, Row, Segmented, Select, SelectProps, Space, Spin, Table, Tabs, Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import ceil from 'lodash/ceil';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import VirtualList from 'rc-virtual-list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeSMSAccount } from '../../../store/actions/messages';
import '../../../styles/sms.css';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { EditSMSTemplate, FullSMSSent, Lang, SMSBlacklist, SMSContact, SMSSend, SMSTemplate, SMSTemplateFields, SMSTransfert } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import SMSSent from '../../messages/tabs/smsSent';

// interface IProps {
// }

//type Props = IProps & UserDispatchProps;

const SMS = () => {
    // const dispatch = useDispatch();

    const [sms, setSMS] = useState<SMSSend>({ message: "", to: [], type: "alert", links: [] });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCreditModal, setShowCreditModal] = useState<boolean>(false);
    const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
    const [template, setTemplate] = useState<EditSMSTemplate>({ id: undefined, name: "", marketing: false, type: "", simulate: true, content: [] });
    const [delayChecked, setDelayChecked] = useState<boolean>(false);
    const [selectedSMSSent, setSelectedSMSSent] = useState<FullSMSSent | undefined>(undefined);
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("sent");

    const [companyUsers, setCompanyUsers] = useState<SMSContact[]>([]);
    const [contacts, setContacts] = useState<SMSContact[]>([]);
    const [contactsLoading, setContactsLoading] = useState<boolean>(true);
    const [companyUsersLoading, setCompanyUsersLoading] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<string[]>([]);

    const [companyCustomers, setCompanyCustomers] = useState<SMSContact[]>([]);
    const [companyCustomersLoading, setCompanyCustomersLoading] = useState<boolean>(true);

    const [amount, setAmount] = useState<number | null>(0);
    const [thresholdLimit, setThresholdLimit] = useState<number | null>(50);
    const [options, setOptions] = useState<SelectProps['options']>([]);

    const [languageLoading, setLanguageLoading] = useState<boolean>(true);
    const [lang, setLang] = useState<string>("fr");
    const [languages, setLanguages] = useState<Lang[]>([]);
    const [templateFieldsLoading, setTemplateFieldsLoading] = useState<boolean>(true);
    const [templateFields, setTemplateFields] = useState<SMSTemplateFields[]>([]);
    const [smsSending, setSMSSending] = useState<boolean>(false);
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const smsAccount = useSelector((state: ApplicationState) => state.messages.smsAccount);
    const smsAccountLoading = useSelector((state: ApplicationState) => state.messages.smsAccountLoading);
    const width = useSelector((state: ApplicationState) => state.window.width);
    const dispatch = useDispatch();
    const canHaveBCSMS = useMemo(() => currentUser?.company_detail?.ext, [currentUser?.company_detail?.ext]);
    const intl = useIntl();

    const canHaveBCTemplate = useMemo(() => {
        if (!currentUser?.company_detail?.ext) return false;
        if (smsAccount === undefined) return false;
        if (template.type === "RE") return true;
        return !smsAccount.template.some((t) => t.type === "RE" && t.id !== template.id);
    }, [currentUser?.company_detail?.ext, smsAccount, template]);

    // const onChangeSelectedValue = (keys: React.Key[]) => setSelectedValues(keys as number[]);

    const onChangeTab = useCallback((tab: string): void => {
        // let link = "";
        switch (tab) {
            case "blacklist":
                setSelectedTab("blacklist");
                break;
            case "transfert":
                setSelectedTab("transfert");
                break;
            case "template":
                setSelectedTab("template");
                break;
            default:
                setSelectedTab("sent");
        }
        setSelectedValues([]);
    }, []);

    const getLanguages = useCallback(() => {
        setLanguageLoading(true);
        Network.getLanguages().then(
            response => {
                setLanguages(response);
                setLanguageLoading(false);

            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the languages' }), "error")
        );
    }, [intl]);
    const getTemplateFields = useCallback(() => {
        setTemplateFieldsLoading(true);
        Network.getSMSTemplateFields().then(
            response => {
                setTemplateFields(response);
                setTemplateFieldsLoading(false);

            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the automatic fields' }), "error")
        );
    }, [intl]);
    const reloadBlacklist = useCallback((force = false) => {
        if (smsAccount) {
            Network.reloadSMSBlacklist(force).then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.blacklist = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the blacklist SMS list' }), "error")
            );
        }
    }, [smsAccount, dispatch, intl]);

    const reloadTemplate = useCallback(() => {
        if (smsAccount) {
            Network.getSMSTemplate().then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.template = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the SMS templates' }), "error")
            );
        }
    }, [dispatch, intl, smsAccount]);
    const reloadSent = useCallback(() => {
        if (smsAccount) {
            Network.getSMSSent().then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.sent = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the sent SMS' }), "error")
            );
        }
    }, [dispatch, intl, smsAccount]);
    const reloadTransfert = useCallback(() => {
        if (smsAccount) {
            Network.getTransfert().then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.transfert = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the credit transfers' }), "error")
            );
        }
    }, [dispatch, intl, smsAccount]);

    const getContacts = useCallback((type = "customer") => {
        switch (type) {
            case "customer":
                setCompanyCustomersLoading(true);

                Network.getContacts("customer").then(
                    response => {
                        setCompanyCustomers(response);
                        setCompanyCustomersLoading(false);


                    },
                    () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contacts' }), "error")
                );
                break;
            case "internal":
                setCompanyUsersLoading(true);
                Network.getContacts("internal").then(
                    response => {
                        setCompanyUsers(response);
                        setCompanyUsersLoading(false);
                    },
                    () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contacts' }), "error")
                );
                break;
            default:
                setContactsLoading(true);
                Network.getContacts("contact").then(
                    response => {
                        setContacts(response);
                        setContactsLoading(false);
                    },
                    () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contacts' }), "error")
                );
                break;
        }
    }, [intl]);
    const openModal = useCallback(() => {
        setShowModal(true);
        setSMS({ message: "", to: [], type: "alert", links: [], delay: undefined, simulate: true });
        setDelayChecked(false);
        getContacts("customer");
        getContacts("internal");
        getContacts("contact");
        setSelectedUser([]);

    }, [getContacts]);

    const openCreditModal = useCallback(() => {
        setShowCreditModal(true);
        setThresholdLimit(50);
        setAmount(0);
    }, []);

    const openTemplateModal = useCallback((template: SMSTemplate | undefined) => {
        if (template === undefined) {
            setTemplate({ marketing: false, simulate: true, type: "BA", content: [] });
        } else {
            setTemplate(template);
        }
        setLang("fr");
        setShowTemplateModal(true);

    }, []);

    const saveTemplate = useCallback(() => {
        if (smsAccount !== undefined) {
            Network.saveSMSTemplate(template).then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.template = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                    setShowTemplateModal(false);
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the template' }), "error")
            );
        }
    }, [dispatch, intl, smsAccount, template]);

    const deleteTemplate = useCallback(() => {
        if (smsAccount !== undefined) {
            Network.deleteSMSTemplate(selectedValues).then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.template = response;
                    dispatch(changeSMSAccount(newSmsAccount));
                    setShowTemplateModal(false);
                    setSelectedValues([]);
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the template' }), "error")
            );
        }
    }, [dispatch, intl, selectedValues, smsAccount]);

    const sendSMS = useCallback(() => {
        if (smsAccount === undefined) return false;
        setSMSSending(true);

        const to: string[] = [];
        selectedUser.forEach((value) => {
            if (value.startsWith('cl_')) {
                companyCustomers.forEach((c) => {
                    if (c.id.toString() === value.split('_')[1]) {
                        to.push(c.contact[0].mobile);
                    }
                });

            } else if (value.startsWith('co')) {
                contacts.forEach((c) => {
                    if (c.id.toString() === value.split('_')[1]) {
                        to.push(c.contact[0].mobile);
                    }
                });

            } else if (value.startsWith('us_')) {
                companyUsers.forEach((c) => {
                    if (c.id.toString() === value.split('_')[1]) {
                        to.push(c.contact[0].mobile);
                    }
                });
            } else {
                to.push(value);
            }
        });
        sms.to = to;

        const urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig);
        const splittedValue = sms.message.split(/(\s+)/);
        // let newCount = 0;
        let newString = '';
        for (let i = 0; i < splittedValue.length; i++) {
            if (i % 2 != 1) {
                if (splittedValue[i].match(urlRegex)) {
                    // newCount += 18;
                    newString += '<-short->';
                    sms.links.push(splittedValue[i]);
                } else {
                    // newCount += splittedValue[i].length;
                    newString += splittedValue[i];
                }
            } else {
                // newCount += String(splittedValue[i]).length;
                newString += splittedValue[i];
            }
        }
        sms.message = newString;

        if (sms.message === '') {
            showNotification(intl.formatMessage({ defaultMessage: 'You must add a message' }), "warning");
            setSMSSending(false);
            return;
        }
        if (sms.to.length === 0) {
            showNotification(intl.formatMessage({ defaultMessage: 'A phone number is required' }), "warning");
            setSMSSending(false);
            return;
        }

        Network.sendSMS(sms).then(
            response => {
                const newSmsAccount = cloneDeep(smsAccount);
                newSmsAccount.sent = response;
                dispatch(changeSMSAccount(newSmsAccount));
                setShowModal(false);
                setSMSSending(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while sending the SMS' }), "error");
                setSMSSending(false);
            }
        );
    }, [companyCustomers, companyUsers, contacts, dispatch, intl, selectedUser, sms, smsAccount]);

    const getMoreCredit = useCallback(() => {
        if (smsAccount !== undefined) {
            Network.getMoreCredit(amount ?? 0, thresholdLimit ?? 0).then(
                response => {
                    const newSmsAccount = cloneDeep(smsAccount);
                    newSmsAccount.credit = response.credit;
                    newSmsAccount.thresholdLimit = response.thresholdLimit;
                    dispatch(changeSMSAccount(newSmsAccount));
                    setShowCreditModal(false);
                    reloadTransfert();
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while adding credits' }), "error")
            );
        }

    }, [amount, dispatch, intl, reloadTransfert, smsAccount, thresholdLimit]);

    useEffect(() => {
        if (languages.length <= 0) getLanguages();
        if (templateFields.length <= 0) getTemplateFields();
    }, [languages.length, templateFields.length, getLanguages, getTemplateFields]);

    useEffect(() => {
        const opts: SelectProps['options'] = [];
        const clientOptions: SelectProps['options'] = [];
        companyCustomers?.forEach(t => {
            t.contact.forEach(contact => {
                contact.mobile &&
                    clientOptions?.push({
                        value: "cl_" + t.id,
                        label: t.first_name + " " + t.last_name + " " + contact.mobile,
                    });
            });
        });

        opts.push({ label: <FormattedMessage defaultMessage={'Customers'} />, options: clientOptions });

        const contactOptions: SelectProps['options'] = [];

        contacts?.forEach((t) => {
            t.contact.forEach(contact => {
                contact.mobile &&
                    contactOptions?.push({
                        value: "co_" + t.id,
                        label: t.first_name + " " + t.last_name + " " + contact.mobile,
                    });
            });

        });

        opts.push({ label: <FormattedMessage defaultMessage={'Contacts'} />, options: contactOptions });

        const userOptions: SelectProps['options'] = [];
        companyUsers?.forEach(t => {
            t.contact.forEach(contact => {
                contact.mobile &&
                    userOptions?.push({
                        value: "us_" + t.id,
                        label: t.first_name + " " + t.last_name + " " + contact.mobile,
                    });
            });
        });

        opts.push({ label: <FormattedMessage defaultMessage={'Internal users'} />, options: userOptions });
        setOptions(opts);

    }, [companyCustomers, companyUsers, contacts]);

    const SMSTransfertColumns: ColumnProps<SMSTransfert>[] = useMemo(() => [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            width: 100,
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.date).unix() - moment(b.date).unix(),
            },
            render: (ob: SMSTransfert) => {
                return <div className="both-center">{moment(ob.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Credit'} />,
            key: 'credit',
            width: 100,
            render: (ob: SMSTransfert) => {
                return <div className="both-center">{ob.credit}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Paid on'} />,
            key: 'payed_date',
            width: 100,
            render: (ob: SMSTransfert) => {
                return (

                    <>
                        {ob.payed_date ?
                            <div className="both-center"><Tooltip title={ob.payed_date}><FAIcon prefix='fad' name='circle-check' /></Tooltip></div>
                            :
                            <div className="both-center"><FAIcon prefix='fad' name='circle-xmark' /></div>
                        }
                    </>);
            },

        },
    ], []);
    // const SMSTemplateColumns: ColumnProps<SMSTemplate>[] = [
    //     {
    //         title: 'Nom',
    //         key: 'name',
    //         render: (ob: SMSTemplate) => {
    //             return <div className="both-center">{ob.name}</div>
    //         },

    //     },
    //     {
    //         title: 'Type',
    //         key: 'type',
    //         render: (ob: SMSTemplate) => {
    //             return <div className="both-center">{ob.type === 'RE' ? "Rappel automatique" : "Normal"}</div>
    //         },

    //     },
    //     {
    //         title: 'Normal/Marketing',
    //         key: 'marketing',
    //         width: 55,
    //         render: (ob: SMSTemplate) => {
    //             return (
    //                 <>
    //                     {ob.marketing ?
    //                         <div className="both-center"><HiOutlineSpeakerphone /></div>
    //                         :
    //                         <div className="both-center"><BiMessageAltDetail /></div>
    //                     }
    //                 </>
    //             )
    //         },

    //     },
    //     {
    //         title: 'Simulation',
    //         key: 'simulate',
    //         width: 40,
    //         render: (ob: SMSTemplate) => {
    //             return (
    //                 <>
    //                     {ob.simulate ?
    //                         <div className="both-center"><AiFillCheckCircle className='anticon' /></div>
    //                         :
    //                         <div className="both-center"><AiFillCloseCircle className='anticon' /></div>
    //                     }
    //                 </>

    //             )
    //         },

    //     },
    //     ...(canHaveBCSMS() ?
    //         [{

    //             title: 'Rappel',

    //             key: 'type',
    //             width: 30,
    //             render: (ob: SMSTemplate) => {
    //                 return (
    //                     <>
    //                         {ob.type === "RE" ?
    //                             <div className="both-center"><AiFillCheckCircle className='anticon' /></div>
    //                             :
    //                             <div className="both-center"><AiFillCloseCircle className='anticon' /></div>
    //                         }
    //                     </>

    //                 )
    //             },

    //         }] : []),
    //     {
    //         title: 'Langues',
    //         dataIndex: 'content',
    //         key: 'content',
    //         render: (ob: SMSTemplateContent[]) => {
    //             console.log("OB", ob)
    //             return (
    //                 ob !== undefined && ob.map(c => (
    //                     <Tag className="__report-groups-users-tags" color="#f5f5f5" key={c.id} >
    //                         {c.language}
    //                     </Tag>
    //                 ))
    //             )


    //         }

    //     },
    //     {
    //         key: 'modifier',
    //         width: "20px",
    //         render: (ob: SMSTemplate) => {
    //             return (
    //                 <div style={{ textAlign: "right" }}>
    //                     <Space>
    //                         <CircleButton icon={<EditOutlined />} title={"Modifier le template"} onClick={() => openTemplateModal(ob)}></CircleButton>
    //                     </Space>
    //                 </div>
    //             )
    //         }
    //     }

    // ]
    const SMSBlacklistColumns: ColumnProps<SMSBlacklist>[] = useMemo(() => [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            width: 100,
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.date).unix() - moment(b.date).unix(),
            },
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{moment(ob.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Phone'} />,
            key: 'phone',
            width: 60,
            onFilter: (value, record) => {
                return record.phone!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: SMSBlacklist, b: SMSBlacklist) => a.phone < b.phone ? -1 : 1,
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.phone}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Message'} />,
            key: 'message',
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.message}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 1'} />,
            key: 'info1',
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.info1}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 2'} />,
            key: 'info2',
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.info2}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 3'} />,
            key: 'info3',
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.info3}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 4'} />,
            key: 'info4',
            render: (ob: SMSBlacklist) => {
                return <div className="both-center">{ob.info4}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'STOP reply'} />,
            key: 'sms_sent_id',
            width: 85,
            render: (ob: SMSBlacklist) => {
                return (
                    <>
                        {ob.sms_sent_id ?
                            <div className="both-center"><FAIcon prefix='fad' name='circle-check' /></div>
                            :
                            <div className="both-center"><FAIcon prefix='fad' name='circle-xmark' /></div>
                        }
                    </>

                );
            },

        },
    ], []);

    const SMSSentColumns: ColumnProps<FullSMSSent>[] = useMemo(() => [
        {
            title: <FormattedMessage defaultMessage={'Status'} />,
            key: 'status',
            width: 35,
            render: (ob: FullSMSSent) => {
                return (
                    <>
                        {(ob.status === undefined || ob.status === null) ?
                            <Tooltip title={<div><span style={{ fontWeight: "bold" }}><FormattedMessage defaultMessage={'INFORMATION'} /></span><p><FormattedMessage defaultMessage={'The SMS has probably been sent'} /></p></div>} ><div className="both-center"><FAIcon prefix='fad' name='circle-info' /></div></Tooltip>
                            :
                            <>
                                {ob.status.code === 1 || ob.status.code === 3 ?
                                    <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{ob.status.message}</span><p>{ob.status.description}</p></div>} ><div className="both-center"><FAIcon prefix='fad' name='circle-check' /></div></Tooltip>
                                    :
                                    <>
                                        {ob.status.code === 2 || ob.status.code === 4 || ob.status.code === 5 || ob.status.code === 6 || ob.status.code === 7 || ob.status.code === 9 || ob.status.code === 11 || ob.status.code === 12 ?
                                            <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{ob.status.message}</span><p>{ob.status.description}</p></div>} ><div className="both-center"><FAIcon prefix='fad' name='circle-xmark' /></div></Tooltip>
                                            :
                                            <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{ob.status.message}</span><p>{ob.status.description}</p></div>} ><div className="both-center"><FAIcon prefix='fad' name='circle-question' /></div></Tooltip>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                );
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            width: 45,
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.date).unix() - moment(b.date).unix(),
            },
            render: (ob: FullSMSSent) => {
                return <div>{moment(ob.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Sender'} />,
            width: 45,
            key: 'sender',
            onFilter: (value, record) => {
                return record.sender!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: FullSMSSent, b: FullSMSSent) => a.sender < b.sender ? -1 : 1,
            render: (ob: FullSMSSent) => {
                return <div>{ob.sender}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Recipient'} />,
            key: 'to',
            width: 40,
            onFilter: (value, record) => {
                return record.to!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: FullSMSSent, b: FullSMSSent) => a.to < b.to ? -1 : 1,
            render: (ob: FullSMSSent) => {
                return <div>{ob.to}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Message'} />,
            key: 'text',
            render: (ob: FullSMSSent) => {
                return <div style={{ whiteSpace: 'pre-wrap', overflowX: "auto", wordWrap: "break-word" }}>{ob.text}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            key: 'marketing',
            width: 20,
            render: (ob: FullSMSSent) => {
                return (
                    <>
                        {ob.marketing ?
                            <div className="both-center"><FAIcon prefix='fad' name='megaphone' /></div>
                            :
                            <div className="both-center"><FAIcon prefix='fad' name='message' /></div>
                        }
                    </>
                );
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Links'} />,
            key: 'links',
            width: 25,
            render: (ob: FullSMSSent) => {
                return <div className="both-center">{ob.links ? ob.links.length : 0}</div>;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Simulation'} />,
            key: 'simulate',
            width: 30,
            render: (ob: FullSMSSent) => {
                return (
                    <>
                        {ob.simulate ?
                            <div className="both-center"><FAIcon prefix='fad' name='circle-check' /></div>
                            :
                            <div className="both-center"><FAIcon prefix='fad' name='circle-xmark' /></div>
                        }
                    </>

                );
            },

        },
    ], []);

    const template_content = (template.content !== undefined ? template.content.find(tc => tc.language === lang) : false) || { id: undefined, language: lang, text: "" };
    if (smsAccount === undefined) {
        return <div>
            <Spin
                style={{ marginTop: '20px', textAlign: "center", display: "block" }}
                size="large"
                spinning={smsAccountLoading}
            />
        </div>;
    }
    return (
        <>
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                    {/* <Card
                        title={"Compte de SMS"}
                        style={props.smsLoading ? { textAlign: "center" } : { textAlign: "inherit" }}
                        extra={[
                            <Space key={`sms-account-card-extras`}>
                                <CircleButton
                                    small={false}
                                    icon={<SendOutlined />}
                                    title={"Envoyer un SMS"}
                                    onClick={() => openModal()} />
                                {!smsAccount.unlimited &&
                                    <CircleButton
                                        small={false}
                                        icon={<PlusOutlined />}
                                        title={"Ajouter du crédit"}
                                        onClick={() => openCreditModal()} />
                                }
                            </Space>
                        ]}
                    > */}
                    <>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                <Card
                                    title={smsAccount.active ? <span><FAIcon prefix='fad' name='circle-check' /> {smsAccount.sender}</span> : <span><FAIcon prefix='fad' name='circle-xmark' /> {smsAccount.sender}</span>}
                                    style={smsAccountLoading ? { textAlign: "center", height: width < 1600 ? 120 : 280 } : { textAlign: "inherit", minHeight: width < 1600 ? 130 : 280 }}
                                    extra={[
                                    ]}
                                >
                                    {(smsAccount.lastname !== undefined && smsAccount.lastname !== null && smsAccount.lastname !== "") || (smsAccount.firstname !== undefined && smsAccount.firstname !== null && smsAccount.firstname !== "") ? <div className="sms-account-details"><span className="sms-account-details-title"><FAIcon prefix='fad' name='user' /> </span><span className="sms-account-details-content ">{smsAccount.firstname + " " + smsAccount.lastname}</span></div> : null}
                                    {smsAccount.adress !== undefined && smsAccount.adress !== null && smsAccount.adress !== "" ? <div className="sms-account-details"><span className="sms-account-details-title"><FAIcon prefix='fad' name='house' /> </span><span className="sms-account-details-content ">{smsAccount.adress}</span></div> : null}
                                    {(smsAccount.city !== undefined && smsAccount.city !== null && smsAccount.city !== "") || (smsAccount.zip !== undefined && smsAccount.zip !== null && smsAccount.zip !== "") ? <div className="sms-account-details"><span className="sms-account-details-title"><FAIcon prefix='fad' name='city' /> </span><span className="sms-account-details-content ">{smsAccount.zip + " " + smsAccount.city}</span></div> : null}
                                    {smsAccount.country !== undefined && smsAccount.country !== null && smsAccount.country !== "" ? <div className="sms-account-details"><span className="sms-account-details-title"><FAIcon prefix='fad' name='flag' /> </span><span className="sms-account-details-content ">{smsAccount.country}</span></div> : null}
                                    {smsAccount.phone !== undefined && smsAccount.phone !== null && smsAccount.phone !== "" ? <div className="sms-account-details"><span className="sms-account-details-title"><FAIcon prefix='fad' name='phone' /> </span><span className="sms-account-details-content ">{smsAccount.phone}</span></div> : null}

                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 12 }}>
                                <Row style={{ textAlign: "center" }} gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Card
                                            title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Credits'} /></span>}
                                            extra={[
                                            ]}
                                        >
                                            <div><span>{smsAccount.unlimited ? "Pas de limite de crédit" : smsAccount.credit}</span></div>
                                        </Card>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Card
                                            title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'SMS Simulated / Sent'} /></span>}
                                            extra={[
                                            ]}
                                        >
                                            <div><span>{smsAccount.sent.filter((sent) => sent.simulate).length + " / " + smsAccount.sent.filter((sent) => !sent.simulate).length}</span></div>
                                        </Card>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Card
                                            title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Blacklist'} /></span>}
                                            style={{}}
                                            extra={[
                                            ]}
                                        >
                                            <div><span>{smsAccount.blacklist.length}</span></div>
                                        </Card>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Card
                                            title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Limit before warning'} /></span>}
                                            style={{}}
                                            extra={[
                                            ]}
                                        >
                                            <div><span>{smsAccount.unlimited ? <FormattedMessage defaultMessage={'No warning'} /> : smsAccount.thresholdLimit}</span></div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    {/* </Card> */}
                </Col>
                <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                    <Tabs
                        // centered={false}
                        animated
                        type={"card"}
                        tabPosition={'top'}
                        tabBarExtraContent={
                            <div style={{ width: "150px", textAlign: "right" }}>
                                <Space align={"start"} >
                                    {selectedTab === "template" && selectedValues.length > 0 &&
                                        <DeleteButton
                                            text={<FormattedMessage defaultMessage={'Are you sure you want to delete this template?'} />}
                                            title={intl.formatMessage({ defaultMessage: 'Delete a template' })}
                                            okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                                            cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                                            onConfirm={() => deleteTemplate()}
                                            placement="left"
                                        />
                                    }
                                    {((selectedTab === "transfert" && !smsAccount.unlimited) || selectedTab === "sent" || selectedTab === "template") &&
                                        <CircleButton
                                            placement={'left'}
                                            small
                                            icon={<FAIcon prefix='far' name='plus' />}
                                            title={selectedTab === "sent" ? intl.formatMessage({ defaultMessage: 'Send an SMS' }) : selectedTab === 'transfert' ? intl.formatMessage({ defaultMessage: 'Add credits' }) : intl.formatMessage({ defaultMessage: 'Add template' })}
                                            onClick={selectedTab === "sent" ? () => openModal() : selectedTab === "transfert" ? () => openCreditModal() : () => openTemplateModal(undefined)}
                                        />
                                    }
                                    <CircleButton
                                        placement={'left'}
                                        small
                                        icon={<FAIcon prefix='fad' name='rotate' />}
                                        title={selectedTab === "blacklist" ? intl.formatMessage({ defaultMessage: 'Refresh blacklist' }) : selectedTab === 'sent' ? intl.formatMessage({ defaultMessage: 'Refresh sent items' }) : selectedTab === 'transfert' ? intl.formatMessage({ defaultMessage: 'Refresh transfer list' }) : intl.formatMessage({ defaultMessage: 'Refresh template list' })}
                                        onClick={selectedTab === "blacklist" ? () => reloadBlacklist(true) : selectedTab === "sent" ? () => reloadSent() : selectedTab === "transfert" ? () => reloadTransfert() : () => reloadTemplate()}
                                    />
                                </Space>
                            </div>
                        }
                        onChange={onChangeTab}
                        activeKey={selectedTab}
                    // className="sms-list-table-parent"
                    >
                        <Tabs.TabPane key="sent" tab={
                            <Space>
                                <FAIcon prefix='fad' name='share' />
                                <FormattedMessage defaultMessage={'SMS sent'} />
                            </Space>
                        }>
                            <Table
                                //tableLayout={"fixed"}
                                onRow={(record) => {
                                    return {
                                        onClick: () => { setSelectedSMSSent(record); }, // click row
                                    };
                                }}
                                className={"__logistic-object-value-list"}
                                rowKey={(i: FullSMSSent) => i.id !== undefined ? i.id : -1}
                                dataSource={smsAccount.sent}
                                columns={SMSSentColumns}
                                pagination={{
                                    defaultPageSize: 8,
                                    pageSizeOptions: ["8", "16", "32", "64", "72"],
                                    showSizeChanger: true,
                                    hideOnSinglePage: true,
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} SMS'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="blacklist" tab={
                            <Space>
                                <FAIcon prefix='fad' name='xmark-to-slot' />
                                <FormattedMessage defaultMessage={'Blacklisted numbers'} />
                            </Space>
                        }>
                            <Table
                                //tableLayout={"fixed"}
                                className={"__logistic-object-value-list"}
                                rowKey={(i: SMSBlacklist) => i.id !== undefined ? i.id : -1}
                                dataSource={smsAccount.blacklist}
                                columns={SMSBlacklistColumns}
                                pagination={{
                                    defaultPageSize: 8,
                                    pageSizeOptions: ["8", "16", "32", "64", "72"],
                                    showSizeChanger: true,
                                    hideOnSinglePage: true,
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} numbers'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="transfert" tab={
                            <Space>
                                <FAIcon prefix='fad' name='coins' />
                                <FormattedMessage defaultMessage={'Transfers (credits)'} />
                            </Space>
                        }>
                            <Table
                                // tableLayout={"fixed"}
                                className={"__logistic-object-value-list"}
                                rowKey={(i: SMSTransfert) => i.id !== undefined ? i.id : -1}
                                dataSource={smsAccount.transfert}
                                columns={SMSTransfertColumns}
                                pagination={{
                                    defaultPageSize: 8,
                                    pageSizeOptions: ["8", "16", "32", "64", "72"],
                                    showSizeChanger: true,
                                    hideOnSinglePage: true,
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} transfers'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            />
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane key="template" tab={
                            <span>
                                <InboxOutlined className='anticon' />
                                {"Modèles"}
                            </span>
                        }>
                            <Table
                                tableLayout={"fixed"}
                                className={"__logistic-object-value-list"}
                                rowKey={(i: SMSTemplate) => i.id !== undefined ? i.id : -1}
                                dataSource={smsAccount.template}
                                columns={SMSTemplateColumns}
                                rowSelection={{ type: 'checkbox', onChange: onChangeSelectedValue, selectedRowKeys: selectedValues }}
                                pagination={{
                                    defaultPageSize: 8,
                                    pageSizeOptions: ["8", "16", "32", "64", "72"],
                                    showSizeChanger: true,
                                    hideOnSinglePage: true,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} valeures`
                                }}
                            />
                        </Tabs.TabPane> */}
                    </Tabs>



                </Col>
            </Row>
            {selectedSMSSent && <SMSSent sms={selectedSMSSent} setSelectedSMSSent={setSelectedSMSSent} />}
            <Modal
                title={<FormattedMessage defaultMessage={'Send an SMS'} />}
                width={"800px"}
                open={showModal}
                onOk={sendSMS}
                onCancel={() => setShowModal(false)}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={() => setShowModal(false)} key="sms-sent-button-cancel">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <Button type="primary" disabled={smsSending} onClick={sendSMS} key="sms-sent-button-ok">
                        <FormattedMessage defaultMessage={'Send'} />
                    </Button>
                ]}>
                <Row gutter={[25, 25]}>
                    <Col xs={{ span: 12 }} xxl={{ span: 12 }}>
                        <Space style={{ width: "100%" }} direction={"vertical"}>
                            <Segmented
                                value={sms.type !== undefined ? sms.type : "alert"}
                                options={[
                                    {
                                        label: intl.formatMessage({ defaultMessage: 'Alert (normal)' }),
                                        value: "alert"
                                    },
                                    {
                                        label: intl.formatMessage({ defaultMessage: 'Marketing (advertising)' }),
                                        value: "marketing"
                                    }
                                ]}
                                onChange={(e) => {
                                    const to_change_sms = cloneDeep(sms);
                                    to_change_sms.type = e as string;
                                    setSMS(to_change_sms);
                                }
                                }
                            />
                            <Checkbox
                                checked={sms.simulate !== undefined ? sms.simulate : false}
                                onChange={(e) => {
                                    const to_change_sms = cloneDeep(sms);
                                    to_change_sms.simulate = e.target.checked;
                                    setSMS(to_change_sms);
                                }
                                }
                            >
                                <FormattedMessage defaultMessage={'Simulate shipment'} />
                            </Checkbox>
                            <Select
                                loading={(contactsLoading || companyCustomersLoading || companyUsersLoading)}
                                mode="tags"
                                style={{ width: '100%' }}
                                options={options}
                                value={selectedUser}
                                maxTagCount={'responsive'}
                                defaultActiveFirstOption={false}
                                optionFilterProp={"value"}
                                tokenSeparators={[',', ';', ' ']}
                                placeholder={<FormattedMessage defaultMessage={'Phone number (recipient)'} />}
                                onChange={(values: string[]) => {
                                    setSelectedUser(values);
                                }
                                }
                            />
                            <Input.TextArea
                                value={sms.message}
                                placeholder={intl.formatMessage({ defaultMessage: 'Message' })}
                                showCount={{
                                    formatter: (arg) => {
                                        const urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig);
                                        const splittedValue = arg.value.split(/(\s+)/);
                                        let newCount = 0;
                                        for (let i = 0; i < splittedValue.length; i++) {
                                            if (i % 2 != 1) {
                                                if (splittedValue[i].match(urlRegex)) {
                                                    newCount += 18;
                                                    // sms.links.push(splittedValue[i])
                                                } else {
                                                    newCount += splittedValue[i].length;
                                                }
                                            } else {
                                                newCount += String(splittedValue[i]).length;
                                            }
                                        }
                                        const newNrOfSMS = ceil(newCount / 160);
                                        return `${newCount} / ${newNrOfSMS > 0 ? `${160 * newNrOfSMS}` : 160} = ${newNrOfSMS}$`;
                                    }
                                }}
                                maxLength={2500}
                                style={{ height: "100px", maxHeight: "300px" }}
                                autoSize={true}
                                onChange={(e) => {
                                    const to_change_sms = cloneDeep(sms);
                                    to_change_sms.message = e.target.value;
                                    setSMS(to_change_sms);
                                }
                                }
                            />

                        </Space>
                    </Col>
                    <Col xs={{ span: 12 }} xxl={{ span: 12 }}>
                        <Space style={{ width: "100%" }} direction={"vertical"}>
                            <Checkbox
                                checked={delayChecked}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        const to_change_sms = cloneDeep(sms);
                                        to_change_sms.delay = undefined;
                                        setSMS(to_change_sms);
                                    }
                                    setDelayChecked(e.target.checked);
                                }}
                            >
                                <FormattedMessage defaultMessage={'Set a date and time for sending'} />
                            </Checkbox>
                            <DatePicker
                                disabled={!delayChecked}
                                format={getFormat('DATE_AND_TIME')}
                                style={{ width: "100%" }}
                                showTime
                                value={sms.delay !== undefined ? moment(sms.delay) : sms.delay}
                                onChange={(e) => {
                                    const to_change_sms = cloneDeep(sms);
                                    to_change_sms.delay = e?.format("YYYY-MM-DDTHH:mm:ss");
                                    setSMS(to_change_sms);
                                }}
                            />
                            {/* <Divider />
                            <Input
                                value={linkValue}
                                placeholder={"https://"}
                                style={{ paddingRight: "3px" }}
                                suffix={
                                    <CircleButton
                                        small={true}
                                        icon={<PlusCircleOutlined />}
                                        title={"Ajouter un lien"}
                                        onClick={() => {
                                            if (linkValue !== '') {
                                                const to_change_sms = cloneDeep(sms)
                                                to_change_sms.links.push(linkValue)
                                                setSMS(to_change_sms)
                                                setLinkValue("")
                                            }

                                        }
                                        }
                                    />
                                }
                                onChange={(e) => {
                                    setLinkValue(e.target.value)
                                }
                                }
                            />

                            {sms.links.map((link: string, index: number) => {
                                return (
                                    <Input
                                        key={`sms-links-${link}-${index}`}
                                        value={link}
                                        placeholder={"https://"}
                                        style={{ paddingRight: "3px" }}
                                        suffix={
                                            <CircleButton
                                                small={true}
                                                icon={<MinusCircleOutlined />}
                                                title={"Supprimer un lien"}
                                                onClick={() => {
                                                    const to_change_sms = cloneDeep(sms)
                                                    to_change_sms.links = to_change_sms.links.filter(url => url !== link)
                                                    setSMS(to_change_sms)
                                                    if (linkValue === "") {
                                                        setLinkValue(link)
                                                    }


                                                }
                                                }
                                            />
                                        }
                                        onChange={(e) => {
                                            const to_change_sms = cloneDeep(sms)
                                            to_change_sms.links[index] = e.target.value
                                            setSMS(to_change_sms)
                                        }
                                        }
                                    />
                                )
                            })} */}
                        </Space>
                    </Col>
                </Row>


            </Modal>
            <Modal
                title={<FormattedMessage defaultMessage={'Add credits'} />}
                width={"300px"}
                visible={showCreditModal}
                onOk={getMoreCredit}
                onCancel={() => setShowCreditModal(false)}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={() => setShowCreditModal(false)} key="sms-sent-button-cancel">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <Button type="primary" onClick={getMoreCredit} key="sms-sent-button-ok">
                        <FormattedMessage defaultMessage={'Add'} />
                    </Button>
                ]}>
                <Space style={{ width: "100%" }} direction={"vertical"}>
                    <p><FormattedMessage defaultMessage={'Number of credits to add'} />
                        <InputNumber

                            value={amount}
                            placeholder={intl.formatMessage({ defaultMessage: 'Credits' })}
                            onChange={setAmount}
                        /></p>
                    <p><FormattedMessage defaultMessage={'Limit before warning'} />
                        <InputNumber

                            value={thresholdLimit}
                            placeholder={"limit"}
                            onChange={setThresholdLimit}
                        /></p>
                </Space>
            </Modal>
            <Modal
                title={template.id === undefined ? <FormattedMessage defaultMessage={'Add a template'} /> : <FormattedMessage defaultMessage={'Edit a template'} />}
                width={"800px"}
                visible={showTemplateModal}
                onOk={saveTemplate}
                onCancel={() => setShowTemplateModal(false)}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={() => setShowTemplateModal(false)} key="sms-sent-button-cancel">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <Button type="primary" onClick={saveTemplate} key="sms-sent-button-ok">
                        <FormattedMessage defaultMessage={'Save'} />
                    </Button>
                ]}>
                <Row gutter={[25, 25]}>
                    <Col xs={{ span: canHaveBCSMS ? 12 : 24 }} xxl={{ span: canHaveBCSMS ? 12 : 24 }}>
                        <Space style={{ width: "100%" }} direction={"vertical"}>
                            <Segmented
                                value={template.marketing ? "marketing" : "alert"}
                                options={[
                                    {
                                        label: intl.formatMessage({ defaultMessage: 'Alert (normal)' }),
                                        value: "alert"
                                    },
                                    {
                                        label: intl.formatMessage({ defaultMessage: 'Marketing (advertising)' }),
                                        value: "marketing"
                                    }
                                ]}
                                onChange={(e) => {
                                    const to_change_template = cloneDeep(template);
                                    to_change_template.marketing = e === "marketing" ? true : false;
                                    setTemplate(to_change_template);
                                }
                                }
                            />
                            <Checkbox
                                checked={template.simulate !== undefined ? template.simulate : false}
                                onChange={(e) => {
                                    const to_change_template = cloneDeep(template);
                                    to_change_template.simulate = e.target.checked;
                                    setTemplate(to_change_template);
                                }
                                }
                            >
                                <FormattedMessage defaultMessage={'Simulate sending'} />
                            </Checkbox>
                            {canHaveBCSMS &&
                                <Checkbox
                                    disabled={!canHaveBCTemplate}
                                    checked={template.type === "RE" ? true : false}
                                    onChange={(e) => {
                                        const to_change_template = cloneDeep(template);
                                        to_change_template.type = e.target.checked ? "RE" : "BA";
                                        setTemplate(to_change_template);
                                    }
                                    }
                                >
                                    <FormattedMessage defaultMessage={'Auto-send template (BC)'} />
                                </Checkbox>
                            }

                            <Input
                                value={template.name}
                                placeholder={intl.formatMessage({ defaultMessage: 'Template name' })}
                                onChange={(e) => {
                                    const to_change_template = cloneDeep(template);
                                    to_change_template.name = e.target.value;
                                    setTemplate(to_change_template);
                                }
                                }
                            />
                            <Select
                                className='course-management-select-bar'
                                allowClear
                                style={{ width: "100%" }}
                                defaultValue={"fr"}
                                loading={languageLoading}
                                placeholder={<FormattedMessage defaultMessage={'Language'} />}
                                onChange={setLang}
                                value={lang}
                                showSearch={true}
                                filterOption={(input, option) => {
                                    if (option !== undefined) {
                                        return (option.code.toLowerCase()).includes(input.toLowerCase()) || (option.name.toLowerCase()).includes(input.toLowerCase());
                                    } else {
                                        return false;
                                    }
                                }}
                                fieldNames={{ label: "name", value: "code" }}
                                options={languages}
                                maxTagCount='responsive'
                            />


                            <Input.TextArea
                                value={template_content.text}
                                placeholder={intl.formatMessage({ defaultMessage: 'Message' })}
                                showCount={{
                                    formatter: (arg) => {
                                        const urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig);
                                        const splittedValue = arg.value.split(/(\s+)/);
                                        let newCount = 0;
                                        for (let i = 0; i < splittedValue.length; i++) {
                                            if (i % 2 != 1) {
                                                if (splittedValue[i].match(urlRegex)) {
                                                    newCount += 18;
                                                    // sms.links.push(splittedValue[i])
                                                } else {
                                                    newCount += splittedValue[i].length;
                                                }
                                            } else {
                                                newCount += String(splittedValue[i]).length;
                                            }
                                        }
                                        const newNrOfSMS = ceil(newCount / 160);
                                        return `${newCount} / ${newNrOfSMS > 0 ? `${160 * newNrOfSMS}` : 160} = ${newNrOfSMS}$`;
                                    }
                                }}
                                maxLength={2500}
                                autoSize={true}
                                onChange={(e) => {
                                    const to_change_template = cloneDeep(template);
                                    let found = false;
                                    to_change_template.content?.forEach((content) => {
                                        if (content.language === lang) {
                                            content.text = e.target.value;
                                            found = true;
                                        }
                                    });
                                    if (!found) {
                                        to_change_template.content?.push({ id: undefined, text: e.target.value, language: lang });
                                    }
                                    setTemplate(to_change_template);
                                }
                                }
                            />
                            <span>
                                <FormattedMessage defaultMessage={'Languages'} />{':'}
                                {template.content?.map((content) => {
                                    return (
                                        <Tag key={`sms-template-content-${content.id}`} style={{ cursor: "pointer" }} onClick={() => setLang(content.language)} icon={content.language === lang && <FAIcon prefix='fad' name='star' />} color={"var(--primary-color)"}>
                                            {content.language}
                                        </Tag>
                                    );
                                })}
                            </span>

                        </Space>
                    </Col>
                    {
                        canHaveBCSMS &&
                        <Col xs={{ span: 12 }} xxl={{ span: 12 }}>
                            {
                                <List
                                    loading={templateFieldsLoading}
                                    header={<div style={{ fontWeight: "bold" }}>Champs automatique</div>}
                                    bordered
                                    size={"small"}
                                >
                                    <VirtualList
                                        data={templateFields}
                                        height={350}
                                        itemHeight={20}
                                        itemKey="name"
                                    >
                                        {(templateField: SMSTemplateFields) => (
                                            <List.Item style={{ cursor: "pointer" }} onClick={() => {
                                                const to_change_template = cloneDeep(template);
                                                const content = to_change_template.content?.find((c) => c.language === lang);
                                                if (content !== undefined) {
                                                    content.text = content.text + " [[" + templateField.name + "]]";
                                                } else {
                                                    to_change_template.content?.push({ id: undefined, text: "[[" + templateField.name + "]]", language: lang });

                                                }
                                                setTemplate(to_change_template);
                                            }}>
                                                {templateField.title}
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            }
                        </Col>
                    }
                </Row>
            </Modal>
        </>
    );
};

export default SMS;