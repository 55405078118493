import React, { CSSProperties } from 'react';

interface Props {
    children: React.ReactNode;
    style?: CSSProperties;
}

/**
 * Component that represent a card
 */
class SimpleBloc extends React.Component<Props> {
    render() {
        return (
            <div style={{ borderRadius: 'var(--border-radius)', padding: '10px', backgroundColor: 'var(--background-color)', ...this.props.style }}>
                {this.props.children}
            </div>
        );
    }
}
export default SimpleBloc;