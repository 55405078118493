import { Col, InputNumber, List, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import toFinite from 'lodash/toFinite';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeTypesOfContract } from '../../../store/actions/configurations';
import Network from '../../../utils/network';
import { TypeOfContract, TypeOfContractWithUpdate } from '../../../utils/types/generalTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

interface iProps {
    typesOfContract: TypeOfContractWithUpdate;
    typeOfContract: TypeOfContract;
    stopEdition: () => void;
}

type Props = iProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    typeOfContract: TypeOfContract | undefined;
}

/**
 * Component that represent a list item for the types Of Contract list
 */
class TypeOfContractItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            typeOfContract: undefined,
        };
    }

    componentDidMount() {
        // if it is a new type of day, set state's type Of Contract
        if (this.props.typeOfContract.id === undefined) {
            this.setState({ typeOfContract: { ...this.props.typeOfContract } });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // when add or edit a type Of Contract, focus the input field-
        if (this.state.typeOfContract !== undefined && prevState.typeOfContract === undefined) {
            const input = document.getElementById(`tod-edit-input-${this.props.typeOfContract.id ? this.props.typeOfContract.id : '0'}`);
            if (input) input.focus();
        }
    }

    /**
     * Change a type Of Contract title
     * @param event the triggered event
     */
    onChangeTitle = (event: InputFieldOnChangeEvent) => {
        const { typeOfContract } = this.state;
        if (typeOfContract) typeOfContract.name = event.target.value;
        this.setState({ typeOfContract });
    };

    /**
     * Change a type Of Contract min hours
     * @param event the triggered event
     */
    // onChangeMinHours = (event: number) => {
    //     let { typeOfContract } = this.state;
    //     if (typeOfContract?.hoursMax !== undefined ) {
    //         if (typeOfContract.hoursMax <= toFinite(event) && typeOfContract?.hoursMax !== -1) {

    //             if (typeOfContract.hoursMax > 100) {
    //                 if (typeOfContract) typeOfContract.hoursMin = typeOfContract.hoursMax - 100;
    //                 this.setState({ typeOfContract });
    //             } else {
    //                 if (typeOfContract) typeOfContract.hoursMin = 0;
    //                 this.setState({ typeOfContract });
    //             }

    //             showNotification("Heures min supérieur aux heures max.", "warning");

    //         } else {
    //             if (typeOfContract) typeOfContract.hoursMin = toFinite(event);
    //             this.setState({ typeOfContract });
    //         }
    //     }
    // }

    onChangeMinHours = (event: number | null) => {
        const { typeOfContract } = this.state;
        if (typeOfContract) typeOfContract.hoursMin = toFinite(event);
        this.setState({ typeOfContract });
    };

    /**
     * Change a type Of Contract max hours
     * @param event the triggered event
     */
    // onChangeMaxHours = (event: number) => {
    //     let { typeOfContract } = this.state;

    //     if (typeOfContract?.hoursMin !== undefined ) {
    //         if (typeOfContract.hoursMin >= toFinite(event) && typeOfContract?.hoursMax !== -1) {


    //             if (typeOfContract) typeOfContract.hoursMax = typeOfContract.hoursMin +100;
    //             this.setState({ typeOfContract });

    //             showNotification("Heures max inférieur aux heures min.", "warning");

    //         } else {
    //             if (typeOfContract) typeOfContract.hoursMax = toFinite(event);
    //             this.setState({ typeOfContract });
    //         }
    //     }

    // }
    onChangeMaxHours = (event: number | null) => {
        const { typeOfContract } = this.state;
        if (typeOfContract) typeOfContract.hoursMax = toFinite(event);
        this.setState({ typeOfContract });
    };


    /**
     * Create or edit the type Of Contract
     */
    onUpdateTypeOfContract = (): void => {
        const { typeOfContract } = this.state;
        const { changeTypesOfContract, intl } = this.props;

        if (typeOfContract?.hoursMax === -1 || typeOfContract?.hoursMin === -1 || typeOfContract?.name === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'Missing data in one or more fields.' }), "warning");
            return;
        }

        if (typeOfContract?.hoursMax && typeOfContract?.hoursMin === undefined || typeOfContract?.hoursMin && typeOfContract?.hoursMax === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'Missing data in one or more fields.' }), "warning");
            return;
        }

        if (typeOfContract?.hoursMax && typeOfContract?.hoursMin && typeOfContract?.hoursMax < typeOfContract?.hoursMin) {
            showNotification(intl.formatMessage({ defaultMessage: 'Max hours cannot be less than Min hours.' }), "warning");
            return;
        }

        if (typeOfContract) {
            Network.updateTypeOfContract({
                ...typeOfContract,
                displayName: typeOfContract.name
            }).then(
                (response) => {
                    const typesOfContract = cloneDeep(this.props.typesOfContract);
                    if (typeOfContract.id === undefined) {
                        typesOfContract.data.push(response);
                    } else {
                        typesOfContract.data = typesOfContract.data.map(toc => {
                            if (toc.id === response.id) {
                                return response;
                            } else {
                                return toc;
                            }
                        });
                    }
                    changeTypesOfContract!(typesOfContract.data);
                    showNotification(intl.formatMessage({ defaultMessage: 'The type of contract has been successfully updated' }), "success");
                    this.props.stopEdition();
                    this.setState({ typeOfContract: undefined });
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updated the type of contract.' }), "warning"),
            );
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'Missing type of contract' }), "error");
        }
    };

    /**
     * Delete a type Of Contract
     */
    onDeleteTypeOfContract = () => {
        const { changeTypesOfContract, intl } = this.props;
        Network.deleteTypeOfContract(this.props.typeOfContract.id!).then(
            () => {
                const typesOfContract = cloneDeep(this.props.typesOfContract);

                typesOfContract.data = typesOfContract.data.filter(toc => toc.id !== this.props.typeOfContract.id);

                changeTypesOfContract!(typesOfContract.data);
                showNotification(intl.formatMessage({ defaultMessage: 'The type of contract has been successfully deleted' }), "success");
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the type of contract' }), "warning"),
        );
    };

    render() {
        const { typeOfContract } = this.state;
        const { intl } = this.props;
        return (
            <List.Item
                title={intl.formatMessage({ defaultMessage: 'Type of day disabled' })} //style={archived ? { backgroundColor: '#e0e0e0', paddingLeft: '5px', color: '#9e9e9e' } : {paddingLeft: '5px'}}
                actions={
                    typeOfContract ?
                        [
                            <CircleButton
                                small
                                key={`typeOfContractItem-list-action-${typeOfContract.id}-save`}
                                icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                title={intl.formatMessage({ defaultMessage: 'Save' })}
                                placement="left"
                                disabled={this.state.typeOfContract?.name === '' ? true : false}
                                onClick={this.onUpdateTypeOfContract} />,
                            <CircleButton
                                small
                                key={`typeOfContractItem-list-action-${typeOfContract.id}-cancel`}
                                icon={<FAIcon prefix='far' name='xmark' />}
                                title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                placement="left"
                                onClick={typeOfContract.id ? () => this.setState({ typeOfContract: undefined }) : () => this.props.stopEdition()} />

                        ] : [
                            <CircleButton
                                small
                                key={`typeOfContractItem-list-action-modify`}
                                icon={<FAIcon prefix='fad' name='pencil' />}
                                title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                placement="left"
                                onClick={() => this.setState({ typeOfContract: { ...this.props.typeOfContract } })} />,
                            <DeleteButton
                                small
                                key={`typeOfContractItem-list-action-delete`}
                                text={<><p><FormattedMessage defaultMessage={'Do you want to delete this type of contract?'} /></p><p><FormattedMessage defaultMessage={'User reports related to this type of contract may not display correctly.'} /></p></>}
                                onConfirm={this.onDeleteTypeOfContract}
                                placement="topRight"
                                buttonPlacement="right" />
                        ]
                }>
                {
                    typeOfContract ?
                        <Row style={{ width: "100%" }}>
                            <Col xs={{ span: 8 }}>
                                <div style={{ marginRight: '30px' }}>
                                    <InputField
                                        id={`tod-edit-input-${this.props.typeOfContract.id ? this.props.typeOfContract.id : '0'}`}
                                        onPressEnter={this.onUpdateTypeOfContract}
                                        value={typeOfContract?.name}
                                        placeholder={`${intl.formatMessage({ defaultMessage: 'Title' })}*`}
                                        title={intl.formatMessage({ defaultMessage: 'Title' })}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeTitle(e)} />
                                </div>
                            </Col>
                            <Col xs={{ span: 8 }} >
                                <div style={{ marginRight: '15px' }}>
                                    <InputNumber
                                        style={{ minWidth: '50px', maxWidth: '100px' }}
                                        min={0}
                                        max={10000}
                                        step="100"
                                        id="contract-hoursMin-input"
                                        className="contract-hoursMin-input"
                                        value={typeOfContract.hoursMin !== -1 ? typeOfContract.hoursMin : undefined}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Min hours' })}
                                        onChange={this.onChangeMinHours} />
                                </div>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <div>
                                    <InputNumber
                                        style={{ minWidth: '50px', maxWidth: '100px' }}
                                        min={0}
                                        max={10000}
                                        step="100"
                                        id="contract-hoursMax-input"
                                        className="contract-hoursMax-input"
                                        value={typeOfContract.hoursMax !== -1 ? typeOfContract.hoursMax : undefined}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Max hours' })}
                                        onChange={this.onChangeMaxHours} />
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row style={{ width: "100%" }}>
                            <Col xs={{ span: 8 }}>
                                <p>{this.props.typeOfContract.name}</p>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <p>{this.props.typeOfContract.hoursMin}</p>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                {this.props.typeOfContract.hoursMax}
                            </Col>
                        </Row>

                }
            </List.Item >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfContract: (c: TypeOfContract[]) => dispatch(changeTypesOfContract(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfContract: state.configurations.typesOfContract,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TypeOfContractItem));