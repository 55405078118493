import { Alert, Button, Col, Drawer, Empty, Modal, Row, Space, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { changeSectors, loadPois } from '../../../store/actions/location';
import Network from '../../../utils/network';
import { POI, Sector } from '../../../utils/types/generalTypes';
import { ApplicationState, LocationDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField from '../../common/fields/inputField';
import SpeedDial from '../../common/fields/speedDial';
import TableTransfer from '../../common/fields/tableTransfer';
import Card from '../../common/general/card';
import { tableColumnTextFilterConfig } from '../tableSearch';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {

}

type Props = IProps & LocationDispatchProps & ReduxProps & IntlProps;

interface State {
    // pois: POI[];
    // sectors: Sector[];
    // courses: Course[];
    selectedSectors: number[];
    searchText: string;
    searchedColumn: string | number;
    isDrawerVisible: boolean;
    sectorEdit?: Sector;
    sectorShow?: Sector;
    isModalAssign: boolean;
    displayAlert: boolean;
    alertMessage: string;
    alertType: 'success' | 'info' | 'warning' | 'error';
    loadingButton: boolean;
}


class SectorTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loadingButton: false,
            selectedSectors: [],
            searchText: '',
            searchedColumn: '',
            isDrawerVisible: false,
            isModalAssign: false,
            alertMessage: '',
            alertType: 'success',
            displayAlert: false,
        };
    }

    componentDidMount() {
        this.props.loadPois!();
        this.refreshSectors();
    }

    /**
     * Called when a poi is (un)checked
     * @param keys the new keys of checked poi
     */
    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedSectors: keys as number[] });

    /**
         * Give the poi table's rows some attributes
         * @param record the data source for the row
         */
    poiOnRow = (record: Sector) => ({
        onClick: () => this.setState({ sectorShow: record })
    });

    deleteSector = (sector?: Sector, sectorIds?: number[]) => {
        const { intl } = this.props;
        if (sector && sector.id !== undefined) {
            Network.deleteSector([sector.id]).then(
                () => {
                    let { sectors } = this.props;
                    sectors = sectors?.filter(s => s.id !== sector.id);
                    sectors && this.props.changeSectors!(sectors);
                    this.props.loadPois!(true);
                    showNotification(intl.formatMessage({ defaultMessage: 'The sector {title} successfully deleted' }, { title: sector.title }), "success");
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the sector {title}' }, { title: sector.title }), "error");
                },
            );
        } else if (sectorIds !== undefined && sectorIds.length > 0) {
            Network.deleteSector(sectorIds).then(
                () => {
                    let { sectors } = this.props;
                    sectors = sectors?.filter(s => sectorIds.find(stod => stod === s.id) === undefined);
                    sectors && this.props.changeSectors!(sectors);
                    this.props.loadPois!(true);
                    showNotification(intl.formatMessage({ defaultMessage: 'The sectors have been successfully deleted' }), "success");
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the sectors' }), "error");
                },
            );
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the sectors' }), "error");
        }
    };

    updateSector = () => {
        const sector = { ...this.state.sectorEdit };
        const { intl } = this.props;
        const { loadingButton } = this.state;
        if (!loadingButton && sector && sector.title !== undefined && sector.title !== '') {
            this.setState({ loadingButton: true }, () =>

                Network.updateSector(sector).then(
                    (response: Sector) => {
                        let { sectors } = this.props;
                        if (sector.id === undefined) {
                            if (sectors && sectors.length > 0) {
                                sectors.unshift(response);
                            } else {
                                sectors = [response];
                            }
                        } else {
                            sectors = sectors?.map(s => {
                                if (s.id === response.id) {
                                    return response;
                                } else {
                                    return s;
                                }
                            });
                        }
                        const alertMessage = intl.formatMessage({ defaultMessage: 'The sector has been successfully updated' });
                        sectors && this.props.changeSectors!(sectors);
                        this.props.loadPois!(true);
                        this.setState({ loadingButton: false, sectorShow: response, sectorEdit: undefined, alertMessage, alertType: 'success', displayAlert: true });
                    },
                    () => {
                        if (sector.id !== undefined) {
                            const alertMessage = intl.formatMessage({ defaultMessage: 'An error occurred while updating' });
                            this.setState({ loadingButton: false, sectorEdit: undefined, alertMessage, alertType: 'error', displayAlert: true });
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating POI' }), 'error');
                            this.setState({ loadingButton: false, sectorShow: undefined, sectorEdit: undefined });
                        }
                    },
                ));
        }
    };

    refreshSectors = () => {
        Network.getSector().then(
            (response: Sector[]) => {
                this.props.changeSectors!(response);
            }
        );
    };

    startAddSector = () => {
        this.setState({ sectorEdit: {} });
    };

    sectorColumnsActions = (record: Sector) => {
        return [
            <Space key='column-actions'>
                <CircleButton
                    icon={<FAIcon prefix='fad' name='pencil' />}
                    small
                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                    placement="left"
                    onClick={() => this.setState({ sectorShow: record, sectorEdit: { ...record } })}
                />
                <DeleteButton
                    small
                    title={this.props.intl.formatMessage({ defaultMessage: 'Permanently delete' })}
                    text='TODO'
                    onConfirm={(e) => {
                        if (e) {
                            e.stopPropagation();
                            this.deleteSector(record);
                        }
                    }}
                    placement={'left'}
                />
            </Space>
        ];
    };

    closeShowDrawer = () => {
        this.setState({ sectorShow: undefined, displayAlert: false });
    };

    closeEditDrawer = () => {
        if (this.state.sectorEdit?.id !== undefined) {
            this.setState({ sectorEdit: undefined });
        } else {
            this.setState({ sectorEdit: undefined, sectorShow: undefined });
        }
    };

    render() {
        const sectors = this.props.sectors ? [...this.props.sectors] : undefined;
        const pois = this.props.pois?.data ? [...this.props.pois?.data] : [];
        const basePois = this.props.basePois?.data ? [...this.props.basePois?.data] : [];
        const allPois = [...basePois, ...pois];
        const { intl } = this.props;
        const headerButtons = [
            <Space key="poi-header-buttons">
                <DeleteButton
                    small
                    disabled={this.state.selectedSectors === undefined || this.state.selectedSectors.length === 0}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Permanently delete' })}
                    text='TODO'
                    onConfirm={(e) => {
                        if (e) {
                            e.stopPropagation();
                            this.deleteSector(undefined, this.state.selectedSectors);
                            this.setState({ selectedSectors: [] });
                        }
                    }}
                    placement={'bottom'}
                />
                <CircleButton
                    small
                    onClick={() => this.startAddSector()}
                    className="__card-button-space"
                    title={intl.formatMessage({ defaultMessage: 'Add a sector' })}
                    icon={<FAIcon prefix='far' name='plus' />}
                    placement="bottom" />
            </Space>
        ];
        return (
            <>
                <Card icon={<FAIcon prefix='fad' name='signs-post' />} title={<FormattedMessage defaultMessage={'Sectors'} />} headerElements={[
                    this.props.isSmartphone ?
                        <SpeedDial
                            small
                            key='poi-actions-speeddial'
                            title={intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                            openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                            buttons={headerButtons} />
                        :
                        headerButtons

                ]}>
                    <Table
                        key="table-pois"
                        className='__poi-list'
                        rowKey={(p: any) => p.id}
                        dataSource={sectors}
                        columns={this.props.isSmartphone ? this.sectorColumnsMobile : this.sectorColumns}
                        // loading={this.state.groupsLoading}
                        pagination={false}
                        onRow={this.poiOnRow}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedSectors }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No sector'} />} /> }}
                    />
                </Card>
                <Drawer
                    destroyOnClose={true}
                    width={this.props.isSmartphone ? '100%' : '450px'}
                    title={this.state.sectorShow?.title}
                    placement="right"
                    onClose={() => this.closeShowDrawer()}
                    open={this.state.sectorShow !== undefined}
                    className="__drawer"
                    footer={
                        <div style={{ textAlign: 'right', }} >
                            {
                                <>
                                    <Button onClick={() => this.closeShowDrawer()} style={{ marginRight: 8 }}>
                                        <FormattedMessage defaultMessage={'Close'} />
                                    </Button>
                                    <Button onClick={() => this.setState({ sectorEdit: { ...this.state.sectorShow }, displayAlert: false })} type="primary">
                                        <FormattedMessage defaultMessage={'Edit'} />
                                    </Button>
                                </>
                            }
                        </div>
                    }
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 12 }}>
                                <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Title'} /></p>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <p style={{ textAlign: 'right' }}>{this.state.sectorShow?.title}</p>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Locations'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <Space direction="vertical">
                                    {
                                        this.state.sectorShow?.pois?.map(s => (
                                            <Tag className="__tags-sunkhronos" style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }} color="#f5f5f5" key={s.id} >
                                                {s.title}
                                            </Tag>
                                        ))
                                    }
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[10, 10]}>
                            {
                                this.state.displayAlert &&
                                <Col style={{ marginTop: '10px' }} xs={{ span: 24 }}>
                                    <Alert
                                        afterClose={() => this.setState({ displayAlert: false })}
                                        message={this.state.alertMessage}
                                        type={this.state.alertType}
                                        showIcon closable
                                    />
                                </Col>
                            }
                        </Row>
                    </div>


                </Drawer>
                <Modal
                    destroyOnClose={true}
                    width={this.props.isSmartphone ? '100%' : 800}
                    title={this.state.sectorEdit?.id ? <FormattedMessage defaultMessage={'Edit sector'} /> : <FormattedMessage defaultMessage={'Add sector'} />}
                    onCancel={() => this.closeEditDrawer()}
                    open={this.state.sectorEdit !== undefined}
                    className="__drawer"
                    footer={
                        <div style={{ textAlign: 'right', }} >
                            <Button onClick={() => this.closeEditDrawer()} style={{ marginRight: 8 }}>
                                <FormattedMessage defaultMessage={'Close'} />
                            </Button>
                            <Button loading={this.state.loadingButton} disabled={this.state.sectorEdit?.title === undefined || this.state.sectorEdit.title === ''} onClick={(e) => { e.stopPropagation(); this.updateSector(); }} type="primary">
                                <FormattedMessage defaultMessage={'Save'} />
                            </Button>
                        </div>
                    }
                >
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 12 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Title'} /></p>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <InputField
                                onChange={(e: { target: { value: string | undefined; }; }) => {
                                    const sector = this.state.sectorEdit;
                                    sector && (sector.title = e.target.value);
                                    this.setState({ sectorEdit: sector });
                                }}
                                value={this.state.sectorEdit?.title}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Locations'} /></p>
                        </Col>

                        <Col span={24}>
                            <TableTransfer
                                titles={[
                                    <span key={`edit-sectors-TableTransfer-poi`} style={{ textTransform: "uppercase", fontSize: "93%" }}><FormattedMessage defaultMessage={'Available locations'} /></span>,
                                    <span key={`edit-sectorys-TableTransfer-poi-members-of-groups`} style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: "95%" }}><FormattedMessage defaultMessage={'Assigned locations'} /></span>
                                ]}
                                rowKey={(item: any) => item.id}
                                dataSource={allPois}
                                columns={this.poiSmallColumns}
                                targetKeys={(this.state.sectorEdit && this.state.sectorEdit.pois) ? this.state.sectorEdit.pois.map(p => p.id!) : []}
                                onChange={(e) => {
                                    const sector = this.state.sectorEdit;
                                    const selectedPois = allPois?.filter(c => e.findIndex(id => id === c.id) !== -1);
                                    sector && (sector.pois = selectedPois);
                                    this.setState({ sectorEdit: sector });
                                }}
                                pagination={{
                                    defaultPageSize: 4,
                                    //pageSizeOptions: ["8", "16", "32", "64", "72"],
                                    //showSizeChanger: true,
                                    hideOnSinglePage: true,
                                    size: 'small',
                                    //showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`
                                }}
                                hideSearch={true}
                                locale={{
                                    itemUnit: intl.formatMessage({ defaultMessage: 'point of interest' }),
                                    itemsUnit: intl.formatMessage({ defaultMessage: 'points of interest' }),
                                    notFoundContent: intl.formatMessage({ defaultMessage: 'No point of interest' }),
                                    searchPlaceholder: intl.formatMessage({ defaultMessage: 'Search for a location' })
                                }}
                            />
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
    poiSmallColumns: ColumnProps<POI>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            className: '__width_25',
            key: 'isBasePoi',
            //dataIndex: 'isBasePoi',
            render: (record: POI) => {
                console.log(record);
                return record.isBasePoi ? <FAIcon prefix='fad' name='location-dot' title={this.props.intl.formatMessage({ defaultMessage: 'Departure location' })} /> : <FAIcon prefix='fad' name='house-building' title={this.props.intl.formatMessage({ defaultMessage: 'Workplace' })} />;
            }

        }
    ];
    sectorColumns: ColumnProps<Sector>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<Sector>(),
            sorter: (a: Sector, b: Sector) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '120px',
            className: '__poi-actions',
            render: (record: Sector) => {
                return (
                    this.props.isSmartphone ?
                        <SpeedDial
                            small
                            key='poi-actions-speeddial'
                            title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                            openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                            buttons={this.sectorColumnsActions(record)} />
                        :
                        this.sectorColumnsActions(record)

                );
            },
        }
    ];

    sectorColumnsMobile: ColumnProps<Sector>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<Sector>(),
            sorter: (a: Sector, b: Sector) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '90px',
            className: '__poi-actions',
            render: (record: Sector) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <SpeedDial
                        small
                        key='poi-actions-speeddial'
                        title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                        openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                        buttons={this.sectorColumnsActions(record)} />
                </div>;
            },
        }
    ];
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    sectors: state.location.sectors,
    pois: state.location.pois,
    basePois: state.location.basePois,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSectors: (sectors: Sector[]) => dispatch(changeSectors(sectors)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(SectorTab));