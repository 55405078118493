import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import '../../styles/help.css';
import { ApplicationState } from '../../utils/types/storeTypes';
import FAIcon from '../common/FAIcon';
import Card from '../common/general/card';

interface IProps {
    width: number;
}

type Props = IProps;

interface State {
}

class About extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        const { width } = this.props;
        const small = width < 1600;
        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    <Card title={<FormattedMessage defaultMessage={'About us'} />} icon={<FAIcon prefix='fad' name='info' />}>
                        <Row gutter={[20, 20]}>
                            <Col xs={{ span: 24 }} style={{ display: 'flex', gap: '10px', flexDirection: small ? 'column' : 'row' }} className='__markdown-help'>
                                <div style={{ flex: '1', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p><FormattedMessage defaultMessage={'A tailor-made application made in Switzerland.'} /></p>
                                    <p><FormattedMessage defaultMessage={'Over 4,000 active users and already 100 satisfied customers from a wide range of sectors.'} /></p>
                                    <p><FormattedMessage defaultMessage={'A step-by-step training program enables you to get to grips with the platform quickly.'} /></p>
                                    <br />
                                    <p><Space><FAIcon prefix='fad' name='envelope' /><a href="mailto:support@webevolutions.ch">support@webevolutions.ch</a></Space></p>
                                    <p><Space><FAIcon prefix='fad' name='phone' /><a href="tel:0041774987503">+41 77 498 75 03</a></Space></p>
                                </div>
                                <div style={{ height: "360px", width: small ? '100%' : 'calc(100vw - 1100px)', backgroundPosition: 'center', backgroundImage: 'url("https://sunkhronos.ch/templates/yootheme/cache/contact-sunkhronos-team-connectez-votre-equipe-communication-mobile-intranet-team-application-confindent-securite--confidential-6516c264.jpeg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <iframe title="Google Maps WebEvolutions" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.2664104350206!2d7.444778415979487!3d46.28468127911912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ed95441b762b1%3A0x3ad54e3cf7985ed6!2sWebEvolutions!5e0!3m2!1sfr!2sch!4v1679908910523!5m2!1sfr!2sch" width="100%" height="270px" style={{ border: "0px" }} loading="lazy" ></iframe>
                            </Col>
                            <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                                <span>Powered by <a href='https://webevolutions.ch/fr/' rel="noreferrer" target={"_blank"}>WebEvolutions</a>{` © ${moment().year()} - Sunkhronos - All rights reserved`}</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    width: state.window.width,
});

export default connect(mapStateToProps)(About);