import { List } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { CompanyDetailsPrivilege, Privileges } from '../../../privileges';
import Network from '../../../utils/network';
import { TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, checkCompanyDetailsPrivilege, checkPrivilege } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import SwitchLabeled from '../../common/fields/switchLabeled';


type ReduxProps = ConnectedProps<typeof connector>;

interface iProps {
    typeOfDay: TypeOfDay;
    refresh: (message?: string, forceReload?: boolean) => void;
}

type Props = iProps & ReduxProps & IntlProps;

interface State {
    typeOfDay: TypeOfDay | undefined;
}

/**
 * Component that represent a list item for the types of day list
 */
class TypeOfDayItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            typeOfDay: undefined,
        };
    }

    componentDidMount() {
        // if it is a new type of day, set state's type of day
        if (this.props.typeOfDay.id === undefined) {
            this.setState({ typeOfDay: { ...this.props.typeOfDay } });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // when add or edit a type of day, focus the input field
        if (this.state.typeOfDay !== undefined && prevState.typeOfDay === undefined) {
            const input = document.getElementById(`tod-edit-input-${this.props.typeOfDay.id ? this.props.typeOfDay.id : '0'}`);
            if (input) input.focus();
        }
    }

    /**
     * Change a type of day title
     * @param event the triggered event
     */
    onChangeTitle = (event: InputFieldOnChangeEvent) => {
        const { typeOfDay } = this.state;
        typeOfDay!.title = event.target.value;
        this.setState({ typeOfDay });
    };

    /**
     * Change a type of day title
     * @param event the triggered event
     */
    onChangeAbbreviation = (event: InputFieldOnChangeEvent) => {
        const { typeOfDay } = this.state;
        typeOfDay!.abbreviation = event.target.value;
        this.setState({ typeOfDay });
    };

    /**
     * Change a type of day in reports
     * @param event the triggered event
     */
    onChangeInReports = (checked: boolean) => {
        const { typeOfDay } = this.state;
        typeOfDay!.inReports = checked;
        this.setState({ typeOfDay });
    };

    /**
     * Change a type of day in reports
     * @param event the triggered event
     */
    onChangeCountAsWorktime = (checked: boolean) => {
        const { typeOfDay } = this.state;
        typeOfDay!.countAsWorktime = checked;
        this.setState({ typeOfDay });
    };

    onChangeCanBeBadged = (checked: boolean) => {
        const typeOfDay = cloneDeep(this.state.typeOfDay);
        if (typeOfDay) {
            console.log('TAFAFASFAF1', checked);
            typeOfDay.canBeBadged = checked;
        }
        console.log('TAFAFASFAF2', checked);
        this.setState({ typeOfDay });
    };

    /**
     * Create or edit the type of day
     */
    onUpdateTypeOfDay = (): void => {
        Network.updateTypeOfDay(this.state.typeOfDay!).then(
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'Day type successfully updated' }), "success");
                this.props.refresh();
                this.setState({ typeOfDay: undefined });
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the day type' }), "warning"),
        );
    };

    /**
     * Delete a type of day
     */
    onDeleteTypeOfDay = () => {
        Network.deleteTypeOfDay(this.props.typeOfDay.id!).then(
            () => this.props.refresh(this.props.intl.formatMessage({ defaultMessage: 'Day type successfully deleted' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the day type.' }), "warning"),
        );
    };

    /**
     * Delete a type of day
     */
    activateTypeOfDay = () => {
        Network.activateTypeOfDay(this.props.typeOfDay.id!).then(
            () => {
                this.props.refresh(this.props.intl.formatMessage({ defaultMessage: 'The day type has been successfully activated' }), true);
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while activating the day type' }), "warning"),
        );
    };

    /**
     * Delete a type of day
     */
    deactivateTypeOfDay = () => {
        Network.deactivateTypeOfDay(this.props.typeOfDay.id!).then(
            () => {
                this.props.refresh(this.props.intl.formatMessage({ defaultMessage: 'Day type successfully deactivated' }), true);
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deactivating the type of day' }), "warning"),
        );
    };

    render() {
        const { typeOfDay } = this.state;
        const { archived } = this.props.typeOfDay;
        const { intl } = this.props;
        return (
            <List.Item
                title={intl.formatMessage({ defaultMessage: 'Deactivated day type' })} style={archived ? { backgroundColor: '#e0e0e0', paddingLeft: '5px', color: '#9e9e9e' } : { paddingLeft: '5px' }}
                extra={<div style={{ display: 'flex', flexDirection: 'row', gap: 8, width: 180, justifyContent: 'end' }}>
                    {typeOfDay ?
                        [

                            typeOfDay.id ?
                                <CircleButton
                                    icon={<FAIcon prefix='far' name='xmark' />}
                                    small
                                    title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                    placement="left"
                                    onClick={() => this.setState({ typeOfDay: undefined })} />
                                : <CircleButton
                                    icon={<FAIcon prefix='far' name='xmark' />}
                                    small
                                    title={intl.formatMessage({ defaultMessage: 'Delete' })}
                                    placement="left"
                                    onClick={() => { this.setState({ typeOfDay: undefined }); this.props.refresh(); }} />,
                            <CircleButton
                                key={`typeOfDayItem-disabled-list-${typeOfDay.id}-save`}
                                icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                title={intl.formatMessage({ defaultMessage: 'Save' })}
                                placement="left"
                                small
                                disabled={this.state.typeOfDay?.title === '' ? true : false}
                                onClick={this.onUpdateTypeOfDay} />
                        ] : [
                            <CircleButton
                                key={`typeOfDayItem-disabled-list-${archived}-modify`}
                                small
                                icon={<FAIcon prefix='fad' name='pencil' />}
                                title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                placement="left"
                                onClick={() => this.setState({ typeOfDay: { ...this.props.typeOfDay } })} />,
                            <CircleButton
                                key={`typeOfDayItem-disabled-list-${archived}-activate`}
                                small
                                icon={archived ? <span className='anticon'><FAIcon prefix='far' name='check' /></span> : <span className='anticon'><FAIcon prefix='fad' name='box-archive' /></span>}
                                title={archived ? intl.formatMessage({ defaultMessage: 'Activate' }) : intl.formatMessage({ defaultMessage: 'Deactivate (related tasks and templates will also be deactivated)' })}
                                placement="left"
                                onClick={() => archived ? this.activateTypeOfDay() : this.deactivateTypeOfDay()} />,
                            <DeleteButton
                                key={`typeOfDayItem-disabled-list-${archived}-delete`}
                                text={<FormattedMessage defaultMessage={'Do you want to delete this day type?'} />}
                                small
                                onConfirm={this.onDeleteTypeOfDay}
                                placement="topRight"
                                buttonPlacement="right" />
                        ]
                    }</div>
                }>
                {
                    typeOfDay ?
                        <div className="types-of-day-content">
                            <div style={{ display: 'flex' }}>
                                <InputField
                                    id={`tod-edit-input-${this.props.typeOfDay.id ? this.props.typeOfDay.id : '0'}`}
                                    onPressEnter={this.onUpdateTypeOfDay}
                                    className="configurations-section-field asterisk_input"
                                    value={typeOfDay!.title}
                                    placeholder={`${intl.formatMessage({ defaultMessage: 'Title' })}*`}
                                    title={intl.formatMessage({ defaultMessage: 'Title' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.onChangeTitle(e)} />
                                <InputField
                                    maxLength={2}
                                    showCount
                                    title={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                    onPressEnter={this.onUpdateTypeOfDay}
                                    className="configurations-section-field-small"
                                    value={typeOfDay!.abbreviation}
                                    onChange={(e: InputFieldOnChangeEvent) => this.onChangeAbbreviation(e)} />
                            </div>
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <span>
                                    <SwitchLabeled
                                        checked={typeOfDay.inReports ?? false}
                                        onChange={e => this.onChangeInReports(e)}
                                        label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                    />
                                </span>
                                <span>
                                    <SwitchLabeled
                                        checked={typeOfDay.countAsWorktime ?? false}
                                        onChange={e => this.onChangeCountAsWorktime(e)}
                                        label={intl.formatMessage({ defaultMessage: 'Count as working time' })}
                                    />
                                </span>
                                {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&
                                    <span>
                                        <SwitchLabeled
                                            checked={typeOfDay.canBeBadged ?? true}
                                            onChange={e => this.onChangeCanBeBadged(e)}
                                            label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                        />
                                    </span>}
                            </div>
                        </div>
                        :
                        <div className="types-of-day-content">
                            <p>{`${this.props.typeOfDay.title} ${this.props.typeOfDay.abbreviation ? `(${this.props.typeOfDay.abbreviation})` : ""}`}</p>
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 0 }}>
                                <span style={{ fontSize: '13px' }}>
                                    <SwitchLabeled
                                        disabled
                                        size='small'
                                        className="checkbox-readonly"
                                        checked={this.props.typeOfDay.inReports ?? false}
                                        label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                    />
                                </span>
                                <span style={{ fontSize: '13px' }}>
                                    <SwitchLabeled
                                        disabled
                                        size='small'
                                        className="checkbox-readonly"
                                        checked={this.props.typeOfDay.countAsWorktime ?? false}
                                        label={intl.formatMessage({ defaultMessage: 'Count as working time' })}
                                    />
                                </span>
                                {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&
                                    <span style={{ fontSize: '13px' }}>
                                        <SwitchLabeled
                                            disabled
                                            size='small'
                                            className="checkbox-readonly"
                                            checked={this.props.typeOfDay.canBeBadged ?? true}
                                            label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                        />
                                    </span>
                                }
                            </div>
                        </div>
                }
            </List.Item >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    company: state.user.company,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(TypeOfDayItem));
