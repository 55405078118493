import { Card, Col, Empty, Row, Spin } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ICustomer, IMandate } from "../../../../utils/types/customerTypes";
import { IUserExportHoursGlobalOfficeUserData, IUserExportHoursGlobalOfficeUserDataCustomer } from "../../../../utils/types/networkTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { fixedDecimal } from "../../../../utils/utils";

interface Props {

    userData: IUserExportHoursGlobalOfficeUserData | undefined;
    loading: boolean;
}

//TODO: Show warning if isAuxiliary is true
const ExportGlobalOfficeResult = (props: Props) => {
    const {userData, loading} = props;
    const customers = useSelector((state: ApplicationState) => state.customerManagement.customers);
    const mandates = useSelector((state: ApplicationState) => state.customerManagement.mandates);


    const filterByCustomer = useMemo(() => {
        if (userData !== undefined) {
            return Object.keys(userData).map((customerId) => {
                let customer: ICustomer | undefined = undefined;
                let tmpMandates: { mandate: IMandate | undefined, totalHours?: number, timeOff?: number; }[] = [];
                if (customerId === 'orphelin') {
                    tmpMandates = [{
                        mandate: undefined,
                        totalHours: userData['orphelin'].totalHours,
                        timeOff: userData['orphelin'].totalTimeOff,
                    }];
                } else {
                    customer = customers.data?.find(c => c.id === parseInt(customerId));
                    const customerData = userData[customerId] as IUserExportHoursGlobalOfficeUserDataCustomer;  // Cast explicite
                    tmpMandates = Object.keys(customerData).map((mandateId) => {
                        const mandate = mandates.data?.find(m => m.id === parseInt(mandateId));

                        return {
                            mandate: mandate,
                            totalHours: customerData[mandateId].totalHours,
                            timeOff: customerData[mandateId].totalTimeOff
                        };
                    });
                }

                //const tmpMandates:any=[]
                return {
                    customer: customer,
                    mandates: tmpMandates
                };
            });
        }
        else {
            return [];
        }
    }, [customers, mandates.data, userData]);

    return (

        <Spin spinning={loading}>
            {
                userData === undefined ?
                    <Empty />
                    :
                    <Row gutter={[10, 10]}>
                        {
                            filterByCustomer !== undefined && filterByCustomer.length > 0 &&
                                filterByCustomer.map((customer) => {
                                    return (
                                        <Col xs={{ span: 24 }} xxl={{ span: 12 }} key={`customer-${customer.customer?.id}`}>
                                            <Card size='small' title={customer.customer ? customer.customer.title : <FormattedMessage defaultMessage={'Without client'} />} className='integration-data-container'>
                                                {
                                                    customer.mandates.map((mandate) => {
                                                        return (
                                                            <p key={`mandate-${mandate.mandate?.id}`}>
                                                                <div><strong>{mandate.mandate ? mandate.mandate.title : <FormattedMessage defaultMessage={'Without mandate'} />}</strong></div>
                                                                <div className='integration-data-item'><FormattedMessage defaultMessage={'Total hours'} /><span>{mandate.totalHours ? fixedDecimal(mandate.totalHours.toString()) : 0.0}</span></div>
                                                                <div className='integration-data-item'><FormattedMessage defaultMessage={'Total hours off'} /><span>{mandate.timeOff ? fixedDecimal(mandate.timeOff.toString()) : 0.0}</span></div>
                                                            </p>
                                                        );
                                                    })
                                                }
                                            </Card>
                                        </Col>
                                    );
                                })
                        }
                    </Row>
            }
        </Spin>
    );
};

export default ExportGlobalOfficeResult;