import { Modal, Select, Switch, Tag } from 'antd';
import moment, { Moment } from 'moment';
import React, { ReactElement } from 'react';
import { ColorResult } from 'react-color';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { changeTemplates } from '../../store/actions/planning';
import { MOMENT_SHORT_TIME_FORMAT } from '../../utils/constants';
import Network from '../../utils/network';
import { NetworkSettings } from '../../utils/types/networkTypes';
import { PlanningColor, PlanningSettings, PlanningTemplate } from '../../utils/types/planningTypes';
import { ApplicationState, PlanningDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { cloneSettings, convertNetworkEventsToPlanningEventsV2, convertPlanningSettingsToNetworkSettings, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import { InputFieldOnChangeEvent } from '../common/fields/inputField';
import { default as CustomTimePicker, TimePickerType, TimePickerVersion } from '../common/fields/timePicker';
enum DataType { WorkingDaysOfWeek, StartHourOfDay, EndHourOfDay, ShowAvatars, GroupAdminWriteAuthorization, CheckEventsOverlap }



type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
    visible: boolean;
    defaultStep?: number;
    onCancel: () => void;
    onUpdate: (response: NetworkSettings) => void;
    onUpdateTemplate?: (template: PlanningTemplate, isEdit: boolean) => void;
    //Redux
    settings: PlanningSettings;
    templates?: PlanningTemplate[];
}

interface State {
    settings: PlanningSettings;
    daysOfWeek: DaysOfWeek;
    currentStep: number;
    searchedTemplates?: string;
}

type Props = IProps & PlanningDispatchProps & ReduxProps & IntlProps;

/**
 * Types for the array of week day for Settings
 */
type DayOfWeek = { value: number, text: string; };
type DaysOfWeek = DayOfWeek[];

/**
 * Component that represent the planning settings modal
 */
class SettingsModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            settings: cloneSettings(this.props.settings),
            daysOfWeek: this.getDaysOfWeek(),
            currentStep: this.props.defaultStep ? this.props.defaultStep : 0,
            searchedTemplates: undefined,
        };
    }

    componentDidMount() {
        // get the template events
        //this.refreshTemplates();
    }

    /**
     * refresh the tempaltes
     */
    refreshTemplates = () => {
        // get all templates
        Network.getTemplates().then(
            response => {
                if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning");
                else {
                    this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning"),
        );
    };

    /**
     * Get days of week array
     * @return days of week array
     */
    getDaysOfWeek = (): DaysOfWeek => {
        const { intl } = this.props;
        return [
            { value: 0, text: intl.formatMessage({ defaultMessage: 'Monday' }) },
            { value: 1, text: intl.formatMessage({ defaultMessage: 'Tuesday' }) },
            { value: 2, text: intl.formatMessage({ defaultMessage: 'Wednesday' }) },
            { value: 3, text: intl.formatMessage({ defaultMessage: 'Thursday' }) },
            { value: 4, text: intl.formatMessage({ defaultMessage: 'Friday' }) },
            { value: 5, text: intl.formatMessage({ defaultMessage: 'Saturday' }) },
            { value: 6, text: intl.formatMessage({ defaultMessage: 'Sunday' }) },
        ];
    };

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any): void => {
        const { settings } = this.state;
        switch (type) {
            case DataType.WorkingDaysOfWeek:
                settings.workingDaysOfWeek = value.sort();
                break;
            case DataType.StartHourOfDay:
                settings.startHourOfDay = value;
                break;
            case DataType.EndHourOfDay:
                settings.endHourOfDay = value;
                break;
            case DataType.ShowAvatars:
                settings.showAvatars = value;
                break;
            case DataType.CheckEventsOverlap:
                settings.checkEventsOverlap = value;
                break;
            case DataType.GroupAdminWriteAuthorization:
                settings.groupAdminWriteAuthorization = value;
                break;
        }
        this.setState({ settings });
    };

    /**
     * Add a color
     */
    addColor = () => {
        const { settings } = this.state;
        settings.colors!.unshift({
            id: (settings.colors!.length + 1) * -1, //add extra negative id
            title: "",
            color: "var(--primary-color)",
        });
        this.setState({ settings });
    };

    /**
     * Check if the add color button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddColor = (): boolean => this.state.settings.colors!.filter(c => c.title.length === 0 || c.color.length === 0).length === 0;

    /**
     * Change title of color
     * @param event the input field on change event
     * @param id the color's id
     */
    onChangeColorTitle = (event: InputFieldOnChangeEvent, id: number | string): void => {
        const { settings } = this.state;
        const color = settings.colors!.find(c => c.id === id);
        color!.title = event.target.value;
        this.setState({ settings });
    };

    /**
     * Change the color's color
     * @param c the selected color
     * @param id the color's id
     */
    onChangeColor = (c: ColorResult, id: number | string): void => {
        const { settings } = this.state;
        const color = settings.colors!.filter(c => c.id === id)[0];
        color.color = c.hex;
        this.setState({ settings });
    };

    /**
     * Delete a color
     * @param the color to delete
     */
    onDeleteColor = (color: PlanningColor): void => {
        const { settings } = this.state;
        settings.colors = settings.colors!.filter(c => c.id !== color.id);
        this.setState({ settings });
    };

    /**
    * Update the settings
    */
    onUpdate = (): void => {
        const settings: NetworkSettings = convertPlanningSettingsToNetworkSettings(this.state.settings);

        // update settings
        Network.updateSettings(settings).then(
            response => {
                this.props.onUpdate(response);
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The planning settings have been successfully updated' }), "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the planning settings' }), "warning"),
        );
    };

    /**
     * Delete a template
     * @param template the concerned template
     */
    onDeleteTemplate = (template: PlanningTemplate) => {
        Network.deleteTemplate(template.id!).then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The template has been successfully deleted' }), "success");
                this.refreshTemplates();
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the template' }), "warning"),
        );
    };

    /**
     * Handle templates filtering with search bar
     * @param event the triggered event
     */
    handleSearchGroups = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedTemplates: undefined });
        } else {
            this.setState({ searchedTemplates: search });
        }
    };

    /**
     * Render the select tags
     * @param props props given by antd
     * @returns a Tag component
     */
    renderSelect = (props: any): ReactElement<number> => {
        const { value, closable, onClose } = props;

        return (
            <Tag className="select-tags" closable={closable} onClose={onClose}>
                {this.state.daysOfWeek[value].text}
            </Tag>
        );
    };

    render() {
        const { settings } = this.state;
        const { intl } = this.props;
        // const daysOfWeekLeft = daysOfWeek.filter((d) => settings.workingDaysOfWeek ? !(settings.workingDaysOfWeek as number[]).includes(d.value) : true);
        console.log('SETTINGS', settings);
        return (
            <Modal
                title={<FormattedMessage defaultMessage={'Settings'} />}
                open={this.props.visible}
                onOk={this.onUpdate}
                onCancel={this.props.onCancel}
                destroyOnClose={true}
                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                okText={<FormattedMessage defaultMessage={'Save'} />}
                cancelButtonProps={{ type: 'dashed' }}
                width={650}>
                <div className="planning-settings-section">
                    {/* <span className="planning-settings-row">
                        <p><FormattedMessage defaultMessage={'Days of week'} />{':'}</p>
                        <Select
                            mode="multiple"
                            className="planning-settings-input"
                            value={settings.workingDaysOfWeek as number[]}
                            onChange={(values) => this.dataChanged(DataType.WorkingDaysOfWeek, values)}
                            tagRender={this.renderSelect}
                            allowClear
                            filterOption={true}
                            maxTagCount="responsive"
                            optionFilterProp="label">
                            {daysOfWeekLeft.map((d) => <Select.Option label={d.text} key={`planning-settings-select-${d.value}`} value={d.value}>{d.text}</Select.Option>)}
                        </Select>
                    </span> */}
                    {/* <span className="planning-settings-row">
                        <p>{"Une journée commence à:"}</p>
  


                        <CustomTimePicker
                            titleFrom=''
                            formatTimeFrom='HH:mm:ss'
                            valueFrom={settings.startHourOfDay ? moment(settings.startHourOfDay) : undefined}
                            onChangeFrom={(time: Moment | null) => this.dataChanged(DataType.StartHourOfDay, time)}
                            type={TimePickerType.PICKER}
                            version={TimePickerVersion.FIELD}
                        />
                    </span> */}
                    <span className="planning-settings-row">
                        {/* <p>{"Une journée se termine à:"}</p> */}
                        {/* <TimePicker
                            className="planning-settings-input"
                            onChange={(time) => this.dataChanged(DataType.EndHourOfDay, time)}
                            value={settings.endHourOfDay ? moment(settings.endHourOfDay) : undefined}
                            format={"HH:mm"}
                            placeholder={"Fin"}
                            disabled={!settings.startHourOfDay}
                            disabledHours={settings.startHourOfDay ? () => _.range(0, (settings.startHourOfDay! as Moment).get('hours') + 1) : undefined} /> */}
                        <CustomTimePicker
                            titleFrom={`${intl.formatMessage({ defaultMessage: 'A day begins at' })}:`}
                            formatTimeFrom={MOMENT_SHORT_TIME_FORMAT}
                            placeHolderTimeFrom={intl.formatMessage({ defaultMessage: 'Start' })}
                            valueFrom={settings.startHourOfDay ? moment(settings.startHourOfDay) : undefined}
                            onChangeFrom={(time: Moment | null) => this.dataChanged(DataType.StartHourOfDay, time)}


                            titleTo={`${intl.formatMessage({ defaultMessage: 'A day ends at' })}:`}
                            formatTimeTo={MOMENT_SHORT_TIME_FORMAT}
                            placeHolderTimeTo={intl.formatMessage({ defaultMessage: 'End' })}
                            valueTo={settings.endHourOfDay ? moment(settings.endHourOfDay) : undefined}
                            onChangeTo={(time: Moment | null) => this.dataChanged(DataType.EndHourOfDay, time)}
                            //disableTimeTo={settings.startHourOfDay ? () => _.range(0, (settings.startHourOfDay! as Moment).get('hours') + 1) : undefined}

                            tooLong={{ duration: 24, errorStr: intl.formatMessage({ defaultMessage: 'The duration of the day cannot exceed 24 hours' }) }}
                            type={TimePickerType.RANGE}
                            version={TimePickerVersion.FIELD}
                        />
                    </span>
                    <span className="planning-settings-row">
                        <p><FormattedMessage defaultMessage={'Show user images'} />{':'}</p>
                        <Switch
                            checked={settings.showAvatars}
                            onChange={event => this.dataChanged(DataType.ShowAvatars, event)} />
                    </span>
                    <span className="planning-settings-row">
                        <p><FormattedMessage defaultMessage={'Check events overlap on event creation/copy'} />{':'}</p>
                        <Switch
                            checked={settings.checkEventsOverlap}
                            onChange={check => this.dataChanged(DataType.CheckEventsOverlap, check)} />
                    </span>
                    <span className="planning-settings-row">
                        <div style={{ width: 250 }}><span><FormattedMessage defaultMessage={'A group admin can modify or delete an event'} /></span></div>
                        <Select
                            value={settings.groupAdminWriteAuthorization}
                            onChange={(value) => this.dataChanged(DataType.GroupAdminWriteAuthorization, value)}
                            style={{ width: 320, fontSize: '14px' }}
                            options={[
                                { label: intl.formatMessage({ defaultMessage: 'it has created' }), value: 'owned_only' },
                                { label: intl.formatMessage({ defaultMessage: 'created by another group admin' }), value: 'groupadmin' },
                                { label: intl.formatMessage({ defaultMessage: 'any event (also admin)' }), value: 'any' },
                            ]}
                        />
                    </span>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    settings: state.planning.settings,
    templates: state.planning.templates,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(SettingsModal));