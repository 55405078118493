import { Col, Empty, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Country, Medal } from '../../../utils/types/generalTypes';
import { CRMPromotion } from '../../../utils/types/networkTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';
import { tableColumnTextFilterConfig } from '../../courseManagement/tableSearch';
import ModalCreatePromotion from './modalCreatePromotion';


interface Props extends IntlProps {
    medals: Medal[];
    countries: Country[];
}

interface State {
    promotions?: CRMPromotion[];
    loadingPromotions: boolean;
    createPromotion: boolean;
}

/**
 * Component for the promotions tab in the CRM page
 */
class PromotionsTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loadingPromotions: false,
            createPromotion: false,
        };
    }

    componentDidMount() {
        this.getPromotions();
    }

    getPromotions = () => {
        this.setState({ loadingPromotions: true });
        Network.getPromotions().then(
            (response: CRMPromotion[]) => {
                this.setState({ promotions: response, loadingPromotions: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the promotions' }), "warning");
                this.setState({ promotions: undefined, loadingPromotions: false });
            },
        );
    };

    closeModalCreatePromotion = (reload: boolean) => {
        this.setState({ createPromotion: false });
        if (reload === true) {
            this.getPromotions();
        }
    };

    render() {
        const { promotions, createPromotion } = this.state;
        const { intl } = this.props;

        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    <Card
                        title={<FormattedMessage defaultMessage={'Promotions history'} />}
                        icon={<FAIcon prefix='fad' name='rectangle-ad' />}
                        headerElements={[
                            <CircleButton
                                onClick={() => this.setState({ createPromotion: true })}
                                icon={<FAIcon prefix='far' name='plus' />}
                                small
                                title={intl.formatMessage({ defaultMessage: 'Create a promotion' })}
                                placement="topLeft"
                                key="crm-promotion-add" />
                        ]}
                    >
                        <Table
                            // loading={this.state.isLoadingPois}
                            key={`promotions-tab-promotion`}
                            className='__poi-list'
                            rowKey={(cd: CRMPromotion) => cd.id}
                            dataSource={promotions}
                            columns={this.columns}
                            // loading={this.state.groupsLoading}
                            pagination={{
                                defaultPageSize: 16,
                                pageSizeOptions: ["8", "16", "24", "32", "64", "72"],
                                showSizeChanger: true,
                                hideOnSinglePage: true,
                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} promotions'} values={{ range0: range[0], range1: range[1], total }} />
                            }}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No promotion'} />} /> }}
                            scroll={{ x: 'max-content' }}
                        // onRow={this.codeDiscountOnRow}
                        // rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedPoi }}
                        />

                        <ModalCreatePromotion createPromotion={createPromotion} closeModalCreatePromotion={this.closeModalCreatePromotion} />
                    </Card>
                </Col>
            </Row >

        );
    }

    columns: ColumnProps<CRMPromotion>[] = [
        {
            title: <FormattedMessage defaultMessage={'Code'} />,
            dataIndex: 'code',
            key: 'code',
            onFilter: (value, record) => {
                return record.code!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<CRMPromotion>(),
            sorter: (a: CRMPromotion, b: CRMPromotion) => !a.code ? -1 : !b.code ? 1 : a.code < b.code ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            dataIndex: 'created',
            key: 'created',
            width: '200px',
            render: (created: string) => moment(created, 'YYYY/MM/DDTHH:mm:ss').format(getFormat('DATE'))

        },
    ];
}

const mapStateToProps = (state: ApplicationState) => ({
    medals: state.crm.medals,
    countries: state.crm.countries,
});

export default connect(mapStateToProps)(injectIntl(PromotionsTab));