import { Col, Empty, Row, Space, Table } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsersGroups } from '../../../store/actions/teamManagement';
import { groupsColumns } from '../../../utils/constants';
import Network from '../../../utils/network';
import { Group, RouterProps, User } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { alert, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';

interface IProps {
    user: User | undefined;
    usersGroups: Group[] | undefined;
}

type Props = IProps & TeamManagementDispatchProps & RouterProps & IntlProps;

interface State {
    groups: Group[];
    selectedGroups: number[];
    isEditing: boolean;
    loading: boolean;
}

/**
 * Component that represent the user's groups page
 */
class GroupsUser extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            groups: [],
            selectedGroups: [],
            isEditing: false,
            loading: false,
        };
    }

    /**
     * Get the user's groups
     * @param message a message to display as a success message - optional
     */
    refresh = (message?: string, callbackSuccess?: () => void, callbackError?: () => void) => {
        //get user's groups
        Network.getGroups(undefined, this.props.user!.id).then(
            response => {
                this.props.changeUsersGroups!(response);
                if (message) alert(message, "success");
                if (callbackSuccess) callbackSuccess();
            },
            () => {
                if (callbackError) callbackError();
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning");
            },
        );
    };

    /**
     * Starting editing groups
     */
    startEditing = () => {
        this.setState({ loading: true });
        Network.getGroups().then(
            response => {
                const selectedGroups: number[] = this.props.usersGroups!.map(u => u.id!);
                this.setState({ groups: response, selectedGroups, isEditing: true, loading: false });
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning"),
        );
    };

    /**
     * Cancel editing user's groups
     */
    cancelEditing = () => this.setState({ isEditing: false });

    /**
     * Called when a group is (un)checked
     * @param keys the new keys of checked groups
     */
    onChangeGroups = (keys: React.Key[]) => this.setState({ selectedGroups: keys as number[] });

    /**
     * Check if the edit button must be visible
     */
    showButton = () => this.state.isEditing && this.state.selectedGroups.length > 0;

    /**
     * Give the groups table's rows some attributes
     */
    onRow = (record: any) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/group-details?id=${record.id}`)
    });

    /**
     * Handle the modification of the user's groups
     */
    handleEdit = () => {
        this.setState({ loading: true });
        const newGroups = this.state.groups.filter((g: Group) => this.state.selectedGroups.find((id: number) => g.id === id));
        Network.updateUsersGroups(this.props.user!.id, this.props.usersGroups!, newGroups).then(
            () => {
                setTimeout(() => {
                    this.refresh(undefined, () => {
                        this.setState({ selectedGroups: [], loading: false, isEditing: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The group members have been successfully updated' }), "success");
                    }, () => this.setState({ loading: false }));
                }, 500);
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the groups' }), "warning")
        );
    };

    render() {
        const { isEditing } = this.state;
        const { intl } = this.props;
        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }}>
                    <Card icon={<FAIcon prefix='fad' name='users' />} title={<FormattedMessage defaultMessage={'Groups'} />} headerElements={[
                        <Space key={`team-groups-action`}>
                            <CircleButton
                                small
                                disabled={this.state.loading}
                                title={isEditing ? intl.formatMessage({ defaultMessage: 'Cancel' }) : intl.formatMessage({ defaultMessage: 'Edit' })}
                                icon={isEditing ? <FAIcon prefix='far' name='xmark' /> : <FAIcon prefix='fad' name='pencil' />}
                                onClick={isEditing ? this.cancelEditing : this.startEditing} />
                            {
                                isEditing ?
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Save' })}
                                        disabled={!this.showButton()}
                                        loading={this.state.loading}
                                        icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                        onClick={this.handleEdit} />
                                    : null
                            }
                        </Space>
                    ]}>
                        <Table
                            rowKey={(g: any) => g.id}
                            dataSource={isEditing ? this.state.groups : this.props.usersGroups}
                            columns={groupsColumns}
                            loading={this.props.usersGroups === undefined || this.state.loading}
                            pagination={false}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No group'} />} /> }}
                            rowSelection={isEditing ? {
                                type: 'checkbox',
                                onChange: this.onChangeGroups,
                                selectedRowKeys: this.state.selectedGroups,
                                getCheckboxProps: record => ({ disabled: record.is_team_group })
                            } : undefined}
                            onRow={isEditing ? undefined : this.onRow} />
                    </Card>
                </Col>
                {
                    this.showButton() ?
                        <Col xs={{ span: 24 }}>

                        </Col>
                        : null
                }
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsersGroups: (g: Group[]) => dispatch(changeUsersGroups(g)),
});

const mapStateToProps = (state: ApplicationState) => ({
    user: state.teamManagement.user,
    usersGroups: state.teamManagement.usersGroups,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(GroupsUser)));