import { Input } from 'antd';
import { InputProps, InputRef, PasswordProps, SearchProps, TextAreaProps } from 'antd/lib/input';
import React, { RefObject } from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../FAIcon';

export type InputFieldOnPressEnterEvent = React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>;
export type InputFieldOnChangeEvent = React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>;

interface IProps {
    error?: string;
    type?: "password" | "textarea" | "search" | "input";
    inputRef?: RefObject<InputRef>;
}

type Props = InputProps & TextAreaProps & PasswordProps & SearchProps & IProps & IntlProps;

/**
 * Component that represent a text input field
 */
class InputField extends React.Component<Props> {

    render() {
        const { error, type, className, style, id, inputRef, ...props } = this.props;

        //get the type of the field
        let Field = undefined;
        switch (type) {
            case "password":
                Field = Input.Password;
                break;
            case "textarea":
                Field = Input.TextArea;
                break;
            case "search":
                Field = Input.Search;
                break;
            default:
                Field = Input;
        }

        return (
            <div className={`inputfield-content ${className ? className : ''}`} style={style}>
                <Field
                    id={id}
                    ref={inputRef}
                    className={error ? 'inputfield-error' : ''}
                    iconRender={visible => visible ? <FAIcon prefix='fad' name='eye' /> : <FAIcon prefix='fad' name='eye-slash' />}
                    placeholder={type === "search" ? this.props.intl.formatMessage({ defaultMessage: 'Search' }) : undefined}
                    {...props} />
                {
                    error ?
                        <p className="inputfield-error-text">{error}</p>
                        : null
                }
            </div>
        );
    }
}

export default injectIntl(InputField);