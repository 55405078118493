import { Spin } from 'antd';
import { Component, CSSProperties } from 'react';

import CountUp from 'react-countup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ICustomerStat } from '../../../../utils/types/customerTypes';
import { isNullOrEmpty } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../FAIcon';
import SpaceContent from '../../general/spaceContent';
import "./customerCard.css";

interface Props extends IntlProps {
    title: string;
    unassigned: boolean;
    code?: string;
    description: string;
    color?: string;
    logo?: React.ReactNode;
    actions?: React.ReactNode;
    customerStats?: ICustomerStat;
}

interface MyCustomCSS extends CSSProperties {
    '--modern-card-color': string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type State = {
    clicked: boolean;
};

export class CustomerCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            clicked: false
        };
    }

    render() {
        const { description: propsDescription, logo, actions, color, code, unassigned, title: propsTitle, customerStats, intl } = this.props;
        const { clicked } = this.state;
        const title = unassigned ? intl.formatMessage({ defaultMessage: 'Unassigned mandates' }) : propsTitle;
        const description = unassigned ? intl.formatMessage({ defaultMessage: 'This generic client groups unassigned mandates.' }) : propsDescription;

        return (
            <div onMouseEnter={() => this.setState({ clicked: true })} onClick={() => this.setState({ clicked: true })} className={`__modern-card${clicked ? ' clicked' : ''}`} style={{ '--modern-card-color': color ?? '#bea156' } as MyCustomCSS}>
                <div className="__modern-card-image">{logo}</div>
                <div className="__modern-card-info">
                    <p className='__modern-card-title' title={title}>{title}</p>
                    <p className='__modern-card-code' title={code}>{code}</p>
                </div>
                <div className='__modern-card-description' title={description}>
                    {
                        isNullOrEmpty(description) ?
                            <p><FormattedMessage defaultMessage={'At this stage, no description has been recorded for this client.'} /></p>
                            : <p>{description}</p>
                    }
                    <Spin spinning={customerStats === undefined}>
                        <div style={{ marginTop: '15px' }} className="__modern-card-stats">
                            <CountUp
                                delay={0.2}
                                start={clicked ? undefined : 0}
                                end={customerStats ? customerStats.sites : 0}
                                duration={1.5}
                            >
                                {({ countUpRef }) => (
                                    <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                        <FAIcon prefix='fad' name='code-branch' />
                                        <span ref={countUpRef} />
                                        <span><FormattedMessage defaultMessage={'Branches'} description={'Succursales'} /></span>
                                    </div>
                                )}
                            </CountUp>
                            <CountUp
                                delay={0.2}
                                start={clicked ? undefined : 0}
                                end={customerStats ? customerStats.contacts : 0}
                                duration={1.5}
                            >
                                {({ countUpRef }) => (
                                    <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                        <FAIcon prefix='fad' name='address-book' />
                                        <span ref={countUpRef} />
                                        <span><FormattedMessage defaultMessage={'Contacts'} /></span>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="__modern-card-stats">
                            <CountUp
                                delay={0.2}
                                start={clicked ? undefined : 0}
                                end={customerStats ? customerStats.mandates : 0}
                                duration={1.5}
                            >
                                {({ countUpRef }) => (
                                    <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                        <FAIcon prefix='fad' name='folder-check' />
                                        <span ref={countUpRef} />
                                        <span><FormattedMessage defaultMessage={'Mandates'} /></span>
                                    </div>
                                )}
                            </CountUp>
                            <CountUp
                                delay={0.2}
                                start={clicked ? undefined : 0}
                                end={customerStats ? customerStats.missions : 0}
                                duration={1.5}
                            >
                                {({ countUpRef }) => (
                                    <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                        <FAIcon prefix='fad' name='calendar-lines-pen' />
                                        <span ref={countUpRef} />
                                        <span><FormattedMessage defaultMessage={'Missions'} /></span>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                    </Spin>
                </div>
                <div className="__modern-card-buttons">
                    <SpaceContent space={10}>
                        {actions}
                    </SpaceContent>
                </div>
            </div>
        );
    }
}

export default (injectIntl(CustomerCard));