import { Card as ACard, Badge, Button, Calendar, Col, DatePicker, Empty, Input, InputNumber, Modal, Row, Segmented, Select, Space, Spin, Table, TimePicker, Tooltip, } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment, { Moment } from "moment";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { changeOccupancyRates, changeTypesOfDay, } from "../../../store/actions/configurations";
import { changeTemplates } from "../../../store/actions/planning";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { Countries, Majoration } from "../../../utils/types/generalTypes";
import { NetworkHolidays, NetworkVacations, } from "../../../utils/types/networkTypes";
import { PlanningHolidays, PlanningOccupancyRate, PlanningTemplate, PlanningVacations, TypeOfDay, } from "../../../utils/types/planningTypes";
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch, } from "../../../utils/types/storeTypes";
import { convertNetworkHolidaysToPlanningHolidays, convertNetworkVacationsToPlanningVacations, showNotification, } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";

interface IProps {
    height: number;
}

type Props = IProps & ConfigurationsDispatchProps & PlanningDispatchProps & IntlProps;

interface State {
    majorations: Majoration[];
    rangeTime: { start: Moment; end: Moment; };
    year: Moment;
    yearLoading: boolean;
    activePanel: number;
    days: string[];
    holidaysLoading: boolean;
    vacationsLoading: boolean;
    countries: Countries;
    holidays: PlanningHolidays[];
    vacations: PlanningVacations[];
    editedMajoration: Majoration | undefined;
}

/**
 * Component for the Travel and vehicle tab in the configurations page
 */
class MajorationTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            countries: {},
            holidays: [],
            vacations: [],
            holidaysLoading: true,
            vacationsLoading: true,
            days: [
                this.props.intl.formatMessage({ defaultMessage: "Monday" }),
                this.props.intl.formatMessage({ defaultMessage: "Tuesday" }),
                this.props.intl.formatMessage({ defaultMessage: "Wednesday" }),
                this.props.intl.formatMessage({ defaultMessage: "Thursday" }),
                this.props.intl.formatMessage({ defaultMessage: "Friday" }),
                this.props.intl.formatMessage({ defaultMessage: "Saturday" }),
                this.props.intl.formatMessage({ defaultMessage: "Sunday" }),
                this.props.intl.formatMessage({ defaultMessage: "Public holiday" }),
                this.props.intl.formatMessage({ defaultMessage: "Day off" }),
                this.props.intl.formatMessage({ defaultMessage: "Date" }),
            ],
            activePanel: 1,
            editedMajoration: undefined,
            majorations: [],
            rangeTime: {
                start: moment(),
                end: moment().add(1, "day"),
            },
            year: moment(),
            yearLoading: true,
        };
    }
    componentDidMount(): void {
        this.refresh(this.state.year.year());
        this.refreshHolidays();
        this.refreshVacations();
    }
    /**
     * Refresh the types of day
     * @param message a message to display as a success message - optional
     */
    refresh = (year: number) => {
        // get all majoration
        this.setState({ yearLoading: true });
        return Network.getAllIncreasedhoursByDay(year).then(
            (response) => {
                console.log("RESPONSE", response);
                this.setState({ yearLoading: false, majorations: response });
            },
            () => {
                this.setState({ yearLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: "An error occurred while loading the hours of {year}" }, { year: year }), "warning");
            }
        );
    };
    refreshVacations = (): void => {
        this.setState({ vacationsLoading: true });
        Network.getVacations().then(
            (response: NetworkVacations[]) => {
                this.setState({
                    vacations: response.map((v) => convertNetworkVacationsToPlanningVacations(v)),
                    vacationsLoading: false,
                });
            },
            () => {
                this.setState({ vacationsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: "An error occurred while loading the vacations", }), "warning");
            }
        );
    };
    refreshHolidays = (): void => {
        this.setState({ holidaysLoading: true });
        Network.getRegions().then(
            (response) => this.setState({ countries: response }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: "An error occurred while loading the available cantons", }), "warning")
        );
        Network.getHolidays().then(
            (response: NetworkHolidays[]) =>
                this.setState({ holidays: response.map((r) => convertNetworkHolidaysToPlanningHolidays(r)), holidaysLoading: false, }),
            () => {
                this.setState({ holidaysLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: "An error occurred while loading the holidays", }), "warning");
            }
        );
    };
    changeYear = (date: Moment) => {
        this.setState({ year: date });
        this.refresh(date.year());
    };

    onPanelChange = (value: Moment) => {
        if (value.year() !== this.state.year.year())
            this.changeYear(value);
        else
            this.setState({ year: value });
    };
    showModal = (majoration: Majoration) => {
        this.setState({ editedMajoration: majoration });
    };

    majorationColumns: ColumnProps<Majoration>[] = [
        {
            title: <FormattedMessage defaultMessage={"Title"} />,
            key: "title",
            className: "__min-width-220",
            dataIndex: "title",
            onFilter: (value, record) => {
                return record.title
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: Majoration, b: Majoration) => (a.title < b.title ? -1 : 1),
        },
        {
            title: <FormattedMessage defaultMessage={"Valid from"} />,
            key: "ruleStartDate",
            className: "__width_150 __centered-text",
            sorter: { compare: (a, b) => moment(a.ruleStartDate).unix() - moment(b.ruleStartDate).unix() },
            render: (ob: Majoration) => moment(ob.ruleStartDate).format(getFormat("DATE")),
        },
        {
            title: <FormattedMessage defaultMessage={"Valid until"} />,
            key: "ruleEndDate",
            className: "__width_150 __centered-text",
            sorter: { compare: (a, b) => moment(a.ruleEndDate).unix() - moment(b.ruleEndDate).unix() },
            render: (ob: Majoration) => moment(ob.ruleEndDate).format(getFormat("DATE")),
        },
        {
            title: <FormattedMessage defaultMessage={"Day"} />,
            key: "day",
            className: "__width_180 __centered-text",
            defaultSortOrder: "descend",
            sorter: (a: Majoration, b: Majoration) => (a.day < b.day ? -1 : 1),
            render: (ob: Majoration) => {
                if (ob.day === 10 && ob.date)
                    return (
                        this.state.days[ob.day - 1] +
                        " (" +
                        moment(ob.date).format(getFormat("DATE")) +
                        ")"
                    );
                else
                    return this.state.days[ob.day - 1];

            },
        },
        {
            title: <FormattedMessage defaultMessage={"Start hour"} />,
            key: "startTime",
            className: "__width_150 __centered-text",
            sorter: { compare: (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix() },
            render: (ob: Majoration) => moment("01-01-1970 " + ob.startTime, "DD-MM-YYYY HH:mm:ssZ").utc().format(getFormat("TIME")),
        },
        {
            title: <FormattedMessage defaultMessage={"End hour"} />,
            key: "endTime",
            className: "__width_150 __centered-text",
            sorter: { compare: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix() },
            render: (ob: Majoration) => moment("01-01-1970 " + ob.endTime, "DD-MM-YYYY HH:mm:ssZ").utc().format(getFormat("TIME")),
        },
        {
            title: <FormattedMessage defaultMessage={"Percentage"} />,
            key: "percentage",
            className: "__width_120",
            dataIndex: "percentage",
            sorter: { compare: (a, b) => a.percentage - b.percentage },
            render: (percentage) => percentage + " %",
        },
        //     {
        //         title: 'Actions',
        //         key: 'actions',
        //         fixed: 'right',
        //         className: '__width_80 __centered-text',
        //         render: (ob: Majoration) => {
        //             return (
        //                 <Space>
        //                     <DeleteButton
        //                         text={"Voulez-vous supprimer ces majorations?"}
        //                         key="template-modal-delete-color"
        //                         onConfirm={() => console.log("TODO")}
        //                         placement="topRight"
        //                         buttonPlacement="right" />
        //                     <CircleButton
        //                         icon={<EditOutlined />}
        //                         title={"Modifier"}
        //                         placement="left"
        //                         onClick={() => this.showModal(ob)} />
        //                 </Space>
        //             )
        //         },
        //     }
    ];

    dateCellRender = (value: Moment): React.ReactNode => {
        const { majorations } = this.state;
        const majs: Majoration[] = majorations.filter((majoration) => {
            console.log("TEST", moment(majoration!.ruleStartDate).month(), value.month());
            return (
                majoration !== undefined &&
                majoration.ruleStartDate &&
                moment(majoration.ruleStartDate).month() <= value.month() &&
                majoration.ruleEndDate &&
                moment(majoration.ruleEndDate).month() >= value.month() &&
                ((majoration.day <= 7 && majoration.day === value.day()) ||
                    (majoration.day === 10 &&
                        majoration.date &&
                        moment(majoration.date).format("YYYY-MM-DD") ===
                        value.format("YYYY-MM-DD")))
            );
        });
        //return the modified cell
        return (
            <div
                onClick={majs.length > 0 ? () => console.log("TODO") : undefined}
                style={{
                    minHeight: "100%",
                    minWidth: "100%",
                    backgroundColor: majs.length > 0 ? "var(--planning-is-period)" : undefined,
                }}
            >
                <ul style={{ paddingLeft: "5px" }}>
                    {majs.map((period) => (
                        <li
                            style={{
                                listStyle: "none",
                                display: "flex",
                                alignItems: "center",
                            }}
                            key={`team-periods-day-${period.id}`}
                        >
                            <Tooltip
                                title={
                                    <div style={{ textAlign: "center" }}>
                                        <p>{`${period.percentage}%`}</p>
                                        {
                                            period.allDay ?
                                                <p>
                                                    <FormattedMessage defaultMessage={"All day"} />
                                                </p>
                                                :
                                                <div>
                                                    <p>
                                                        <FormattedMessage
                                                            defaultMessage={"Begins at {start}"}
                                                            values={{
                                                                start: moment(
                                                                    "01-01-1970 " + period.startTime,
                                                                    "DD-MM-YYYY HH:mm:ssZ"
                                                                )
                                                                    .utc()
                                                                    .format(getFormat("TIME")),
                                                            }}
                                                        />
                                                    </p>
                                                    <p>
                                                        <FormattedMessage
                                                            defaultMessage={"Ends at {start}"}
                                                            values={{
                                                                start: moment(
                                                                    "01-01-1970 " + period.endTime,
                                                                    "DD-MM-YYYY HH:mm:ssZ"
                                                                )
                                                                    .utc()
                                                                    .format(getFormat("TIME")),
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                        }
                                        {
                                            period.day <= 7 ?
                                                <p>
                                                    <FormattedMessage
                                                        defaultMessage={"Every {day}"}
                                                        values={{ day: this.state.days[period.day] }}
                                                    />
                                                </p>
                                                :
                                                period.day === 8 || period.day === 9 ?
                                                    undefined
                                                    :
                                                    <p>
                                                        <FormattedMessage
                                                            defaultMessage={"The {date}"}
                                                            values={{
                                                                date: moment(period.date).format(getFormat("DATE")),
                                                            }}
                                                        />
                                                    </p>
                                        }
                                        <p>
                                            <FormattedMessage
                                                defaultMessage={"Valid from {date}"}
                                                values={{
                                                    date: moment(period.ruleStartDate).format(
                                                        getFormat("DATE")
                                                    ),
                                                }}
                                            />
                                        </p>
                                        <p>
                                            <FormattedMessage
                                                defaultMessage={"Valid until {date}"}
                                                values={{
                                                    date: moment(period.ruleEndDate).format(
                                                        getFormat("DATE")
                                                    ),
                                                }}
                                            />
                                        </p>
                                    </div>
                                }
                            >
                                <Badge
                                    color="var(--primary-color)"
                                    text={period.title.length > 12 ? period.title.slice(0, 12) + "..." : period.title}
                                />
                            </Tooltip>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    monthCellRender = (value: Moment): React.ReactNode => {
        const { majorations } = this.state;
        const majs: Majoration[] = majorations.filter((majoration) => {
            console.log("TEST", moment(majoration!.ruleStartDate).month(), value.month());
            return (
                majoration !== undefined &&
                majoration.ruleStartDate &&
                moment(majoration.ruleStartDate).month() <= value.month() &&
                majoration.ruleEndDate &&
                moment(majoration.ruleEndDate).month() >= value.month() &&
                (majoration.day <= 7 ||
                    (majoration.day === 10 &&
                        majoration.date &&
                        moment(majoration.date).month() === value.month()))
            );
        });

        //return the modified cell
        return (
            <div
                onClick={majorations.length > 0 ? () => console.log("TODO") : undefined}
                style={{
                    minHeight: "100%",
                    minWidth: "100%",
                    backgroundColor: majs.length > 0 ? "var(--planning-is-period)" : undefined,
                }}
            >
                <ul style={{ paddingLeft: "5px" }}>
                    {
                        majs.map((period) => (
                            <li
                                style={{ listStyle: "none" }}
                                key={`team-periods-month-${period.id}`}
                            >
                                <Tooltip
                                    title={
                                        <div style={{ textAlign: "center" }}>
                                            <p>{`${period.percentage}%`}</p>
                                            {
                                                period.allDay ?
                                                    <p>
                                                        <FormattedMessage defaultMessage={"All day"} />
                                                    </p>
                                                    :
                                                    <div>
                                                        <p>
                                                            <FormattedMessage
                                                                defaultMessage={"Begins at {start}"}
                                                                values={{
                                                                    start: moment(
                                                                        "01-01-1970 " + period.startTime,
                                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                                    )
                                                                        .utc()
                                                                        .format(getFormat("TIME")),
                                                                }}
                                                            />
                                                        </p>
                                                        <p>
                                                            <FormattedMessage
                                                                defaultMessage={"Ends at {start}"}
                                                                values={{
                                                                    start: moment(
                                                                        "01-01-1970 " + period.endTime,
                                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                                    )
                                                                        .utc()
                                                                        .format(getFormat("TIME")),
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                            }
                                            {
                                                period.day <= 7 ?
                                                    <p>
                                                        <FormattedMessage
                                                            defaultMessage={"Every {day}"}
                                                            values={{ day: this.state.days[period.day] }}
                                                        />
                                                    </p>
                                                    :
                                                    period.day === 8 ? undefined : period.day === 9 ?
                                                        undefined
                                                        :
                                                        <p>
                                                            <FormattedMessage
                                                                defaultMessage={"The {date}"}
                                                                values={{
                                                                    date: moment(period.date).format(getFormat("DATE")),
                                                                }}
                                                            />
                                                        </p>
                                            }
                                            <p>
                                                <FormattedMessage
                                                    defaultMessage={"Valid from {date}"}
                                                    values={{
                                                        date: moment(period.ruleStartDate).format(
                                                            getFormat("DATE")
                                                        ),
                                                    }}
                                                />
                                            </p>
                                            <p>
                                                <FormattedMessage
                                                    defaultMessage={"Valid until {date}"}
                                                    values={{
                                                        date: moment(period.ruleEndDate).format(
                                                            getFormat("DATE")
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    }
                                >
                                    <Badge color="var(--primary-color)" text={period.title} />
                                </Tooltip>
                            </li>
                        ))}
                </ul>
            </div>
        );
    };

    render() {
        const { editedMajoration } = this.state;
        const { intl } = this.props;
        let tableHeight = this.props.height - 50;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                        <Card
                            className="majoration-card"
                            icon={<FAIcon prefix='fad' name='percent' />}
                            title={<FormattedMessage defaultMessage={"Increased days"} />}
                            headerElements={[
                                <Space key={`majorationTab-card-header`}>
                                    <DatePicker
                                        style={{ marginRight: "10px" }}
                                        picker="year"
                                        placeholder={intl.formatMessage({ defaultMessage: "Year" })}
                                        value={this.state.year}
                                        onChange={(i: Moment | null) => {
                                            if (i !== null)
                                                this.changeYear(i);
                                        }}
                                        allowClear={false}
                                    />
                                    <CircleButton
                                        small
                                        key="majoration_add_button"
                                        style={{ marginRight: "8px", display: "none" }}
                                        title={intl.formatMessage({ defaultMessage: "Add an increased day" })}
                                        onClick={() => {
                                            this.showModal({
                                                title: "",
                                                active: true,
                                                allDay: false,
                                                day: 1,
                                                percentage: 0,
                                                ruleStartDate: moment("01/01/" + this.state.year.year()),
                                                ruleEndDate: moment("12/31/" + this.state.year.year()),
                                            });
                                        }}
                                        icon={<FAIcon prefix='far' name='plus' />}
                                        placement="right"
                                    />
                                </Space>,
                            ]}
                        >
                            {
                                this.state.yearLoading ?
                                    <Spin
                                        style={{ width: "100%", marginTop: "20px" }}
                                        size="large"
                                        spinning={this.state.yearLoading}
                                    />
                                    :
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                                            <Table
                                                className={"__logistic-object-value-list"}
                                                rowKey={(i: Majoration) =>
                                                    i.id !== undefined ? i.id : -1
                                                }
                                                dataSource={this.state.majorations}
                                                columns={this.majorationColumns}
                                                //rowSelection={{ type: 'checkbox', onChange: onChangeSelectedValue, selectedRowKeys: selectedValues }}
                                                pagination={{
                                                    defaultPageSize: 32,
                                                    pageSizeOptions: ["8", "16", "32", "64", "72"],
                                                    showSizeChanger: true,
                                                    hideOnSinglePage: true,
                                                    showTotal: (total, range) => (
                                                        <FormattedMessage
                                                            defaultMessage={"{range0}-{range1} of {total} increased days"}
                                                            values={{ range0: range[0], range1: range[1], total, }}
                                                        />
                                                    ),
                                                }}
                                                locale={{
                                                    emptyText: (
                                                        <Empty
                                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                            description={
                                                                <FormattedMessage defaultMessage={"No increased day"} />
                                                            }
                                                        />
                                                    ),
                                                }}
                                                scroll={{ x: true, y: tableHeight }}
                                            />
                                        </Col>
                                    </Row>
                            }
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} xxl={{ span: 24 }} style={{ display: "none" }}>
                        <Card
                            className="majoration-card"
                            icon={<FAIcon prefix='fad' name='calendar-lines' />}
                            title={<FormattedMessage defaultMessage={"Summary"} />}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                                    <ACard
                                        className="majoration-calendar fade-in-animation"
                                        title="Détails"
                                    >
                                        <Calendar
                                            onPanelChange={this.onPanelChange}
                                            dateCellRender={this.dateCellRender}
                                            monthCellRender={this.monthCellRender}
                                            value={this.state.year}
                                        />
                                    </ACard>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    width="880px"
                    className="majoration-modal"
                    title={
                        editedMajoration?.id ?
                            <FormattedMessage defaultMessage={"Edit increased day"} />
                            :
                            <FormattedMessage defaultMessage={"Add an increased day"} />
                    }
                    visible={editedMajoration !== undefined}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ editedMajoration: undefined })}
                    footer={[
                        <Button
                            type="dashed"
                            onClick={() => this.setState({ editedMajoration: undefined })}
                            key="majoration-modal-button-cancel"
                        >
                            <FormattedMessage defaultMessage={"Cancel"} />
                        </Button>,
                        <Button
                            id="occupancy-rate-modal-ok-button"
                            type="primary"
                            onClick={() => console.log("TODO")}
                            loading={false}
                            key="majoration-modal-button-ok"
                        >
                            {
                                editedMajoration?.id ?
                                    <FormattedMessage defaultMessage={"Save"} />
                                    :
                                    <FormattedMessage defaultMessage={"Add"} />
                            }
                        </Button>,
                    ]}
                >
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} xxl={{ span: 24 }}></Col>
                        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                            <Space direction={"vertical"}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                                        <Input
                                            addonBefore={<FormattedMessage defaultMessage={"Title"} />}
                                            placeholder={intl.formatMessage({ defaultMessage: "Monday evening increased" })}
                                            value={editedMajoration?.title}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={"Valid from"} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={"Valid until"} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            value={
                                                editedMajoration?.ruleStartDate
                                                    ? moment(editedMajoration.ruleStartDate)
                                                    : moment("01/01/" + this.state.year.year())
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    editedMajoration!.ruleStartDate = e;
                                                    this.setState({ editedMajoration: editedMajoration });
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            value={
                                                editedMajoration?.ruleEndDate
                                                    ? moment(editedMajoration.ruleEndDate)
                                                    : moment("12/31/" + this.state.year.year())
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    editedMajoration!.ruleEndDate = e;
                                                    this.setState({ editedMajoration: editedMajoration });
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={"Start hour"} />{":"}</p>
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={"End hour"} />{":"}</p>
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <TimePicker
                                            style={{ width: "100%" }}
                                            value={
                                                editedMajoration?.startTime
                                                    ? moment(
                                                        "01-01-1970 " + editedMajoration.startTime,
                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                    ).utc()
                                                    : moment(
                                                        "01-01-1970 08:00:00Z",
                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                    ).utc()
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    editedMajoration!.startTime = e
                                                        .utc()
                                                        .format("HH:mm:ssZ");
                                                    this.setState({ editedMajoration: editedMajoration });
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <TimePicker
                                            style={{ width: "100%" }}
                                            value={
                                                editedMajoration?.endTime
                                                    ? moment(
                                                        "01-01-1970 " + editedMajoration.endTime,
                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                    ).utc()
                                                    : moment(
                                                        "01-01-1970 18:00:00Z",
                                                        "DD-MM-YYYY HH:mm:ssZ"
                                                    ).utc()
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    editedMajoration!.endTime = e
                                                        .utc()
                                                        .format("HH:mm:ssZ");
                                                    this.setState({ editedMajoration: editedMajoration });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                            <Space direction={"vertical"}>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    addonBefore={<FormattedMessage defaultMessage={"Percentage"} />}
                                    value={editedMajoration?.percentage}
                                />
                                <Segmented
                                    block
                                    value={
                                        editedMajoration?.day &&
                                        (editedMajoration.day <= 7 ?
                                            "daily"
                                            : editedMajoration.day === 8
                                                ? "vacations"
                                                : editedMajoration.day === 9
                                                    ? "holidays"
                                                    : "date"
                                        )
                                    }
                                    options={[
                                        {
                                            label: intl.formatMessage({ defaultMessage: "Daily" }),
                                            value: "daily",
                                        },
                                        {
                                            label: this.state.days[7],
                                            value: "vacations",
                                        },
                                        {
                                            label: this.state.days[8],
                                            value: "holidays",
                                        },
                                        {
                                            label: this.state.days[9],
                                            value: "date",
                                        },
                                    ]}
                                    onChange={(e) => {
                                        if (e === "daily") {
                                            editedMajoration!.day = 1;
                                        } else if (e === "vacations") {
                                            editedMajoration!.day = 8;
                                        } else if (e === "holidays") {
                                            editedMajoration!.day = 9;
                                        } else if (e === "date") {
                                            editedMajoration!.day = 10;
                                        }
                                        this.setState({ editedMajoration: editedMajoration });
                                    }}
                                />
                                {editedMajoration?.day && editedMajoration.day <= 7 && (
                                    <Select
                                        style={{ width: "100%" }}
                                        value={editedMajoration !== undefined ? editedMajoration.day : 1}
                                        disabled={this.state.days.length === 0}
                                        placeholder={<FormattedMessage defaultMessage={"Select a day"} />}
                                        showSearch
                                        onChange={(e) => {
                                            editedMajoration!.day = e;
                                            this.setState({ editedMajoration: editedMajoration });
                                        }}
                                        filterOption={true}
                                        optionFilterProp="label"
                                    >
                                        {
                                            this.state.days?.filter((d, key) => key < 7).map((d, key) => {
                                                return (
                                                    <Select.Option
                                                        label={d}
                                                        value={key + 1}
                                                        key={`majoration-select-day-${key}`}
                                                    >
                                                        {d}
                                                    </Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                )}
                                {editedMajoration?.day && editedMajoration.day === 8 && <></>}
                                {editedMajoration?.day && editedMajoration.day === 9 && <></>}
                                {editedMajoration?.day && editedMajoration.day === 10 && (
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        value={
                                            editedMajoration.date
                                                ? moment(editedMajoration.date)
                                                : moment()
                                        }
                                        onChange={(e) => {
                                            if (e) {
                                                editedMajoration!.date = e;
                                                this.setState({ editedMajoration: editedMajoration });
                                            }
                                        }}
                                    />
                                )}
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }} xxl={{ span: 24 }}></Col>
                    </Row>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MajorationTab));
