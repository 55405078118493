import { IconName, IconPrefix } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Empty, List, Menu, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../rbacRules';
import '../../../styles/directoryListing.css';
import Network from '../../../utils/network';
import { ListingCategory, ListingDirectory, ListingSubCategory, RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import SpeedDial from '../../common/fields/speedDial';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import injectIntlHOC from '../../common/wrapper/injectIntlHOC';
import CreateEditDirectoryModal from './sidePanel/createEditDirectoryModal';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
}

type Props = RouterProps & IProps & ReduxProps & IntlProps;

interface State {
    currentPage: number;
    totalDirectories: number;
    directories: ListingDirectory[] | undefined;
    categories: ListingCategory[] | undefined;
    directoriesLoading: boolean;
    categoriesLoading: boolean;
    filterCategoryId: number | undefined;
    filterSubCategoryId: number | undefined;
    openCreateEditDirectory: boolean;
    editDirectory: ListingDirectory | undefined;
    openCategoryRightsModal: boolean;
    categorySelected?: ListingCategory;
    showAddMenu: boolean;
    subMenuName?: string;
    subMenuId?: number;
    showAddSubMenu: boolean;
    menuName?: string;
}

/**
 * Page for the directory listing
 */
class SidePanel extends React.Component<Props, State, any> {

    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 0,
            totalDirectories: 0,
            directories: undefined,
            categories: undefined,
            filterCategoryId: undefined,
            filterSubCategoryId: undefined,
            directoriesLoading: false,
            categoriesLoading: false,
            openCreateEditDirectory: false,
            editDirectory: undefined,
            openCategoryRightsModal: false,
            showAddMenu: false,
            subMenuId: undefined,
            subMenuName: undefined,
            showAddSubMenu: false,
            menuName: undefined

        };
    }

    componentDidMount() {
        //fetch directory listing
        this.refreshListing();

        //fetch all categories
        this.refreshCategories();

        //display alert message if needed
        // eslint-disable-next-line react/prop-types
        if (this.props.location && this.props.location.state && this.props.location.state.successMessage) {
            alert(this.props.location.state.successMessage, "success");
            this.props.history.replace({ state: undefined });
        }
    }

    /**
     * Refresh the listing categories
     * @param message a message to display as a success message - optional
     */
    refreshCategories = (message?: string): void => {
        this.setState({ categoriesLoading: true });
        Network.getListingCategories().then(
            response => {
                this.setState({ categories: response });
                this.setState({ categoriesLoading: false });
                if (message) alert(message, "success");
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the categories' }), "warning");
                this.setState({ categoriesLoading: false });
            }
        );
    };

    /**
     * Refresh the directory listing
     * @param message a message to display as a success message - optional
     */
    refreshListing = (message?: string): void => {
        this.setState({ directoriesLoading: true });
        // ATTENTION REMOVE
        // Temporary wait for display pdf and image, upload not wait before refresh
        setTimeout(() => {
            //get total number of directories
            Network.getListingTotalDirectories(this.state.filterCategoryId, this.state.filterSubCategoryId).then(
                response => this.setState({ totalDirectories: response.total }),
                () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the directories' }), "warning"),
            );

            //get directory listing
            Network.getListingDirectories(this.state.currentPage, this.state.filterCategoryId, this.state.filterSubCategoryId).then(
                response => {
                    this.setState({ directories: response, directoriesLoading: false, editDirectory: undefined, openCreateEditDirectory: false });
                    if (message) alert(message, "success");
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the directories' }), "warning");
                    this.setState({ directoriesLoading: false });
                },
            );
        }, 500);
    };

    /**
     * Change the current listing page
     * @param page the new page number
     */
    changeListingPage = (page: number): void => {
        this.setState({ directoriesLoading: true });
        page -= 1; //because the page start from 0 and the antd table pagination at 1

        //get directory listing
        Network.getListingDirectories(page, this.state.filterCategoryId, this.state.filterSubCategoryId).then(
            response => {
                this.setState({ directories: response, directoriesLoading: false, currentPage: page });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the directories' }), "warning");
                this.setState({ directoriesLoading: false });
            },
        );
    };

    /**
     * Set the filter for the directory listing
     * @param category if the filter is for a category - optional
     * @param subCategory if the filter is for a subCategory - optional
     */
    setFilter = (category?: ListingCategory, subCategory?: ListingSubCategory) => {
        if (category) {
            this.setState({
                filterCategoryId: category.id === this.state.filterCategoryId ? undefined : category.id,
                filterSubCategoryId: undefined,
            }, this.refreshListing);
        } else if (subCategory) {
            this.setState({
                filterSubCategoryId: subCategory.id === this.state.filterSubCategoryId ? undefined : subCategory.id,
                filterCategoryId: undefined
            }, this.refreshListing);
        }
    };

    /**
     * Categories columns format for table
     */
    columnsCategories = (): ColumnsType<ListingCategory> => [
        {
            title: <FormattedMessage defaultMessage={'Name'} />,
            dataIndex: 'name',
            key: 'id',
            width: '100%',
            render: (title, record) => <p style={{ fontWeight: record.id === this.state.filterCategoryId ? 'bold' : undefined }}>
                {record.icon ? <FontAwesomeIcon style={{ marginRight: 10 }} icon={[record.icon.split(" ")[0] as IconPrefix, record.icon.split(" ")[1] as IconName]} /> : ''}
                {title}
            </p>,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'id',
            render: record => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <SpeedDial
                        small
                        icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                        openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                        buttons={[
                            <CircleButton
                                small
                                key={`directorylisting-columnsCategories-action-detail`}
                                title={this.props.intl.formatMessage({ defaultMessage: 'Details' })}
                                icon={<FAIcon prefix='fad' name='file-magnifying-glass' />}
                                onClick={(e) => { e.stopPropagation(); this.props.history.push(`/${this.props.match.params.lang}/mobile-app/side-panel/menu/${record.id}`); }} />,
                            <CircleButton
                                key={`directorylisting-columnsCategories-action-rights`}
                                small
                                title={this.props.intl.formatMessage({ defaultMessage: 'Restricting rights' })}
                                icon={<FAIcon prefix='fad' name='lock' />}
                                onClick={(e) => { e.stopPropagation(); this.setState({ openCategoryRightsModal: true, categorySelected: record }); }} />,
                            <DeleteButton
                                small
                                key={`directorylisting-columnsCategories-action-delete`}
                                text={this.props.intl.formatMessage({ defaultMessage: 'Do you want to delete this category and all its subcategories?' })}
                                okText={this.props.intl.formatMessage({ defaultMessage: 'Yes' })}
                                cancelText={this.props.intl.formatMessage({ defaultMessage: 'No' })}
                                onConfirm={e => { e?.stopPropagation(); this.deleteCategory(record.id); }} />
                        ]} />
                </div>)
        }];

    /**
     * Listing directory columns format for table
     */
    columnsDirectory = (): ColumnsType<ListingDirectory> => [
        { title: this.props.intl.formatMessage({ defaultMessage: 'Name' }), dataIndex: 'name', key: 'name' },
        { title: this.props.intl.formatMessage({ defaultMessage: 'Menu' }), className: '__min-width-250', render: record => <p>{this.getCategoryName(record.subcategory)}</p> },
        { title: this.props.intl.formatMessage({ defaultMessage: 'Submenu' }), className: '__width_200', render: record => <p>{this.getSubCategoryName(record.subcategory)}</p> },
        {
            title: this.props.intl.formatMessage({ defaultMessage: 'Actions' }), className: '__width_100', key: 'id', render: record => (<div style={{ display: 'flex', justifyContent: 'center' }}>
                <DeleteButton
                    text={<FormattedMessage defaultMessage={'Do you want to delete this menu?'} />}
                    okText={this.props.intl.formatMessage({ defaultMessage: 'Yes' })}
                    cancelText={this.props.intl.formatMessage({ defaultMessage: 'No' })}
                    placement="left"
                    onConfirm={(e) => { e?.stopPropagation(); this.deleteDirectory(record.id); }} />
            </div>
            )
        }
    ];

    /**
     * Expendable object for category table
     */
    expandable = (): ExpandableConfig<ListingCategory> => ({
        expandedRowRender: (record) => (
            <List
                renderItem={this.renderSubCategory}
                dataSource={record.subcategory} />
        ),
        rowExpandable: (record) => record.subcategory && record.subcategory.length > 0,
    });

    /**
     * Get category name
     * @param subCategoryId the sub-category's id
     * @return the name of the category
     */
    getCategoryName = (subCategoryId: number): string => {
        if (!this.state.categories) return "";
        for (const c of this.state.categories!) {
            for (const s of c.subcategory) {
                if (s.id === subCategoryId) {
                    return c.name;
                }
            }
        }
        return "";
    };

    /**
     * Get sub-category name
     * @param subCategoryId the sub-category's id
     * @return the name of the sub-category
     */
    getSubCategoryName = (subCategoryId: number): string => {
        if (!this.state.categories) return "";
        for (const c of this.state.categories!) {
            for (const s of c.subcategory) {
                if (s.id === subCategoryId) {
                    return s.name;
                }
            }
        }
        return "";
    };

    /**
     * Create a new category
     * @param name the name of the new category to create
     */
    createCategory = (name: string) => {
        Network.createListingCategory({ name }).then(
            () => this.refreshCategories(this.props.intl.formatMessage({ defaultMessage: 'The menu has been successfully created' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the menu' }), "warning"),
        );
    };

    /**
     * Create a new category
     * @param name the name of the new sub category to create
     * @param category_id the id of the parent category
     */
    createSubCategory = (name: string, category_id: number) => {
        Network.createListingSubCategory({ name, category_id }).then(
            () => this.refreshCategories(this.props.intl.formatMessage({ defaultMessage: 'The submenu has been successfully created' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the submenu' }), "warning"),
        );
    };

    /**
     * Delete a category
     * @param categoryId the category's id to delete
     */
    deleteCategory = (categoryId: number) => {
        Network.deleteListingCategory(categoryId).then(
            () => this.refreshCategories(this.props.intl.formatMessage({ defaultMessage: 'The menu has been successfully deleted' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the menu' }), "warning"),
        );
    };

    setCategoryRights = () => {
        const { categorySelected } = this.state;
        let { categories } = this.state;
        if (categorySelected) {
            Network.setCategoryRights({
                categoryId: categorySelected.id,
                groupsIds: categorySelected.groups ? categorySelected.groups : []
            }).then(
                (success: ListingCategory) => {
                    categories = categories?.map(c => {
                        if (c.id === success.id) {
                            return success;
                        } else {
                            return c;
                        }
                    });
                    this.setState({ categories, openCategoryRightsModal: false, categorySelected: undefined }, () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The menu has been successfully configured' }), "success");
                    });

                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while configuring the rights' }), "error");
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'No category selected' }), "error");
        }
    };

    /**
     * Delete a sub category
     * @param subCategoryId the sub category's id to delete
     */
    deleteSubCategory = (subCategoryId: number) => {
        Network.deleteListingSubCategory(subCategoryId).then(
            () => this.refreshCategories(this.props.intl.formatMessage({ defaultMessage: 'The submenu has been successfully deleted' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the submenu' }), "warning"),
        );
    };

    /**
     * Delete a listing item
     * @param itemId the listing item's id to delete
     */
    deleteDirectory = (itemId: number) => {
        Network.deleteListingDirectory(itemId).then(
            () => this.refreshListing(this.props.intl.formatMessage({ defaultMessage: 'The menu has been successfully deleted' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the menu' }), "warning"),
        );
    };

    /**
     * Render a sub-category
     * @param subCategory the sub-category to render
     * @returns the component to render
     */
    renderSubCategory = (subCategory: ListingSubCategory): React.ReactNode => {
        return (
            <List.Item style={{ paddingLeft: '20px' }} onClick={() => this.setFilter(undefined, subCategory)} actions={[
                <DeleteButton
                    key={`directoryListing-sub-category-delete`}
                    text={<FormattedMessage defaultMessage={'Do you want to delete this submenu?'} />}
                    small
                    okText={this.props.intl.formatMessage({ defaultMessage: 'Yes' })}
                    cancelText={this.props.intl.formatMessage({ defaultMessage: 'No' })}
                    onConfirm={() => this.deleteSubCategory(subCategory.id)} />,
            ]}>
                <p style={{ fontWeight: subCategory.id === this.state.filterSubCategoryId ? 'bold' : undefined }}>{subCategory.name}</p>
            </List.Item>
        );
    };

    render() {
        const { categories, filterCategoryId, filterSubCategoryId } = this.state;
        const { intl } = this.props;

        return (
            <Can rule={Rules.DirectoryListing.Visit} redirect="/dashboard">
                <div className="listing-container" >
                    <Card className="listing-card" icon={<FAIcon prefix='fad' name='sidebar' />} title={<FormattedMessage defaultMessage={'Menus'} />} headerElements={[
                        <Dropdown key="listing-add-category" trigger={['click']} overlay={(
                            <Menu>
                                <Menu.Item onClick={() => this.setState({ showAddMenu: true })}><FormattedMessage defaultMessage={'Add an element to the menu'} /></Menu.Item>
                                {categories && categories.length > 0 && <Menu.Item onClick={() => this.setState({ showAddSubMenu: true })}><FormattedMessage defaultMessage={'Add a submenu'} /></Menu.Item>}
                            </Menu>
                        )}>
                            <CircleButton
                                small
                                icon={<FAIcon prefix='far' name='plus' />}
                                title={intl.formatMessage({ defaultMessage: 'Add' })}
                            />
                        </Dropdown>
                    ]}>
                        <Table
                            rowKey={record => record.id}
                            dataSource={categories}
                            columns={this.columnsCategories()}
                            expandable={this.expandable()}
                            pagination={false}
                            loading={this.state.categoriesLoading}
                            onRow={r => ({
                                onClick: () => this.setFilter(r),
                            })}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No menu'} />} /> }}
                        />
                    </Card>
                    <Card
                        className="listing-card"
                        icon={<FAIcon prefix='fad' name='list-ul' />}
                        title={<FormattedMessage defaultMessage={'Submenus list'} />}
                        headerElements={[
                            <CircleButton
                                small
                                title={intl.formatMessage({ defaultMessage: 'Add a directory' })}
                                icon={<FAIcon prefix='far' name='plus' />} key="listing-add-listing-item"
                                onClick={() => this.setState({ openCreateEditDirectory: true })}
                            />
                        ]}>
                        <div className="listing-filter">
                            <p style={{ marginRight: '10px' }}><FormattedMessage defaultMessage={'Filter'} />{':'}</p>
                            {filterCategoryId && <p><FormattedMessage defaultMessage={'Menu - {name}'} values={{ name: categories?.find(c => c.id === filterCategoryId)?.name }} /></p>}
                            {filterSubCategoryId && <p><FormattedMessage defaultMessage={'Submenu - {name}'} values={{ name: this.getSubCategoryName(filterSubCategoryId) }} /></p>}
                            {!filterCategoryId && !filterSubCategoryId && <p><FormattedMessage defaultMessage={'None'} /></p>}
                        </div>
                        <Table
                            rowKey={record => record.id}
                            dataSource={this.state.directories}
                            columns={this.columnsDirectory()}
                            loading={this.state.directoriesLoading}
                            onRow={r => ({
                                onClick: () => this.setState({ openCreateEditDirectory: true, editDirectory: r }),
                            })}
                            pagination={{
                                current: this.state.currentPage + 1,
                                pageSize: 20,
                                hideOnSinglePage: true,
                                total: this.state.totalDirectories,
                                onChange: this.changeListingPage,
                            }}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No submenu'} />} /> }}
                        />
                    </Card>
                    {categories && this.state.openCreateEditDirectory &&
                        <CreateEditDirectoryModal
                            visible={this.state.openCreateEditDirectory}
                            categories={this.state.categories!}
                            editDirectory={this.state.editDirectory}
                            initialCategoryId={this.state.filterCategoryId}
                            initialSubCategoryId={this.state.filterSubCategoryId}
                            onClose={() => this.setState({ openCreateEditDirectory: false, editDirectory: undefined })}
                            // ATTENTION REMOVE
                            // Temporary wait for display pdf and image, upload not wait before refresh (message "Veuillez...")
                            onDone={() => this.refreshListing(this.state.editDirectory ? intl.formatMessage({ defaultMessage: 'Directory successfully modified. Please refresh the page if it is not displayed.' }) : intl.formatMessage({ defaultMessage: 'The directory created successfully. Please refresh the page if it is not displayed.' }))} />
                    }

                </div>
                <Modal
                    centered={true}
                    // bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 190px)' }}
                    // className="configurations-modal"
                    title={<FormattedMessage defaultMessage={'Restricting rights'} />}
                    visible={this.state.openCategoryRightsModal}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ openCategoryRightsModal: false })}
                    footer={[
                        <Button key={`directorylisting-rights-cancel`} type="dashed" onClick={() => this.setState({ openCategoryRightsModal: false, categorySelected: undefined })}>
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button key={`directorylisting-rights-save`} type="primary" onClick={() => { this.setCategoryRights(); }}>
                            <FormattedMessage defaultMessage={'Save'} />
                        </Button>
                    ]}>
                    <Select
                        // disabled={this.props.loading}
                        mode="multiple"
                        maxTagCount="responsive"
                        allowClear
                        placeholder={<><FormattedMessage defaultMessage={'Restrict to groups'} />{':'}</>}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            const category = cloneDeep(this.state.categorySelected);
                            const selectedGroups = this.props.groups?.filter(c => e.findIndex(id => id === c.id) !== -1);
                            category && (category.groups = selectedGroups?.map(sg => sg.id!));
                            this.setState({ categorySelected: category });
                        }}
                        value={this.state.categorySelected?.groups}
                        filterOption={true}
                        optionFilterProp="label"
                        showArrow
                    >
                        {
                            this.props.groups && this.props.groups.length > 0 &&
                            this.props.groups.map((g) => {
                                return <Select.Option label={g.name} value={g.id} key={"groups-" + g.id}>{g.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Modal>
                {
                    this.state.showAddMenu &&
                    <Modal
                        open
                        onCancel={() => this.setState({ showAddMenu: false })}
                        footer={[]}
                        title={<span className="flex"><FAIcon prefix='fad' name='folder' color='var(--primary-color)' style={{ marginRight: '20px' }} /><p><FormattedMessage defaultMessage={'Add a menu'} /></p></span>}
                    >
                        <div className='listing-add-category-modal'>
                            <InputField
                                className="listing-add-category-modal-input"
                                placeholder={intl.formatMessage({ defaultMessage: 'Menu name' })}
                                onChange={(e: InputFieldOnChangeEvent) => this.setState({ menuName: e.target.value })}
                                onPressEnter={() => {
                                    if (this.state.menuName) {
                                        this.createCategory(this.state.menuName);
                                        this.setState({ showAddMenu: false, menuName: '' });
                                    }
                                }} />
                            <Button id="listing-add-category-modal-button" onClick={() => {
                                if (this.state.menuName) {
                                    this.createCategory(this.state.menuName);
                                    this.setState({ showAddMenu: false, menuName: '' });
                                }
                            }}><FormattedMessage defaultMessage={'Add'} /></Button>
                        </div>
                    </Modal>
                }
                {
                    this.state.showAddSubMenu &&
                    <Modal
                        open
                        onCancel={() => this.setState({ showAddSubMenu: false })}
                        footer={[]}
                        title={<span className="flex"><FAIcon prefix='fad' name='folder' color='var(--primary-color)' style={{ marginRight: '20px' }} /><p><FormattedMessage defaultMessage={'Add a submenu'} /></p></span>}
                    >
                        <div className='listing-add-subcategory-modal'>
                            <div className="flex">
                                <p><FormattedMessage defaultMessage={'Menu'} />{':'}</p>
                                <Select
                                    onChange={(e) => this.setState({ subMenuId: e as number })}
                                    className="listing-add-category-modal-select">
                                    {this.state.categories?.map(c => (<Select.Option value={c.id} key={`listing-categories-${c.id}`}>{c.name}</Select.Option>))}
                                </Select>
                            </div>
                            <div className="flex" style={{ marginTop: '20px' }}>
                                <p> <FormattedMessage defaultMessage={'Submenu'} />{':'}</p>
                                <InputField
                                    className="listing-add-category-modal-input"
                                    placeholder={intl.formatMessage({ defaultMessage: 'Submenu name' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.setState({ subMenuName: e.target.value })}
                                    onPressEnter={() => this.state.subMenuName && this.state.subMenuId ? this.createSubCategory(this.state.subMenuName, this.state.subMenuId) : undefined} />
                                <Button id="listing-add-subcategory-modal-button" onClick={() => {
                                    if (this.state.subMenuName && this.state.subMenuId) {
                                        this.createSubCategory(this.state.subMenuName, this.state.subMenuId);
                                        this.setState({ showAddSubMenu: false, subMenuName: '', subMenuId: undefined });
                                    }
                                }}><FormattedMessage defaultMessage={'Add'} /></Button>
                            </div>
                        </div>
                    </Modal>
                }
            </Can >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    groups: state.teamManagement.groups,
});
const connector = connect(mapStateToProps);
export default connector(withRouter(injectIntlHOC(SidePanel)));