import { Avatar, DatePicker, Divider, Empty, Image, Select, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuadrigisLogo from '../../../images/icons/quadrigis.svg';
import { ContractTypeMode, MOMENT_MONTH_FORMAT, MOMENT_SHORT_TIME_FORMAT, VehicleTypesDescription, VehicleTypesIds } from '../../../utils/constants';
import Network from '../../../utils/network';
import { CctSecurity } from '../../../utils/objects/cct/cctSecurity';
import { RouterProps, User, UserJobTMP } from '../../../utils/types/generalTypes';
import { NetworkAllHolidays, NetworkCctSecurityResponse, NetworkIncreasedHoursByDay } from '../../../utils/types/networkTypes';
import { AllHolidays, MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray } from '../../../utils/types/planningTypes';
import { CctSecurityData, CctSecurityEventsByContractByMonth, CctSecurityType, CctSecurityUserEventsByMonth, CctSecurityUserEventsByMonthOverview, CctSecurityUserEventsByMonthRecap, CctSecurityUserEventsByMonthSummary, CctSecurityUserEventsByMonthTravelSummary, CctSecurityUserHoursSummaryInMonth, IncreasedHoursByDay, InitialDaysOffCctSecurity, InitialHoursByContractByYear, InitialHoursCctSecurity } from '../../../utils/types/reportTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, convertNetworkAllHolidaysToAllHolidays, convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay, convertNetworkCctSecurityResponseToCctSecurityResponse, isNullOrEmpty, showNotification, val } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import Card from '../../common/general/card';
import { generateCCTReportToExcelNew, ReportEventType } from '../common/generateReportNew';
import DrawerCctSecurity from './drawerReportContent';
import MultipleReportPrintPage from './multipleReportPrintPage';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, RouterProps {
}

interface State {
    startDate: Moment;
    endDate: Moment;

    isLoading?: number;
    users?: User[];

    searchText?: string;
    selectGroupIds?: number[];
    selectedUserIds?: number[];
    filteredUserIds?: number[];

    selectedJob?: { userId: number, jobId: number | undefined; }[];
    isCcntVisible: boolean;
    cctSecurity?: CctSecurityType;
    monthlyHours?: MonthlyHoursOfTheYearArray;
    canChangeGroup: boolean;
    allHolidays?: AllHolidays[];
    cctSecurityEventsByContractByMonth?: CctSecurityEventsByContractByMonth[];
    increasedhoursByDayLoading: boolean;
    increasedhoursByDay?: IncreasedHoursByDay[];
    departmentIdSelected?: number;

    quadragisLoading: boolean;
}

class ReportTab extends React.Component<Props, State> {
    static getFiltered = (allUsers: User[] | undefined, searchText: string | undefined, selectGroupIds: number[] | undefined) => {

        let users = cloneDeep(allUsers);
        if (!users || (!searchText && !selectGroupIds)) return undefined;

        if (searchText) users = users.filter(u => `${u.last_name}${u.first_name}`.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
        if (selectGroupIds) users = users.filter(u => u.group_users ? u.group_users.some(gu => selectGroupIds.includes(gu.group)) : true);

        return users.map(u => u.id);
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month"),
            isCcntVisible: false,
            canChangeGroup: true,
            increasedhoursByDayLoading: false,
            departmentIdSelected: this.props.departments.data.at(0)?.id,
            quadragisLoading: false,
        };
    }

    componentDidMount() {
        this.updateListOfContracts();
        this.getAllHolidays();
        this.getAllIncreasedhoursByDay();

        Network.getMonthlyHours(this.state.startDate.year()).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHours: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                };
                this.setState({ monthlyHours: monthlyHours });
            },
            (error) => {
                if (error.message.includes(this.props.intl.formatMessage({ defaultMessage: 'No monthly hours' }))) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                }
            },
        );

        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0 && (!isEqual(prevProps.currentUser, this.props.currentUser) || !isEqual(prevProps.users, this.props.users))) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
        if (!isEqual(prevProps.users, this.props.users)) this.updateListOfContracts();

        if (!isEqual(prevProps.departments, this.props.departments)) {
            this.setState({ departmentIdSelected: this.props.departments.data.at(0)?.id }, this.updateListOfContracts);
        }
    }

    getAllHolidays = () => {
        Network.getAllHolidays(this.state.startDate.startOf("year").format(MOMENT_MONTH_FORMAT), this.state.endDate.endOf("month").format(MOMENT_MONTH_FORMAT)).then(
            (response: NetworkAllHolidays[]) => {
                this.setState({ allHolidays: convertNetworkAllHolidaysToAllHolidays(response) });
            },
            () => {
                this.setState({ allHolidays: undefined });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the public holidays' }), "error");
            }
        );
    };

    getAllIncreasedhoursByDay = () => {
        this.setState({ increasedhoursByDayLoading: true });
        Network.getAllIncreasedhoursByDay(this.state.startDate.year()).then(
            (response: NetworkIncreasedHoursByDay[]) => {
                this.setState({ increasedhoursByDayLoading: false, increasedhoursByDay: convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay(response) });
            },
            () => {
                this.setState({ increasedhoursByDayLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the year {year}' }, { year: this.state.startDate.year().toString() }), "warning");
            }
        );
    };

    updateListOfContracts = () => {
        const users = cloneDeep(this.props.users);

        const selectedJob: { userId: number, jobId: number | undefined; }[] = [];
        users?.forEach((u: User) => {
            const contracts = cloneDeep(u.job);

            if (contracts && contracts.length > 0) {
                u.job = this.contractsAvailableBetweenMonths(contracts);
                selectedJob.push({
                    userId: u.id,
                    jobId: u.job[0]?.id
                });
            }
        });

        this.setState({ users, selectedJob, filteredUserIds: ReportTab.getFiltered(users, this.state.searchText, this.state.selectGroupIds) });
    };


    contractsAvailableBetweenMonths = (user_contracts: Array<UserJobTMP>) => {
        const { startDate: startMonth, endDate: endMonth } = this.state;
        let contracts = user_contracts.sort((a, b) => {
            const dateA = moment(a.date_in_report);
            const dateB = moment(b.date_in_report);
            return dateB.diff(dateA);
        });

        contracts = contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "month") || startMonth.isAfter(moment(c.contract_expiry_date), "month")));
        if (this.props.departments.data.length > 0) {
            contracts = contracts.filter(c => c.department?.id === this.state.departmentIdSelected);
        }
        return contracts;
    };

    getInitialOvertimeHoursByUser = (userId: number, initialHours: InitialHoursCctSecurity[]) => {
        return initialHours?.filter(ih => ih.userId === userId).map(ih => {
            const initialHours: InitialHoursByContractByYear = {
                id: ih.id,
                initialHours: ih.initialOvertimeHours,
                year: ih.year,
                contractId: ih.contractId ? ih.contractId : -1
            };
            return initialHours;
        });
    };

    getInitialDaysOffByUser = (userId: number, initialDaysOff: InitialDaysOffCctSecurity[]) => initialDaysOff?.filter(ih => ih.userId === userId);

    parseCctSecurityResponse = (cctSecurityData: CctSecurityData, initialHours: InitialHoursByContractByYear[], initialDaysOff: InitialDaysOffCctSecurity[], mutiple: boolean, startMonth: Moment, endMonth: Moment, contractId?: number): (undefined | CctSecurityEventsByContractByMonth[]) => {
        const { increasedhoursByDay } = this.state;
        const allHolidays: AllHolidays[] = this.state.allHolidays ? cloneDeep(this.state.allHolidays) : [];

        let contracts = cctSecurityData.contracts;
        if (this.state.departmentIdSelected !== undefined && this.state.departmentIdSelected >= 0) {
            contracts = contracts.filter(c => c.id === contractId);
        }
        const userEventsData = new CctSecurity(cctSecurityData.events, {}, contracts);
        const contractsEvents = userEventsData.initCctSecurity(startMonth.clone().startOf("year"), endMonth.clone(), increasedhoursByDay ? increasedhoursByDay : [], allHolidays, this.state.monthlyHours, initialHours, initialDaysOff, this.props.typesOfDayOff);
        if (contractsEvents === undefined) {
            showNotification("Problem dev", "error");
            return;
        }

        return contractsEvents;
    };

    generateReports = (userIds: number[]) => {
        const { intl, users } = this.props;
        const { selectedJob, departmentIdSelected, startDate, endDate } = this.state;
        if (userIds.length < 1) return;

        this.setState({ isLoading: userIds.length > 1 ? -1 : userIds.at(0), isCcntVisible: true });

        const departmenId: number | undefined = departmentIdSelected ?? -1;
        const jobIds: number[] = [];

        userIds.forEach(u => {
            const userJob = selectedJob?.find(e => e.userId === u);
            if (userJob && userJob.jobId) jobIds.push(userJob.jobId);
            else jobIds.push(-1);
        });

        Network.generateReportsCctSecurity(startDate.format("YYYY-MM-01"), endDate.format("YYYY-MM-01"), userIds, jobIds, departmenId).then(
            (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);
                let initialHours: InitialHoursByContractByYear[];
                let initialDaysOff: InitialDaysOffCctSecurity[];
                let cctSecurityEventsByContractByMonth: CctSecurityEventsByContractByMonth[] = [];
                let cctSecurityEventsByContractByMonthTmp: CctSecurityEventsByContractByMonth[] | undefined = [];
                let contract: { userId: number; jobId: number | undefined; } | undefined;
                cctSecurityResponse.data.forEach(userData => {
                    contract = selectedJob?.find(e => e.userId === userData.id);
                    initialHours = this.getInitialOvertimeHoursByUser(userData.id, cctSecurityResponse.initialHours);
                    initialDaysOff = this.getInitialDaysOffByUser(userData.id, cctSecurityResponse.initialDaysOff);
                    cctSecurityEventsByContractByMonthTmp = this.parseCctSecurityResponse(userData, initialHours, initialDaysOff, false, startDate, endDate, contract?.jobId);
                    cctSecurityEventsByContractByMonthTmp = cctSecurityEventsByContractByMonthTmp?.filter(c => jobIds?.some(jId => jId === c.id));
                    cctSecurityEventsByContractByMonthTmp = cctSecurityEventsByContractByMonthTmp?.map(data => ({ ...data, user: users.find(e => e.id === userData.id) }));
                    if (!isNullOrEmpty(cctSecurityEventsByContractByMonthTmp)) {
                        cctSecurityEventsByContractByMonth = cctSecurityEventsByContractByMonth.concat(cctSecurityEventsByContractByMonthTmp);
                    }
                });

                this.setState({ isLoading: undefined, selectedUserIds: userIds, cctSecurityEventsByContractByMonth });

            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ isLoading: undefined });
            }
        );
    };

    handleSearchUsers = (event: InputFieldOnChangeEvent) => {
        let searchText: string | undefined = event.target.value;
        if (searchText.length === 0) searchText = undefined;

        this.setState({ searchText, filteredUserIds: ReportTab.getFiltered(this.state.users, searchText, this.state.selectGroupIds) });
    };

    changeGroup = (values: number[]) => {
        let selectGroupIds: number[] | undefined = values;
        if (values.length === 0) selectGroupIds = undefined;

        this.setState({ selectGroupIds, filteredUserIds: ReportTab.getFiltered(this.state.users, this.state.searchText, selectGroupIds) });
    };

    changeMonth = (month: Moment | null) => {
        if (month === null) return;

        const startDate = month.startOf("month");
        const endDate = startDate.clone().endOf("month");

        this.setState({ startDate, endDate, isCcntVisible: false }, () => {
            this.updateListOfContracts();
            this.getAllHolidays();

            Network.getMonthlyHours(this.state.startDate.year()).then(
                (response: MonthlyHoursOfTheYear) => {
                    const monthlyHours: MonthlyHoursOfTheYearArray = {
                        id: response.id,
                        year: response.year,
                        hours: [
                            response.janHours ? response.janHours : 0,
                            response.febHours ? response.febHours : 0,
                            response.marHours ? response.marHours : 0,
                            response.aprHours ? response.aprHours : 0,
                            response.mayHours ? response.mayHours : 0,
                            response.junHours ? response.junHours : 0,
                            response.julHours ? response.julHours : 0,
                            response.augHours ? response.augHours : 0,
                            response.sepHours ? response.sepHours : 0,
                            response.octHours ? response.octHours : 0,
                            response.novHours ? response.novHours : 0,
                            response.decHours ? response.decHours : 0,
                        ]
                    };
                    this.setState({ monthlyHours: monthlyHours });
                },
                (error) => {
                    if (error.message.includes("No monthly hours")) {
                        this.setState({ monthlyHours: undefined });
                    } else {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the hours by year' }), "error");
                    }
                },
            );
        });
    };

    reportToExcel = (type: ReportEventType, cctSecurityEventsByContractByMonth: CctSecurityEventsByContractByMonth[] | undefined) => {
        const { endDate } = this.state;
        const { intl } = this.props;
        const cctSecurityEventsByMonthlist: CctSecurityUserEventsByMonth[] = [];
        if (cctSecurityEventsByContractByMonth) {
            cctSecurityEventsByContractByMonth.filter(data => data.user).map(d => {
                const data = this.parseEventsByDay(d);
                if (data) cctSecurityEventsByMonthlist.push(data);
            });
            generateCCTReportToExcelNew(
                { creator: 'Sunkhronos', orientation: 'landscape', type },
                cctSecurityEventsByMonthlist.filter(data => data.user).map(data => {
                    const overview: CctSecurityUserEventsByMonthOverview[] = isNullOrEmpty(data.months) || !data.months[0].overview ? [] : data.months[0].overview;
                    const recap: CctSecurityUserEventsByMonthRecap[] = isNullOrEmpty(data.months) || !data.months[0].recap ? [] : data.months[0].recap;
                    const summary: CctSecurityUserEventsByMonthSummary[] = isNullOrEmpty(data.months) || !data.months[0].summary ? [] : data.months[0].summary;
                    const travel: CctSecurityUserEventsByMonthTravelSummary[] = isNullOrEmpty(data.months) || !data.months[0].travel ? [] : data.months[0].travel;

                    return ({
                        user: data.user!,
                        contract: {
                            name: data.contractName || intl.formatMessage({ defaultMessage: 'Contract without name' }),
                            type: data.contractType || { hoursMax: 10, hoursMin: 10, name: intl.formatMessage({ defaultMessage: 'Unnamed' }), mode: ContractTypeMode.NORMAL }
                        },
                        data: {
                            month: data.months?.[0].monthNumber || 10,
                            overview,
                            recap,
                            summary,
                            travel
                        }
                    });
                }),
                endDate,
                intl
            );
        }
        this.setState({ cctSecurityEventsByContractByMonth: undefined });

    };

    columns: ColumnsType<User> = [
        {
            className: "__report-userslist-image",
            title: <FormattedMessage defaultMessage={'Image'} />,
            dataIndex: 'image',
            key: 'image',
            fixed: true,
            render: (link: string) => (
                <div onClick={e => { e.stopPropagation(); }} className="both-center">
                    <Avatar
                        size="large"
                        src={link?.length > 0 ?
                            <Image
                                onClick={e => e.stopPropagation()}
                                sizes='small'
                                preview={{ mask: <FAIcon prefix='fad' name='eye' /> }}
                                height={'40px'}
                                width={'40px'}
                                src={link}
                                style={{ objectFit: 'cover' }}
                            />
                            : null} icon={<FAIcon prefix='fad' name='user' />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
            width: '80px',
        },
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'last_name',
            key: 'last_name',
            fixed: true,
            sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
            defaultSortOrder: 'ascend',
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
        },
        {
            title: <FormattedMessage defaultMessage={'Groups'} />,
            key: 'group_users',
            render: (record: User) => {
                if (!record || !record.group_users) {
                    return "";
                }
                const groups = record.group_users.sort((a, b) => (a.group_name && b.group_name) ? a.group_name.localeCompare(b.group_name) : 0).map(gu => gu);

                return (
                    <>
                        {groups.map(group => (
                            <Tag className="__report-groups-users-tags" style={group.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={group.id} onClick={() => group.id && this.changeGroup([group.group])}>
                                {group.group_name}
                            </Tag>
                        ))}
                    </>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Available contracts'} />,
            key: 'contract_available',
            render: (record: User) => {
                const { Option } = Select;
                if (!record || !record.job || record.job.length === 0) {
                    return <span><FormattedMessage defaultMessage={'No contract corresponds to the selected months'} /></span>;
                }
                const { selectedJob } = this.state;
                const selectValue = selectedJob?.find(e => e.userId === record.id);
                return (
                    (record.job && record.job.length > 0) ?
                        <Select value={selectValue?.jobId} style={{ width: 300 }} onChange={(e) => {
                            selectValue && (selectValue.jobId = e);
                            this.setState({ selectedJob });
                        }}>
                            {record.job.map(contract => (
                                <Option key={`contract-${contract.id}`} value={contract.id!}>{contract.name ? contract.name + ": " + contract.date_in_report + " > " + contract.contract_expiry_date : <FormattedMessage defaultMessage={'Unamed contract'} />}</Option>
                            ))}
                        </Select>
                        :
                        <span><FormattedMessage defaultMessage={'No contract corresponds to the selected months'} /></span>
                );
            }
        },
        {
            className: "__report-userslist-download",
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            render: (user: User) => (
                <div className="flex-center">
                    <CircleButton
                        title={this.props.intl.formatMessage({ defaultMessage: 'See report' })}
                        icon={<FAIcon prefix='fad' name='eye' />}
                        small
                        onClick={() => this.generateReports([user.id])}
                        disabled={Boolean(this.state.isLoading) || !user.job || user.job.length === 0}
                        loading={this.state.isLoading === user.id} />
                </div>
            ),
            width: '150px',
        }
    ];

    parseEventsByDay = (userEventSummary: CctSecurityEventsByContractByMonth) => {
        const parseEventsToDisplay = (month: CctSecurityUserHoursSummaryInMonth) => {
            //? Preparing arrays
            let numberOfTargetMonth = -1;
            let userEventsByMonthTravel: CctSecurityUserEventsByMonthTravelSummary[] = [];
            const userEventsByMonthOverview: CctSecurityUserEventsByMonthOverview[] = [];
            const userEventsByMonthRecap: CctSecurityUserEventsByMonthRecap[] = [];
            let userEventsByMonthSummary: CctSecurityUserEventsByMonthSummary[] = [];

            if (month !== undefined && month.summaryByDay) {
                //? Month recap
                //: Adding the values to display
                month.summaryByDay.forEach((day, idx) => {
                    day.events?.forEach((event, idx2) => {
                        //? Push data into array to show in main table
                        const typeOfVehicleName = VehicleTypesDescription.find(type => type.id === event.vehicle?.type)?.name;

                        userEventsByMonthRecap.push({
                            id: ((idx + 1) * 1000) + idx2,
                            color: 'ffffff',
                            date: day.date!,
                            title: (event.title === undefined ? "" : event.title),
                            schedule: `(${event.dateFrom.format(MOMENT_SHORT_TIME_FORMAT)} - ${event.dateTo.format(MOMENT_SHORT_TIME_FORMAT)})`,
                            typeOfDay: (event.typeOfDay?.title !== undefined ? event.typeOfDay.title : (event.typeOfDayOff?.title !== undefined ? event.typeOfDayOff.title : "")),
                            hoursPerEvent: val(event.summary?.eventHours, -1),
                            breaktime: val(event.summary?.breaktimesHours),
                            paidBreaktime: val(event.summary?.breaktimesPaidHours),
                            notPaidBreaktime: val(event.summary?.breaktimesNotPaidHours),
                            overtime: val(event.summary?.overtimesHours),
                            totalHours: val(event.summary?.totalHours, -1),
                            effectiveHours: val(event.summary?.effectiveHours),
                            eventHours: val(event.summary?.eventHours, -1),
                            increasedHours: val(event.summary?.increasedHours, 0),
                            vacationIncreasedHours: val(event.summary?.vacationIncreasedHours),
                            project: (event.summary?.project === undefined ? "" : event.summary.project),
                            projectNumber: (event.summary?.projectNumber === undefined ? "" : event.summary.projectNumber),
                            address: (event.poi?.address ? event.poi.address : "-"),
                            startingPoint: (event.basePoi?.title === undefined ? "" : event.basePoi?.title),
                            vehicle: typeOfVehicleName === undefined ? "" : this.props.intl.formatMessage(typeOfVehicleName),
                            travelZone: event.summary?.deplacement?.compensationText ? event.summary.deplacement.compensationText : '', //TODO Calculate travel zone
                            distanceMade: event.distance,
                            distanceCompensated: event.summary?.deplacement?.distanceTotalCompensated, //TODO Calculate compensated distance
                            remarks: (event.summary?.userRemarks === undefined ? "" : event.summary?.userRemarks),
                        });

                        //? setting month Number
                        numberOfTargetMonth = parseInt(this.state.endDate.format("MM"));

                        //? Month Travel
                        //: Adding the travel zones
                        userEventsByMonthTravel = [
                            {
                                id: month.totEngagementZone?.travelZone?.id!,
                                name: month.totEngagementZone?.travelZone?.name!,
                                passengerVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance),
                                ownVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance),
                                workVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance),
                            },
                            {
                                id: month.totFlatRateZone1?.travelZone?.id!,
                                name: month.totFlatRateZone1?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totFlatRateZone2?.travelZone?.id!,
                                name: month.totFlatRateZone2?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totEngagementZone2?.travelZone?.id!,
                                name: month.totEngagementZone2?.travelZone?.name!,
                                passengerVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totDynamicRateZone?.travelZone?.id!,
                                name: month.totDynamicRateZone?.travelZone?.name!,
                                passengerVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                        ];
                    });
                });
            }

            //? Month Overview tot hours
            //: Adding the total hours (at the end to get the totHours value)
            userEventsByMonthOverview.push({
                id: 0,
                name: this.props.intl.formatMessage({ defaultMessage: 'Total hours' }),
                prevMonthBalance: val(month.monthSummary?.prevTotalHours),
                currentMonthBalance: val(month.monthSummary?.totalHours),
                nextMonthBalance: val(month.monthSummary?.nextTotalHours)
            });

            month.monthSummary?.lstTypeDayOff?.forEach(element => {
                userEventsByMonthOverview.push({
                    id: element.typeDayOff?.id!,
                    name: element.typeDayOff?.title!,
                    prevMonthBalance: val(element.previousBalance),
                    currentMonthBalance: val(element.actualBalance),
                    nextMonthBalance: val(element.nextBalance),
                });
            });

            //? Month Summary
            //: Add month summary (hours, increased hours and vacation) to display
            userEventsByMonthSummary = [
                {
                    id: 0,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Brut hours' }),
                    value: val(month.monthSummary?.eventHours)
                },
                {
                    id: 1,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Overtimes' }),
                    value: val(month.monthSummary?.overtimeUndertimeBalance)
                },
                {
                    id: 2,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Paid break hours' }),
                    value: val(month.monthSummary?.breaktimePaidBalance)
                }, {
                    id: 3,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Unpaid break hours' }),
                    value: val(month.monthSummary?.breaktimeNotPaidBalance)
                },
                {
                    id: 4,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Effective hours' }),
                    value: val(month.monthSummary?.effectiveHours)
                },
                {
                    id: 10,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus hours' }),
                    value: val(month.monthSummary?.increasedHours)
                },
                {
                    id: 20,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus vacations' }),
                    value: val(month.monthSummary?.vacationIncreasedHours)
                },
                {
                    id: 30,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Total balance' }),
                    value: val(month.monthSummary?.totalHours)
                }
            ];

            return {
                monthNumber: numberOfTargetMonth,
                recap: userEventsByMonthRecap,
                summary: userEventsByMonthSummary,
                overview: userEventsByMonthOverview,
                travel: userEventsByMonthTravel
            };
        };

        let monthData: CctSecurityUserHoursSummaryInMonth | undefined;
        switch (this.state.endDate.month()) {
            case 0:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
            case 1:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.febHours;
                break;
            case 2:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.marHours;
                break;
            case 3:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.aprHours;
                break;
            case 4:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.mayHours;
                break;
            case 5:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.junHours;
                break;
            case 6:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.julHours;
                break;
            case 7:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.augHours;
                break;
            case 8:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.sepHours;
                break;
            case 9:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.octHours;
                break;
            case 10:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.novHours;
                break;
            case 11:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.decHours;
                break;
            default:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
        }

        if (monthData) {
            const cctSecurityUserEventsByMonth: CctSecurityUserEventsByMonth = {
                user: userEventSummary.user,
                contractName: userEventSummary.name,
                contractType: userEventSummary.typeOfContract,
                months: [
                    parseEventsToDisplay(monthData)
                ]
            };

            return cctSecurityUserEventsByMonth;
        }

        showNotification(this.props.intl.formatMessage({ defaultMessage: 'No data for this month' }), "error");
        return undefined;
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUserIds: keys as number[] });

    //TODO: Remove this when the multiple page display is faster
    downloadMultipleCctReports = (userIds: number[], type: ReportEventType) => {
        const { intl, users } = this.props;
        const { selectedJob, departmentIdSelected, startDate, endDate } = this.state;
        if (userIds.length < 1) return;

        if (type === 'QUADRIGIS')
            this.setState({ quadragisLoading: true });
        else
            this.setState({ isLoading: userIds.length > 1 ? -1 : userIds.at(0) });

        const departmenId: number | undefined = departmentIdSelected ?? -1;
        const jobIds: number[] = [];

        userIds.forEach(u => {
            const userJob = selectedJob?.find(e => e.userId === u);
            if (userJob && userJob.jobId) jobIds.push(userJob.jobId);
            else jobIds.push(-1);
        });

        Network.generateReportsCctSecurity(startDate.format("YYYY-MM-01"), endDate.format("YYYY-MM-01"), userIds, jobIds, departmenId).then(
            (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);
                let initialHours: InitialHoursByContractByYear[];
                let initialDaysOff: InitialDaysOffCctSecurity[];
                let cctSecurityEventsByContractByMonth: CctSecurityEventsByContractByMonth[] = [];
                let cctSecurityEventsByContractByMonthTmp: CctSecurityEventsByContractByMonth[] | undefined = [];
                let contract: { userId: number; jobId: number | undefined; } | undefined;
                cctSecurityResponse.data.forEach(userData => {
                    contract = selectedJob?.find(e => e.userId === userData.id);
                    initialHours = this.getInitialOvertimeHoursByUser(userData.id, cctSecurityResponse.initialHours);
                    initialDaysOff = this.getInitialDaysOffByUser(userData.id, cctSecurityResponse.initialDaysOff);
                    cctSecurityEventsByContractByMonthTmp = this.parseCctSecurityResponse(userData, initialHours, initialDaysOff, false, startDate, endDate, contract?.jobId);
                    cctSecurityEventsByContractByMonthTmp = cctSecurityEventsByContractByMonthTmp?.filter(c => jobIds?.some(jId => jId === c.id));
                    cctSecurityEventsByContractByMonthTmp = cctSecurityEventsByContractByMonthTmp?.map(data => ({ ...data, user: users.find(e => e.id === userData.id) }));
                    if (!isNullOrEmpty(cctSecurityEventsByContractByMonthTmp)) {
                        cctSecurityEventsByContractByMonth = cctSecurityEventsByContractByMonth.concat(cctSecurityEventsByContractByMonthTmp);
                    }
                });

                const cctSecurityEventsByMonthlist: CctSecurityUserEventsByMonth[] = [];

                cctSecurityEventsByContractByMonth.filter(data => data.user).map(d => {
                    const data = this.parseEventsByDay(d);
                    if (data) cctSecurityEventsByMonthlist.push(data);
                });

                generateCCTReportToExcelNew(
                    { creator: 'Sunkhronos', orientation: 'landscape', type },
                    cctSecurityEventsByMonthlist.filter(data => data.user).map(data => {
                        const overview: CctSecurityUserEventsByMonthOverview[] = isNullOrEmpty(data.months) || !data.months[0].overview ? [] : data.months[0].overview;
                        const recap: CctSecurityUserEventsByMonthRecap[] = isNullOrEmpty(data.months) || !data.months[0].recap ? [] : data.months[0].recap;
                        const summary: CctSecurityUserEventsByMonthSummary[] = isNullOrEmpty(data.months) || !data.months[0].summary ? [] : data.months[0].summary;
                        const travel: CctSecurityUserEventsByMonthTravelSummary[] = isNullOrEmpty(data.months) || !data.months[0].travel ? [] : data.months[0].travel;

                        return ({
                            user: data.user!,
                            contract: {
                                name: data.contractName || intl.formatMessage({ defaultMessage: 'Contract without name' }),
                                type: data.contractType || { hoursMax: 10, hoursMin: 10, name: intl.formatMessage({ defaultMessage: 'Unnamed' }), mode: ContractTypeMode.NORMAL }
                            },
                            data: {
                                month: data.months?.[0].monthNumber || 10,
                                overview,
                                recap,
                                summary,
                                travel
                            }
                        });
                    }),
                    this.state.endDate,
                    intl);

                this.setState({ isLoading: undefined, selectedUserIds: userIds, cctSecurityEventsByContractByMonth, quadragisLoading: false });

            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ isLoading: undefined, quadragisLoading: false });
            }
        );
    };

    render() {
        const { Option } = Select;
        const { MonthPicker } = DatePicker;
        const { isCcntVisible, isLoading, canChangeGroup, departmentIdSelected, selectedUserIds, quadragisLoading } = this.state;
        const { width, intl, company } = this.props;
        let tableHeight = this.props.height - 276;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <div>
                <Card icon={<FAIcon prefix='fad' name='chart-simple' />}
                    title={<FormattedMessage defaultMessage={'CLA Repports'} />}
                    headerElements={[
                        // <div key={`cct-report-tab-header-actions-progress`} style={this.state.isLoading === -1 ? { display: "flex", justifyContent: 'flex-end', alignItems: 'center' } : { display: 'none' }} >
                        //     <Progress strokeColor={"var(--primary-color)"} style={this.state.isLoading === -1 ? { width: "300px", marginLeft: '5px' } : { width: '0px' }} percent={this.state.compressionProgression} showInfo={false} />
                        // </div>,

                        this.props.departments.data && this.props.departments.data.length > 0 ?
                            <>
                                <Select
                                    key={`cct-report-tab-header-actions-department-select`}
                                    className="team-create-user-inputs"
                                    showSearch
                                    allowClear
                                    onChange={(e: number | undefined) => this.setState({ departmentIdSelected: e }, this.updateListOfContracts)}
                                    placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                    value={departmentIdSelected}
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                </Select>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                            </>
                            : null,
                        <>
                            {
                                company?.hasQuadrigisReport &&
                                <CircleButton
                                    key={`cct-report-tab-header-actions-download-quadrigis`}
                                    small
                                    style={quadragisLoading ? {} : { display: 'flex' }}
                                    title={intl.formatMessage({ defaultMessage: 'Download multiple reports (Quadrigis)' })}
                                    icon={<img src={QuadrigisLogo} style={{ width: 22, margin: 'auto', filter: isNullOrEmpty(this.state.selectedUserIds) || isLoading !== undefined ? 'grayscale(1)' : undefined }} />}
                                    onClick={() => !isNullOrEmpty(this.state.selectedUserIds) && this.downloadMultipleCctReports(this.state.selectedUserIds, 'QUADRIGIS')}
                                    // onClick={() => !isNullOrEmpty(this.state.selectedUserIds) && this.generateReports(this.state.selectedUserIds)}
                                    disabled={isNullOrEmpty(this.state.selectedUserIds) || isLoading !== undefined}
                                    loading={quadragisLoading}
                                />
                            }
                        </>,
                        //TODO: Changed this to a direct download, cuz it's to slow for the moment. Need to be fixed later though
                        <CircleButton
                            key={`cct-report-tab-header-actions-download`}
                            small
                            title={intl.formatMessage({ defaultMessage: 'Download multiple reports' })}
                            icon={<FAIcon prefix='fad' name='file-xls' />}
                            onClick={() => !isNullOrEmpty(this.state.selectedUserIds) && this.downloadMultipleCctReports(this.state.selectedUserIds, 'CCTSECURITY')}
                            // onClick={() => !isNullOrEmpty(this.state.selectedUserIds) && this.generateReports(this.state.selectedUserIds)}
                            disabled={isNullOrEmpty(this.state.selectedUserIds) || quadragisLoading}
                            loading={isLoading === -1}
                        />,
                    ]
                    }
                >
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: width < 1565 ? "column" : "row", marginBottom: '10px' }}>
                        <div style={{ display: "flex", flexDirection: width < 1565 ? "column" : "row" }}>
                            <InputField
                                onChange={this.handleSearchUsers}
                                style={width < 1565 ? { width: '100%', marginBottom: '5px' } : { width: '300px', marginRight: '5px' }}
                                type="search" />
                            <Select
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear
                                placeholder={<FormattedMessage defaultMessage={'Groups'} />}
                                style={width < 1565 ? { width: '100%', marginBottom: '5px' } : { width: '400px', marginRight: '5px' }}
                                onChange={this.changeGroup}
                                value={this.state.selectGroupIds}
                                filterOption={true}
                                optionFilterProp="label"
                                showArrow
                                disabled={!canChangeGroup}
                            >
                                {
                                    this.props.groups && this.props.groups.sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0).map((g) => {
                                        return <Option label={g.name} value={g.id!} key={"groups-" + g.id}>{g.name}</Option>;
                                    })
                                }
                            </Select>
                            <MonthPicker
                                format={"MM/YYYY"}
                                style={width < 1565 ? { width: '100%', marginBottom: '5px' } : { width: '120px', marginRight: '5px' }}
                                allowClear={false}
                                value={this.state.endDate.clone()}
                                onChange={this.changeMonth}
                            />
                        </div>
                    </div>
                    <Spin spinning={isLoading !== undefined || quadragisLoading}>
                        <Table
                            className="report-ccnt-list-main"
                            dataSource={this.state.users?.filter(u => (this.state.filteredUserIds === undefined || this.state.filteredUserIds.includes(u.id)) && u.active === true && u.report === true && u.visible !== false)}
                            loading={this.props.usersLoading}
                            columns={this.columns}
                            pagination={false}
                            rowKey={(g: any) => g.id}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No user'} />} /> }}
                            rowSelection={{
                                type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUserIds, getCheckboxProps: (record) => ({
                                    disabled: !record.job || record.job.length === 0
                                }),
                            }}
                            scroll={{ x: true, y: tableHeight }}
                        />
                    </Spin>
                    {
                        isNullOrEmpty(selectedUserIds) ?
                            null
                            : selectedUserIds.length === 1 ?
                                <DrawerCctSecurity
                                    type={'CCTSECURITY'}
                                    key={`drawer-cct-security-${isCcntVisible}`}
                                    startMonth={this.state.startDate}
                                    endMonth={this.state.endDate}
                                    isLoading={Boolean(isLoading)}
                                    cctSecurity={this.state.cctSecurityEventsByContractByMonth?.at(0)}
                                    isVisible={isCcntVisible}
                                    close={() => this.setState({ isCcntVisible: false })} />
                                :
                                isCcntVisible ?
                                    <MultipleReportPrintPage
                                        type={'CCTSECURITY'}
                                        key={`drawer-cct-security-${isCcntVisible}`}
                                        startMonth={this.state.startDate}
                                        endMonth={this.state.endDate}
                                        isLoading={Boolean(isLoading)}
                                        multipleReports={this.state.cctSecurityEventsByContractByMonth}
                                        close={() => this.setState({ isCcntVisible: false })} />
                                    : null
                    }
                </Card >
            </div >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    usersLoading: state.teamManagement.usersLoading,
    width: state.window.width,
    height: state.window.height,
    departments: state.configurations.departments,
    typesOfDayOff: state.configurations.typesOfDayOff,
    company: state.user.company
});

const connector = connect(mapStateToProps);
export default connector(withRouter(injectIntl(ReportTab)));