import { Moment } from 'moment';
import React from 'react';

interface Props {
    value: Moment; // Date actuellement sélectionnée
    dateCellRender: (value: Moment) => React.ReactNode; // Rendu personnalisé pour chaque cellule de jour
    headerRender?: (date: Moment) => React.ReactNode; // Rendu personnalisé pour l'en-tête
    validRange?: [Moment, Moment]; // Plage valide des dates
}

const WeekCalendar = (props: Props) => {
    const { value, dateCellRender } = props;

    // Calcul des jours de la semaine (dimanche à samedi)
    const startOfWeek = value.clone().startOf('week');
    const weekDays = Array.from({ length: 7 }, (_, i) =>
        startOfWeek.clone().add(i, 'days')
    );

    return (
        <div
            className="__week-calendar"
            style={{
                padding: "8px 0",
            }}
        >
            {/* Week Days Header */}
            <div
                className="__week-header"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, 1fr)", // 7 colonnes égales
                    gap: "2px",
                    //paddingBottom: "8px",
                }}
            >
                {weekDays.map((day) => (
                    <div
                        key={day.format("YYYY-MM-DD")}
                        style={{
                            textAlign: "center",
                            lineHeight: '18px',
                            padding: "0 12px 5px 0",

                        }}
                    >
                        {day.format("dd")} {/* Exemple : Mo, Tu, etc. */}
                    </div>
                ))}
            </div>

            {/* Week Days Cells */}
            <div
                className="__week-row"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, 1fr)", // 7 colonnes égales
                    gap: "2px",
                }}
            >
                {weekDays.map((day) => (
                    <div
                        key={day.format("YYYY-MM-DD")}
                        className="__cell"
                        style={{
                            minHeight: "80px",
                            //display: "flex",
                            //flexDirection: "column",
                            //justifyContent: "space-between",
                            //alignItems: "center",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                            borderTop: "2px solid #c6c6c6",
                            padding: '4px 8px 0',
                            position: 'relative',
                            borderRadius: 0,
                            height: 'auto',
                            width: 'auto',
                            overflow: "hidden",
                        }}
                    >
                        {/* Day Number */}
                        <div
                            style={{
                                lineHeight: '24px',
                                transition: 'color 0.3s',
                                fontWeight: 400,
                                color: "rgba(0, 0, 0, 0.25)",
                                textAlign: 'center'
                            }}
                        >
                            {day.format("DD")}
                        </div>

                        {/* Cell Content */}
                        <div
                            style={{
                                //height: "60px", // Hauteur fixe pour le contenu
                                //overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minHeight: '86px',
                                //height: 'auto',
                                textAlign: "center",
                                position: 'static',
                                height: "calc(100% - 24px)"
                                
                            }}
                        >
                            {dateCellRender(day)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeekCalendar;