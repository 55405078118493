import { CaretLeftOutlined, CaretRightOutlined, CheckCircleFilled, CheckOutlined, DashOutlined, DeleteFilled, DeleteOutlined, DownloadOutlined, FilterOutlined, FullscreenExitOutlined, FullscreenOutlined, HomeOutlined, LeftOutlined, MoreOutlined, RightOutlined, SettingOutlined, UndoOutlined } from '@ant-design/icons';
import { createTheme, LinearProgress, ThemeProvider } from '@mui/material';
import * as Sentry from "@sentry/react";
import { Badge, Button, Checkbox, Col, Collapse, DatePicker, Divider, message, Modal, notification, Popconfirm, Row, Segmented, Select, Space, Switch, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import unionWith from 'lodash/unionWith';
import moment, { Moment } from 'moment';
import 'moment-duration-format';
import React, { useCallback } from 'react';
import isEqual from "react-fast-compare";
import { AiOutlineSync } from 'react-icons/ai';
import { BiMap, BiTimer } from 'react-icons/bi';
import { BsCalendar3 } from 'react-icons/bs';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { ImStatsBars2 } from 'react-icons/im';
import { PiListChecksLight, PiSelectionForegroundThin } from 'react-icons/pi';
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleFill } from 'react-icons/ri';
import { TbArrowMerge } from 'react-icons/tb';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { changeProject, changeTypesOfDay, changeTypesOfDayOff, loadDepartments } from '../../../store/actions/configurations';
import { changeNextLoading, changeNextUserRows, changeNowLoading, changeNowUserRows, changePrevLoading, changePrevUserRows, changeSelectGroups, changeSelectUsers, changeSettings, changeTeamAvailabilities, changeTemplates, changeUserRows, loadSettings, toggleLoadingPlanning } from '../../../store/actions/planning';
import { changeGroups, changeUsers } from '../../../store/actions/teamManagement';
import { toggleLoading } from '../../../store/actions/user';
import { API_ERROR_MESSAGE, INTERNET_ERROR_MESSAGE } from '../../../utils/api';
import { CaseType, MOMENT_FORMAT, MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_FORMAT_TO_NETWORK, MOMENT_MONTH_TIME_FORMAT, MOMENT_TIME_FORMAT } from '../../../utils/constants';
import { CalendarDataConfirmationState, CalendarDataType, PlanningEventOwner } from '../../../utils/enumerations';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Department, EventClocked, FastPlanningContract, Group, PlanningShowType, POI, RouterProps, StaffType, User, UsersAvailability, UserSummary } from '../../../utils/types/generalTypes';
import { LockedDaysOrMonth, NetworkAllHolidays, NetworkEvent, NetworkMonthlyPlanningRow, NetworkOccupancyRate, NetworkSettings } from '../../../utils/types/networkTypes';
import { EventCalculated, EventCreateError, MonthlyPlanningByUser, MonthlyPlanningByUserByDay, PlanningEvent, PlanningOvertime, PlanningSettings, PlanningTemplate, PlanningUserRow, Project, SelectedEventParsed, SelectedEventV2, TypeOfDay, UserAvailabilityBodyRequest, UserAvailabilityDay, UserAvailabilityWithUpdate, UserSelectedEventsParsed } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { checkPrivilege, checkRBACRule, cloneEvent, convertNetworkEventsToPlanningEvents, convertNetworkEventsToPlanningEventsV2, convertNetworkEventToPlanningEvent, convertNetworkSettingsToPlanningSettings, downloadBlob, getCaseAndPlural, isNullOrEmpty, showNotification, toggleFullScreen } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import SpeedDial from '../../common/fields/speedDial';
import Anticon from '../../common/general/anticon';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import MissionsControlV2 from '../../customersManagement/missions/missionsControlV2';
import OccupancyRatesControl from '../../dashboard/components/occupancyRatesControl';
import EditEventModal from '../../planning/editEventModal';
import SettingsModal from '../../planning/settingsModal';
import ShowEventModal from '../../planning/showEventModal';
import TemplateModal from '../../planning/templateModal';
import CalendarV3 from './common/calendarV3';
import DailySummary from './common/dailySummary';
import ModalDuplicateEvents from './common/modalDuplicateEvents';

export interface CreationCallback {
    (eventId: number): void;
}
interface DPProps {
    className?: string;
    displayMode: 'monthly' | 'weekly';
    onChangeDate: (date: Moment | null) => void;
    date: Moment;
    loading: boolean;
}

export const CustomDatePicker = (props: DPProps) => {
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const { displayMode, date, onChangeDate, loading } = props;

    const customWeekStartEndFormat = useCallback((value: any) => {
        return `${moment(value).startOf('week').format('DD MMM')} - ${moment(value).endOf('week').format('DD MMM')}`;
    }, []);

    return (
        <div key={`custom-date-picker-div-${date.format('YYYYMMDD')}`} className={props.className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
            {displayMode === 'monthly' ?
                <DatePicker.MonthPicker
                    key={`month-picker-${date.format("MM")}`}
                    suffixIcon={null}
                    className={`__monthly-planning-month-picker ${props.className}-picker`}
                    value={date}
                    onChange={onChangeDate}
                    placement={'bottomLeft'}
                    popupClassName={`${props.className}-picker-popup`}
                    //picker="month"
                    //mode="month"
                    size='large'
                    bordered={false}
                    inputReadOnly
                    format={isSmartphone ? "MMM YY" : "MMM YYYY"}
                    disabled={loading}
                    allowClear={false}
                />
                :
                displayMode === 'weekly' ?
                    <DatePicker.WeekPicker
                        className={`__weekly-planning-month-picker ${props.className}-picker`}
                        key={`week-picker-${date.format("YY")}-${date.week()}`}
                        //picker='week'
                        suffixIcon={null}
                        placement={'bottomLeft'}
                        size='large'
                        bordered={false}
                        format={customWeekStartEndFormat}
                        value={date}
                        onChange={onChangeDate}
                        allowClear={false}
                        inputReadOnly
                        disabled={loading}
                    />
                    :
                    <DatePicker
                        key={`day-picker-${date.format("DDMMYYYY")}`}
                        suffixIcon={null}
                        className={`__monthly-planning-month-picker ${props.className}-picker`}
                        value={date}
                        onChange={onChangeDate}
                        placement={'bottomLeft'}
                        picker="date"
                        size='large'
                        bordered={false}
                        inputReadOnly
                        format={isSmartphone ? "MMM YY" : "MMM YYYY"}
                        disabled={loading}
                        allowClear={false}
                    />
            }
        </div>

    );
};


enum Zoom { SMALL, NORMAL }

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, RouterProps, IntlProps { }

interface State {
    date: Moment;
    prevDate: Moment;
    days: Moment[];
    editEvent: PlanningEvent | undefined;
    editEventLoading: boolean;
    editEventAvailabilities?: UserAvailabilityDay[];
    editEventAvailabilityClosedByDefault?: boolean;
    canEditStartDate: boolean;
    editOvertime: PlanningOvertime | undefined;
    editUndertime: PlanningOvertime | undefined;
    detailsEvent: PlanningEvent | undefined;
    detailsEventLoading: boolean;
    detailsUser: User | undefined;
    detailsGroup: Group | undefined;
    zoom: Zoom;
    showSettings: boolean;
    settingDefaultStep: number | undefined;
    editTemplate: PlanningTemplate | undefined;
    isEditTemplate: boolean;
    displayWorkingTime: boolean;
    copiedEvents: PlanningEvent[] | undefined;
    changeMonthButton: boolean;
    currentProjects: Project[] | undefined;
    currentTypesOfDay: TypeOfDay[] | undefined;
    currentStaffTypes: StaffType[] | undefined;
    currentDepartments?: Department[];
    currentPois?: POI[];
    currentFilter: CalendarDataType;
    allGroups: Group[];
    displayFilter: boolean;
    displayOccupancyRate: boolean;
    displayMissionsStatus: boolean;
    displaySummary: boolean;
    selectPoiOnMap: boolean;
    filterMapVisible: boolean;
    refreshOccupancyRateFilter: boolean;
    refreshMissionsStatus: boolean;
    firstOpen: boolean;
    selectedEvents: SelectedEventV2[];
    openDeleteModal: boolean;
    confirmedFilter?: number;
    staffTypes?: StaffType[];
    departmentIdSelected?: number;
    isDownloadingPlanning: boolean;
    monthlyPlanningByUserByDay?: MonthlyPlanningByUserByDay[];
    monthlyPlanningRawData?: NetworkMonthlyPlanningRow[];
    userIdsEditable?: number[];
    allUsersEditable: boolean;
    loadingMonthlyPlanning: boolean;
    cellHoverDay?: string;
    isOnContextMenu: boolean;
    allHolidays?: NetworkAllHolidays[];
    pickedStartDate: Moment;
    modalDownloadCompanyReport: boolean;
    companyReportWithHours: boolean;

    dailySummaryResetFilters: boolean;

    editEventClocked?: EventClocked;
    cutEvent: string | undefined;
    displayMode: 'monthly' | 'weekly';
    displayStats: boolean;
    usersStats?: EventCalculated[];
    contracts?: FastPlanningContract[];
    filteredPlanningByUserByDay: MonthlyPlanningByUserByDay[] | undefined;
    centerView: boolean;
    usersUnavailabilities: UsersAvailability[];
    hideUsersWithNoEvents: boolean;
    loadingStep: 'idle' | 'data' | 'availabilities' | 'stats' | 'sucess' | 'error',
    loadingExtra: number,
    openDuplicateEvents: boolean;
    mode?: 'add' | 'remove' | 'reverse';

    loadingDeleteMultiple: boolean;
    loadingConfirmMultiple: boolean;

    lockedDaysByUsersByMonth?: LockedDaysOrMonth;

}

class Planning extends React.Component<Props, State> {
    confirmationState: CalendarDataConfirmationState = CalendarDataConfirmationState.All;

    constructor(props: Props) {
        super(props);
        const now = moment();
        const month = moment().startOf("month");
        const pickedStartDate = month.isSame(moment(), "month") ? moment() : month.clone();
        const prevDate = moment(month);
        let tab: 'weekly' | 'monthly' | undefined = this.props.match.params.tab as any;
        if (tab === undefined || !['monthly', 'weekly'].includes(tab)) {
            tab = 'monthly';
        }

        this.state = {
            loadingStep: 'idle',
            loadingExtra: 0,
            hideUsersWithNoEvents: false,
            usersUnavailabilities: [],
            centerView: true,
            filteredPlanningByUserByDay: undefined,
            displayStats: false,
            usersStats: undefined,
            pickedStartDate: pickedStartDate,
            displayMode: tab,
            date: now,
            prevDate: prevDate,
            days: [],
            editEvent: undefined,
            editEventLoading: false,
            canEditStartDate: true,
            editOvertime: undefined,
            editUndertime: undefined,
            detailsEvent: undefined,
            detailsEventLoading: false,
            detailsUser: undefined,
            detailsGroup: undefined,
            zoom: Zoom.NORMAL,
            showSettings: false,
            settingDefaultStep: undefined,
            editTemplate: undefined,
            isEditTemplate: false,
            displayWorkingTime: true,
            copiedEvents: undefined,
            changeMonthButton: true,
            currentProjects: undefined,
            currentTypesOfDay: undefined,
            currentStaffTypes: undefined,
            currentFilter: CalendarDataType.All,
            allGroups: this.props.groups?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [],
            displayFilter: false,
            displayOccupancyRate: false,
            displayMissionsStatus: false,
            displaySummary: false,
            selectPoiOnMap: false,
            filterMapVisible: false,
            refreshOccupancyRateFilter: false,
            refreshMissionsStatus: false,
            firstOpen: true,
            selectedEvents: [],
            openDeleteModal: false,
            isDownloadingPlanning: false,
            loadingMonthlyPlanning: false,
            isOnContextMenu: false,
            allUsersEditable: false,
            modalDownloadCompanyReport: false,
            companyReportWithHours: false,
            dailySummaryResetFilters: false,
            cutEvent: undefined,
            openDuplicateEvents: false,
            loadingDeleteMultiple: false,
            loadingConfirmMultiple: false,

            lockedDaysByUsersByMonth: undefined,
        };
    }

    componentDidMount() {
        const { intl } = this.props;
        // console.log("PERF MOUNT Planning")
        this.getUsers(true);
        this.loadAllHolidays();
        this.loadMonthlyPlanningData();
        this.props.loadDepartments!();

        window.addEventListener('keydown', e => {
            this._deletePress(e);
        });

        window.addEventListener('keyup', e => {
            this._selectMode(e);
        });


        Network.getStaffType().then(
            response => {
                this.setState((state) => ({ loadingExtra: state.loadingExtra + 10, staffTypes: response }));
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );

        //this.getUsers();
        this.getGroups();
        this.refreshTemplates();

        this.props.loadSettings();


        Network.getTypeOfDay().then(
            response => this.props.changeTypesOfDay!(response),
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );

        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff!(response),
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );

        Network.getProjects().then(
            (response: Project[]) => !isEqual(this.props.project?.sort((a, b) => b.id === undefined || a.id === undefined ? 0 : b.id - a.id), response) && this.props.changeProject!(response),
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the {projects}' }, { projects: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'projects' }) }), "warning"),
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { selectGroups, selectUsers, users } = this.props;
        const { hideUsersWithNoEvents, date, currentTypesOfDay, currentStaffTypes, confirmedFilter, currentDepartments, currentProjects, currentPois } = this.state;

        if (!isEqual(users, prevProps.users)) {
            // console.log("PERF PROCESS parseMonthlyPlanningData");
            this.parseMonthlyPlanningData();
        }

        if (
            !isEqual(selectUsers, prevProps.selectUsers) ||
            !isEqual(selectGroups, prevProps.selectGroups) ||
            !isEqual(confirmedFilter, prevState.confirmedFilter) ||
            !isEqual(currentDepartments, prevState.currentDepartments) ||
            !isEqual(currentProjects, prevState.currentProjects) ||
            date.unix() !== prevState.date.unix() ||
            !isEqual(currentTypesOfDay, prevState.currentTypesOfDay) ||
            !isEqual(currentStaffTypes, prevState.currentStaffTypes) ||
            !isEqual(hideUsersWithNoEvents, prevState.hideUsersWithNoEvents) ||
            !isEqual(currentPois, prevState.currentPois)
        ) {
            // console.log("PERF PROCESS loadAllHolidays, calculatePlanningByUserByDay");
            this.loadAllHolidays();
            this.calculatePlanningByUserByDay();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this._deletePress);
        window.removeEventListener('keyup', this._selectMode);
    }

    getLockedDays = (startDate: Moment, endDate?: Moment) => {
        Network.getLockedDays(startDate, endDate).then(
            (response) => {
                if (response.error === false)
                    this.setState({ lockedDaysByUsersByMonth: response.data });
                else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading month state' }), "error");
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading month state' }), "error");
            },
        );
    };


    _selectMode = (e?: KeyboardEvent) => {
        if (e !== undefined && (e.key === 'Control' || e.key === 'Meta')) {
            this.setState({ mode: undefined });
        } else if (e !== undefined && e.key === 'Shift') {
            this.setState({ mode: undefined });
        }
    };

    _deletePress = (e?: KeyboardEvent) => {
        const { selectedEvents, copiedEvents, cutEvent } = this.state;
        if ((e !== undefined && e.key === "Delete") && selectedEvents.length > 0 && this.state.openDeleteModal === false) {
            this.setState({ openDeleteModal: true });
        } else if (e !== undefined && e.key === "Escape" && (selectedEvents.length > 0 || !isNullOrEmpty(copiedEvents) || cutEvent)) {
            const resetParcialState = this.resetCopyCut(false);
            this.setState({ ...resetParcialState, selectedEvents: [] });
        } else if (e !== undefined && e.key === "Enter" && this.state.openDeleteModal) {
            this.deleteMultipleEvents();
        } else if (e !== undefined && (e.ctrlKey || e.metaKey)) {
            this.setState({ mode: 'add' });
        } else if (e !== undefined && e.shiftKey) {
            this.setState({ mode: 'remove' });
        }

        e?.stopPropagation();
    };

    loadAllHolidays = () => {
        const { date, displayMode } = this.state;
        const start = displayMode === 'monthly' ? date.clone().startOf("month").format(MOMENT_FORMAT_DATE_TO_NETWORK) : date.clone().startOf("week").format(MOMENT_FORMAT_DATE_TO_NETWORK);
        const end = displayMode === 'monthly' ? date.clone().endOf("month").format(MOMENT_FORMAT_DATE_TO_NETWORK) : date.clone().endOf("week").format(MOMENT_FORMAT_DATE_TO_NETWORK);
        Network.getAllHolidays(start, end).then(
            (response) => {
                this.setState((state) => ({ loadingExtra: state.loadingExtra + 10, allHolidays: response }));
            },
            () => {
                this.setState({ allHolidays: undefined });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurref while loading the public holidays' }), "error");
            }
        );
    };

    getUsers = (forceReload = false) => {
        if (forceReload || isNullOrEmpty(this.props.users)) {
            Network.getAllUsers().then(
                response => {
                    this.props.changeUsers!(response);
                    this.setState((state) => ({ loadingExtra: state.loadingExtra + 10 }));
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurref while loading the users' }), "warning")
            );
        }
    };

    groupByUser(x: NetworkMonthlyPlanningRow[], f: any) {
        return x.reduce((a: MonthlyPlanningByUser[], b: NetworkMonthlyPlanningRow) => {
            const found = a.find(t => t.userId === f(b));
            if (found) {
                found.events.push(b);
            } else {
                a.push({
                    userId: f(b),
                    userFirstName: b.userFirstName,
                    userLastName: b.userLastName,
                    userImage: b.userImage,
                    events: [b],
                });
            }
            return a;
        }, []);
    }

    compareAvailability(a: UserAvailabilityDay, b: UserAvailabilityDay) {
        if (a.startTime.isBefore(b.startTime)) {
            return -1;
        } else if (a.startTime.isAfter(b.startTime)) {
            return 1;
        } else {
            if (a.endTime.isBefore(b.endTime)) {
                return -1;
            } else if (a.endTime.isAfter(b.endTime)) {
                return 1;
            } else {
                return 0;
            }
        }
    }

    parseMonthlyPlanningData = () => {
        const { monthlyPlanningRawData, date } = this.state;
        const { users } = this.props;

        if (users && users.length > 0 && monthlyPlanningRawData) {
            const monthlyPlanningByUser: MonthlyPlanningByUser[] = [];
            const monthlyPlanningByUserByDay: MonthlyPlanningByUserByDay[] = [];

            this.props.users?.forEach(u => {
                if (u.visible !== false) {
                    monthlyPlanningByUser.push({
                        availabilityClosedByDefault: u.availabilityClosedByDefault,
                        userId: u.id,
                        userFirstName: u.first_name,
                        userLastName: u.last_name,
                        userImage: u.image,
                        events: monthlyPlanningRawData.filter(e => e.userId === u.id)
                    });
                }

            });
            monthlyPlanningByUser.forEach((user) => {
                const monthlyPlanningByDay: MonthlyPlanningByUserByDay = {
                    userId: user.userId,
                    userFirstName: user.userFirstName,
                    userLastName: user.userLastName,
                    userImage: user.userImage,
                    availabilityClosedByDefault: user.availabilityClosedByDefault,
                    nbEvents: user.events.filter(e => {
                        return (e.typeOfDayInReports === undefined || e.typeOfDayInReports === null || e.typeOfDayInReports === true) &&
                            (e.typeOfDayOffInReports === undefined || e.typeOfDayOffInReports === null || e.typeOfDayOffInReports === true);
                    }).length,
                    allEvents: user.events,
                };
                const currentMonthDates = (this.state.displayMode === 'monthly') ?
                    new Array(date.clone().daysInMonth()).fill(null).map((x, i) => `${date.clone().startOf('month').add(i, 'days').format("YYYYMMDD")}`)
                    :
                    (this.state.displayMode === 'weekly') ?
                        new Array(7).fill(null).map((x, i) => `${date.clone().startOf('week').add(i, 'days').format("YYYYMMDD")}`)
                        :
                        new Array(1).fill(null).map((x, i) => `${date.clone().startOf('day').add(i, 'days').format("YYYYMMDD")}`);

                currentMonthDates.forEach((dateStr: string) => {
                    const eventFiltered = user.events.filter(e => `${moment(e.startDate).format("YYYYMMDD")}` === dateStr);
                    if (monthlyPlanningByDay.cellEvents === undefined) {
                        monthlyPlanningByDay.cellEvents = { [dateStr]: eventFiltered };
                    } else {
                        monthlyPlanningByDay.cellEvents[dateStr] = eventFiltered;
                    }
                });

                monthlyPlanningByUserByDay.push(monthlyPlanningByDay);

            });
            this.setState({ monthlyPlanningByUserByDay, loadingMonthlyPlanning: false }, () => {
                this.calculatePlanningByUserByDay();

            });
        }
    };

    centerViewToToday = () => {
        const isThisDate = (this.state.displayMode === 'monthly') ?
            moment().format("YYYYMM") === this.state.date.format("YYYYMM")
            :
            (this.state.displayMode === 'weekly') ?
                moment().week() === this.state.date.week()
                :
                moment().format("YYYYMMDD") === this.state.date.format("YYYYMMDD"); //for futur daily
        let goStartOfTable = false;
        if (isThisDate) {
            const columnIndex = moment().date() + 1; // +1 compensating the user column

            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(${columnIndex})`);
            if (targetColumn) {
                targetColumn.scrollIntoView({ inline: "center" });
            } else {
                goStartOfTable = true;
            }
        } else {
            goStartOfTable = true;
        }

        if (goStartOfTable) {
            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(1)`);
            if (targetColumn) {
                targetColumn.scrollIntoView({ inline: "start" });
            }
        }
        this.setState({ centerView: false });
    };

    parseResponse = (value: any) => {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    };

    loadMonthlyPlanningData = () => {
        this.setState({ selectedEvents: [], loadingMonthlyPlanning: true, loadingStep: 'data' }, () => {
            const { date, displayMode } = this.state;
            const start_date = displayMode === 'monthly' ? date.clone().startOf("month") : date.clone().startOf("week");
            const end_date = displayMode === 'monthly' ? date.clone().endOf("month") : date.clone().endOf("week");

            // if (this.state.lockedDaysYearLoaded === undefined or this.state.loc)
            this.getLockedDays(start_date, end_date);

            const start = start_date.format(MOMENT_FORMAT_DATE_TO_NETWORK);
            const end = end_date.format(MOMENT_FORMAT_DATE_TO_NETWORK);

            Network.getPlanning(start, end).then(
                (response) => {
                    this.setState((state) => ({ loadingStep: 'availabilities', monthlyPlanningRawData: this.parseResponse(response.data), userIdsEditable: response.rights, allUsersEditable: response.allUsers, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                        this.loadUnavailabilities();
                        this.parseMonthlyPlanningData();
                    });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events' }), "error");
                    this.setState({ loadingStep: 'error', monthlyPlanningByUserByDay: [], monthlyPlanningRawData: [], loadingMonthlyPlanning: false, userIdsEditable: undefined, allUsersEditable: false, usersStats: undefined }, this.parseMonthlyPlanningData);
                },
            );
        });

    };

    loadStatsData = () => {
        const { date, displayMode } = this.state;
        const start = displayMode === 'monthly' ? date.clone().startOf("month").format(MOMENT_FORMAT_DATE_TO_NETWORK) : date.clone().startOf("week").format(MOMENT_FORMAT_DATE_TO_NETWORK);
        const end = displayMode === 'monthly' ? date.clone().endOf("month").format(MOMENT_FORMAT_DATE_TO_NETWORK) : date.clone().endOf("week").format(MOMENT_FORMAT_DATE_TO_NETWORK);

        Network.getPlanningStats(start, end).then(
            (response) => {
                this.setState({ usersStats: response.usersStats, contracts: response.contracts, loadingStep: 'sucess' }, () => this.calculatePlanningByUserByDay());
            },
            () => {
                this.setState({ loadingStep: 'error', usersStats: undefined, contracts: undefined });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events statistics' }), "error");
            },
        );

    };

    loadUnavailabilities = () => {
        const { date, displayMode } = this.state;
        const start = displayMode === 'monthly' ? date.clone().startOf("month").startOf('day').format(MOMENT_FORMAT_TO_NETWORK) : date.clone().startOf("week").startOf('day').format(MOMENT_FORMAT_TO_NETWORK);
        const end = displayMode === 'monthly' ? date.clone().endOf("month").endOf('day').format(MOMENT_FORMAT_TO_NETWORK) : date.clone().endOf("week").endOf('day').format(MOMENT_FORMAT_TO_NETWORK);

        Network.getPlanningUnavailabilities(start, end).then(
            (response) => {

                this.setState({ usersUnavailabilities: response.data, loadingStep: 'stats' }, () => {
                    this.loadStatsData();
                    // this.calculatePlanningByUserByDay();
                });
            },
            () => {
                this.setState({ loadingStep: 'error' });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the unavailabilities' }), "error");
            },
        );

    };

    getGroups = (forceReload = false) => {
        if (forceReload || isNullOrEmpty(this.props.groups)) {
            Network.getGroups().then(
                response => {
                    this.setState((state) => ({ loadingExtra: state.loadingExtra + 10 }));
                    this.props.changeGroups!(response);
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning")
            );
        }
    };

    refreshTemplates = () => {
        Network.getTemplates().then(
            response => {
                if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning");
                else {
                    this.setState((state) => ({ loadingExtra: state.loadingExtra + 10 }));
                    this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning")
        );
    };

    previousDate = () => {
        const { displayMode } = this.state;
        if (displayMode === 'monthly') {
            const date = this.state.date.clone().subtract(1, "month").startOf("month");
            const pickedStartDate = date.isSame(moment(), "month") ? moment() : date.clone();

            this.setState({ date: date.clone(), pickedStartDate, centerView: true, loadingStep: 'idle' }, this.loadMonthlyPlanningData);
        } else if (displayMode === 'weekly') {
            const date = this.state.date.clone().subtract(1, "week").startOf("week");
            this.setState({ date: date.clone(), centerView: true, loadingStep: 'idle' }, this.loadMonthlyPlanningData);
        }
    };

    nextDate = () => {
        const { displayMode } = this.state;
        if (displayMode === 'monthly') {
            const date = this.state.date.clone().add(1, "month").startOf("month");
            const pickedStartDate = date.isSame(moment(), "month") ? moment() : date.clone();
            this.setState({ date: date.clone(), pickedStartDate, centerView: true, loadingStep: 'idle' }, this.loadMonthlyPlanningData);
        } else if (displayMode === 'weekly') {
            const date = this.state.date.clone().add(1, "week").startOf("week");
            this.setState({ date: date.clone(), centerView: true, loadingStep: 'idle' }, this.loadMonthlyPlanningData);
        }
    };

    onChangeDate = (date: Moment | null) => {
        const { displayMode } = this.state;
        if (displayMode === 'monthly') {
            if (date) {
                const pickedStartDate = date.isSame(moment(), "month") ? moment() : date.clone();
                this.setState({ date: date.clone(), pickedStartDate, centerView: true, loadingStep: 'idle', loadingExtra: 0 }, this.loadMonthlyPlanningData);
            }
        } else if (displayMode === 'weekly') {
            if (!date) return;
            this.setState({ date: date.clone(), centerView: true, loadingStep: 'idle', loadingExtra: 0 }, this.loadMonthlyPlanningData);
        }
    };

    createNewEvent = (date: Moment, newOccupancyRate: NetworkOccupancyRate) => { //!
        let startParsed: Moment = moment(date.format(MOMENT_MONTH_TIME_FORMAT)).startOf('day');
        let endParsed: Moment = moment(date.format(MOMENT_MONTH_TIME_FORMAT)).endOf('day');
        if (newOccupancyRate.startHour && newOccupancyRate.endHour) {
            const orStartTime = moment(newOccupancyRate.startHour, MOMENT_TIME_FORMAT);
            // let orStartDate = moment(newOccupancyRate.startDate, MOMENT_MONTH_FORMAT);
            const orEndTime = moment(newOccupancyRate.endHour, MOMENT_TIME_FORMAT);
            // let orEndDate = moment(newOccupancyRate.endDate, MOMENT_MONTH_FORMAT);
            if (orStartTime.isAfter(orEndTime, "seconds")) {
                endParsed = endParsed.add(1, "days");
            }

            startParsed = startParsed.clone().set("hours", orStartTime.hours()).set("minutes", orStartTime.minutes()).set("second", orStartTime.seconds());
            endParsed = endParsed.clone().set("hours", orEndTime.hours()).set("minutes", orEndTime.minutes()).set("second", orEndTime.seconds());
        }

        this.setState({
            editEvent: {
                lookingForUserAvailability: true,
                startDate: startParsed,
                endDate: endParsed,
                typeOfDay: newOccupancyRate.typeOfDay,
                staffType: newOccupancyRate.staffType,
                poi: newOccupancyRate.poi,
                basePoi: newOccupancyRate.basePoi,
                title: newOccupancyRate.title,
                department: newOccupancyRate.department,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
            canEditStartDate: false,
            // TODO availabilities
        });
    };

    createEventFromMission = (ruleId: number, date: Moment, templateId?: number) => {
        const startDate: Moment = date.clone().startOf('day');
        const endDate: Moment = date.clone().endOf('day');
        if (templateId) {
            // this.getTemplateFiles(templateId, async (files) => {
            //     if (files) {
            //         const validateFiles = await Promise.all(files.map(async f => this.generateFilesToValidateFromTemplate(f)))
            //         const filesToValidate = validateFiles.filter(f => f.res_type === 'FLE')
            //         const imagesToValidate = validateFiles.filter(f => f.res_type === 'IMG')
            //         this.setState({ filesToValidate, imagesToValidate })
            //     } else {
            //         this.setState({ filesToValidate: [], imagesToValidate: [] })
            //     }
            // })
            Network.getEventFromTemplate(templateId, date).then(
                (response) => {
                    this.setState({
                        editEvent: {
                            ...convertNetworkEventToPlanningEvent(response.data),
                            lookingForUserAvailability: true,
                            ruleId
                        },
                        canEditStartDate: false,
                    });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template' }), "error");
                }
            );
        } else {
            this.setState({
                editEvent: {
                    title: "",
                    startDate,
                    endDate,
                    lookingForUserAvailability: true,
                    ruleId
                },
                canEditStartDate: false,
            });
        }
    };

    onClickCreateEvent = (columnKey: string, userId: number): void => {

        const date = moment(columnKey, 'YYYYMMDD');
        this.setState({
            editEvent: {
                lookingForUserAvailability: false,
                startDate: this.props.settings?.startHourOfDay ? date.clone().hours(this.props.settings.startHourOfDay.hours()).minutes(this.props.settings.startHourOfDay.minutes()).seconds(0).milliseconds(0) : date.clone().seconds(0).milliseconds(0),
                endDate: this.props.settings?.endHourOfDay ? date.clone().hours(this.props.settings.endHourOfDay.hours()).minutes(this.props.settings.endHourOfDay.minutes()).seconds(this.props.settings.endHourOfDay.seconds()) : date.clone().add(1, 'hours'),
                title: '',
                userId: userId,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
            canEditStartDate: false,
            //editEventAvailabilities: cloneDeep(availabilities),
            //editEventAvailabilityClosedByDefault: availabilityClosedByDefault
        });
    };

    onClickEditEvent = (eventId: number): void => {
        const { intl } = this.props;
        this.setState({ editEventLoading: true }, () => {
            Network.getEventsV2(eventId).then(
                response => {
                    if (response.error === false) {
                        const newEvent = convertNetworkEventToPlanningEvent(response.data);
                        if (
                            ((this.props.currentUser?.role === 2)) ||
                            ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'any') ||
                            ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'groupadmin' && (this.props.currentUser?.id === newEvent.creatorId || !this.props.users.some((user) => user.id === newEvent.creatorId && [2, 3].includes(user.role)))) ||
                            ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'owned_only' && this.props.currentUser?.id === newEvent.creatorId)
                        ) {
                            this.setState({
                                editEvent: newEvent,
                                editEventLoading: false,
                                canEditStartDate: false
                                // TODO availabilities
                            });

                        } else {
                            this.setState({
                                editEventLoading: false,
                            });
                            showNotification((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'groupadmin' ? intl.formatMessage({ defaultMessage: 'You can only modify events that have not been created by an administrator.' }) : intl.formatMessage({ defaultMessage: 'You can only modify events that you have created.' }), "warning");
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');

                        this.setState({
                            ...this.resetCopyCut(false),
                            editEventLoading: false,
                        });
                    }


                },
                () => {
                    this.setState({
                        editEventLoading: false,
                    });
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "error");
                }
            );
        });

    };

    onConfirmEvent = (eventId: number, userId: number): void => {
        const { monthlyPlanningByUserByDay } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);

        eventId && userId && Network.confirmEventV2(eventId, userId).then(
            (response) => {
                if (response.error === true) {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the event' }), "warning");
                } else {
                    const userData = monthlyPlanningByUserByDay?.find(u => u.userId === response.userId);
                    if (userData !== undefined && userData.cellEvents !== undefined) {
                        const dateStr = `${moment(response.startDate).format("YYYYMMDD")}`;
                        const userDataDay = userData.cellEvents[dateStr];
                        if (userDataDay === undefined) {
                            userData.cellEvents[dateStr] = [response];
                        } else {
                            const eventIndex = userDataDay.findIndex(d => d.id === response.id);
                            if (eventIndex > -1) {
                                userDataDay[eventIndex] = response;
                            } else {

                                userData.cellEvents[dateStr]?.push(response);
                                userData.cellEvents[dateStr]?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)) < 0 ? -1 : 1);
                            }
                        }
                        if (userData.allEvents) {
                            userData.allEvents.push(response);
                        } else {
                            userData.allEvents = [response];
                        }
                        this.setState({ monthlyPlanningByUserByDay }, () => {
                            this.calculatePlanningByUserByDay();
                            message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully confirmed' }));
                        });
                    } else {
                        this.loadMonthlyPlanningData();
                    }
                }
                this.props.toggleLoading!(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the event' }), "error");
                this.props.toggleLoading!(false);
            }
        );
    };

    onMultipleConfirmEvent = (eventsIds: SelectedEventV2[]): void => {
        const { monthlyPlanningByUserByDay } = this.state;
        const { intl } = this.props;
        const eventsToConfirm = eventsIds.filter(e => !e.locked);
        if (eventsToConfirm.length > 0) {

            this.setState({ loadingConfirmMultiple: true });

            if (eventsIds) {
                Network.confirmMultipleEvent(eventsToConfirm.map(se => se.eventId)).then(
                    (response) => {
                        if (response.error === true) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                        } else {
                            const eventsIdsConfirmed: number[] | undefined | null = response["eventsIds"];
                            if (eventsIdsConfirmed && eventsIdsConfirmed.length > 0) {
                                const newEventsIds = eventsIds.filter(e => eventsIdsConfirmed?.find(c => c === e.eventId));
                                newEventsIds.forEach(nIds => {
                                    const userData = monthlyPlanningByUserByDay?.find(u => u.userId === nIds.userId);
                                    if (userData && userData.cellEvents !== undefined) {
                                        const userEvent = userData.allEvents?.find(e => e.id === nIds?.eventId);
                                        if (userEvent) {
                                            const dateStr = `${moment(userEvent.startDate).format("YYYYMMDD")}`;
                                            const userDataDay = userData.cellEvents[dateStr];
                                            if (userDataDay) {
                                                const eventIndex = userDataDay.findIndex(d => d.id === userEvent!.id);
                                                if (eventIndex > -1) {
                                                    userDataDay[eventIndex].countNotConfirmed = 0;
                                                }
                                            }
                                        }
                                    }
                                });

                                this.setState({ monthlyPlanningByUserByDay, selectedEvents: [] }, () => {
                                    this.calculatePlanningByUserByDay();
                                    message.success(intl.formatMessage({ defaultMessage: 'The events has been successfully confirmed' }));
                                });
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                            }
                        }
                        this.setState({ loadingConfirmMultiple: false });
                    },
                    () => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                        this.setState({ loadingConfirmMultiple: true });
                    }
                );
            }
        }
        else
            showNotification(intl.formatMessage({ defaultMessage: 'Unable to confirm' }), "warning", intl.formatMessage({ defaultMessage: 'All the events you selected are either locked or the day they are in is locked' }));
    };

    /**
     * Update an event
     * @param event the updated event
     * @param sendMessage if a message must be sent when editing the event
     */
    onEditEvent = (errors: EventCreateError[], event: PlanningEvent, sendMessage?: boolean, forceUpdate?: boolean, skipCheckAvailability?: boolean): void => {
        const { monthlyPlanningByUserByDay } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);

        // method that update the event
        const update = () => {
            let ignoreTimeclock = false;
            let forceReasons: string | undefined;
            if (errors.length > 0) {
                forceReasons = '';
                ignoreTimeclock = true;
                errors.forEach(eventData => {
                    eventData.errors.forEach(error => {
                        if (error.codes && error.codes.length > 0)
                            error.codes.forEach(code => {
                                forceReasons += `${error.type}#${code};`;
                            });
                        else
                            forceReasons += `${error?.type};`;
                    });
                });
            }

            Network.updateEventV2(event, sendMessage, forceUpdate, forceReasons, ignoreTimeclock).then(
                (response: NetworkMonthlyPlanningRow) => {
                    const userData = monthlyPlanningByUserByDay?.find(u => u.userId === response.userId);
                    if (userData !== undefined && userData.cellEvents !== undefined) {
                        const dateStr = `${moment(response.startDate).format("YYYYMMDD")}`;
                        const userDataDay = userData.cellEvents[dateStr];
                        if (userDataDay === undefined) {
                            userData.cellEvents[dateStr] = [response];
                        } else {
                            const eventIndex = userDataDay.findIndex(d => d.id === response.id);
                            if (eventIndex > -1) {
                                userDataDay[eventIndex] = response;
                            } else {
                                userData.cellEvents[dateStr]?.push(response);
                                userData.cellEvents[dateStr]?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)) < 0 ? -1 : 1);
                            }
                        }
                        if (userData.allEvents) {
                            userData.allEvents.push(response);
                        } else {
                            userData.allEvents = [response];
                        }
                    } else {
                        this.loadMonthlyPlanningData();
                    }

                    this.setState((state) => ({ monthlyPlanningByUserByDay, editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                        this.loadStatsData();
                        message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully updated' }));
                    });
                    this.props.toggleLoading!(false);
                },
                (error) => {
                    this.props.toggleLoading!(false);
                    let parsedError: any = undefined;
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (e) {
                        Sentry.captureMessage(error);
                        Sentry.captureMessage(error.message);
                        Sentry.captureException(e);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "You only can modify events of your group" ||
                            parsedError.message === "You do not have the necessary rights to perform this action.") {
                            showNotification(intl.formatMessage({ defaultMessage: 'You do not have the rights for this user' }), "error");
                            this.setState({ editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true });
                            return;
                        } else if (parsedError.message === "You only can modify events of your group that not created by administrator") {
                            showNotification(intl.formatMessage({ defaultMessage: 'This event was created by a super administrator, you cannot modify it.' }), "error");
                            this.setState({ editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true });
                            return;
                        } else if (parsedError.message === "You only can modify events of your group that you create") {
                            showNotification(intl.formatMessage({ defaultMessage: 'You can only modify events that you have created.' }), "error");
                            this.setState({ editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true });
                            return;
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "error");
                    }
                }
            );
        };

        if (event.userId) {
            if (!skipCheckAvailability && !forceUpdate) {

                // check availavility
                Network.eventCheckAvailability(event, [event.userId]).then(
                    response => {
                        if (response.length > 0) {
                            this.props.toggleLoading!(false);
                            showNotification(intl.formatMessage({ defaultMessage: 'The user has no availability (periods) for the creation of this event.' }), "error");
                        } else {
                            // update the event
                            update();
                        }
                    },
                    () => {
                        this.props.toggleLoading!(false);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "error");
                    }
                );
            } else {
                update();
            }
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An unknown error occured, please contact the administrator' }), "error");
        }
    };

    /**
     * Create an event
     * @param event the new event
     * @param userIds an array of all owner user'id
     * @param groupIds an array of all owner group'id
     * @param sendMessage if a message must be sent when creating the event
     * @param asTemplate if a template must be created with the event configuration
     */

    onCreateEvent = (
        errors: EventCreateError[],
        event: PlanningEvent,
        userIds: number[],
        sendMessage?: boolean,
        asTemplate?: boolean,
        forceCreate?: boolean,
        skipCheckAvailability?: boolean,
        callback?: CreationCallback,
    ): void => {

        const { monthlyPlanningByUserByDay } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);
        // method that create the event
        const createTemplate = () => {
            let i = -1;
            Network.updateTemplate({
                startDate: moment(event.startDate),
                endDate: moment(event.endDate),
                title: event.title,
                description: event.description,
                staffType: event.staffType,
                poi: event.poi,
                basePoi: event.basePoi,
                vehicle: event.vehicle,
                distance: event.distance,
                sendMessage: sendMessage,
                color: event.color,
                typeOfDay: event.typeOfDay,
                typeOfDayOff: event.typeOfDayOff,
                project: event.project,
                breakTimes: event.breakTimes,
                department: event.department,
                reminders: event?.reminders?.map(r => {
                    return ({ ...r, id: i-- });
                })
            }).then(
                () => {
                    this.refreshTemplates();
                    message.success(intl.formatMessage({ defaultMessage: 'The template has been successfully updated' }));
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the template' }), "error")
            );
        };
        const create = () => {
            const forcedUsersBody: { id: number, forceCreate: boolean, forceReasons: string; }[] = [];

            if (errors.length > 0)
                errors.forEach(eventData => {
                    let reasons = '';

                    eventData.errors.forEach(error => {
                        if (error.codes && error.codes.length > 0)
                            error.codes.forEach(code => {
                                reasons += `${error.type}#${code};`;
                            });
                        else
                            reasons += `${error?.type};`;
                    });

                    forcedUsersBody.push({
                        id: eventData.user.id,
                        forceCreate: true,
                        forceReasons: reasons
                    });
                });

            if (userIds.length > 0)
                userIds.forEach(id => {
                    const foundErrorWithId = forcedUsersBody.find(e => e.id === id);
                    if (foundErrorWithId === undefined)
                        forcedUsersBody.push({ id, forceCreate: false, forceReasons: '' });
                });

            Network.createEventV2(forcedUsersBody, event, sendMessage).then(
                (response: NetworkMonthlyPlanningRow[]) => {
                    asTemplate && createTemplate();
                    response.forEach(element => {
                        const userData = monthlyPlanningByUserByDay?.find(u => u.userId === element.userId);
                        if (userData !== undefined) {
                            const dateStr = `${moment(element.startDate).format("YYYYMMDD")}`;
                            if (userData.cellEvents === undefined) {
                                userData.cellEvents = { [dateStr]: [element] };
                            } else {
                                userData.cellEvents[dateStr]?.push(element);
                            }
                            if (userData.allEvents) {
                                userData.allEvents.push(element);
                                userData.cellEvents[dateStr]?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)) < 0 ? -1 : 1);
                            } else {
                                userData.allEvents = [element];
                            }
                            if ((element.typeOfDayInReports === undefined || element.typeOfDayInReports === null || element.typeOfDayInReports === true) &&
                                (element.typeOfDayOffInReports === undefined || element.typeOfDayOffInReports === null || element.typeOfDayOffInReports === true)) {
                                if (userData.nbEvents) {
                                    userData.nbEvents += 1;
                                } else {
                                    userData.nbEvents = 1;
                                }
                            }
                            callback && callback(element.id);
                        } else {
                            this.loadMonthlyPlanningData();
                        }
                    });

                    this.setState((state) => ({ monthlyPlanningByUserByDay, editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                        this.loadStatsData();
                        message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully created' }));
                    });
                    this.props.toggleLoading!(false);

                },
                (error) => {
                    this.props.toggleLoading!(false);
                    let parsedError: any = undefined;
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "You only can modify events of your group" ||
                            parsedError.message === "You do not have the necessary rights to perform this action.") {
                            message.error(intl.formatMessage({ defaultMessage: 'You do not have the rights for this user' }));
                            this.setState({ editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true });
                            return;
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the event' }), "error");
                    }
                }
            );
        };

        // if event is global update directely, otherwise check availability
        // check availavility
        if (!skipCheckAvailability && !forceCreate) {
            Network.eventCheckAvailability(event, userIds).then(
                response => {
                    if (response.length > 0) {
                        this.props.toggleLoading!(false);

                        notification.error({
                            message: intl.formatMessage({ defaultMessage: 'Some users have no availability (periods) for the creation of this event:' }),
                            description: (
                                <ul>
                                    {response.map((u: UserSummary) => <li key={`eventCheckAvailability-user-${u.id}`}>{`${u.last_name} ${u.first_name}`}</li>)}
                                </ul>
                            ),
                            duration: null,
                        });

                    } else {
                        create();
                    }
                },
                () => {
                    this.props.toggleLoading!(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the event' }), "warning");
                }
            );
        } else {
            create();
        }

    };

    deleteEvent = (eventId: number, userId: number, dateStr: string): void => {
        // if deleteOne, delete just this event's occurence
        const { monthlyPlanningByUserByDay, copiedEvents } = this.state;
        const { intl } = this.props;
        Network.deleteEventV2([eventId]).then(
            (data) => {
                if (data["status"] && data["status"] === "success") {
                    const userData = monthlyPlanningByUserByDay?.find(u => u.userId === userId);
                    if (userData !== undefined && userData.cellEvents !== undefined) {
                        const elementDeleted = userData.allEvents?.find(e => e.id === eventId);
                        if (elementDeleted) {
                            if ((elementDeleted.typeOfDayInReports === undefined || elementDeleted.typeOfDayInReports === null || elementDeleted.typeOfDayInReports === true) &&
                                (elementDeleted.typeOfDayOffInReports === undefined || elementDeleted.typeOfDayOffInReports === null || elementDeleted.typeOfDayOffInReports === true)) {
                                if (userData.nbEvents) {
                                    userData.nbEvents -= 1;
                                } else {
                                    userData.nbEvents = 0;
                                }
                            }
                        }
                        userData.cellEvents[dateStr] = userData.cellEvents[dateStr]?.filter(d => d.id !== eventId);
                        userData.allEvents = userData.allEvents?.filter(d => d.id !== eventId);
                        const copiedEventsContainDeleted = copiedEvents?.find(ce => ce.id === eventId);
                        if (copiedEventsContainDeleted) {
                            this.setState({ copiedEvents: copiedEvents?.filter(ce => ce.id !== eventId) });
                        }
                        this.setState((state) => ({ monthlyPlanningByUserByDay, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                            this.loadStatsData();
                            message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully deleted' }));
                        });

                    } else {
                        this.loadMonthlyPlanningData();
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                }
            },
            (error) => {
                let parsedError: any = undefined;
                if (error.message && ![INTERNET_ERROR_MESSAGE, API_ERROR_MESSAGE].includes(error.message)) {
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                }
                if (parsedError?.message === "You only can delete events of your group that you create") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                } else if (parsedError?.message === "You only can delete events of your group that not created by administrator") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that have not been created by an administrator' }), "error");
                } else if (parsedError?.message === "You only can delete your own events") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete your events' }), "error");
                } else if (parsedError?.message === "You only can delete your own events than you created") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                } else if (parsedError?.message === "You only can delete events of your group") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events from your groups' }), "error");
                } else if (parsedError?.message === "You do not have the necessary rights to perform this action.") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You do not have the necessary rights to perform this removal' }), "error");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                }
            }
        );
    };

    deleteMultipleEvents = () => {
        const { selectedEvents } = this.state;
        const { intl } = this.props;
        const eventsToDelete = selectedEvents.filter(se => !se.locked);
        if (eventsToDelete.length > 0) {
            this.setState({ loadingDeleteMultiple: true });
            Network.deleteEventV2(eventsToDelete.map(se => se.eventId)).then(
                (data) => {
                    const { monthlyPlanningByUserByDay, copiedEvents } = this.state;

                    if (data["status"] && data["status"] === "success") {
                        eventsToDelete.forEach(selectedCellEvent => {
                            const user = monthlyPlanningByUserByDay?.find(ur => ur.userId === selectedCellEvent.userId);
                            const event = user?.allEvents?.find(e => e.id === selectedCellEvent.eventId);
                            if (event && event.id && event.userId) {
                                const dateStr = `${moment(event.startDate).format("YYYYMMDD")}`;
                                const userData = monthlyPlanningByUserByDay?.find(u => u.userId === event.userId);
                                if (userData !== undefined && userData.cellEvents !== undefined) {
                                    const elementDeleted = userData.allEvents?.find(e => e.id === event.id);
                                    userData.cellEvents[dateStr] = userData.cellEvents[dateStr]?.filter(d => d.id !== event.id);
                                    userData.allEvents = userData.allEvents?.filter(d => d.id !== event.id);

                                    const copiedEventsContainDeleted = copiedEvents?.find(ce => ce.id === event.id);
                                    if (copiedEventsContainDeleted) {
                                        this.setState({ copiedEvents: copiedEvents?.filter(ce => ce.id !== event.id) });
                                    }
                                    if (elementDeleted) {
                                        if ((elementDeleted.typeOfDayInReports === undefined || elementDeleted.typeOfDayInReports === null || elementDeleted.typeOfDayInReports === true) &&
                                            (elementDeleted.typeOfDayOffInReports === undefined || elementDeleted.typeOfDayOffInReports === null || elementDeleted.typeOfDayOffInReports === true)) {

                                            if (userData.nbEvents) {
                                                userData.nbEvents -= 1;
                                            } else {
                                                userData.nbEvents = 0;
                                            }
                                        }
                                    }
                                } else {
                                    this.loadMonthlyPlanningData();
                                    this.loadStatsData();
                                    return;
                                }
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'The event with id {id} has not been found' }, { id: selectedCellEvent.eventId }), "error");
                            }
                        });

                        this.setState((state) => ({ loadingDeleteMultiple: false, openDeleteModal: false, selectedEvents: [], monthlyPlanningByUserByDay, editEvent: undefined, editEventAvailabilities: undefined, editEventAvailabilityClosedByDefault: undefined, canEditStartDate: true, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                            this.loadStatsData();
                            message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully deleted' }));
                        });
                    } else {
                        this.setState({ loadingDeleteMultiple: false, openDeleteModal: false, selectedEvents: [] });
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                    }
                },
                (error) => {
                    let parsedError: any = undefined;
                    if (error.message && ![INTERNET_ERROR_MESSAGE, API_ERROR_MESSAGE].includes(error.message)) {
                        try {
                            parsedError = JSON.parse(error.message);
                        } catch (error) {
                            Sentry.captureException(error);
                        }
                    }
                    if (parsedError?.message === "You only can delete events of your group that you create") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                    } else if (parsedError?.message === "You only can delete your own events") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete your events' }), "error");
                    } else if (parsedError?.message === "You only can delete events of your group that not created by administrator") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that have not been created by an administrator.' }), "error");
                    } else if (parsedError?.message === "You only can delete your own events than you created") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                    } else if (parsedError?.message === "You only can delete events of your group") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events from your groups' }), "error");
                    } else if (parsedError?.message === "You do not have the necessary rights to perform this action.") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You do not have the necessary rights to perform this removal' }), "error");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                    }
                    this.setState({ loadingDeleteMultiple: false, openDeleteModal: false, selectedEvents: [] });
                }
            );
        }
        else
            showNotification(intl.formatMessage({ defaultMessage: 'Unable to delete' }), "warning", intl.formatMessage({ defaultMessage: 'All the events you selected are either locked or the day they are in is locked' }));
    };

    onClickCreateOvertime = (date: Moment, user: User, eventId?: number) => {
        eventId !== undefined &&
            this.setState({
                editOvertime: {
                    userId: user.id,
                    title: undefined,
                    startDate: date.clone(),
                    endDate: date.clone().add(1, 'hours'),
                    eventId: eventId,
                }
            });
    };

    onClickCreateUndertime = (date: Moment, user: User, eventId?: number) => {
        eventId !== undefined &&
            this.setState({
                editUndertime: {
                    userId: user.id,
                    title: undefined,
                    startDate: date.clone(),
                    endDate: date.clone().add(1, 'hours'),
                    eventId: eventId,
                    isNegative: true,
                }
            });
    };

    onClickEditOvertime = (overtime: PlanningOvertime) => {
        this.setState({ editOvertime: overtime });
    };

    onUpdateOvertime = (overtime: PlanningOvertime) => {
        Network.updateOvertime(overtime).then(
            () => console.log("#TODO"),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the overtime' }), "warning"),
        );
    };

    onUpdateUndertime = (undertime: PlanningOvertime) => {
        undertime.isNegative = true;
        Network.updateOvertime(undertime).then(
            () => console.log("#TODO"),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the undertime' }), "warning"),
        );
    };

    onDeleteOvertime = (overtime: PlanningOvertime) => {
        Network.deleteOvertime(overtime.id!).then(
            () => console.log("#TODO"),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the overtime' }), "warning"),
        );
    };

    onDeleteOvertimeFromEvent = (overtime: PlanningOvertime) => {
        Network.deleteOvertime(overtime.id!).then(
            (response: NetworkEvent) => {
                const eventParent: PlanningEvent = convertNetworkEventToPlanningEvent(response);
                const { userRows } = this.props;
                const { detailsEvent } = this.state;
                if (detailsEvent) {
                    detailsEvent.overtimes = eventParent.overtimes;
                    this.setState({ detailsEvent });
                }
                const userRow = userRows.find(ur => ur.user.id === eventParent.userId);
                const userDayInt = parseInt(moment(eventParent.startDate).format('D'));
                const userDayId = this.state.days.findIndex(ur => parseInt(ur.format('D')) === userDayInt);
                const userDay = userRow?.days[userDayId];
                if (userRow && userDay) {
                    userDay.events?.map(e => {
                        if (e.id === eventParent.id) {
                            e.overtimes = eventParent.overtimes;
                            e.totalHours = eventParent.totalHours;
                            e.totalHoursOvertime = eventParent.totalHoursOvertime;
                        }
                        return e;
                    });
                }
                this.props.changeUserRows!(userRows);
                message.success(this.props.intl.formatMessage({ defaultMessage: 'The overtime {title} has been successfully deleted' }, { title: overtime.title }));
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the overtime' }), "warning"),
        );
    };

    onCopyEvent = (eventIds: number[]) => {
        Network.getMultipleEventsV2(eventIds).then(
            response => {
                if (response.error === false) {
                    // message.success("Événement copié")
                    this.setState({ copiedEvents: convertNetworkEventsToPlanningEvents(response.data), cutEvent: undefined, selectedEvents: [] });
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');
                    this.setState({ copiedEvents: undefined, cutEvent: undefined });
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "warning")
        );
    };

    onCutEvent = (eventIds: number[], day: string) => {
        // TODO : add cut to multiple events
        const { intl } = this.props;
        Network.getEventsV2(eventIds[0], true).then(
            response => {
                if (response.error === false) {
                    if (response.hasUserData === false) {
                        // message.success("Événement coupé")
                        this.setState({ copiedEvents: [convertNetworkEventToPlanningEvent(response.data)], cutEvent: day });
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'The event cannot be cut because it contains user information\n(Timeclock or overtime)' }), 'warning');
                        // message.success("Événement copié")
                        this.setState({ copiedEvents: [convertNetworkEventToPlanningEvent(response.data)], cutEvent: undefined });
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');
                    this.setState({ copiedEvents: undefined, cutEvent: undefined });
                }
            },
            (error) => {
                let parsedError: any = undefined;
                try {
                    parsedError = JSON.parse(error.message);
                } catch (error) {
                    Sentry.captureException(error);
                }
                if (parsedError?.error) {
                    switch (parsedError.message) {
                        case "You only can cut events of your group that not created by administrator":
                            showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that have not been created by an administrator' }), "error");
                            break;
                        case "You only can cut events of your group that you create":
                        case "You only can modify your own events":
                            showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that you have created' }), "error");
                            break;
                        case "You only can modify events of your group":
                            showNotification(intl.formatMessage({ defaultMessage: 'You can only move events for members of your group' }), "error");
                            break;
                        case "No event correspond to the given id":
                            showNotification(intl.formatMessage({ defaultMessage: 'The event to be copied no longer exists' }), "warning");
                            break;
                        default:
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');
                    }
                    this.resetCopyCut();

                } else {
                    this.resetCopyCut();
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');
                }
            }
        );
    };

    resetCopyCut = (saveStateDirectly = true) => {
        const parcialState = { cutEvent: undefined, copiedEvents: undefined };

        if (saveStateDirectly)
            this.setState(parcialState);

        return parcialState;
    };

    onPasteEvent = (userId: number, dateStr: string, forceCopy?: boolean, skipCheckAvailability?: boolean, callbackSuccess?: () => void, callbackError?: () => void) => {
        const { copiedEvents, monthlyPlanningByUserByDay, cutEvent } = this.state;
        const { intl } = this.props;
        if (isNullOrEmpty(copiedEvents)) return;
        // get start date
        const startDate = moment(dateStr, 'YYYYMMDD');
        const copy = () => {
            Network.copyPasteEvents(copiedEvents.map(ce => ({
                eventId: ce.id ?? 0,
                userId: userId,
                date: startDate.format('YYYY-MM-DD')
            })
            ), forceCopy).then(
                data => {
                    console.log(data);
                    if (data.error === true) {
                        callbackError && callbackError();
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while copying the event' }), "warning");
                    } else {
                        callbackSuccess && callbackSuccess();
                        const userData = monthlyPlanningByUserByDay?.find(u => u.userId === userId);
                        if (userData !== undefined) {
                            if (userData.cellEvents === undefined) {
                                userData.cellEvents = { [dateStr]: data };
                            } else {
                                try {
                                    userData.cellEvents[dateStr] = userData.cellEvents[dateStr].concat(data);
                                } catch (error) {
                                    console.log("Content :", userData.cellEvents, dateStr); // Log for new sentry
                                    throw error;
                                }
                                userData.cellEvents[dateStr].sort((a, b) => moment(a.startDate).diff(moment(b.startDate)) < 0 ? -1 : 1);
                            }

                            if ((data.typeOfDayInReports === undefined || data.typeOfDayInReports === null || data.typeOfDayInReports === true) &&
                                (data.typeOfDayOffInReports === undefined || data.typeOfDayOffInReports === null || data.typeOfDayOffInReports === true)) {
                                if (userData.nbEvents) {
                                    userData.nbEvents += data.length;
                                } else {
                                    userData.nbEvents = data.length;
                                }

                            }

                            if (userData.allEvents) {
                                userData.allEvents = userData.allEvents.concat(data);
                            } else {
                                userData.allEvents = data;
                            }
                            this.setState((state) => ({ monthlyPlanningByUserByDay: cloneDeep(monthlyPlanningByUserByDay), refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus }), () => {
                                this.loadStatsData();
                                // message.success("Événement collé")
                            });

                        } else {
                            this.loadMonthlyPlanningData();
                        }
                    }
                },
                (error) => {
                    let parsedError: any = undefined;
                    callbackError && callbackError();
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "No event correspond to the given id") {
                            showNotification(intl.formatMessage({ defaultMessage: 'The event to copy does not exist anymore' }), "warning");
                            this.setState({ copiedEvents: undefined });
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while copying the event' }), "warning");
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while copying the event' }), "warning");
                    }
                }
            );
        };
        const cut = () => {
            Network.cutPasteEvent(copiedEvents[0].id!, userId, startDate.format("YYYY-MM-DDTHH:mm:ss"), forceCopy).then(
                data => {
                    if (data.error === true) {
                        callbackError && callbackError();
                        this.resetCopyCut();

                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");

                    } else {
                        callbackSuccess && callbackSuccess();
                        const oldUserData = monthlyPlanningByUserByDay?.find(u => u.userId === copiedEvents[0].userId);
                        if (oldUserData !== undefined && oldUserData.cellEvents !== undefined && cutEvent !== undefined) {
                            const oldDayIndex = cutEvent;
                            if (oldUserData.cellEvents[oldDayIndex] !== undefined && oldUserData.cellEvents[oldDayIndex]!.length > 0) {
                                oldUserData.cellEvents[oldDayIndex] = oldUserData.cellEvents[oldDayIndex]!.filter((el) => el.id !== copiedEvents[0].id);
                                oldUserData.allEvents = oldUserData.allEvents?.filter((el) => el.id !== copiedEvents[0].id);
                            }
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'The copied event does not exist anymore or can not be found' }), "warning");
                            return;
                        }
                        const userData = monthlyPlanningByUserByDay?.find(u => u.userId === userId);
                        if (userData !== undefined) {

                            if (userData.cellEvents === undefined) {
                                userData.cellEvents = { [dateStr]: [data] };
                            } else {
                                userData.cellEvents[dateStr]?.push(data);
                                userData.cellEvents[dateStr]?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)) < 0 ? -1 : 1);
                            }

                            if ((data.typeOfDayInReports === undefined || data.typeOfDayInReports === null || data.typeOfDayInReports === true) &&
                                (data.typeOfDayOffInReports === undefined || data.typeOfDayOffInReports === null || data.typeOfDayOffInReports === true)) {
                                if (userData.nbEvents) {
                                    userData.nbEvents += 1;
                                } else {
                                    userData.nbEvents = 1;
                                }

                            }

                            if (userData.allEvents) {
                                userData.allEvents.push(data);
                            } else {
                                userData.allEvents = [data];
                            }
                            this.setState((state) => ({ monthlyPlanningByUserByDay, refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter, refreshMissionsStatus: !state.refreshMissionsStatus, cutEvent: undefined, copiedEvents: [cloneEvent(convertNetworkEventToPlanningEvent(data))] }), () => {
                                this.loadStatsData();
                                // message.success("Événement collé")
                            });

                        } else {
                            this.loadMonthlyPlanningData();
                            this.setState({ cutEvent: undefined, copiedEvents: [cloneEvent(convertNetworkEventToPlanningEvent(data))] });
                        }
                    }
                },
                (error) => {
                    let parsedError: any = undefined;
                    callbackError && callbackError();
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                    if (parsedError?.error) {
                        switch (parsedError.message) {
                            case "You only can cut events of your group that not created by administrator":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that have not been created by an administrator' }), "error");
                                break;
                            case "You only can cut events of your group that you create":
                            case "You only can modify your own events":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that you have created' }), "error");
                                break;
                            case "You only can modify events of your group":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events for members of your group' }), "error");
                                break;
                            case "No event correspond to the given id":
                                showNotification(intl.formatMessage({ defaultMessage: 'The event to be copied no longer exists' }), "warning");
                                break;
                            default:
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");
                        }
                        this.resetCopyCut();

                    } else {
                        this.resetCopyCut();
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");
                    }
                }
            );
        };
        if (!skipCheckAvailability && !forceCopy) {
            const userAvailabilitiesRequestData: UserAvailabilityBodyRequest[] = [];
            copiedEvents.forEach(ce => {
                const date = moment(dateStr, 'YYYYMMDD').hours(ce.startDate.hours()).minutes(ce.startDate.minutes()).seconds(ce.startDate.seconds());
                const duration = moment.duration(ce.endDate.diff(ce.startDate));
                const durationInSeconds = duration.asSeconds();
                const endDate = date.clone().add(durationInSeconds, "seconds");
                userAvailabilitiesRequestData.push({
                    userId,
                    startDate: date,
                    endDate: endDate,
                });
            });
            Network.eventsCheckAvailabilities(userAvailabilitiesRequestData).then(
                response => {
                    if (response.length > 0) {
                        this.props.toggleLoading!(false);

                        message.error(intl.formatMessage({ defaultMessage: 'No availability' }));


                    } else {
                        if (cutEvent !== undefined) cut(); else copy();
                    }
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: "An error occurred while verifying the user's availability" }), "warning");
                }
            );
        } else {
            if (cutEvent !== undefined) cut(); else copy();
        }
    };

    showEventDetails = (eventId?: number) => {
        if (eventId === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "error");
        } else {
            this.setState({ detailsEventLoading: true }, () => {
                Network.getEventsV2(eventId).then(
                    (response) => {
                        if (response.error === false) {
                            const event: PlanningEvent = convertNetworkEventToPlanningEvent(response.data);
                            this.setState({
                                detailsEventLoading: false,
                                detailsEvent: event,
                                detailsUser: event.userId ? this.props.users?.find(u => u.id === event.userId) : undefined,
                                detailsGroup: event.groupId ? this.props.groups?.find(g => g.id === event.groupId) : undefined,
                            });
                        } else {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), 'warning');
                            this.setState({ ...this.resetCopyCut(false), detailsEvent: undefined });
                        }
                    },
                    () => {
                        this.setState({ detailsEventLoading: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "error");
                    }
                );
            });

        }
    };

    onUpdateSettings = (settings: NetworkSettings) => {
        this.setState({ showSettings: false, settingDefaultStep: undefined });
        this.props.changeSettings!(convertNetworkSettingsToPlanningSettings(settings));
    };

    onChangeProject = (values: number[]): void => {
        const projectsToAdd: Project[] = this.props.project?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        let { currentProjects } = this.state;
        currentProjects = projectsToAdd?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentProjects });
    };

    onChangeDepartments = (values: number[]) => {
        let { currentDepartments } = this.state;
        currentDepartments = this.props.departments.data.filter(g => values.findIndex(id => id === g.id) !== -1);
        this.setState({ currentDepartments });
    };

    onChangeTypesOfDay = (values: number[]): void => {
        const typesOfDayToDisplay: TypeOfDay[] = this.props.typesOfDay?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        let { currentTypesOfDay } = this.state;
        currentTypesOfDay = typesOfDayToDisplay?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentTypesOfDay });
    };

    onChangeStaffTypes = (values: number[]): void => {
        const { staffTypes } = this.state;
        let { currentStaffTypes } = this.state;
        currentStaffTypes = staffTypes?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentStaffTypes });
    };

    onChangeConfirmed = (value?: number): void => {
        this.setState({ confirmedFilter: value });
    };

    onChangePoi = (values: number[]): void => {
        const pois: POI[] = this.props.pois?.data?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        let { currentPois } = this.state;
        currentPois = pois?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentPois });
    };

    updateZoom = (value: number) => {
        switch (this.state.zoom) {
            case Zoom.SMALL:
                this.setState({ zoom: value < 0 ? Zoom.SMALL : Zoom.NORMAL });
                break;
            case Zoom.NORMAL:
                if (value < 0) this.setState({ zoom: Zoom.SMALL });
                break;
        }
    };

    getZoomStyles = (): React.CSSProperties[] => {
        switch (this.state.zoom) {
            case Zoom.SMALL:
                return [{
                    minWidth: '50px',
                    width: '50px',
                    maxWidth: '50px',
                    height: '150px',
                }, {
                    transform: 'rotate(90deg)',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'center',
                }];
            default:
                return [{
                    minWidth: '100px',
                    width: '100px',
                    maxWidth: '100px',
                    height: '60px',
                }, {
                    // transform: 'rotate(45deg)',
                }];
        }
    };

    changeGroup = (values: number[]) => {
        this.props.changeSelectGroups!(values.length === 0 ? undefined : values);
    };

    changeUser = (values: number[]) => {
        this.props.changeSelectUsers!(values.length === 0 ? undefined : values);
    };

    resetFilters = ({ all = true, group = false, user = false, confirm = false, poi = false, project = false, stafftype = false, typeofday = false, department = false, noEvents = false }) => {
        if (all || group) this.changeGroup([]);
        if (all || user) this.changeUser([]);
        if (all || confirm) this.onChangeConfirmed(undefined);
        if (all || poi) this.onChangePoi([]);
        if (all || project) this.onChangeProject([]);
        if (all || stafftype) this.onChangeStaffTypes([]);
        if (all || typeofday) this.onChangeTypesOfDay([]);
        if (all || department) this.onChangeDepartments([]);
        if (all || noEvents) this.setState({ hideUsersWithNoEvents: false });
    };

    renderDisplaySelection = (displayFilter = false) => {
        const { Option } = Select;
        const { Panel } = Collapse;
        const { loadingMonthlyPlanning, currentTypesOfDay, staffTypes, currentStaffTypes } = this.state;
        const { selectGroups, selectUsers, currentUser, intl } = this.props;

        let groupsToAdd: Group[] = this.props.groups?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [];
        const usersToAdd: User[] = this.props.users?.filter(u => u.active === true && u.visible !== false).sort((a, b) => a.last_name! > b.last_name! ? 1 : -1) ?? [];
        const typesOfDayToDisplay: TypeOfDay[] = this.props.typesOfDay?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        const flatGroupsIds = currentUser && currentUser.role === 1 ? currentUser.can_read_only_all_planning ? groupsToAdd.map(g => g.id) : currentUser.groupsAdmin ? currentUser.groupsAdmin.map(groupA => groupA.group_id) : [] : groupsToAdd.map(g => g.id);
        groupsToAdd = groupsToAdd.filter(group => flatGroupsIds.includes(group.id));
        return (
            <div >
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }}>
                        <Collapse style={{ backgroundColor: 'transparent', border: 'none' }}>
                            <Panel header={<span style={!isNullOrEmpty(selectGroups) ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Groups'} /></span>} key="groups-panel-key"
                                extra={<UndoOutlined style={isNullOrEmpty(selectGroups) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(selectGroups) && this.resetFilters({ all: false, group: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                <Space style={{ width: '100%' }} direction='vertical'>
                                    <Select
                                        listHeight={500}
                                        className="__filter-limit-size"
                                        disabled={loadingMonthlyPlanning || groupsToAdd.length === 0}
                                        mode="multiple"
                                        // autoClearSearchValue={false}
                                        allowClear
                                        placeholder={<FormattedMessage defaultMessage={'Groups to display'} />}
                                        style={{ width: '100%' }}
                                        value={selectGroups}
                                        onChange={(e) => this.changeGroup(e)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                    >
                                        {
                                            groupsToAdd.length > 0 &&
                                            groupsToAdd.map((g) => {
                                                return <Option label={g.name} value={g.id!} key={"groups-" + g.id}>{g.name}</Option>;
                                            })
                                        }
                                    </Select>
                                </Space>
                            </Panel>
                            <Panel header={<span style={!isNullOrEmpty(selectUsers) ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Users'} /></span>} key="users-panel-key"
                                extra={<UndoOutlined style={isNullOrEmpty(selectUsers) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(selectUsers) && this.resetFilters({ all: false, user: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                <Space style={{ width: '100%' }} direction='vertical'>
                                    <div className={`__filter-users-group ${(!isNullOrEmpty(selectGroups) && selectGroups.length === 1 && (!isNullOrEmpty(selectUsers)) && this.props.currentUser?.role === 2) ? '__filter-users-group-show' : ''}`}>
                                        <Select
                                            className="__filter-users-group-select"
                                            listHeight={500}
                                            disabled={loadingMonthlyPlanning}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Users to display'} />}
                                            // autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={selectUsers}
                                            onChange={this.changeUser}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                usersToAdd.length > 0 &&
                                                usersToAdd.map((g) => {
                                                    return <Option value={g.id!} key={"users-" + g.id} label={`${g.last_name} ${g.first_name}`}>{g.last_name} {g.first_name}</Option>;
                                                })
                                            }
                                        </Select>
                                        {
                                            this.props.currentUser?.role === 2 ?
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={<FormattedMessage defaultMessage={'{count, plural, one {Add this user to the group} other {Add these users to the group}}'} values={{ count: selectUsers?.length }} />}
                                                    onConfirm={() => this.onAddUsersConfirm(selectUsers!, selectGroups![0])}
                                                    okText={<FormattedMessage defaultMessage={'Continue'} />}
                                                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}>
                                                    <CircleButton
                                                        placement='bottomRight'
                                                        title={intl.formatMessage({ defaultMessage: '{count, plural, one {Add this user to the following group} other {Add these users to the following group}}' }, { count: selectUsers?.length })}
                                                        className={"__filter-users-group-button"}
                                                        small
                                                        icon={<Anticon icon={<TbArrowMerge />} />}
                                                    />
                                                </Popconfirm>
                                                : null
                                        }
                                    </div>
                                </Space>
                            </Panel>

                            {displayFilter && <>
                                {
                                    typesOfDayToDisplay.length > 0 &&
                                    <Panel header={<span style={!isNullOrEmpty(currentTypesOfDay) ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Type of day'} /></span>} key="users-type-of-day-panel-key"
                                        extra={<UndoOutlined style={isNullOrEmpty(currentTypesOfDay) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(currentTypesOfDay) && this.resetFilters({ all: false, typeofday: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <Select
                                            disabled={loadingMonthlyPlanning}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Types of day to display'} />}
                                            autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={currentTypesOfDay ? currentTypesOfDay.filter(p => p.id !== undefined).map(p => p.id!) : []}
                                            onChange={this.onChangeTypesOfDay}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                typesOfDayToDisplay.map((p: Project) => {
                                                    return <Option label={p.title} value={p.id!} key={"tod-" + p.id}>{p.title}</Option>;
                                                })
                                            }
                                        </Select>

                                    </Panel>
                                }
                                {
                                    staffTypes && staffTypes.length > 0 &&
                                    <Panel header={<span style={!isNullOrEmpty(currentStaffTypes) ? { fontWeight: 'bold' } : {}}>Aptitude</span>} key="users-stafftypes-panel-key"
                                        extra={<UndoOutlined style={isNullOrEmpty(currentStaffTypes) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(currentStaffTypes) && this.resetFilters({ all: false, stafftype: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <Select
                                            disabled={loadingMonthlyPlanning}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Ability to display'} />}
                                            autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={currentStaffTypes ? currentStaffTypes.filter(p => p.id !== undefined).map(p => p.id!) : []}
                                            onChange={this.onChangeStaffTypes}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                staffTypes?.map((p: StaffType) => {
                                                    return <Option label={p.name} value={p.id!} key={"stafftype-" + p.id}>{p.name}</Option>;
                                                })
                                            }
                                        </Select>

                                    </Panel>
                                }


                            </>}
                        </Collapse>
                    </Col>
                </Row>
            </div >
        );
    };

    renderFilter = () => {
        const { Option } = Select;
        const { Panel } = Collapse;
        const { currentDepartments, currentProjects, currentPois, confirmedFilter, loadingMonthlyPlanning } = this.state;
        const { departments, intl } = this.props;
        const projectsToAdd: Project[] = this.props.project?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        const poistoAdd: POI[] = this.props.pois?.data?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];

        return (
            <div >
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }}>
                        <Collapse style={{ backgroundColor: 'transparent', border: 'none' }}>
                            <>
                                {
                                    <Panel header={<span style={confirmedFilter !== undefined ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Confirmed'} /></span>} key="events-confirmations-panel-key"
                                        extra={<UndoOutlined style={confirmedFilter === undefined ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); confirmedFilter !== undefined && this.resetFilters({ all: false, confirm: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <Select
                                            disabled={loadingMonthlyPlanning}
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Event status'} />}
                                            style={{ width: '100%' }}
                                            value={confirmedFilter}
                                            onChange={this.onChangeConfirmed}
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            <Option label={intl.formatMessage({ defaultMessage: 'Confirmed events' })} value={1} key={"event-confirmed"}><FormattedMessage defaultMessage={'Confirmed events'} /></Option>
                                            <Option label={intl.formatMessage({ defaultMessage: 'Not confirmed events' })} value={0} key={"event-not-confirmed"}><FormattedMessage defaultMessage={'Not confirmed events'} /></Option>
                                        </Select>

                                    </Panel>
                                }
                                {
                                    departments.data.length > 0 &&
                                    <Panel header={<span style={!isNullOrEmpty(currentDepartments) ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Department'} /></span>} key="departments-panel-key"
                                        extra={<UndoOutlined style={isNullOrEmpty(currentDepartments) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(currentDepartments) && this.resetFilters({ all: false, department: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <Select
                                            disabled={loadingMonthlyPlanning}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Departments to display'} />}
                                            autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={currentDepartments ? currentDepartments.filter(p => p.id !== undefined).map(p => p.id!) : []}
                                            onChange={this.onChangeDepartments}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                departments.data.map(p => {
                                                    return <Option label={p.name} value={p.id!} key={"department-" + p.id}>{p.name}</Option>;
                                                })
                                            }
                                        </Select>

                                    </Panel>
                                }
                                {
                                    projectsToAdd.length > 0 && ((checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) || checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) || checkPrivilege(Privileges.Planning.readOnlyAllPlanning, this.props.currentUser)) &&
                                    <Panel header={<span style={!isNullOrEmpty(currentProjects) ? { fontWeight: 'bold' } : {}}>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}</span>} key="projects-panel-key"
                                        extra={<UndoOutlined style={isNullOrEmpty(currentProjects) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(currentProjects) && this.resetFilters({ all: false, project: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <Select
                                            disabled={loadingMonthlyPlanning}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'{projects} to display'} values={{ projects: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Projects' }) }} />}
                                            autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={currentProjects ? currentProjects.filter(p => p.id !== undefined).map(p => p.id!) : []}
                                            onChange={this.onChangeProject}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                projectsToAdd.map((p: Project) => {
                                                    return <Option label={p.title} value={p.id!} key={"project-" + p.id}>{p.title}</Option>;
                                                })
                                            }
                                        </Select>

                                    </Panel>
                                }
                                {
                                    poistoAdd.length > 0 && (checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) || checkPrivilege(Privileges.Planning.readOnlyAllPlanning, this.props.currentUser)) &&
                                    <Panel header={<span style={!isNullOrEmpty(currentPois) ? { fontWeight: 'bold' } : {}}><FormattedMessage defaultMessage={'Workplaces'} /></span>} key="poi-panel-key"
                                        extra={<UndoOutlined style={isNullOrEmpty(currentPois) ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); !isNullOrEmpty(currentPois) && this.resetFilters({ all: false, poi: true }); }} title={intl.formatMessage({ defaultMessage: 'Reset this filter' })} />}>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ paddingRight: '5px' }}>
                                                        <Select
                                                            disabled={loadingMonthlyPlanning}
                                                            mode="multiple"
                                                            autoClearSearchValue={false}
                                                            allowClear
                                                            placeholder={<FormattedMessage defaultMessage={'Points of interest to display'} />}
                                                            style={{ width: '100%' }}
                                                            value={currentPois ? currentPois.filter(p => p.id !== undefined).map(p => p.id!) : []}
                                                            onChange={this.onChangePoi}
                                                            filterOption={true}
                                                            optionFilterProp="label"
                                                            showArrow
                                                            maxTagCount={"responsive"}
                                                        >
                                                            {
                                                                poistoAdd.map((p: Project) => {
                                                                    return <Option label={p.title} value={p.id!} key={"poi-" + p.id}>{p.title}</Option>;
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                    <td style={{ width: '33px' }}>
                                                        <CircleButton
                                                            loading={loadingMonthlyPlanning}
                                                            small={true}
                                                            icon={<BiMap className="anticon-svg" />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({ selectPoiOnMap: true });
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Panel>
                                }
                            </>
                        </Collapse>
                    </Col>
                </Row>
            </div >
        );
    };

    onAddUsersConfirm = (usersId: number[], groupId: number) => {
        const { intl } = this.props;
        const group = this.props.groups?.find(g => g.id === groupId);
        let processedUsers = 0;
        const successUsers: User[] = [];
        const errorUsers: User[] = [];

        usersId.forEach(userId => {
            Network.addUserToGroup(groupId, userId).then(
                () => {
                    processedUsers++;
                    const user = this.props.users?.find(u => userId === u.id);
                    user && successUsers.push(user);
                },
                () => {
                    processedUsers++;
                    const user = this.props.users?.find(u => userId === u.id);
                    user && errorUsers.push(user);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while adding {user} to the group {group}' }, { user: `${user?.last_name} ${user?.first_name}`, group: group?.name }), "warning");
                }
            ).finally(() => {
                if (processedUsers === usersId.length) {
                    if (errorUsers.length > 0) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while adding the user(s) {users} to the group {group}' }, { users: errorUsers.map(u => `${u.last_name} ${u.first_name}`).join(", "), group: group?.name }), "warning");
                    } else {
                        message.success(intl.formatMessage({ defaultMessage: 'The users have been successfully created to the group {group}' }, { group: group?.name }));
                    }

                    const users = cloneDeep(this.props.users);

                    successUsers.forEach(successUser => {
                        const userIdx = users?.findIndex(u => u.id === successUser.id);

                        if (users && userIdx && userIdx >= -1) {
                            users[userIdx].group_users?.push({
                                active: true,
                                group: groupId,
                                group_name: group?.name,
                                id: -1,
                                is_admin: false,
                            });
                        }
                    });
                    users && this.props.changeUsers!(users);
                    this.props.changeSelectUsers!(errorUsers.length === 0 ? undefined : errorUsers.map(eu => eu.id));
                }
            });
        });
    };

    goTo = (link: string) => this.props.history.push(`/${this.props.match.params.lang}${link}`);

    onSelectCell = (eventId: number, userId: number, date: string, locked?: boolean) => {

        this.setState(prevState => {
            const selectedEvents = cloneDeep(prevState.selectedEvents);

            const indexSelected = selectedEvents.findIndex(sc => sc.eventId === eventId && sc.userId === userId);

            if (indexSelected >= 0) {
                selectedEvents.splice(indexSelected, 1);
            } else {
                selectedEvents.push({ userId, eventId, date, locked });
            }
            return { selectedEvents };
        });
    };

    onSelectMultiple = (data: { eventId: number, userId: number, date: string; locked?: boolean; }[]) => {
        this.setState({ selectedEvents: data });
    };

    onSelectRowCell = (eventId: number, userId: number, date: string, locked?: boolean) => {
        const { monthlyPlanningByUserByDay } = this.state;
        let selectedEvents = cloneDeep(this.state.selectedEvents);

        const previousSelectedEventsOfUser = selectedEvents.reverse().find(sc => sc.userId === userId);

        if (previousSelectedEventsOfUser) {
            const userData = monthlyPlanningByUserByDay?.find(data => data.userId === userId);

            const newSelectedEventsOfUser: SelectedEventV2[] = [];
            const nowSelected: SelectedEventV2 = { userId, eventId, date, locked };

            let previousSelectedEventsOfUserAllData = userData?.allEvents?.find(e => e.id === previousSelectedEventsOfUser.eventId);
            let nowSelectedAllData = userData?.allEvents?.find(e => e.id === nowSelected.eventId);
            if (previousSelectedEventsOfUser.eventId === nowSelected.eventId) {
                this.onSelectCell(eventId, userId, date, locked);
                return;
            } else if (previousSelectedEventsOfUserAllData === undefined || nowSelectedAllData === undefined) {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while selecting the events' }), "warning");
                return;
            } else if (moment(previousSelectedEventsOfUserAllData.startDate).isAfter(moment(nowSelectedAllData.startDate), "minutes")) {
                const tmp = previousSelectedEventsOfUserAllData;
                previousSelectedEventsOfUserAllData = nowSelectedAllData;
                nowSelectedAllData = tmp;
            }

            userData?.allEvents?.forEach((e) => {
                if (moment(e.startDate).isBetween(moment(previousSelectedEventsOfUserAllData!.startDate), moment(nowSelectedAllData!.startDate), "minutes", "[]")) {
                    newSelectedEventsOfUser.push({ userId: userId, eventId: e.id, date: moment(e.startDate).format("YYYYMMDD"), locked: e.eventLocked });
                }
            });
            selectedEvents = selectedEvents.filter(sc => sc.userId !== userId);
            selectedEvents = selectedEvents.concat(newSelectedEventsOfUser);
            this.setState({ selectedEvents });


        } else {
            this.onSelectCell(eventId, userId, date, locked);
        }
    };

    groupBy(x: SelectedEventParsed[], f: any) {
        return x.reduce((a: UserSelectedEventsParsed[], b: SelectedEventParsed) => {
            const found = a.find(t => t.name === f(b));
            if (found) {
                found.events.push({ title: b.eventTitle, startDate: b.eventStartDate, endDate: b.eventEndDate, locked: b.eventLocked });
            } else {
                a.push({
                    name: f(b),
                    events: [{ title: b.eventTitle, startDate: b.eventStartDate, endDate: b.eventEndDate, locked: b.eventLocked }]
                });
            }
            return a;
        }, []);
    }

    generateCompanyReport = () => {
        const { companyReportWithHours, departmentIdSelected } = this.state;
        this.setState({ isDownloadingPlanning: true });
        // generate reports
        Network.generateCompanyReport(this.state.date.format("YYYY-MM-01"), companyReportWithHours, departmentIdSelected).then(
            blob => {
                downloadBlob(blob, `company_${this.state.date.format("YYYY-MM")}.xlsx`);
                this.setState({ isDownloadingPlanning: false, modalDownloadCompanyReport: false, companyReportWithHours: false });
                message.success(this.props.intl.formatMessage({ defaultMessage: 'The planning has been successfully downloaded' }));
            },
            () => {
                this.setState({ isDownloadingPlanning: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while downloading the planning' }), "error");
            }
        );
    };

    calculatePlanningByUserByDay = () => {
        const { hideUsersWithNoEvents, monthlyPlanningByUserByDay, date, currentTypesOfDay, currentStaffTypes, confirmedFilter, currentDepartments, currentProjects, currentPois } = this.state;
        const { selectGroups, selectUsers } = this.props;

        // START filter monthly planning data
        const hasAtLeastOneFilterActivate = !isNullOrEmpty(selectGroups) ||
            !isNullOrEmpty(selectUsers) ||
            !isNullOrEmpty(currentTypesOfDay) ||
            !isNullOrEmpty(currentProjects) ||
            !isNullOrEmpty(currentStaffTypes) ||
            !isNullOrEmpty(currentPois) ||
            !isNullOrEmpty(currentDepartments) ||
            confirmedFilter !== undefined;

        let filteredPlanningByUserByDay: MonthlyPlanningByUserByDay[] | undefined;

        if (hasAtLeastOneFilterActivate) {
            filteredPlanningByUserByDay = monthlyPlanningByUserByDay ? cloneDeep(monthlyPlanningByUserByDay) : undefined;
            let filteredPlanning: MonthlyPlanningByUserByDay[] | undefined;
            let filteredGroupPlanning: MonthlyPlanningByUserByDay[] | undefined;

            // SUB START filter list of users
            if (!isNullOrEmpty(selectGroups)) {
                const usersFromGroup = this.props.users?.filter(u => selectGroups?.some(sg => u.group_users?.map(gu => gu.group).includes(sg))).map(u => u.id);
                filteredGroupPlanning = filteredPlanningByUserByDay?.filter(data => data.userId && usersFromGroup?.includes(data.userId));
            }
            if (!isNullOrEmpty(selectUsers)) {
                filteredPlanning = filteredPlanningByUserByDay?.filter(data => data.userId && selectUsers?.includes(data.userId));
            }

            if (!isNullOrEmpty(filteredGroupPlanning) && !isNullOrEmpty(filteredPlanning)) {
                filteredPlanningByUserByDay = unionWith(filteredGroupPlanning, filteredPlanning, (a, b) => a.userId === b.userId).sort((a, b) => {
                    const aFullName = `${a.userLastName} ${a.userFirstName}`;
                    const bFullName = `${b.userLastName} ${b.userFirstName}`;
                    return aFullName.localeCompare(bFullName);
                });
            } else if (!isNullOrEmpty(filteredGroupPlanning)) {
                filteredPlanningByUserByDay = filteredGroupPlanning;
            } else if (!isNullOrEmpty(filteredPlanning)) {
                filteredPlanningByUserByDay = filteredPlanning;
            }
            // END SUB START filter list of users

            if (!isNullOrEmpty(currentTypesOfDay)) {
                filteredPlanningByUserByDay = filteredPlanningByUserByDay?.filter(data => {
                    if ((data.allEvents && data.allEvents.findIndex(e => e.typeOfDayId && currentTypesOfDay?.map(tod => tod.id).includes(e.typeOfDayId)) > -1)) {
                        return true;
                    }
                    return false;
                });
            }
            if (!isNullOrEmpty(currentStaffTypes)) {
                const usersWithStaffType = this.props.users?.filter(u => u.staffType?.some(sg => currentStaffTypes?.find(st => st.id === sg.id) !== undefined)).map(u => u.id);
                filteredPlanningByUserByDay = filteredPlanningByUserByDay?.filter(data => data.userId && usersWithStaffType?.includes(data.userId));
            }

            filteredPlanningByUserByDay?.forEach(data => {
                const currentMonthDates = new Array(date.clone().daysInMonth()).fill(null).map((x, i) => `${date.clone().startOf('month').add(i, 'days').format("YYYYMMDD")}`);

                currentMonthDates.forEach((dateStr: string) => {
                    if (confirmedFilter !== undefined && data.cellEvents !== undefined) {
                        if (confirmedFilter === 0) {
                            data.cellEvents[dateStr] = data.cellEvents[dateStr]?.filter(e => e.countNotConfirmed > 0);
                        } else if (confirmedFilter > 0) {
                            data.cellEvents[dateStr] = data.cellEvents[dateStr]?.filter(e => e.countNotConfirmed === 0);
                        }
                    }
                    if (data.cellEvents !== undefined) {
                        if (!isNullOrEmpty(currentProjects)) {
                            data.cellEvents[dateStr] = data.cellEvents[dateStr]?.filter(e => currentProjects?.find(p => p.id === e.projectId) !== undefined);
                        }
                        if (!isNullOrEmpty(currentDepartments)) {
                            data.cellEvents[dateStr] = data.cellEvents[dateStr]?.filter(e => currentDepartments?.find(p => p.id === e.departmentId) !== undefined);
                        }
                        if (!isNullOrEmpty(currentPois)) {
                            data.cellEvents[dateStr] = data.cellEvents[dateStr]?.filter(e => currentPois?.find(p => p.id === e.poiId) !== undefined);
                        }
                    }
                });
                if (confirmedFilter !== undefined) {
                    if (confirmedFilter === 0) {
                        data.allEvents = data.allEvents?.filter(e => e.countNotConfirmed > 0);
                    } else if (confirmedFilter > 0) {
                        data.allEvents = data.allEvents?.filter(e => e.countNotConfirmed === 0);
                    }
                }
                if (!isNullOrEmpty(currentDepartments)) {
                    data.allEvents = data.allEvents?.filter(e => currentDepartments?.find(p => p.id === e.departmentId) !== undefined);
                }
                if (!isNullOrEmpty(currentProjects)) {
                    data.allEvents = data.allEvents?.filter(e => currentProjects?.find(p => p.id === e.projectId) !== undefined);
                }
                if (!isNullOrEmpty(currentPois)) {
                    data.allEvents = data.allEvents?.filter(e => currentPois?.find(p => p.id === e.poiId) !== undefined);
                }
            });
        } else {
            filteredPlanningByUserByDay = monthlyPlanningByUserByDay ? cloneDeep(monthlyPlanningByUserByDay) : undefined;
        }
        if (hideUsersWithNoEvents) {
            filteredPlanningByUserByDay = filteredPlanningByUserByDay ? filteredPlanningByUserByDay.filter(planningByUserByDay => planningByUserByDay.allEvents && planningByUserByDay.allEvents?.length > 0) : undefined;
        }

        this.setState({ filteredPlanningByUserByDay });
    };

    render() {
        const { loadingConfirmMultiple, loadingDeleteMultiple, hideUsersWithNoEvents, loadingStep, filteredPlanningByUserByDay, dailySummaryResetFilters, monthlyPlanningByUserByDay, loadingMonthlyPlanning, date, displayMode, displayFilter, displayOccupancyRate, displayMissionsStatus, displaySummary, refreshOccupancyRateFilter, refreshMissionsStatus, selectedEvents, userIdsEditable, allUsersEditable, modalDownloadCompanyReport, isDownloadingPlanning, currentTypesOfDay, currentProjects, currentStaffTypes, currentPois, currentDepartments, confirmedFilter } = this.state;
        const { selectGroups, selectUsers, isSmartphone, fullscreen, intl } = this.props;

        // START filter monthly planning data
        const hasAtLeastOneFilterActivate = !isNullOrEmpty(selectGroups) ||
            !isNullOrEmpty(selectUsers) ||
            !isNullOrEmpty(currentTypesOfDay) ||
            !isNullOrEmpty(currentProjects) ||
            !isNullOrEmpty(currentStaffTypes) ||
            !isNullOrEmpty(currentPois) ||
            !isNullOrEmpty(currentDepartments) ||
            confirmedFilter !== undefined;

        const headerButton: JSX.Element[] = [];
        if (allUsersEditable || this.props.currentUser?.can_read_only_all_planning || (userIdsEditable !== undefined && userIdsEditable.length > 2)) {
            headerButton.push(
                <CircleButton
                    className='__monthly-planning-btn-filter'
                    small={true}
                    type={displayFilter ? "primary" : "default"}
                    icon={
                        <Badge dot={hasAtLeastOneFilterActivate || hideUsersWithNoEvents === true}>
                            <FilterOutlined />
                        </Badge>}
                    onClick={() => this.setState({ displayFilter: !displayFilter, displayOccupancyRate: false, displayMissionsStatus: false, displaySummary: false })}
                    title={hasAtLeastOneFilterActivate || hideUsersWithNoEvents === true ? intl.formatMessage({ defaultMessage: 'Active filters' }) : intl.formatMessage({ defaultMessage: 'No active filters' })} />
            );
        }
        if (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)) {
            headerButton.push(
                <CircleButton
                    small={true}
                    type={displayOccupancyRate ? "primary" : "default"}
                    icon={<span className="anticon" ><FaRegCalendarCheck /></span>}
                    onClick={() => this.setState((state) => ({ displayOccupancyRate: !state.displayOccupancyRate, displayMissionsStatus: false, displayFilter: false, displaySummary: false }))}
                    title={intl.formatMessage({ defaultMessage: 'Control of planned requirements' })} />
            );
        }
        if (checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) && (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2))) {
            headerButton.push(
                <CircleButton
                    small={true}
                    type={displayMissionsStatus ? "primary" : "default"}
                    icon={<span className="anticon" ><PiListChecksLight /></span>}
                    onClick={() => this.setState((state) => ({ displayMissionsStatus: !state.displayMissionsStatus, displayOccupancyRate: false, displayFilter: false, displaySummary: false }))}
                    title={intl.formatMessage({ defaultMessage: 'Missions control' })} />
            );
        }
        if (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)) {
            headerButton.push(
                <CircleButton
                    small={true}
                    type={displaySummary ? "primary" : "default"}
                    icon={<span className="anticon" ><ImStatsBars2 /></span>}
                    onClick={() => this.setState((state) => ({ displaySummary: !state.displaySummary, displayOccupancyRate: false, displayMissionsStatus: false, displayFilter: false }))}
                    title={intl.formatMessage({ defaultMessage: 'Daily summary' })} />
            );
        }
        headerButton.push(
            <CircleButton
                small={true}
                type={this.state.displayStats ? "primary" : "default"}
                icon={<span className='anticon' ><BiTimer style={{ width: '22px', height: '22px' }} /></span>}
                onClick={() => this.setState((state) => ({ displayStats: !state.displayStats }))}
                title={intl.formatMessage({ defaultMessage: 'Counter display' })} />
        );

        const fullHeadersButtons = [
            <Space key={`monthtlyplanningv3-fullHeaderButtons`}>
                {!isSmartphone && this.props.width > 1450 &&
                    <Segmented
                        disabled={!(this.state.loadingStep === 'idle' || this.state.loadingStep === 'sucess') || this.state.isDownloadingPlanning}
                        key={'timeclock-show-options'}
                        value={this.state.displayMode}
                        options={PlanningShowType.map(t => { return { value: t.value, label: intl.formatMessage(t.label) }; })}
                        onChange={(mode) => {
                            this.setState({ displayMode: mode as any, centerView: true }, () => this.loadMonthlyPlanningData());
                            this.goTo(`/planning/${mode}`);
                        }}
                    />
                }
                {!isSmartphone && <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />}
                {isSmartphone || this.props.width < 1250 ?
                    <SpeedDial
                        small={true}
                        key='course-actions-speeddial'
                        title={intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        style={{ right: '35px' }}
                        buttons={headerButton}
                    />
                    :
                    headerButton
                }
                {!isSmartphone && <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />}
                {
                    !isSmartphone &&
                    <>
                        <CircleButton
                            small={true}
                            loading={this.state.isDownloadingPlanning}
                            type={"default"}
                            icon={<DownloadOutlined />}
                            onClick={() => this.setState({ modalDownloadCompanyReport: true })}
                            placement='topLeft'
                            title={intl.formatMessage({ defaultMessage: 'Download the planning' })} />
                    </>

                }
                {
                    <Can key="planning-button-settings" rule={Rules.Planning.Settings}>
                        <CircleButton
                            small={true}
                            placement='topLeft'
                            title={intl.formatMessage({ defaultMessage: 'Settings' })}
                            icon={<SettingOutlined />}
                            onClick={() => this.setState({ showSettings: true })} />
                    </Can>
                }
            </Space>
        ];


        const selectedCellsParsed: SelectedEventParsed[] = selectedEvents.map(selectedCell => {
            const user = filteredPlanningByUserByDay?.find(ur => ur.userId === selectedCell.userId);
            const event = user?.allEvents?.find(e => e.id === selectedCell.eventId);
            return {
                userFullName: `${user?.userFirstName} ${user?.userLastName}`,
                eventTitle: `${event?.title}`,
                eventStartDate: event ? moment(event.startDate) : undefined,
                eventEndDate: event ? moment(event.endDate) : undefined,
                eventLocked: event ? event.eventLocked : false
            };
        });
        const selectedCellsParsedByUser: UserSelectedEventsParsed[] = this.groupBy([...selectedCellsParsed], (event: SelectedEventParsed) => event.userFullName);

        const theme = createTheme({
            palette: {
                primary: {
                    main: '#C09F50',
                },
                secondary: {
                    main: '#3a1e2c',

                },
            },
        });
        return (
            <Card
                className="team-card"
                icon={<BsCalendar3 className='anticon' />}
                title={
                    <Space>
                        {this.props.width > 440 &&
                            <CircleButton
                                small
                                icon={<LeftOutlined />}
                                title={displayMode === 'monthly' ? intl.formatMessage({ defaultMessage: 'Previous month' }) : displayMode === 'weekly' ? intl.formatMessage({ defaultMessage: 'Previous week' }) : intl.formatMessage({ defaultMessage: 'Previous' })}
                                onClick={this.previousDate}
                                loading={loadingMonthlyPlanning} />
                        }
                        <CustomDatePicker
                            displayMode={displayMode}
                            date={date}
                            onChangeDate={this.onChangeDate}
                            loading={loadingMonthlyPlanning}

                            key={`custom-datepicker-${date.format('YYYYMMDD')}`}
                            className='planning-datepicker-input-container'
                        />

                        {this.props.width > 440 &&
                            <CircleButton
                                small
                                icon={<RightOutlined />}
                                title={displayMode === 'monthly' ? intl.formatMessage({ defaultMessage: 'Next month' }) : displayMode === 'weekly' ? intl.formatMessage({ defaultMessage: 'Next week' }) : intl.formatMessage({ defaultMessage: 'Next' })}
                                onClick={this.nextDate}
                                loading={loadingMonthlyPlanning}
                            />
                        }
                        {this.props.width > 470 &&
                            <CircleButton
                                small
                                icon={<HomeOutlined />}
                                title={intl.formatMessage({ defaultMessage: 'Today' })}
                                onClick={() => this.onChangeDate(moment())}
                                disabled={loadingMonthlyPlanning}
                            />
                        }
                        {
                            !isSmartphone && this.props.width > 1190 ?
                                <>
                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Refresh the planning' })}
                                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                                        onClick={this.loadMonthlyPlanningData}
                                        disabled={loadingMonthlyPlanning} />
                                    {
                                        (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)) ?
                                            <CircleButton
                                                small
                                                type={fullscreen ? 'primary' : 'default'}
                                                title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                                                icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                                onClick={toggleFullScreen}
                                            />
                                            : null
                                    }
                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                    <CircleButton
                                        small
                                        withoutTooltip={loadingMonthlyPlanning || selectedEvents.length === 0}
                                        title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Unselect all events (Esc)' })}
                                        icon={<Anticon icon={<PiSelectionForegroundThin />} />}
                                        onClick={() => this.setState({ selectedEvents: [] })}
                                        disabled={loadingDeleteMultiple || loadingConfirmMultiple || loadingMonthlyPlanning || selectedEvents.length === 0} />
                                    <CircleButton
                                        small
                                        withoutTooltip={loadingMonthlyPlanning || selectedEvents.length === 0}
                                        title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Delete {count} selected events' }, { count: selectedEvents.length })}
                                        icon={<DeleteOutlined />}
                                        onClick={() => this.setState({ openDeleteModal: true })}
                                        disabled={loadingConfirmMultiple || loadingMonthlyPlanning || selectedEvents.length === 0}
                                        loading={loadingDeleteMultiple} />
                                    <CircleButton
                                        small
                                        withoutTooltip={loadingMonthlyPlanning || selectedEvents.length === 0}
                                        title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Confirm {count} selected events' }, { count: selectedEvents.length })}
                                        icon={<CheckOutlined />}
                                        onClick={() => selectedEvents && this.onMultipleConfirmEvent(selectedEvents)}
                                        disabled={loadingDeleteMultiple || loadingMonthlyPlanning || selectedEvents.length === 0}
                                        loading={loadingConfirmMultiple} />
                                </>
                                : null
                        }

                    </Space>

                }
                headerElements={fullHeadersButtons}
            >
                <div className='planning-progressbar-container' style={{ width: '100%', position: "relative" }}>
                    <ThemeProvider theme={theme}>
                        <LinearProgress
                            sx={{
                                transition: 'none',
                            }}
                            style={{
                                transition: 'height 0s ease-out 1s',
                                height: (loadingStep === 'sucess') ? 1 : 3,
                                position: 'absolute',
                                width: '100%',
                                top: '-10px'
                            }}
                            color={
                                loadingStep === 'idle' ? 'inherit'
                                    : loadingStep === 'data' ? 'primary'
                                        : loadingStep === 'availabilities' ? 'primary'
                                            : loadingStep === 'stats' ? 'primary'
                                                : loadingStep === 'sucess' ? 'success'
                                                    : 'error'
                            }
                            variant={'buffer'}
                            valueBuffer={
                                (loadingStep === 'idle' ? 0
                                    : loadingStep === 'data' ? 15
                                        : loadingStep === 'availabilities' ? 30
                                            : loadingStep === 'stats' ? 45
                                                : loadingStep === 'sucess' ? 60
                                                    : 0) + this.state.loadingExtra
                            }
                            value={
                                (loadingStep === 'idle' ? 0
                                    : loadingStep === 'data' ? 12
                                        : loadingStep === 'availabilities' ? 40
                                            : loadingStep === 'stats' ? 70
                                                : loadingStep === 'sucess' ? 100
                                                    : 0)
                            } />
                    </ThemeProvider>
                </div>

                {(isSmartphone || this.props.width <= 1450) &&
                    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 10, gap: 8 }}>
                        {this.props.width <= 1190 && <span style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <Button
                                title={intl.formatMessage({ defaultMessage: 'Refresh the planning' })}
                                icon={<FAIcon prefix={'fad'} name="rotate" />}
                                onClick={this.loadMonthlyPlanningData}
                                disabled={loadingMonthlyPlanning} />
                            {
                                (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)) ?
                                    <Button
                                        type={fullscreen ? "primary" : "default"}
                                        icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                        onClick={toggleFullScreen}
                                        title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                                    />
                                    : null
                            }
                            <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                            <Button
                                title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Unselect all events (Esc)' })}
                                icon={selectedEvents.length === 0 ? <Anticon icon={<RiCheckboxMultipleBlankLine />} style={{ margin: 'auto' }} /> : <Anticon icon={<RiCheckboxMultipleFill />} style={{ margin: 'auto' }} />}
                                onClick={() => this.setState({ selectedEvents: [] })}
                                disabled={loadingMonthlyPlanning || selectedEvents.length === 0} />
                            <Button
                                title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Delete {count} selected events' }, { count: selectedEvents.length })}
                                icon={selectedEvents.length === 0 ? <DeleteOutlined /> : <DeleteFilled />}
                                onClick={() => this.setState({ openDeleteModal: true })}
                                disabled={loadingDeleteMultiple || loadingMonthlyPlanning || selectedEvents.length === 0} />
                            <Button
                                title={selectedEvents.length === 0 ? intl.formatMessage({ defaultMessage: 'No event selected' }) : intl.formatMessage({ defaultMessage: 'Confirm {count} selected events' }, { count: selectedEvents.length })}
                                icon={selectedEvents.length === 0 ? <CheckOutlined /> : <CheckCircleFilled />}
                                onClick={() => selectedEvents && this.onMultipleConfirmEvent(selectedEvents)}
                                disabled={loadingConfirmMultiple || loadingMonthlyPlanning || selectedEvents.length === 0} />
                        </span>}
                        <Segmented
                            block
                            disabled={!(this.state.loadingStep === 'idle' || this.state.loadingStep === 'sucess') || this.state.isDownloadingPlanning}
                            style={{ width: "100%" }}
                            key={'timeclock-show-options'}
                            value={this.state.displayMode}
                            options={PlanningShowType.map(t => { return { value: t.value, label: intl.formatMessage(t.label) }; })}
                            onChange={(mode) => {
                                this.setState({ displayMode: mode as any, centerView: true }, () => this.loadMonthlyPlanningData());
                                this.goTo(`/planning/${mode}`);
                            }}
                        />
                    </div>
                }


                <div style={{ position: 'relative' }} className={`__monthly-planning-main ${displayFilter || displayOccupancyRate || displayMissionsStatus || displaySummary ? 'filters' : 'nofilters'}`}>
                    <CalendarV3
                        lockedDaysByUsersByMonth={this.state.lockedDaysByUsersByMonth}
                        mode={this.state.mode}
                        loadingStep={loadingStep}
                        displayMode={this.state.displayMode}
                        date={date.clone()}
                        filteredPlanningByUserByDay={filteredPlanningByUserByDay}
                        sider={displayFilter || displayOccupancyRate || displayMissionsStatus || displaySummary}
                        currentDepartments={currentDepartments}
                        allHolidays={this.state.allHolidays}
                        copy={{
                            copiedEvents: cloneDeep(this.state.copiedEvents),
                            onCopyEvent: this.onCopyEvent,
                            onCutEvent: this.onCutEvent,
                            cutEvent: this.state.cutEvent,
                            resetCopyCut: this.resetCopyCut,
                            onPasteEvent: this.onPasteEvent,
                        }}
                        selection={{
                            onSelectMultiple: this.onSelectMultiple,
                            onSelectCell: this.onSelectCell,
                            onSelectRowCell: this.onSelectRowCell,
                            selectedEvents: cloneDeep(this.state.selectedEvents)
                        }}
                        usersUnavailabilities={this.state.usersUnavailabilities}
                        deleteEvent={this.deleteEvent}
                        showEventDetails={this.showEventDetails}
                        usersStats={this.state.usersStats}
                        contracts={this.state.contracts}
                        onConfirmEvent={this.onConfirmEvent}
                        onClickCreateEvent={this.onClickCreateEvent}
                        onClickEditEvent={this.onClickEditEvent}
                        userIdsEditable={this.state.userIdsEditable}
                        allUsersEditable={this.state.allUsersEditable}
                        displayStats={this.state.displayStats}
                    />

                    <div className={`__monthly-planning-main-filters ${displayFilter || displayOccupancyRate || displayMissionsStatus || displaySummary ? 'filters' : 'nofilters'}`}>
                        {
                            displayFilter ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                                    <h3 style={{ marginBottom: '0px' }}><FormattedMessage defaultMessage={'Display'} /></h3>
                                    <UndoOutlined style={hasAtLeastOneFilterActivate === false ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={() => hasAtLeastOneFilterActivate === true && this.resetFilters({ user: true, group: true, typeofday: true, stafftype: true })} title={intl.formatMessage({ defaultMessage: 'Reset all filters' })} />
                                </div>
                                :
                                displayOccupancyRate ?

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                                        <h2 style={{ marginBottom: '0px' }}><FormattedMessage defaultMessage={'Requirements control'} /></h2>
                                        <AiOutlineSync style={{ cursor: 'pointer' }} className='anticon' onClick={() => this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }))} title={intl.formatMessage({ defaultMessage: 'Refresh the missions' })} />
                                    </div>
                                    :
                                    displayMissionsStatus ?

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                                            <h2 style={{ marginBottom: '0px' }}><FormattedMessage defaultMessage={'Missions control'} /></h2>
                                            <AiOutlineSync style={{ cursor: 'pointer' }} className='anticon' onClick={() => this.setState((state) => ({ refreshMissionsStatus: !state.refreshMissionsStatus }))} title={intl.formatMessage({ defaultMessage: 'Refresh the missions' })} />
                                        </div>
                                        : displaySummary ?
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                                                    <h2 style={{ marginBottom: '0px' }}><FormattedMessage defaultMessage={'Daily summary'} /></h2>
                                                    <UndoOutlined style={{ cursor: 'pointer' }} onClick={() => !this.state.dailySummaryResetFilters && this.setState({ dailySummaryResetFilters: true })} title={intl.formatMessage({ defaultMessage: 'Reset all filters' })} />
                                                </div>
                                            </>
                                            : null
                        }
                        <hr></hr>
                        <div className='__monthly-planning-main-filters-content'>
                            {
                                //!
                                displayFilter ?
                                    <>
                                        {this.renderDisplaySelection((allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)))}
                                        {
                                            (allUsersEditable || (userIdsEditable !== undefined && userIdsEditable.length > 2)) ?
                                                <>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px', marginTop: "20px" }}>
                                                        <h3 style={{ marginBottom: '0px' }}><FormattedMessage defaultMessage={'Filters'} /></h3>
                                                        <span style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                                            <Tooltip
                                                                placement='topRight'
                                                                mouseLeaveDelay={1.5}
                                                                overlayInnerStyle={{ textAlign: 'center' }}
                                                                title={this.state.hideUsersWithNoEvents ? intl.formatMessage({ defaultMessage: 'Event-only users' }) : intl.formatMessage({ defaultMessage: 'All users' })}
                                                            >
                                                                <Switch
                                                                    checked={this.state.hideUsersWithNoEvents}
                                                                    onChange={() => this.setState((state) => ({ hideUsersWithNoEvents: !state.hideUsersWithNoEvents }))}
                                                                />
                                                            </Tooltip>
                                                            <UndoOutlined style={hasAtLeastOneFilterActivate === false && hideUsersWithNoEvents === false ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={() => (hasAtLeastOneFilterActivate === true || hideUsersWithNoEvents === true) && this.resetFilters({ confirm: true, poi: true, department: true, project: true, noEvents: true })} title={intl.formatMessage({ defaultMessage: 'Reset all filters' })} />
                                                        </span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='__monthly-planning-main-filters-content'>
                                                        {this.renderFilter()}
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </>
                                    :
                                    displayOccupancyRate ?
                                        <OccupancyRatesControl
                                            pickedStartDate={this.state.pickedStartDate}
                                            changePickedStartDate={(e: Moment) => this.setState({ pickedStartDate: e })}
                                            //key={`missions-occupancy-rate-filter-${refreshOccupancyRateFilter}`}
                                            refresh={refreshOccupancyRateFilter}
                                            // refreshOff={() => {
                                            //     console.log('entered in called refresh END')
                                            //     return this.setState({ refreshOccupancyRateFilter: false })
                                            // }}
                                            showEventDetails={this.showEventDetails}
                                            startDate={moment(this.state.date.clone().startOf(displayMode === 'monthly' ? "month" : "week").format(MOMENT_FORMAT))}
                                            endDate={moment(this.state.date.clone().endOf(displayMode === 'monthly' ? "month" : "week").format(MOMENT_FORMAT))}
                                            createNewEvent={this.createNewEvent} filter monthly
                                        />
                                        : displayMissionsStatus ?
                                            <MissionsControlV2
                                                startDate={this.state.date.clone().startOf(displayMode === 'monthly' ? "month" : "week")}
                                                endDate={this.state.date.clone().endOf(displayMode === 'monthly' ? "month" : "week")}
                                                refresh={refreshMissionsStatus}
                                                endRefresh={() => this.setState({ refreshMissionsStatus: false })}
                                                newEvent={this.createEventFromMission}
                                                events={filteredPlanningByUserByDay}
                                                showEvent={this.showEventDetails}
                                                mode={displayMode}
                                            />
                                            // <MissionControl
                                            //     startDate={this.state.date.clone().startOf(displayMode === 'monthly' ? "month" : "week")}
                                            //     endDate={this.state.date.clone().endOf(displayMode === 'monthly' ? "month" : "week")}
                                            //     refresh={refreshMissionsStatus}
                                            //     newEvent={this.createEventFromMission}
                                            //     endRefresh={() => this.setState({ refreshMissionsStatus: false })}
                                            // />
                                            : displaySummary ?
                                                <>
                                                    <DailySummary
                                                        data={monthlyPlanningByUserByDay}
                                                        date={this.state.pickedStartDate}
                                                        changeDate={(e) => this.setState({ pickedStartDate: e })}
                                                        key={`missions-${dailySummaryResetFilters}`}
                                                        reset={dailySummaryResetFilters}
                                                        resetOff={() => this.setState({ dailySummaryResetFilters: false })}
                                                        showEventDetails={this.showEventDetails}
                                                        maxStartDate={moment(this.state.date.clone().startOf(displayMode === 'monthly' ? "month" : "week").format(MOMENT_FORMAT))}
                                                        maxEndDate={moment(this.state.date.clone().endOf(displayMode === 'monthly' ? "month" : "week").format(MOMENT_FORMAT))}
                                                    />
                                                </>
                                                : null
                            }
                        </div>
                    </div>
                    <div
                        className='planning-display-usersStats-controller'
                        onClick={() => this.setState((state) => ({
                            displayStats: !state.displayStats,
                            //displayFilter: !state.displayStats? false: state.displayFilter, 
                            //displayOccupancyRate: !state.displayStats? false: state.displayOccupancyRate, 
                            //displaySummary: !state.displayStats? false: state.displaySummary, 
                        }))}
                    >
                        {this.state.displayStats ?
                            <Anticon icon={<CaretRightOutlined />} />
                            :
                            <Anticon icon={<CaretLeftOutlined />} />
                        }
                    </div>

                </div>

                {(this.state.detailsEvent || this.state.detailsEventLoading) &&
                    <>
                        <ShowEventModal
                            canEdit={true} // Change when simple user can only display details
                            onDeleteOvertime={this.onDeleteOvertimeFromEvent}
                            onChangeEvent={(event) => this.setState({ detailsEvent: event })}
                            event={this.state.detailsEvent}
                            user={this.state.detailsUser}
                            group={this.state.detailsGroup}
                            onCancel={() => this.setState({ detailsEvent: undefined, detailsEventLoading: false, detailsUser: undefined, detailsGroup: undefined })}
                            onEdit={this.onClickEditEvent}
                            onDelete={this.deleteEvent}
                            onCreateTemplate={this.refreshTemplates}
                        />
                    </>
                }
                {
                    this.state.editEvent &&
                    <EditEventModal
                        modalKey={'edit-event-modal'}
                        currentEvent={this.state.editEvent}
                        canEditStartDate={this.state.canEditStartDate}
                        onEdit={this.onEditEvent}
                        onCreate={this.onCreateEvent}
                        loading={this.props.loading || this.state.editEventLoading}
                        onCancel={() => this.setState({ editEvent: undefined })}
                        openSettings={() => this.setState({ showSettings: true, settingDefaultStep: 1 })} />
                }
                {
                    this.state.showSettings &&
                    <SettingsModal
                        visible={this.state.showSettings}
                        onCancel={() => this.setState({ showSettings: false, settingDefaultStep: undefined })}
                        onUpdate={this.onUpdateSettings}
                        onUpdateTemplate={(editTemplate: PlanningTemplate, isEditTemplate: boolean) => this.setState({ editTemplate, isEditTemplate })}
                        defaultStep={this.state.settingDefaultStep} />
                }
                {
                    this.state.editTemplate &&
                    <TemplateModal
                        visible={Boolean(this.state.editTemplate)}
                        template={this.state.editTemplate}
                        isEdit={this.state.isEditTemplate}
                        onClose={() => this.setState({ editTemplate: undefined, isEditTemplate: false })} />
                }
                <Modal
                    closable={!loadingDeleteMultiple}
                    title={<FormattedMessage defaultMessage={'Do you want to delete the events permanently?'} />}
                    open={this.state.openDeleteModal}
                    onCancel={() => this.setState({ openDeleteModal: false })}
                    destroyOnClose={true}
                    width={550}
                    footer={[
                        <Button key={`monthlyPlanningv3-delete-cancel`} disabled={loadingDeleteMultiple} type="dashed" onClick={() => this.setState({ openDeleteModal: false })}>
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button key={`monthlyPlanningv3-delete-confirm`} loading={loadingDeleteMultiple} type="primary" onClick={() => this.deleteMultipleEvents()}>
                            <FormattedMessage defaultMessage={'Delete'} />
                        </Button>

                    ]}>

                    <Row gutter={[10, 10]}>
                        {
                            selectedCellsParsedByUser.map(selectedCell => {
                                return (
                                    <Col xs={{ span: 24 }} key={`selectedCellsParsedByUser-delete-user-${selectedCell.name}`}>
                                        <b>{selectedCell.name}: </b>
                                        <ul>
                                            {
                                                selectedCell.events.map(e => (
                                                    <li key={`selectedCellsParsedByUser-delete-user-${selectedCell.name}-event-${e.title}-${e.startDate?.valueOf()}-${e.endDate?.valueOf()}`}>
                                                        {
                                                            e.locked &&
                                                            <Tooltip title={<FormattedMessage defaultMessage={'This event is locked and will not be treated during the deletion'} />}>
                                                                <FAIcon name='lock' prefix='fas' className='monthlyPlanningv3-delete-lock' />
                                                            </Tooltip>
                                                        }
                                                        {e.title} {e.startDate ? `(${e.startDate.format(getFormat('DATE_AND_TIME_SHORT'))} > ${e.endDate ? e.endDate.format(getFormat('DATE_AND_TIME_SHORT')) : ""})` : ""}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </Modal>
                <Modal
                    closable={!isDownloadingPlanning}
                    onCancel={() => !isDownloadingPlanning && this.setState({ modalDownloadCompanyReport: false, companyReportWithHours: false })}
                    title={<FormattedMessage defaultMessage={'Download the planning'} />}
                    open={modalDownloadCompanyReport}
                    destroyOnClose={true}
                    width={550}
                    footer={[
                        <Button key={`monthlyPlanningv3-download-planning-cancel`} type="dashed" onClick={() => this.setState({ modalDownloadCompanyReport: false, companyReportWithHours: false })} disabled={isDownloadingPlanning}>
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button key={`monthlyPlanningv3-download-planning-download`} type="primary" onClick={() => this.generateCompanyReport()} loading={isDownloadingPlanning}>
                            <FormattedMessage defaultMessage={'Download'} />
                        </Button>

                    ]}>
                    {
                        this.props.departments.data && this.props.departments.data.length > 0 &&
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px' }}>
                            <span><FormattedMessage defaultMessage={'Department'} /></span>
                            <Select
                                className="team-create-user-inputs"
                                showSearch
                                allowClear
                                onChange={(e: number | undefined) => this.setState({ departmentIdSelected: e })}
                                placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                value={this.state.departmentIdSelected}
                                filterOption={true}
                                optionFilterProp="label">
                                {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                            </Select>
                        </div>
                    }
                    <Checkbox checked={this.state.companyReportWithHours} disabled={isDownloadingPlanning} onChange={(e) => this.setState({ companyReportWithHours: e.target.checked })}><FormattedMessage defaultMessage={'Show times?'} /></Checkbox>
                </Modal>
                <ModalDuplicateEvents open={this.state.openDuplicateEvents} selectedEvents={selectedEvents} />
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    toggleLoadingPlanning: (l: boolean) => dispatch(toggleLoadingPlanning(l)),
    changeUserRows: (ur: PlanningUserRow[]) => dispatch(changeUserRows(ur)),
    changePrevUserRows: (ur: PlanningUserRow[]) => dispatch(changePrevUserRows(ur)),
    changeNowUserRows: (ur: PlanningUserRow[]) => dispatch(changeNowUserRows(ur)),
    changeNextUserRows: (ur: PlanningUserRow[]) => dispatch(changeNextUserRows(ur)),
    changePrevLoading: (l: boolean) => dispatch(changePrevLoading(l)),
    changeNowLoading: (l: boolean) => dispatch(changeNowLoading(l)),
    changeNextLoading: (l: boolean) => dispatch(changeNextLoading(l)),
    changeSelectGroups: (sg?: number[]) => dispatch(changeSelectGroups(sg)),
    changeSelectUsers: (su?: number[]) => dispatch(changeSelectUsers(su)),
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
    changeTeamAvailabilities: (a: UserAvailabilityWithUpdate[]) => dispatch(changeTeamAvailabilities(a)),
    loadSettings: (forceReload?: boolean) => dispatch(loadSettings(forceReload)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    fullscreen: state.window.fullscreen,
    height: state.window.height,
    loading: state.user.loading,
    users: state.teamManagement.users,
    company: state.user.company,
    groups: state.teamManagement.groups,
    groupsUsers: state.teamManagement.groupsUsers,
    settings: state.planning.settings,
    loadingPlanning: state.planning.loadingPlanning,
    userRows: state.planning.userRows,
    preloadUserRows: state.planning.preloadUserRows,
    loadingPrevPlanning: state.planning.loadingPrevPlanning,
    loadingNowPlanning: state.planning.loadingNowPlanning,
    loadingNextPlanning: state.planning.loadingNextPlanning,
    selectGroups: state.planning.selectGroups,
    selectUsers: state.planning.selectUsers,
    currentUser: state.user.currentUser,
    project: state.configurations.project,
    pois: state.location.pois,
    typesOfDay: state.configurations.typesOfDay,
    departments: state.configurations.departments,
    teamAvailabilities: state.planning.teamAvailabilities,
    loadingAvailabilities: state.planning.loadingAvailabilities,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(Planning)));