import { DashOutlined, MoreOutlined } from "@ant-design/icons";
import { DatePicker, Divider, Drawer, Dropdown, Empty, Segmented, Select, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import { ForwardRefExoticComponent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../store/store";
import getFormat from "../../../utils/Lang";
import { IIntegrationsType } from "../../../utils/types/generalTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import SpeedDial from "../../common/fields/speedDial";
import { UsersManagementRef } from "../tabs/export";

interface Props {
    element: ForwardRefExoticComponent<any>;
    title: string;
    open: boolean;
    onClose: ()=>void;
    datePicker: boolean;
    toLastMonth: boolean;
}

const IntegrationDrawerSelector = (props: Props) => {
    const Element = props.element;

    const {title, open, onClose, datePicker, toLastMonth} = props;
    const [display, setDisplay] = useState<'weekly' | 'monthly'>('monthly');

    const integrations = useAppSelector(state => state.integrations.enabledIntegrations.data);

    const defaultIntegration = useMemo((): IIntegrationsType => {
        if (!integrations)
            return 'unknown';

        if (integrations.hotelaEnabled)
            return 'hotela';
        else if (integrations.officeMakerEnabled)
            return 'officemaker';
        else if (integrations.globalOfficeEnabled)
            return 'globaloffice';
        else
            return 'unknown';


    }, [integrations]);

    const [selectedIntegration, setSelectedIntegration] = useState<IIntegrationsType>(defaultIntegration);
    const [selectedDate, setSelectedDate] = useState(moment().startOf('month').subtract(1, 'day').startOf('month'));
    const [selectedEndDate, setSelectedEndDate] = useState(moment().startOf('month').subtract(1, 'day'));
    // const [showSettings, setShowSettings] = useState(false);
    const [loading, setLoading] = useState(false);

    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen);
    const width = useSelector((state: ApplicationState) => state.window.width);

    const intl = useIntl();

    const ref = useRef<UsersManagementRef>(null);

    const refresh = useCallback(() => {
        ref.current?.refresh();
    }, [ref]);

    const toggleFullScreen = useCallback(() => {
        const doc = document;

        if (!doc.fullscreenElement) {
            document.body.requestFullscreen();
        }
        else {
            document.exitFullscreen();
        }
    }, []);

    const integratorItems: DefaultOptionType[] = [
        {
            label: 'Hotela',
            value: 'hotela',
            disabled: !integrations?.hotelaEnabled
        },
        {
            label: 'Global Office',
            value: 'globaloffice',
            disabled: !integrations?.globalOfficeEnabled
        },
        {
            label: 'Office Maker',
            value: 'officemaker',
            disabled: !integrations?.officeMakerEnabled
        },
    ];

    const canGoNext = useMemo(() => toLastMonth ? selectedDate.clone().endOf('month').add(1, 'day').isBefore(moment(), "month") : true, [selectedDate, toLastMonth]);

    const onNext = useCallback(() => {
        const date = selectedEndDate.clone();
        if (canGoNext) {

            if(display == 'weekly'){
                const fromDate = date.clone().add(1, 'day').startOf('week')
                setSelectedDate(fromDate.clone());
                setSelectedEndDate(fromDate.clone().endOf('week'));
            }else{
                const fromDate = date.clone().add(1, 'day').startOf('month')
                setSelectedDate(fromDate.clone());
                setSelectedEndDate(fromDate.clone().endOf('month'));
            }
        }
    }, [canGoNext, selectedEndDate, display]);

    const onPrevious = useCallback(() => {
        const date = selectedDate.clone();
        if(display == 'weekly'){
            const fromDate = date.clone().subtract(1, 'day').startOf('week')
            setSelectedDate(fromDate.clone());
            setSelectedEndDate(fromDate.clone().endOf('week'));
        }else{
            const fromDate = date.clone().subtract(1, 'day').startOf('month')
            setSelectedDate(fromDate.clone());
            setSelectedEndDate(fromDate.clone().endOf('month'));
        }
    }, [selectedDate, display]);

    const integrationMobileMenu = useMemo((): ItemType[] => {
        return [
            {
                label: 'Hotela',
                key: 'hotela',
                disabled: !integrations?.hotelaEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },
            {
                label: 'Global Office',
                key: 'globaloffice',
                disabled: !integrations?.globalOfficeEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },
            {
                label: 'Office Maker',
                key: 'officemaker',
                disabled: !integrations?.officeMakerEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },

        ];
    }, [integrations?.globalOfficeEnabled, integrations?.hotelaEnabled, integrations?.officeMakerEnabled]);

    const IntegrationButton = () => {
        return (
            <>
                {
                    width > 1050 ?
                        <Select
                            style={{ width: 220 }}
                            value={selectedIntegration}
                            onChange={(value) => setSelectedIntegration(value)}
                            options={integratorItems}
                        />
                        :
                        <Dropdown trigger={['click']} menu={{ items: integrationMobileMenu }}>
                            <CircleButton
                                small
                                icon={<FAIcon name='grid-2-plus' prefix='far' />}
                                title={intl.formatMessage({ defaultMessage: 'Integration selection' })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            />
                        </Dropdown>
                }
            </>
        );
    };

    const RefreshButton = () => {
        return (
            <CircleButton
                small
                title={intl.formatMessage({ defaultMessage: 'Force reload' })}
                icon={<FAIcon prefix='fad' name='rotate' />}
                onClick={() => refresh()}
            />
        );
    };

    const mobileButtons = () => {
        const buttons: ReactNode[] = [];

        if (width <= 470)
            buttons.push(<RefreshButton />);
        buttons.push(<IntegrationButton />);
        return buttons;
    };

    return (<Drawer
        destroyOnClose={true}
        className="__drawer __integrations__drawer"
        title={
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                {
                    datePicker ?
                        <>
                            
                            <CircleButton small icon={<FAIcon prefix='fal' name='chevron-left' />} onClick={onPrevious} disabled={loading} />
                            {selectedIntegration === 'globaloffice' && display === 'weekly' ?
                                <div key={`custom-date-picker-div-${selectedDate?.format('YYYYMMDD')}`} className='work_control-datepicker-input-container'>
                                    <DatePicker.WeekPicker
                                        className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                                        key={`year-picker-${selectedDate.format("YY-MM-DD")}-${selectedEndDate.format("YY-MM-DD")}`}
                                        suffixIcon={null}
                                        placement={'bottomLeft'}
                                        size='large'
                                        bordered={false}
                                        format={(value) => `${moment(value).startOf('week').format('DD MMM')} - ${moment(value).endOf('week').format('DD MMM')}`}
                                        value={selectedDate}
                                        onChange={(e) => {
                                            if (e) {
                                                const startOfWeek = e.clone().startOf('week')
                                                setSelectedDate(startOfWeek);
                                                setSelectedEndDate(startOfWeek.clone().endOf('week'));
                                            }
                                        }}
                                        allowClear={false}
                                        inputReadOnly
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        disabled={loading}
                                        disabledDate={(current) => toLastMonth ? current.isSameOrAfter(moment(), "month"):false}
                                    />
                                </div>
                                :
                                <div key={`drawer-custom-date-picker-div-${selectedDate?.format('YYYYMMDD')}`} className='work_control-datepicker-input-container'>
                                    <DatePicker
                                        className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                                        key={`drawer-year-picker-${selectedDate.format("YY-MM")}-${selectedDate?.month()}`}
                                        suffixIcon={null}
                                        placement={'bottomLeft'}
                                        size='large'
                                        picker={'month'}
                                        bordered={false}
                                        format={(value) => `${value.clone().startOf('month').format(getFormat('MONTH_AND_YEAR'))}`}
                                        value={selectedDate}
                                        onChange={(e) => {
                                            if (e) {
                                                const startOfMonth = e.clone().startOf('month')
                                                setSelectedDate(startOfMonth);
                                                setSelectedEndDate(startOfMonth.clone().endOf('month'));
                                            }
                                        }}
                                        allowClear={false}
                                        inputReadOnly
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        disabled={loading}
                                        disabledDate={(current) => toLastMonth ? current.isSameOrAfter(moment(), "month"): false}
                                    />
                                </div>
                            }
                            <CircleButton small icon={<FAIcon prefix='fal' name='chevron-right' />} onClick={onNext} disabled={loading || !canGoNext} />

                        </>
                        :
                        <span>{title}</span>
                }
                {
                    selectedIntegration === 'globaloffice'  && width > 1400 &&
                            <>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                <Segmented
                                    block
                                    disabled={loading}
                                    style={{ width: "300px" }}
                                    key={'accounting_globaloffice-show-options'}
                                    value={display}
                                    options={[
                                        { value: 'weekly', label: intl.formatMessage({ defaultMessage: 'Weekly' }) },
                                        { value: 'monthly', label: intl.formatMessage({ defaultMessage: 'Monthly' }) }
                                    ]}
                                    onChange={(value) => {
                                        const date = selectedDate.clone();
                                        if (value === 'monthly') {
                                            setDisplay('monthly');
                                            setSelectedDate(date.clone().startOf('month'));
                                            setSelectedEndDate(date.clone().endOf('month'));
                                        }
                                        else {
                                            setDisplay('weekly');
                                            setSelectedEndDate(date.clone().add(7, 'days'));
                                        }
                                    }}
                                />
                            </>
                }
                {
                    width > 470 &&
                    <>
                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                        <RefreshButton />
                    </>
                }
                {
                    width > 1190 &&
                    <CircleButton
                        small
                        type={fullscreen ? 'primary' : 'default'}
                        title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                        icon={fullscreen ? <FAIcon prefix='fad' name='minimize' /> : <FAIcon prefix='fad' name='maximize' />}
                        onClick={toggleFullScreen}
                    />
                }

            </div>
        }        width={'100%'}
        onClose={() => onClose()}
        extra={[
            <Space key='integration-selector-header'>
                {
                    width > 1050 ?
                        <IntegrationButton />
                        :
                        <SpeedDial
                            small={true}
                            key='course-actions-speeddial'
                            title={intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            style={{ right: '35px' }}
                            buttons={mobileButtons()}
                        />
                }
                {/* <CircleButton
                        small
                        title={intl.formatMessage({ defaultMessage: 'Integrations settings' })}
                        icon={<SettingOutlined />}
                        onClick={() => setShowSettings(true)}
                        />
                        <Divider type='vertical' />
                        <CircleButton
                        small
                        title={intl.formatMessage({ defaultMessage: 'Add new integration' })}
                        icon={<PlusOutlined />}
                        onClick={() => undefined}
                        disabled
                        /> */}
            </Space>
        ]}
        open={open}
        // footer={
        //     <div
        //         style={{
        //             textAlign: 'right',
        //         }}
        //     >
        //         {
        //             <>
        //                 <Button onClick={this.closeShowDetailsProject} style={{ marginRight: 8 }}>
        //                     <FormattedMessage defaultMessage={'Close'} />
        //                 </Button>
        //                 <Button onClick={() => this.showAddProject(showProject)} type="primary">
        //                     <FormattedMessage defaultMessage={'Edit'} />
        //                 </Button>
        //             </>
        //         }
        //     </div>
        // }
    >
        {
            selectedIntegration !== undefined ?
                <Element
                    ref={ref}
                    selectedIntegration={selectedIntegration}
                    selectedDate={selectedDate}
                    selectedEndDate={selectedEndDate}
                    setSelectedDate={setSelectedDate}
                    setSelectedEndDate={setSelectedEndDate}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    loading={loading}
                    setLoading={setLoading}
                    display={display}
                />
                :
                <Empty description={<FormattedMessage defaultMessage={'No integration selected'} />} />
        }
    </Drawer>)
}
export default IntegrationDrawerSelector