import { Alert, Input } from "antd";
import { PasswordProps } from "antd/lib/input/Password";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR, YELLOW_COLOR } from "../../../utils/constants";
import FAIcon from "../FAIcon";

const enum PasswordStrength {
    SHORT = 'short',
    WEAK = 'weak',
    MEDIUM = 'medium',
    STRONG = 'strong',
    NOTSAME = 'notsame'
}

const StrengthMessages = defineMessages<PasswordStrength>({
    [PasswordStrength.SHORT]: { defaultMessage: 'too short' },
    [PasswordStrength.WEAK]: { defaultMessage: 'weak' },
    [PasswordStrength.MEDIUM]: { defaultMessage: 'average' },
    [PasswordStrength.STRONG]: { defaultMessage: 'strong' },
    [PasswordStrength.NOTSAME]: { defaultMessage: "Passwords doesn't match." }
});

interface Props extends PasswordProps {
    password: string | undefined;
    onChangePassword: (value: string) => void,
    setError: (error: boolean) => void,
    repeat?: boolean;
}

interface StringthChecks {
    hasUpperCase: boolean;
    hasLowerCase: boolean;
    hasDigit: boolean;
    hasSpecialChar: boolean;
}

const passwordMinLength = 8;

export const Password = (props: Props) => {
    const [message, setMessage] = useState<PasswordStrength | undefined>(undefined);
    const [strengthChecks, setStrengthChecks] = useState<StringthChecks>({
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
    });
    const [progress, setProgress] = useState("");

    const [repeatPassword, setRepeatPassword] = useState('');
    const [repeatError, setRepeatError] = useState(true);

    const intl = useIntl();

    const checkPassword = (password: string, repeatPassword: string) => {
        const strengthChecks: StringthChecks = {
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false
        };

        const nbStrengthChecks = Object.values(strengthChecks).length;

        if (password.length === 0) {
            setProgress('');
            setMessage(undefined);
            props.setError(false);
            return;

        }

        if (password.length < passwordMinLength) {
            setProgress(`${(1 / nbStrengthChecks) * 100}%`);
            setMessage(PasswordStrength.SHORT);
            props.setError(true);
            return;
        }


        strengthChecks.hasUpperCase = /[A-Z]+/.test(password);
        strengthChecks.hasLowerCase = /[a-z]+/.test(password);
        strengthChecks.hasDigit = /[0-9]+/.test(password);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(password);

        const verifiedList = Object.values(strengthChecks).filter((value) => value);

        const strength = verifiedList.length == nbStrengthChecks
            ? PasswordStrength.STRONG
            : verifiedList.length >= nbStrengthChecks * (0.4)
                ? PasswordStrength.MEDIUM
                : PasswordStrength.WEAK;

        setProgress(`${(verifiedList.length / nbStrengthChecks) * 100}%`);
        setMessage(strength);
        const repeatPasswordError = password !== repeatPassword;

        if (repeatPasswordError) {
            setRepeatError(true);
        } else {
            setRepeatError(false);
        }

        if ([PasswordStrength.WEAK, PasswordStrength.SHORT].includes(strength) || (props.repeat && repeatPasswordError)) {
            props.setError(true);
        } else {
            props.setError(false);
        }

        setStrengthChecks(strengthChecks);
    };

    const handlePassword = (passwordValue: string) => {
        props.onChangePassword && props.onChangePassword(passwordValue);
        checkPassword(passwordValue, repeatPassword);
    };

    const getActiveColor = useCallback((type: PasswordStrength | undefined) => {
        if (type === undefined) return 'transparent';
        if (type === PasswordStrength.STRONG) return GREEN_COLOR;
        if (type === PasswordStrength.MEDIUM) return YELLOW_COLOR;
        if (type === PasswordStrength.WEAK) return ORANGE_COLOR;
        return RED_COLOR;
    }, []);

    return (
        <div style={props.style}>
            <div className={`inputfield-content`}>
                <Input.Password
                    {...props}
                    value={props.password}
                    onChange={({ target }) => {
                        handlePassword(target.value);
                    }}
                    className={message === PasswordStrength.SHORT ? 'inputfield-error' : ''}
                    style={undefined} />

                <div style={{ width: 'calc(100% - 20px)', height: '1.5px', backgroundColor: 'transparent', borderRadius: '100px', position: 'relative', margin: 'auto', marginTop: '-3px', zIndex: '999999999999' }}>
                    <div
                        style={{
                            position: 'absolute',
                            borderRadius: '100px',
                            transition: 'all 0.5s ease-out',
                            height: '100%',
                            width: progress,
                            backgroundColor: getActiveColor(message),
                        }}
                    ></div>
                </div>

                {
                    message && message.length > 0 ?
                        message === PasswordStrength.SHORT ?
                            <p className="inputfield-error-text"><FormattedMessage defaultMessage={'Your password is {strength} ({length} minimum characters)'} values={{ strength: intl.formatMessage(StrengthMessages[message]), length: passwordMinLength }} /></p>
                            : <p className="inputfield-error-text" style={{ color: getActiveColor(message) }}><FormattedMessage defaultMessage={'Your password security is {strength}'} values={{ strength: intl.formatMessage(StrengthMessages[message]) }} /></p>
                        : null
                }

                {
                    message && message.length > 0 && message !== PasswordStrength.SHORT ?
                        <Alert
                            style={{ marginTop: '10px' }}
                            message={
                                <>
                                    {
                                        strengthChecks.hasDigit ?
                                            <p style={{ color: GREEN_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-check' /> <FormattedMessage defaultMessage={'The password contains numbers'} /></p>
                                            :
                                            <p style={{ color: RED_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-xmark' /> <FormattedMessage defaultMessage={'The password does not contain numbers'} /></p>
                                    }
                                    {

                                        strengthChecks.hasUpperCase ?
                                            <p style={{ color: GREEN_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-check' /> <FormattedMessage defaultMessage={'The password contains lowercase letters'} /></p>
                                            :
                                            <p style={{ color: RED_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-xmark' /> <FormattedMessage defaultMessage={'The password does not contain lowercase letters'} /></p>
                                    }
                                    {

                                        strengthChecks.hasLowerCase ?
                                            <p style={{ color: GREEN_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-check' /> <FormattedMessage defaultMessage={'The password contains uppercase letters'} /></p>
                                            :
                                            <p style={{ color: RED_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-xmark' /> <FormattedMessage defaultMessage={'The password does not contain uppercase letters'} /></p>
                                    }
                                    {

                                        strengthChecks.hasSpecialChar ?
                                            <p style={{ color: GREEN_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-check' /> <FormattedMessage defaultMessage={'The password contains special characters'} /></p>
                                            :
                                            <p style={{ color: RED_COLOR, fontSize: '90%', fontStyle: 'italic' }}><FAIcon prefix='fad' name='circle-xmark' /> <FormattedMessage defaultMessage={'The password does not contain special characters'} /></p>
                                    }
                                </>
                            }
                            type="info" />
                        : null
                }
                {
                    props.repeat ?
                        <>
                            <Input.Password
                                style={{ marginTop: '10px' }}
                                placeholder={intl.formatMessage({ defaultMessage: 'Repeat password' })}
                                onChange={({ target }) => {
                                    setRepeatPassword(target.value);
                                    checkPassword(props.password ?? '', target.value);
                                }} />

                            {
                                repeatError && repeatPassword.length > 0 ?
                                    <p className="inputfield-error-text"><FormattedMessage defaultMessage={'The passwords do not match'} /></p>
                                    : null
                            }
                        </>
                        : null
                }
            </div>
        </div>
    );
};

export default Password;