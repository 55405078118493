import { Badge } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ILockedEvent } from "../../../utils/types/generalTypes";
import FAIcon from "../../common/FAIcon";
import ConfirmButton from "../../common/fields/confirmButton";

interface Props {
    locked: boolean;
    eventsOnDay: ILockedEvent[];
    canUnlock: boolean;
    onUnlockDay: () => void;
    onUnlockEvent: (eventId: number) => void;
    autoHeight: boolean;
}

const CalendarCell = (props: Props) => {
    const { locked, eventsOnDay, canUnlock, onUnlockDay, onUnlockEvent } = props;
    const intl = useIntl();
    const [mouseHover, setMouseHover] = useState<boolean>(false)
    
    return (
        <div style={{width: '100%', 'height': '100%', top: 0, left: 0, marginTop: 0}} onMouseEnter={() => setMouseHover(true)} onMouseLeave={() => setMouseHover(false)} >
            <div>
                <ul style={{listStyle: 'none', marginLeft: 5, marginRight: 5, marginTop: 0, marginBottom: 0, padding: 0}}>
                    {eventsOnDay.map(e => (
                        <li style={{height:24}} key={`listed-event-${e.id}`}>
                            <div style={{display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, width: '100%', lineHeight: '15px'}}>
                                <div style={{gap: 8, display: 'inline-flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'}}>
                                    <Badge style={{flex: "0 0 auto"}} color={e.color ?? 'var(--primary-color)'}/>
                                    <div
                                        style={{
                                            flex: "1", // Le texte occupe tout l'espace restant
                                            overflow: "hidden", // Cache le contenu qui dépasse
                                            whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                                            textOverflow: "ellipsis", // Ajoute des points de suspension
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span style={{fontSize: '12px', lineHeight: '15px'}}>{e.title}</span>
                                    </div>
                                </div>
                                {!locked ?
                                    <ConfirmButton
                                        icon={<FAIcon prefix='fad' name='unlock' />}
                                        size='small'
                                        title={intl.formatMessage({ defaultMessage: 'Unlock event' })}
                                        onConfirm={() => onUnlockEvent(e.id)}
                                        disabled={!e.isUnlockable}
                                        text={intl.formatMessage({ defaultMessage: 'Are you sure to unlock event' })}
                                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                        placement={'left'}
                                        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                                        primary
                                        style={{
                                            // pointerEvents: 'all', 
                                            // cursor: 'pointer',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            borderColor: 'white',
                                            display: 'inline',
                                            // transform: 'scale(0.6) translate(0,0)',
                                            // transformOrigin: 'center center'
                                        }}
                                        withoutTooltip
                                        buttonPlacement={'left'}
                                    />
                                    :
                                    <></>
                                }
                            </div>
                        
                        </li>
                    ))}

                </ul>
            </div>
            {locked &&
            <div className={locked? `cell-lock`: ''} style={{position: 'absolute',  width: '100%', 'height': '100%', top: 0, left: 0, marginTop: 0, overflow: 'scroll'}}>
            
            </div>
            }
            {locked && canUnlock &&  mouseHover &&
                <div className='__i-e-icons'>
                    <ConfirmButton
                        icon={<FAIcon prefix='fad' name='unlock' />}
                        small
                        title={intl.formatMessage({ defaultMessage: 'Unlock day' })}
                        onConfirm={onUnlockDay}
                        disabled={!canUnlock}
                        text={intl.formatMessage({ defaultMessage: 'Are you sure to unlock day' })}
                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                        placement={'left'}
                        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                        primary
                        style={{
                            pointerEvents: 'all', 
                            cursor: 'pointer',
                            color: 'black',
                            backgroundColor: 'white',
                            borderColor: 'white'
                        }}
                        withoutTooltip
                        buttonPlacement={'left'}
                    />
                </div>
            }
        </div>
    )
};
export default CalendarCell;