import { Button, Col, Collapse, DatePicker, Divider, message, Row, Segmented, Select } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import '../../styles/newsfeed.css';
import { MOMENT_SHORT_TIME_FORMAT } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { CreateNewsBody } from '../../utils/types/networkTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import FileUploaderMultiple from '../common/fields/fileUploaderMultiple';
import ImageUploaderMultiple from '../common/fields/imageUploaderMultiple';
import InputField from '../common/fields/inputField';
import SwitchLabeled from '../common/fields/switchLabeled';
import Card from '../common/general/card';
import Container from '../common/navigations/container';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

type ReduxProx = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & ReduxProx & IntlProps;

interface State {
    title: (string | undefined)[];
    content: (string | undefined)[];
    images?: (File | string)[];
    emailNotification: boolean;
    pushNotification: boolean;
    startDate: string | undefined;
    withComment: boolean;
    isNewsflash: boolean;
    withLike: boolean;
    likeName: string | undefined;
    files?: (File | string)[];
    loading: boolean,
    groupsIds: undefined | number[];
    targetFeed: 'newsfeed' | 'userfeed' | 'customers';
}

/**
 * Page to create a News
 */
class CreateNewsfeed extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const { currentUser, history } = this.props;
        const targetFeed = history.location.state?.targetFeed ? history.location.state?.targetFeed : currentUser && currentUser.role == 1 ? 'userfeed' : 'newsfeed';

        this.state = {
            title: [undefined, undefined, undefined, undefined],
            content: [undefined, undefined, undefined, undefined],
            startDate: undefined,
            withComment: true,
            isNewsflash: false,
            withLike: true,
            likeName: undefined,
            loading: false,
            groupsIds: undefined,
            emailNotification: false,
            pushNotification: false,
            targetFeed
        };
    }

    /**
     * Decide weither or not to show the translation contents fields
     */
    showTranslationContent = () => this.state.targetFeed === "customers";

    /**
     * Decide weither or not to show the create button
     */
    showButton = () => {
        const { title, content } = this.state;
        let bool: boolean = title !== undefined && title.length > 0;
        if (this.showTranslationContent()) {
            bool = content[0] !== undefined && content[0] !== "" && content[1] !== undefined && content[1] !== "" && content[2] !== undefined && content[2] !== "" && title[0] !== undefined && title[0] !== "" && title[1] !== undefined && title[1] !== "" && title[2] !== undefined && title[2] !== "";
        } else {
            bool = bool && content[0] !== undefined && content[0] !== "" && title[0] !== undefined && title[0] !== "";
        }
        return bool;
    };

    /**
     * Called when a title InputField changed
     * @param event the triggered event
     * @param index the index of the title that changed
     */
    changeTitle = (event: any, index: number) => {
        const { title } = this.state;
        title[index] = event.target.value;
        this.setState({ title });
    };

    /**
     * Called when a content InputField changed
     * @param event the triggered event
     * @param index the index of the content that changed
     */
    changeContent = (event: any, index: number) => {
        if (event.target) {
            const { content } = this.state;
            content[index] = event.target.value;
            this.setState({ content });
        } else {
            const { content } = this.state;
            content[index] = event;
            this.setState({ content });
        }
    };

    /**
     * Called when the ref type is changed
     */
    onChangeRefType = (val: string) => {
        if (val === 'groups') {
            this.setState((state) => ({ groupsIds: state.groupsIds === undefined ? [] : state.groupsIds }));
        } else {
            this.setState({ groupsIds: undefined });
        }
    };


    /**
     * Handle the creation of the news
     */
    handleOnSubmit = () => {
        const { targetFeed, emailNotification, pushNotification, groupsIds, files, title, content, images, startDate, withComment, withLike, likeName, isNewsflash } = this.state;
        this.setState({ loading: true });

        if (!this.showTranslationContent()) {
            if (title[0] === undefined || title[0] === '') {
                message.error(<FormattedMessage defaultMessage={'Please fill in the title field'} />);
                return;
            }
            if (content[0] === undefined || content[0] === '') {
                message.error(<FormattedMessage defaultMessage={'Please fill in the content field'} />);
                return;
            }
        }
        //create the request body
        const body: CreateNewsBody = {
            is_instructor: ['newsfeed', 'userfeed'].includes(targetFeed),
            is_client: targetFeed === 'customers',
            title: title[1] ? title[1] : title[0] as string,
            title_fr: title[1] ? title[0] : undefined,
            title_de: title[2],
            title_it: title[3],
            text: content[1] ? content[1] : content[0] as string,
            text_fr: content[1] ? content[0] : undefined,
            text_de: content[2],
            text_it: content[3],
            image: images && images.length > 0 ? images[0] : undefined,
            images: images,
            files: files,
            email_notification: emailNotification,
            push_notification: pushNotification,
            start_date: moment(startDate).toISOString(),
            comment_enabled: withComment,
            is_newsflash: isNewsflash,
            like_enabled: withLike,
            like_name: likeName && likeName.length > 0 ? likeName : undefined,
            groupsIds: ['newsfeed', 'userfeed'].includes(targetFeed) ? groupsIds : undefined,
            creator_role_type: targetFeed === 'userfeed' ? 1 : 2
        };

        //create the news
        Network.createNews(body).then(
            () => this.props.history.push({ pathname: `/${this.props.match.params.lang}/newsfeed/list`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The News has been successfully created' }) } }),
            () => {
                this.setState({ loading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the news' }), "warning");
            }
        );
    };

    disabledDate = (current: Moment): boolean => {
        // Can not select days before today and today
        if (current.isSameOrAfter(moment(), 'minute')) return false;
        else return true;
    };

    render() {
        const { targetFeed, startDate, groupsIds, files, title, content, emailNotification, pushNotification, images, withComment, withLike, likeName, isNewsflash } = this.state;
        const { currentUser, intl, isSmartphone, groups } = this.props;

        return (
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'News' }), link: "/newsfeed/list" }, { title: "Ajouter", link: "/newsfeed/create" }]}>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                        <Card className="newsfeed-left" icon={<FAIcon prefix='fad' name='newspaper' />} title={intl.formatMessage({ defaultMessage: 'Write a new news item' })}>
                            <Segmented
                                block
                                size={isSmartphone ? 'middle' : 'large'}
                                style={{ width: "100%", fontSize: isSmartphone ? '12px' : '14px', marginBottom: 15 }}
                                key={'timeclock-show-options'}
                                value={targetFeed}
                                options={[
                                    {
                                        value: 'newsfeed',
                                        label: intl.formatMessage({ defaultMessage: 'Newsfeed' })
                                    },
                                    {
                                        value: 'userfeed',
                                        label: intl.formatMessage({ defaultMessage: 'UserFeed' })
                                    },
                                    {
                                        value: 'customers',
                                        label: intl.formatMessage({ defaultMessage: 'Customers' })
                                    },
                                ].filter(opt => (this.props.ext || !this.props.ext && opt.value !== 'customers') && (currentUser && currentUser.role == 1 ? !['newsfeed', 'customers'].includes(opt.value) : true))}
                                onChange={(targetFeed) => this.setState({ targetFeed: targetFeed as ('userfeed' | 'newsfeed' | 'customers') })}
                            />
                            {/* {
                                this.props.ext &&
                                <Radio.Group
                                    className="newsfeed-input"
                                    onChange={(e) => this.setState({ destinator: e.target.value })}
                                    value={destinator}>
                                    <Radio value="S"><FormattedMessage defaultMessage={'For the staff'} /></Radio>
                                    <Can rule={Rules.Newsfeed.Filter}>
                                        <Radio value="C"><FormattedMessage defaultMessage={'For customers'} /></Radio>
                                        <Radio value="A"><FormattedMessage defaultMessage={'For all'} /></Radio>
                                    </Can>
                                </Radio.Group>
                            } */}
                            <InputField
                                className="newsfeed-input"
                                maxLength={300}
                                showCount
                                onChange={(e) => this.changeTitle(e, 0)}
                                value={title[0]}
                                placeholder={intl.formatMessage({ defaultMessage: 'Title {language}' }, { language: this.showTranslationContent() ? '(Fr)*' : '' })} />
                            {

                                // <ReactQuill theme="snow" value={content[0]} onChange={(e) => this.changeContent(e, 0)} />
                                <InputField
                                    className="newsfeed-input"
                                    style={{ marginTop: '10px' }}
                                    type="textarea"
                                    maxLength={2500}
                                    showCount
                                    autoSize={{ minRows: 3 }}
                                    onChange={(e) => this.changeContent(e, 0)}
                                    value={content[0]}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Content {language}' }, { language: this.showTranslationContent() ? '(Fr)*' : '' })} />
                            }
                            {
                                this.showTranslationContent() &&
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        onChange={(e) => this.changeTitle(e, 1)}
                                        value={title[1]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (En)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 1)}
                                        value={content[1]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (En)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        maxLength={100}
                                        showCount
                                        onChange={(e) => this.changeTitle(e, 2)}
                                        value={title[2]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (De)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 2)}
                                        value={content[2]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (De)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        maxLength={100}
                                        showCount
                                        onChange={(e) => this.changeTitle(e, 3)}
                                        value={title[3]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (It)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 3)}
                                        value={content[3]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (It)*' })} />
                                </div>
                            }
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between' }}>
                                <ImageUploaderMultiple
                                    deleteTextMaxWidth={250}
                                    //className="newsfeed-image-uploader"
                                    files={images}
                                    max={10}
                                    editText={intl.formatMessage({ defaultMessage: 'Add more images' })}
                                    addText={intl.formatMessage({ defaultMessage: 'Add one or more images' })}
                                    containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, alignItems: 'center' }}
                                    onUploaded={(images: File[] | undefined) => this.setState({ images })}
                                />
                                <Divider type={'vertical'} style={{ height: 'auto' }} />
                                <FileUploaderMultiple
                                    deleteTextMaxWidth={250}
                                    accept=".pdf"
                                    max={5}
                                    editText={intl.formatMessage({ defaultMessage: 'Add more PDF documents' })}
                                    addText={intl.formatMessage({ defaultMessage: 'Add one or more PDF documents' })}
                                    //className="newsfeed-image-uploader"
                                    containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, alignItems: 'center' }}
                                    files={files ? files : []}
                                    onUploaded={(files: File[] | undefined) => this.setState({ files })}
                                />

                            </div>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                        <Row gutter={[20, 20]}>
                            <Col xs={{ span: 24 }} >

                                <Card className="newsfeed-options" icon={<FAIcon prefix='fad' name='gear' />} title={<FormattedMessage defaultMessage={'Settings'} />}>
                                    <Collapse accordion={false} defaultActiveKey={['newsfeed-collapse-settings-target', `newsfeed-collapse-settings-notifications`, 'newsfeed-collapse-settings-post-options']} key={'newsfeed-collapse-settings'}>
                                        <Collapse.Panel header={<FormattedMessage defaultMessage={'Target'} />} key={`newsfeed-collapse-settings-target`}>
                                            <Segmented
                                                block
                                                size={isSmartphone ? 'middle' : 'large'}
                                                style={{ width: "100%", fontSize: isSmartphone ? '12px' : '14px', marginBottom: 15 }}
                                                key={'timeclock-show-options'}
                                                value={groupsIds === undefined ? 'company' : 'groups'}
                                                options={[
                                                    {
                                                        value: 'company',
                                                        label: intl.formatMessage({ defaultMessage: 'For everyone' })
                                                    },
                                                    {
                                                        value: 'groups',
                                                        label: intl.formatMessage({ defaultMessage: 'For specified groups' })
                                                    },
                                                ].filter(opt => groups === undefined || targetFeed === 'customers' ? opt.value !== 'groups' : true)}
                                                onChange={(mode) => this.onChangeRefType(mode as any)}
                                            />
                                            {groupsIds !== undefined && groups !== undefined ?

                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    placeholder={<FormattedMessage defaultMessage={'Select groups'} />}
                                                    style={{ width: '100%' }}
                                                    //className='__select-auto-height'
                                                    className="newsfeed-input"
                                                    value={groupsIds}
                                                    onChange={(groupsIds) => this.setState({ groupsIds })}
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    showArrow
                                                >
                                                    {
                                                        groups.map((g) => {
                                                            const label = g.name;
                                                            return <Select.Option value={g.id} key={"groups-" + g.id} label={label}>{label}</Select.Option>;
                                                        })
                                                    }
                                                </Select>

                                                : null
                                            }
                                        </Collapse.Panel>
                                        <Collapse.Panel header={<FormattedMessage defaultMessage={'Notifications'} />} key={`newsfeed-collapse-settings-notifications`} extra={[null, null, <FAIcon prefix='fad' name='rotate-right' key='notification-reset-choices' style={!pushNotification && !emailNotification ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); pushNotification || emailNotification && this.setState({ emailNotification: false, pushNotification: false }); }} title={intl.formatMessage({ defaultMessage: 'Reset' })} />]}>
                                            <SwitchLabeled
                                                checked={pushNotification}
                                                onChange={pushNotification => this.setState({ pushNotification })}
                                                label={intl.formatMessage({ defaultMessage: 'Push notification' })}
                                            />
                                            <SwitchLabeled
                                                checked={emailNotification}
                                                onChange={emailNotification => this.setState({ emailNotification })}
                                                label={intl.formatMessage({ defaultMessage: 'Email' })}
                                            />
                                        </Collapse.Panel>
                                        <Collapse.Panel header={<FormattedMessage defaultMessage={'Deferred send'} />} key={`newsfeed-collapse-settings-deferred`} extra={[null, null, <FAIcon prefix='fad' name='rotate-right' key='notification-reset-deferred-choices' style={startDate === undefined ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); startDate && this.setState({ startDate: undefined }); }} title={intl.formatMessage({ defaultMessage: 'Reset' })} />]}>
                                            <DatePicker
                                                className="newsfeed-input"
                                                style={{ width: '100%' }}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Date news sent' })}
                                                showTime={{ format: MOMENT_SHORT_TIME_FORMAT }}
                                                disabledDate={this.disabledDate}
                                                format={getFormat('DATE_AND_TIME_SHORT')}
                                                value={startDate ? moment.utc(startDate) : undefined}
                                                onOk={(d) => this.setState({ startDate: d?.format("YYYY-MM-DDTHH:mm") })}
                                            />
                                        </Collapse.Panel>
                                        <Collapse.Panel header={<FormattedMessage defaultMessage={'Post options'} />} key={`newsfeed-collapse-settings-post-options`} extra={[null, null, <FAIcon prefix='fad' name='rotate-right' key='notification-reset-posts-options-choices' style={withComment && !isNewsflash && withLike && likeName === undefined ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); (!withComment || isNewsflash || !withLike || likeName !== undefined) && this.setState({ withComment: true, isNewsflash: false, withLike: true, likeName: undefined }); }} title={intl.formatMessage({ defaultMessage: 'Reset' })} />]}>
                                            <SwitchLabeled
                                                checked={withComment}
                                                onChange={(withComment) => this.setState({ withComment })}
                                                label={intl.formatMessage({ defaultMessage: 'Allow comments' })}
                                            />
                                            {targetFeed === "customers" &&
                                                <SwitchLabeled
                                                    checked={isNewsflash}
                                                    onChange={(isNewsflash) => this.setState({ isNewsflash })}
                                                    label={intl.formatMessage({ defaultMessage: 'Newsflash' })}
                                                />
                                            }
                                            <SwitchLabeled
                                                checked={withLike}
                                                onChange={(withLike) => this.setState({ withLike })}
                                                label={intl.formatMessage({ defaultMessage: 'Allow likes' })}
                                            />
                                            {
                                                withLike ?
                                                    <InputField
                                                        showCount
                                                        maxLength={20}
                                                        className="newsfeed-input"
                                                        placeholder={intl.formatMessage({ defaultMessage: 'Change "Like" text' })}
                                                        value={likeName}
                                                        onChange={(e) => this.setState({ likeName: e.target.value })} />
                                                    : null
                                            }
                                        </Collapse.Panel>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            disabled={!this.showButton()}
                            type="primary"
                            className="newsfeed-input newsfeed-submit"
                            onClick={this.handleOnSubmit}
                            loading={this.state.loading}
                        >
                            <FormattedMessage defaultMessage={'Send'} />
                        </Button>
                    </Col>
                </Row>
            </Container >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    ext: state.user.currentUser?.company_detail?.ext,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
    groups: state.teamManagement.groups
});

const connector = connect(mapStateToProps);
export default connector(injectIntl(CreateNewsfeed));