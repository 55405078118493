import { Button, Checkbox, Col, DatePicker, Empty, List, Row, Select, Tooltip, message } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeTypesOfDayOff } from '../../../store/actions/configurations';
import { changeSettings } from '../../../store/actions/planning';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Countries } from '../../../utils/types/generalTypes';
import { NetworkHolidays, NetworkVacations } from '../../../utils/types/networkTypes';
import { PlanningHolidays, PlanningSettings, PlanningVacations, TypeOfDay, TypeOfDayOff } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { convertNetworkHolidaysToPlanningHolidays, convertNetworkSettingsToPlanningSettings, convertNetworkVacationsToPlanningVacations, convertPlanningSettingsToNetworkSettings, convertPlanningVacationsToNetworkVacations, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Card from '../../common/general/card';
import CreateEditModal from './createEditModal';
import TypeOfDayOffItem from './typeOfDayOffItem';

enum DataType { Country, SubscribedOptionalHolidays }

interface IProps {
    settings: PlanningSettings | undefined;
    typesOfDayOff: TypeOfDay[];
}

type Props = IProps & PlanningDispatchProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    countries: Countries;
    holidays: PlanningHolidays[];
    vacations: PlanningVacations[];
    settings: PlanningSettings;
    editVacations: PlanningVacations | undefined;
    loading: boolean;
    createTypeOfDayOff: TypeOfDayOff | undefined;
    year: number;
}

/**
 * Component that represent the holiday tab in the configurations page
 */
class HolidaysTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            countries: {},
            holidays: [],
            vacations: [],
            settings: {},
            editVacations: undefined,
            loading: false,
            createTypeOfDayOff: undefined,
            year: moment().year(),
        };
    }

    componentDidMount() {
        // get countries
        Network.getRegions().then(
            response => this.setState({ countries: response }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the available cantons' }), "warning"),
        );

        // get holidays
        Network.getHolidays().then(
            (response: NetworkHolidays[]) => this.setState({ holidays: response.map(r => convertNetworkHolidaysToPlanningHolidays(r)) }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the holidays' }), "warning"),
        );

        // get vacations
        this.refreshVacations();

        // get settings
        this.refreshSettings();
    }

    /**
     * Refresh the settings
     * @param msg a message to display as a success message - optional
     */
    refreshSettings = (msg?: string): void => {
        Network.getSettings().then(
            response => {
                const settings = convertNetworkSettingsToPlanningSettings(response);
                this.props.changeSettings!(settings);
                this.setState({ settings });
                if (msg) message.success(msg);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "warning"),
        );
    };

    /**
     * Refresh the vacations
     * @param msg a message to display as a success message - optional
     */
    refreshVacations = (msg?: string): void => {
        this.setState({ loading: true });
        Network.getVacations().then(
            (response: NetworkVacations[]) => {
                this.setState({ vacations: response.map(v => convertNetworkVacationsToPlanningVacations(v)), editVacations: undefined, loading: false });
                if (msg) message.success(msg);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations' }), "warning"),
        );
    };

    /* Holidays */

    /**
     * Check if the save button must be visible
     * @returns true if the button must be visible, false otherwise
     */
    showSaveButton = (): boolean => Object.values(this.state.settings).length > 0;

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any): void => {
        const { settings } = this.state;
        switch (type) {
            case DataType.Country:
                settings.holidaysCountry = value;
                settings.subscribedOptionalHolidays = [];
                break;
            case DataType.SubscribedOptionalHolidays:
                settings.subscribedOptionalHolidays = value;
                break;
        }

        this.setState({ settings });
    };

    onSaveHolidays = (): void => {
        Network.updateSettings(convertPlanningSettingsToNetworkSettings(this.state.settings)).then(
            () => this.refreshSettings(this.props.intl.formatMessage({ defaultMessage: 'The holidays has been successfully updated' })),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the holidays' }), "warning"),
        );
    };

    /* Vacations */

    /**
     * Start adding a vacations
     */
    addVacations = (): void => {
        const editVacations: PlanningVacations = {
            title: "",
            startDate: moment(),
            endDate: moment(),
        };
        this.setState({ editVacations });
    };

    /**
     * Start editing a vacations
     * @param vacations the vacations to edit
     */
    editVacations = (vacations: PlanningVacations): void => {
        this.setState({ editVacations: vacations });
    };

    /**
     * Update the vacations
     * @param vacations the vacations to update
     */
    onUpdateVacations = (vacations: PlanningVacations) => {
        Network.updateVacations(convertPlanningVacationsToNetworkVacations(vacations)).then(
            () => this.refreshVacations(this.props.intl.formatMessage({ defaultMessage: 'The vacations have been successfully updated' })),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the vacations' }), "warning"),
        );
    };

    /**
     * Delete a vacations
     * @param vacations the vacations to delete
     */
    onDeleteVacations = (vacations: PlanningVacations) => {
        Network.deleteVacations(vacations.id!).then(
            () => this.refreshVacations(this.props.intl.formatMessage({ defaultMessage: 'The vacation have been successfully deleted' })),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the vacations' }), "warning"),
        );
    };

    /**
     * Render an vacations (a list item)
     * @param vacations the item to render
     * @returns the component to render
     */
    renderVacations = (vacations: PlanningVacations): React.ReactElement => {
        return (
            <List.Item style={{ paddingLeft: '20px' }} actions={[
                <CircleButton
                    key={`holidaysTab-list-action-${vacations.id}-modify`}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                    icon={<FAIcon prefix='fad' name='pencil' />}
                    small
                    onClick={e => {
                        e.stopPropagation();
                        this.editVacations(vacations);
                    }} />,
                <DeleteButton
                    key={`holidaysTab-list-action-${vacations.id}-delete`}
                    text={<FormattedMessage defaultMessage={'Do you want to delete these vacations?'} />}
                    small
                    onConfirm={e => {
                        e?.stopPropagation();
                        this.onDeleteVacations(vacations);
                    }}
                    placement="left" />
            ]}>
                <p>{vacations.title} {vacations.startDate.format(getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR'))} {vacations.startDate.diff(vacations.endDate, 'days') !== 0 ? ` -> ${vacations.endDate.format(getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR'))}` : undefined}</p>
            </List.Item>
        );
    };

    /**
     * Check if the add type of day button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddTypeOfDay = (): boolean => this.state.createTypeOfDayOff === undefined;

    /**
     * Add a type of day
     */
    addTypeOfDay = () => {
        const createTypeOfDayOff = {
            title: "",
            inReports: true,
            countAsWorktime: true,
            canBeBadged: false,
            daysPerYear: 0,
        };
        this.setState({ createTypeOfDayOff });
    };

    /**
     * Refresh the types of day
     * @param msg a message to display as a success message - optional
     */
    refresh = (msg?: string) => {
        // get all types of day
        Network.getTypeOfDayOff().then(
            response => {
                this.props.changeTypesOfDayOff!(response);
                this.setState({ createTypeOfDayOff: undefined });
                if (msg) message.success(msg);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning"),
        );
    };

    /**
     * Render a type of day (a list item)
     * @param typeOfDay the item to render
     * @returns the component to render
     */
    renderTypeOfDay = (typeOfDay: TypeOfDayOff): React.ReactNode => {
        return <TypeOfDayOffItem typeOfDayOff={typeOfDay} refresh={this.refresh} cancelNew={() => this.setState({ createTypeOfDayOff: undefined })} />;
    };

    /**
     * Change the current year
     * @param date the moment date 
     */
    changeYear = (date: Moment | null | undefined) => {
        if (!date) return;
        const year = date.year();
        this.setState({ year });
    };

    render() {
        const { countries, holidays, vacations, settings, year, createTypeOfDayOff } = this.state;
        const { intl } = this.props;
        const country = settings.holidaysCountry ? settings.holidaysCountry : this.props.settings?.holidaysCountry;
        const mandatoryCountries = holidays.filter(h => h.mandatoryCountries.find(c => c === country));
        const optionalCountries = holidays.filter(h => h.optionalCountries.find(c => c === country));
        const heightOfLine = 67;
        const nbTypeOfDay = createTypeOfDayOff ? this.props.typesOfDayOff.concat([createTypeOfDayOff]).length : this.props.typesOfDayOff.length;
        let minHeight = nbTypeOfDay > 0 ? nbTypeOfDay * heightOfLine : 100;
        if (minHeight > 350) minHeight = 350;

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }}>
                    <Card icon={<FAIcon prefix='fad' name='calendar-xmark' />} title={<FormattedMessage defaultMessage={'Types of day off'} />} headerElements={[
                        <CircleButton
                            disabled={!this.showAddTypeOfDay()}
                            key="type_of_day_add_button"
                            style={{ marginRight: '8px' }}
                            title={intl.formatMessage({ defaultMessage: 'Add a type of day' })}
                            onClick={this.addTypeOfDay}
                            icon={<FAIcon prefix='far' name='plus' />}
                            small
                            placement="right" />
                    ]}>
                        <List
                            style={{ minHeight: `${minHeight}px` }}
                            className="holidays-list"
                            dataSource={createTypeOfDayOff ? this.props.typesOfDayOff.concat([createTypeOfDayOff]) : this.props.typesOfDayOff}
                            renderItem={this.renderTypeOfDay}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No vacation'} />} /> }}
                        />
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Absence days'} />} icon={<FAIcon prefix='fad' name='island-tropical' />}
                        headerElements={[
                            <DatePicker
                                key="holidays-tab-datepicker"
                                // disabled={newUserYearlyParams !== undefined}
                                picker="year"
                                placeholder={intl.formatMessage({ defaultMessage: 'Year' })}
                                value={moment().year(this.state.year)}
                                onChange={this.changeYear}
                                allowClear={false} />
                        ]}>
                        {/* headerElements={[
                        <DatePicker
                            style={{ marginRight: '10px' }}
                            // disabled={newUserYearlyParams !== undefined}
                            picker="year"
                            placeholder={"Année"}
                            // value={moment().year(this.state.year)}
                            // onChange={this.changeYear}
                            allowClear={false} />
                    ]}> */}
                        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                            <div className="holidays-section">
                                <p><FormattedMessage defaultMessage={'Canton'} /></p>
                                <Select
                                    className="configurations-section-field"
                                    value={country}
                                    onChange={c => this.dataChanged(DataType.Country, c)}
                                    allowClear
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label" >
                                    {Object.keys(countries).sort().map(key => <Select.Option label={countries[key]} value={key} key={`holidays-select-${key}`}>{countries[key]}</Select.Option>)}
                                </Select>
                            </div>
                            {
                                country &&
                                <div>
                                    <div className="holidays-holidays">
                                        <span className="flex">
                                            <p><FormattedMessage defaultMessage={'List of public holidays for the canton of {canton}'} values={{ canton: countries[country] }} /></p>
                                            <Tooltip title={<FormattedMessage defaultMessage={'Holidays (mandatory)'} />}>
                                                <FAIcon prefix='fad' name='info' className='info-tooltip-icon' />
                                                {/* <InfoCircleOutlined style={{ marginLeft: '10px' }} /> */}
                                            </Tooltip>
                                        </span>
                                        {
                                            optionalCountries.filter(oc => oc.startDate.year() === year || oc.endDate.year() === year).length === 0 ?
                                                <Checkbox.Group
                                                    className="holidays-holidays-checkboxes"
                                                    options={[({ label: <FormattedMessage defaultMessage={'No data for {year}'} values={{ year }} />, value: -1 })]}
                                                    disabled={true}
                                                    value={[]} />
                                                :
                                                <Checkbox.Group
                                                    className="holidays-holidays-checkboxes-readonly"
                                                    options={mandatoryCountries.filter(oc => oc.startDate.year() === year || oc.endDate.year() === year).map(h => ({ label: `${h.title} (${h.startDate.format(getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR'))})`, value: h.id }))}
                                                    value={mandatoryCountries.map(h => h.id)} />
                                        }
                                    </div>
                                    {
                                        optionalCountries &&
                                        <div className="holidays-holidays">
                                            <span className="flex">
                                                <FormattedMessage defaultMessage={'List of optional public holidays for the canton of {canton}'} values={{ canton: countries[country] }} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'You can select the holidays you would like to use' })}>
                                                    <FAIcon prefix='fad' name='info' className='info-tooltip-icon' />
                                                    {/* <InfoCircleOutlined style={{ marginLeft: '10px' }} /> */}
                                                </Tooltip>
                                            </span>
                                            {
                                                optionalCountries.filter(oc => oc.startDate.year() === year || oc.endDate.year() === year).length === 0 ?
                                                    <Checkbox.Group
                                                        className="holidays-holidays-checkboxes"
                                                        options={[({ label: <FormattedMessage defaultMessage={'No data for {year}'} values={{ year }} />, value: -1 })]}
                                                        disabled={true}
                                                        value={[]} />
                                                    :
                                                    <Checkbox.Group
                                                        className="holidays-holidays-checkboxes"
                                                        onChange={c => this.dataChanged(DataType.SubscribedOptionalHolidays, c)}
                                                        options={optionalCountries.filter(oc => oc.startDate.year() === year || oc.endDate.year() === year).map(h => ({ label: `${h.title} (${h.startDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR'))})`, value: h.id }))}
                                                        value={settings.subscribedOptionalHolidays ? settings.subscribedOptionalHolidays : this.props.settings?.subscribedOptionalHolidays} />

                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {
                                this.showSaveButton() &&
                                <div className="flex-column-center" style={{ marginTop: '20px' }}>
                                    <Button
                                        onClick={this.onSaveHolidays}
                                        type="primary"
                                        className="holidays-save-button">
                                        <FormattedMessage defaultMessage={'Save'} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Public holidays'} />} icon={<FAIcon prefix='fad' name='island-tropical' />} headerElements={[
                        <DatePicker
                            style={{ marginRight: '8px' }}
                            key="holidays-tab-datepicker"
                            // disabled={newUserYearlyParams !== undefined}
                            picker="year"
                            placeholder={intl.formatMessage({ defaultMessage: 'Year' })}
                            value={moment().year(this.state.year)}
                            onChange={this.changeYear}
                            allowClear={false} />,
                        <CircleButton
                            small
                            key="occupancy-rate-add-occupancy"
                            icon={<FAIcon prefix='far' name='plus' />}
                            title={intl.formatMessage({ defaultMessage: 'Add public holidays' })}
                            placement="topLeft"
                            onClick={this.addVacations} />
                    ]}>
                        <List
                            className='planning-settings-templates-list'
                            dataSource={vacations.filter(oc => oc.startDate.year() === year || oc.endDate.year() === year)}
                            renderItem={this.renderVacations}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No public holiday'} />} /> }}
                        />
                    </Card>
                </Col>
                {this.state.editVacations !== undefined &&
                    <CreateEditModal
                        vacations={this.state.editVacations}
                        onCancel={() => this.setState({ editVacations: undefined })}
                        onDone={this.onUpdateVacations}
                        loading={this.state.loading} />
                }
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    settings: state.planning.settings,
    typesOfDayOff: state.configurations.typesOfDayOff,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HolidaysTab));