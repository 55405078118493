import { Checkbox, Col, Collapse, Popconfirm, Row, Space, Tag } from 'antd';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { OccupancyRateFrequency } from '../../../utils/enumerations';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { POI } from '../../../utils/types/generalTypes';
import { OccupancyRateBodyRequest } from '../../../utils/types/networkTypes';
import { PlanningOccupancyRate } from '../../../utils/types/planningTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, convertNetworkOccupancyRatesToPlanningOccupancyRates, getOccupancyRateFrequencyText, getOccupancyRateSummaryText, getWeekdayText } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CreateEditModal from '../../configurations/occupancyRate/createEditModal';

interface IProps {
    isSmartphone: boolean;
    poiShow: POI;
}

type Props = IProps & IntlProps;

interface State {
    openImage: boolean;
    imageToOpen: string;
    missions?: PlanningOccupancyRate[];
    editMission?: PlanningOccupancyRate;
    loadingSaveMission: boolean;
}


class PoiShowForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            openImage: false,
            imageToOpen: '',
            loadingSaveMission: false
        };
    }

    componentDidMount() {
        this.loadMissions();
    }

    loadMissions = () => {
        Network.getOccupancyRates(undefined, this.props.poiShow.id).then(
            response => {
                this.setState({ missions: convertNetworkOccupancyRatesToPlanningOccupancyRates(response) });
                // this.props.changeOccupancyRates!(occupancyRates);
                // if (message) alert(message, "success");
                // this.setState({ loading: false, editMission: undefined, currentOccupancyRate: this.state.currentOccupancyRate ? occupancyRates.find((o: PlanningOccupancyRate) => o.id === this.state.currentOccupancyRate!.id) : occupancyRates[0] });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the missions' }), "warning");
                // this.setState({ loading: false });
            },
        );
    };

    /**
     * Delete an occupancy rate
     * @param occupancyRate the occupancy rate to delete
     */
    onDeleteMission = (mission: PlanningOccupancyRate): void => {
        Network.deleteOccupancyRate(mission.id!).then(
            () => this.loadMissions(),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the mission' }), "warning"),
        );
    };

    /**
     * Update an occupancy rate
     * @param occupancyRate the occupancy rate body request
     */
    onUpdateMission = (mission: OccupancyRateBodyRequest) => {
        Network.updateOccupancyRate(mission).then(
            () => {
                this.setState({ editMission: undefined });
                this.loadMissions();
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the mission' }), "warning"),
        );
    };

    /**
     * Start adding an occupancy rate
     */
    addMission = (): void => {
        const editMission: PlanningOccupancyRate = {
            title: "",
            typeOfDay: undefined,
            quantity: 1,
            frequency: OccupancyRateFrequency.Daily,
            rrule: {},
            poi: this.props.poiShow
        };
        this.setState({ editMission });
    };

    /**
     * Start editing an occupancy rate
     * @param occupancyRate the occupancy rate to edit
     */
    editMission = (mission: PlanningOccupancyRate): void => {
        this.setState({ editMission: mission });
    };


    genExtra = (mission?: PlanningOccupancyRate) => {
        return <Space direction="horizontal">
            <FAIcon prefix='fad' name='pencil' onClick={(e) => {
                e.stopPropagation();
                mission && this.editMission(mission);
            }} />
            <Popconfirm
                title={<FormattedMessage defaultMessage={'Do you want to delete this mission?'} />}
                okText={<FormattedMessage defaultMessage={'Delete'} />}
                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                okButtonProps={{ type: 'link', danger: true }}
                cancelButtonProps={{ type: 'link' }}
                icon={<FAIcon prefix='fad' name='circle-question' color='#ff2626' />}
                placement={"topRight"}
                onCancel={e => e?.stopPropagation()}
                onConfirm={e => {
                    e?.stopPropagation();
                    mission && this.onDeleteMission(mission);
                }}>
                <FAIcon prefix='fad' name='trash-can' onClick={e => e.stopPropagation()} title={this.props.intl.formatMessage({ defaultMessage: 'Delete' })} />
            </Popconfirm>
        </Space>;
    };

    render() {
        const { missions } = this.state;
        const { poiShow, intl } = this.props;
        return (
            <>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Address'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'right' }}>{poiShow.address}</p>
                    </Col>
                    {
                        poiShow.isBasePoi &&
                        <>
                            <Col xs={{ span: 12 }}>
                                <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Primary location'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <Checkbox
                                    disabled={true}
                                    checked={poiShow.isPrimary} />
                            </Col>
                        </>
                    }
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Longitude'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'right' }}>{poiShow.longitude}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Latitude'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'right' }}>{poiShow.latitude}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Sectors'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        <Space direction="vertical">
                            {
                                poiShow.sectors && poiShow.sectors.length > 0 ?
                                    poiShow.sectors?.map(s => (
                                        <Tag className="__tags-sunkhronos __tags-limit-size" style={s.id ? {} : {}} color="#f5f5f5" key={s.id} >
                                            {s.title}
                                        </Tag>
                                    ))
                                    : <p>-</p>
                            }
                        </Space>
                    </Col>

                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Groups'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        <Space direction="vertical">
                            {
                                poiShow.groups && poiShow.groups.length > 0 ?
                                    poiShow.groups?.map(g => (
                                        <Tag className="__tags-sunkhronos __tags-limit-size" style={g.id ? {} : {}} color="#f5f5f5" key={g.id} >
                                            {g.name}
                                        </Tag>
                                    ))
                                    : <p>-</p>
                            }
                        </Space>
                    </Col>

                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Missions'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'right' }}>{missions ? missions.length : '0'} <FAIcon prefix='far' name='circle-plus' onClick={this.addMission} /></p>
                    </Col>
                    {
                        missions && missions.length > 0 &&
                        <Col xs={{ span: 24 }}>
                            <Collapse
                                className="__collapse-background-transparent"
                                accordion={true}
                                style={{ textAlign: 'initial' }}
                            >
                                {
                                    missions.map(m => {
                                        return (
                                            <Collapse.Panel
                                                header={m.title}
                                                key={`missions-${m.id}`}
                                                extra={this.genExtra(m)}
                                            >
                                                <div style={{ padding: '10px 0px 10px 0px' }}>
                                                    <div className="occupancy-rate-section">
                                                        <p style={{ textAlign: 'justify' }}>
                                                            {getOccupancyRateSummaryText(intl, m)}
                                                        </p>
                                                    </div>
                                                    {
                                                        m.department ?
                                                            <div className="occupancy-rate-section">
                                                                <p><FormattedMessage defaultMessage={'Department'} /></p>
                                                                <p>{m.department ? m.department.name : "-"}</p>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className="occupancy-rate-section">
                                                        <p><FormattedMessage defaultMessage={'Relevant skill'} /></p>
                                                        <p>{m.staffType ? m.staffType.name : "-"}</p>
                                                    </div>
                                                    <div className="occupancy-rate-section">
                                                        <p><FormattedMessage defaultMessage={'Quantity'} /></p>
                                                        <p><FormattedMessage defaultMessage={'{count, plural, one {{count} event / {text}} other {{count} events / {text}}}'} values={{ count: m.quantity, text: this.props.intl.formatMessage(getOccupancyRateFrequencyText(m.frequency)).toLowerCase() }} /></p>
                                                    </div>
                                                    {
                                                        /* 
                                                        <div className="occupancy-rate-section">
                                                            <p>{"Fréquence"}</p>
                                                            <p>{getOccupancyRateFrequencyText(currentOccupancyRate.frequency)}</p>
                                                        </div> 
                                                        */
                                                    }
                                                    {
                                                        m.startDate && m.endDate &&
                                                        <div className="occupancy-rate-section">
                                                            <p><FormattedMessage defaultMessage={'Dates'} /></p>
                                                            <p><FormattedMessage defaultMessage={'From {start} to {end}'} values={{ start: m.startDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')), end: m.endDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')) }} /></p>
                                                        </div>
                                                    }
                                                    {
                                                        m.rrule && m.rrule.byweekday && m.rrule.byweekday.length > 0 &&
                                                        <div className="occupancy-rate-section">
                                                            <p><FormattedMessage defaultMessage={'Days of week'} /></p>
                                                            <p><FormattedMessage defaultMessage={'Each {rule}'} values={{ rule: m.rrule.byweekday.slice(1).reduce((acc, val) => acc + ", " + getWeekdayText(val)?.toLowerCase(), getWeekdayText(m.rrule.byweekday[0])?.toLowerCase()) }} /></p>
                                                        </div>
                                                    }
                                                    {
                                                        m.duringHolidays &&
                                                        <p className="occupancy-rate-section"><FormattedMessage defaultMessage={'Available on public holidays'} /></p>
                                                    }
                                                    {
                                                        m.duringVacations &&
                                                        <p className="occupancy-rate-section"><FormattedMessage defaultMessage={'Available on vacations and days off'} /></p>
                                                    }
                                                </div>
                                            </Collapse.Panel>
                                        );
                                    })
                                }
                            </Collapse>
                        </Col>
                    }
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Event'} />{':'}</p>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <p style={{ textAlign: 'right' }}>
                            {
                                poiShow.isBasePoi && poiShow.eventsDeparture ?
                                    <Space direction="vertical">
                                        {
                                            poiShow.eventsDeparture.map(m => {
                                                return (
                                                    <Tag className="__tags-sunkhronos __tags-limit-size" style={m.id ? {} : {}} color="#f5f5f5" key={m.id} >
                                                        {m.title}
                                                    </Tag>
                                                );
                                            })
                                        }
                                    </Space>

                                    :
                                    <Space direction="vertical">
                                        {
                                            poiShow.eventsWorkPlace && poiShow.eventsWorkPlace.map(m => {
                                                return (
                                                    <Tag className="__tags-sunkhronos __tags-limit-size" style={m.id ? {} : {}} color="#f5f5f5" key={m.id} >
                                                        {m.title}
                                                    </Tag>
                                                );
                                            })
                                        }
                                    </Space>
                            }
                        </p>
                    </Col>
                </Row >
                {this.state.editMission !== undefined &&
                    <CreateEditModal
                        occupancyRate={this.state.editMission}
                        onCancel={() => this.setState({ editMission: undefined })}
                        loading={this.state.loadingSaveMission}
                        onDone={this.onUpdateMission}
                    />
                }

                {
                    this.state.openImage && (
                        <Lightbox
                            mainSrc={this.state.imageToOpen}
                            onCloseRequest={() => this.setState({ openImage: false })}
                        />
                    )
                }
            </>

        );
    }
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps)(injectIntl(PoiShowForm));