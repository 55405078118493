import React, { CSSProperties } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../FAIcon';
import ConfirmButton from './confirmButton';

interface Props extends IntlProps {
    text: React.ReactNode;
    title?: string;
    okText?: string;
    cancelText?: string;
    placement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
    buttonPlacement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
    small?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
    withoutTooltip?: boolean;
    primary?: boolean;
}

interface State {

}

/**
 * Component that represent a delete circle button with pop confirm
 */
class DeleteButton extends React.Component<Props, State> {
    render() {
        return (
            <ConfirmButton
                text={this.props.text}
                okText={this.props.okText ?? <FormattedMessage defaultMessage={'Delete'} />}
                cancelText={this.props.cancelText ?? <FormattedMessage defaultMessage={'Cancel'} />}
                okButtonProps={{ type: 'link', danger: true }}
                cancelButtonProps={{ type: 'link' }}
                confirmIcon={<FAIcon prefix='fad' name='circle-question' color='#ff2626' />}
                placement={this.props.placement}
                onConfirm={this.props.onConfirm}
                onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                primary={this.props.primary ??  undefined}
                withoutTooltip={this.props.withoutTooltip}
                icon={<FAIcon prefix={"fad"} name='trash-can' />}
                title={this.props.title ?? this.props.intl.formatMessage({ defaultMessage: 'Delete' })}
                small={this.props.small}
                style={this.props.style}
                buttonPlacement={this.props.buttonPlacement}
                disabled={this.props.disabled}/>
        );
    }
}

export default injectIntl(DeleteButton);