import { Button, Col, Row, Select } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { usersColumns } from '../../../utils/constants';
import Network from '../../../utils/network';
import { RouterProps, User } from '../../../utils/types/generalTypes';
import { alert } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import Card from '../../common/general/card';
import Container from '../../common/navigations/container';

type Props = RouterProps & IntlProps;

interface State {
    name: string;
    selectedUsers: number[];
    users: User[];
    loading: boolean;
}

/**
 * Page to create a group
 */
class CreateGroup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            selectedUsers: [],
            users: [],
            loading: false,
        };
    }

    columns = usersColumns.filter(o => o.key === "first_name" || o.key === "last_name" || o.key === "image" || o.key === "role");

    componentDidMount() {
        this.setState({ loading: true });
        //get all users
        Network.getAllUsers().then(
            response => this.setState({ users: response, loading: false }),
            () => this.setState({ users: [], loading: false })
        );
    }

    /**
     * Called when a users is (un)checked
     * @param keys the new keys of checked users
     */
    onChangeUsers = (keys: React.Key[]) => {
        this.setState({ selectedUsers: keys as number[] });
    };

    /**
     * Decide wether or not to show the create button
     */
    showButton = () => this.state.name.length > 0;

    /**
     * Handle creation of group
     */
    handleSubmit = () => {
        this.setState({ loading: true });
        Network.createGroup(this.state.name, this.state.selectedUsers).then(
            response => {
                this.setState({ loading: false });
                this.props.history.push({ pathname: `/${this.props.match.params.lang}/team-management/group-details/informations?id=${response.id}`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The group has been successfully created' }) } });
            },
            error => {
                this.setState({ loading: false });
                if (error.message === "A group with the same name already exists") alert(this.props.intl.formatMessage({ defaultMessage: 'A group with the same name already exist' }), "error");
                else alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the group' }), "warning");
            }
        );
    };

    changeUsers = (values: number[]) => {
        this.setState({ selectedUsers: values });
    };

    render() {
        const { name, selectedUsers, users } = this.state;
        const { intl } = this.props;
        const { Option } = Select;

        return (
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Team management' }), link: "/team-management/list" }, { title: intl.formatMessage({ defaultMessage: 'Create a group' }), link: "/users/create-group" }]}>
                <div className="team-create-group-parent">
                    <Card className="team-create-group-table-card" title={<FormattedMessage defaultMessage={'Informations'} />} icon={<FAIcon prefix='fad' name='id-card' />}>
                        <Row gutter={[10, 20]}>

                            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <InputField
                                    onChange={(e: InputFieldOnChangeEvent) => this.setState({ name: e.target.value })}
                                    value={name}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Group name' })} />
                            </Col>
                        </Row>
                        <Row gutter={[10, 20]} style={{ marginTop: '20px' }}>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <Select
                                    dropdownClassName="__edit-group-select"
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    style={{ width: '100%' }}
                                    listHeight={450}
                                    placeholder="Utilisateurs"
                                    onChange={this.changeUsers}
                                    value={selectedUsers}
                                    filterOption={true}
                                    optionFilterProp="label"
                                    showArrow
                                >
                                    {
                                        users.sort((a, b) => a.last_name.localeCompare(b.last_name)).map((o) => {
                                            return <Option label={`${o.last_name} ${o.first_name}`} value={o.id} key={"users" + o.id}>{`${o.last_name} ${o.first_name}`}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[10, 20]} style={{ marginTop: '20px' }}>
                            <Col xs={{ span: 24 }}>
                                <Button
                                    disabled={!this.showButton()}
                                    type="primary"
                                    style={{ width: '250px' }}
                                    loading={this.state.loading}
                                    onClick={this.handleSubmit}>
                                    {"Créer le groupe"}
                                </Button>
                            </Col>

                        </Row>
                    </Card>
                    {/* <Card className="team-create-group-table-card" title="Utilisateurs" icon={<TeamOutlined />}>
                        <InputField
                            onChange={this.handleSearch}
                            style={{ marginBottom: '10px', width: '300px' }}
                            type="search" />
                        <Table
                            rowSelection={{ type: 'checkbox', onChange: this.onChangeUsers, selectedRowKeys: selectedUsers }}
                            dataSource={searchedUsers ? searchedUsers : users}
                            rowKey={(u: any) => u.id}
                            columns={this.columns}
                            pagination={false}
                            loading={this.state.loading} />
                    </Card> */}
                </div>
            </Container>
        );
    }
}

export default injectIntl(CreateGroup);