import { Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { SunkhronosNewsTypes } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import { SunkhronosNewsTag } from "../../../utils/types/networkTypes";

interface Props {
    title: string;
    date: string;
    content: string;
    type: SunkhronosNewsTypes;
    tags?: SunkhronosNewsTag[];
    image?: string;
}

const NewsTypeMessages = defineMessages<SunkhronosNewsTypes>({
    [SunkhronosNewsTypes.INFORMATION]: { defaultMessage: 'Information' },
    [SunkhronosNewsTypes.NEW]: { defaultMessage: 'New' },
    [SunkhronosNewsTypes.FEATURE]: { defaultMessage: 'Feature' },
    [SunkhronosNewsTypes.FIX]: { defaultMessage: 'Fix' },
    [SunkhronosNewsTypes.MAINTENANCE]: { defaultMessage: 'Maintenance' },
});

const NewsCard = (props: Props) => {

    const intl = useIntl();
    // const [isExpanded, setIsExpanded] = useState(false);
    // const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [showMoreTitleVisible, setShowMoreTitleVisible] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const titleRef = useRef<HTMLDivElement | null>(null);

    const newsTypeTag = useMemo(() => {
        let color = '';
        switch (props.type) {
            case SunkhronosNewsTypes.INFORMATION:
                color = 'blue';
                break;
            case SunkhronosNewsTypes.NEW:
                color = 'gold';
                break;
            case SunkhronosNewsTypes.FEATURE:
                color = 'magenta';
                break;
            case SunkhronosNewsTypes.FIX:
                color = 'cyan';
                break;
            case SunkhronosNewsTypes.MAINTENANCE:
                color = 'red';
                break;
            default:
                color = 'purple';
        }

        return (
            <Tag color={color}>
                {intl.formatMessage(NewsTypeMessages[props.type])}
            </Tag>
        );
    }, [intl, props.type]);

    useEffect(() => {
        const titleElement = titleRef.current;
        if (titleElement !== null) {
            // Calculate the number of lines based on height and line-height
            const lineHeight = parseFloat(getComputedStyle(titleElement).lineHeight);
            const maxHeight = lineHeight * 1; // Height for 4 lines

            if (titleElement.scrollHeight > maxHeight) {
                setShowMoreTitleVisible(true);
            }
        }
    }, []);


    return (
        <div className='sunkhronos-news-card'>
            <div className='sunkhronos-news-header'>
                <Tooltip trigger={showMoreTitleVisible ? ['hover'] : ['']} title={props.title}>
                    <div className="sunkhronos-news-title" ref={titleRef}>
                        {props.title}
                    </div>
                </Tooltip>
                <div className='sunkhronos-news-tag'>
                    {newsTypeTag}
                </div>
            </div>
            {/* <div className='sunkhronos-news-image-container'>
                <Image src={props.image} className='sunkhronos-news-image' />
            </div> */}
            <div className='sunkhronos-news-date'>
                {moment(props.date).format(getFormat('DATE'))}
            </div>
            <div className='sunkhronos-news-body'>
                <div className={`sunkhronos-news-content`} dangerouslySetInnerHTML={{ __html: props.content }} ref={contentRef} />
                {/* <div className='sunkrhonos-news-readmore'>
                    {
                        isButtonVisible &&
                        <Button type='link' size='small' onClick={() => setIsExpanded(!isExpanded)}>
                            {
                                isExpanded ?
                                    <FormattedMessage defaultMessage={'Read less'} />
                                    :
                                    <FormattedMessage defaultMessage={'Read more'} />
                            }
                        </Button>
                    }
                </div> */}
            </div>
            <div className='sunkhronos-news-footer'>
                {
                    props.tags && props.tags.map(tag => (
                        <Tag key={`tag-${tag.id}`} color={tag.color} style={{ fontSize: '0.7rem' }}>
                            {tag.title}
                        </Tag>
                    ))
                }
            </div>
        </div>
    );
};

export default NewsCard;