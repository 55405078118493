import { Avatar, Calendar, Card, Col, Popover, Row, Space, Spin, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import moment, { Moment } from 'moment';
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { enabledIntegrationsFetched } from '../../../store/features/integrationsSlice';
import Network from '../../../utils/network';
import { IIntegrationsType, IIntegrationUsers, IUserDetail } from '../../../utils/types/generalTypes';
import { NetworkResponse } from '../../../utils/types/networkTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import ConfirmButton from '../../common/fields/confirmButton';
import VirtualTable from '../../common/general/virtualTable';
import { tableColumnTextFilterConfig } from '../../courseManagement/tableSearch';
import CalendarCell from '../utils/calendarCell';
import WeekCalendar from '../utils/weekCalendar';
import { UsersManagementRef } from './export';
import ExportGlobalOfficeResult from './globaloffice/exportGlobalOfficeResult';
import ExportHotelaResult from './hotela/exportHotelaResult';

interface Props {
    selectedIntegration: IIntegrationsType;
    selectedDate: Moment;
    setSelectedDate: (e: Moment) => void;
    selectedEndDate: Moment;
    setSelectedEndDate: (e: Moment) => void;
    onNext: () => void;
    onPrevious: () => void;
    loading: boolean;
    setLoading: (e: boolean) => void;
    display: 'weekly' | 'monthly';

}

interface IUser {
    id: number;
    image: ReactNode;
    firstName: string;
    lastName: string;
    hasEventLocked: boolean;
    hasDayLocked: boolean;
    isReadyToExport: boolean;
    hasAlreadyBeenExported: boolean;
}



const UsersManagement = (props: Props, ref: React.ForwardedRef<UsersManagementRef>) => {
    const { display, selectedDate, loading, setLoading, selectedEndDate, selectedIntegration } = props;
    const [userLoading, setUserLoading] = useState<boolean>(false);

    const [users, setUsers] = useState<IUser[]>([]);
    const [userDetail, setUserDetail] = useState<IUserDetail|undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
    const [allUsers, setAllUsers] = useState<boolean>(false);

    const dispatch = useDispatch();
    const intl = useIntl();

    const height = useSelector((state: ApplicationState) => state.window.height);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refreshIntegration();
        }
    }));

    const renderUserState = useCallback((record: IUser) => {
        return (
            <div>
                {
                    <Popover
                        title={<FormattedMessage defaultMessage={"Lock status"} />}
                        placement='right'
                        content={
                            <ul>
                                {
                                    !record.hasDayLocked && !record.hasEventLocked && !record.hasAlreadyBeenExported ?
                                        <li><FAIcon name='lock-open' prefix='fad' color={'var(--icon-green)'} /> <FormattedMessage defaultMessage={'User is not locked'} /></li>
                                        :
                                        record.hasAlreadyBeenExported ?
                                            <li><FAIcon name='export' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'Data already exported'} /></li>
                                            :
                                            <>
                                                {
                                                    record.hasDayLocked &&
                                                    <li><FAIcon name='cells-row-lock' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'Has day locked'} /></li>
                                                }
                                                {
                                                    record.hasEventLocked &&
                                                    <li><FAIcon name='cells-lock' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'Has events locked'} /></li>
                                                }

                                            </>
                                }

                            </ul>
                        }
                    >
                        { record.hasAlreadyBeenExported ?
                            <FAIcon name='circle-xmark' prefix='fad' color='var(--icon-red)' />
                            :
                            record.hasDayLocked || record.hasEventLocked ?
                                <FAIcon name='circle-exclamation' prefix='fad' color='var(--icon-red)' />

                                :
                                <FAIcon name='circle-check' prefix='fad' color='var(--icon-green)' />
                            
                        }
                    </Popover>
                }
            </div>
        );
    }, []);


    const columns = useMemo((): ColumnProps<IUser>[] => [
        {
            key: 'state',
            title: <FormattedMessage defaultMessage={'Status'} />,
            dataIndex: 'state',
            className: '__width_80 ',
            align: 'center',
            filters: [
                {
                    text: intl.formatMessage({ defaultMessage: 'Is not locked' }),
                    value: 'notLocked'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Has already been exported' }),
                    value: 'hasAlreadyBeenExported'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Has day locked' }),
                    value: 'hasDayLocked'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Has events locked' }),
                    value: 'hasEventsLocked'
                },
            ],
            onFilter: (value, record) => {
                if (value === 'notLocked')
                    if (!record.hasAlreadyBeenExported && !record.hasDayLocked && !record.hasEventLocked) return true;
                if (value === 'hasAlreadyBeenExported')
                    if (record.hasAlreadyBeenExported) return true;
                if (value === 'hasDayLocked')
                    if (record.hasDayLocked ) return true;
                if (value === 'hasEventLocked')
                    if (record.hasEventLocked) return true;
                return false;
            },
            render: (_, record) => renderUserState(record),
        },
        {
            key: 'image',
            title: <FormattedMessage defaultMessage={'Image'} />,
            dataIndex: 'image',
            className: '__width_80',
            align: 'center',
            render: (record) => <Avatar src={record} icon={<FAIcon name='user' prefix='fad' />} />
        },
        {
            key: 'firstName',
            className: '__min-width-200',
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'firstName',
            onFilter: (value, record) => {
                return record.firstName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IUser>(),
            sorter: (a, b) => a.firstName.toLocaleLowerCase().localeCompare(b.firstName.toLocaleLowerCase()),
        },
        {
            key: 'lastName',
            className: '__min-width-200',
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'lastName',
            onFilter: (value, record) => {
                return record.lastName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IUser>(),
            sorter: (a, b) => a.lastName.toLocaleLowerCase().localeCompare(b.lastName.toLocaleLowerCase()),
        }
    ], [intl, renderUserState]);



    const filterIntegratedUser = useCallback((users: IUser[]) => {
        return users.filter(u => (allUsers || (!allUsers && u.hasAlreadyBeenExported || u.hasDayLocked || u.hasEventLocked)));
    }, [allUsers]);




    const loadIntegrations = useCallback(async () => await dispatch(enabledIntegrationsFetched({})), [dispatch]);

    // Hotela integration 


    const refreshHotelaUsers = useCallback((force = false) => {
        setLoading(true);
        const year = parseInt(selectedDate.format('YYYY'));
        const month = parseInt(selectedDate.format('MM'));
        Network.getHotelaUsersLocked(year, month).then(
            (response: NetworkResponse<IUser[]>) => {
                if (response.error === false) {
                    setUsers(response.data);
                    if (force) {
                        loadIntegrations();
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "error");
            }
        );
    }, [intl, loadIntegrations, setLoading, selectedDate]);

    const loadHotelaUserDetail = useCallback((userId: number) => {
        const year = parseInt(selectedDate.format('YYYY'));
        const month = parseInt(selectedDate.format('MM'));

        Network.getHotelaUserLockDetail(year, month, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading user detail' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the user detail' }), "error");
            }
        );
    }, [intl, selectedDate, setUserLoading])

    
    const cancelHotelaMonthExportation = useCallback((userId: number) => {
        const year = parseInt(selectedDate.format('YYYY'));
        const month = parseInt(selectedDate.format('MM'));

        Network.deleteHotelaUserExport(year, month, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [selectedDate, intl])

    const unlockHotelaDay = useCallback((day: Moment, userId: number) => {
        const year = parseInt(day.format('YYYY'));
        const month = parseInt(day.format('MM'));
        const d = parseInt(day.format('DD'));
        
        Network.deleteHotelaDayLock(year, month, d, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl])

    const unlockHotelaEvent = useCallback((eventId: number, userId: number) => {
        Network.deleteHotelaEventLock(eventId, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl]) 
 
    const unlockHotelaEvents = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        Network.deleteHotelaEventsLock(startDate, endDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl]) 

    const unlockHotelaDays = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        Network.deleteHotelaDaysLock(startDate, endDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl])

    // GO integration
    const refreshGoUsers = useCallback((force = false) => {
        setLoading(true);
        Network.getGoUsersLocked(selectedDate, selectedEndDate).then(
            (response: NetworkResponse<IUser[]>) => {
                if (response.error === false) {
                    setUsers(response.data);
                    if (force) {
                        loadIntegrations();
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading global-office users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the global-office users' }), "error");
            }
        );
    }, [intl, loadIntegrations, setLoading, selectedDate, selectedEndDate]);

    const loadGoUserDetail = useCallback((userId: number) => {

        Network.getGoUserLockDetail(selectedDate, selectedEndDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading user detail' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the user detail' }), "error");
            }
        );
    }, [intl, selectedDate, selectedEndDate, setUserLoading])

    const unlockGoDay = useCallback((day: Moment, userId: number) => {
        Network.deleteGoDayLock(selectedDate, selectedEndDate, day, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl, selectedDate, selectedEndDate])

    const unlockGoDays = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        Network.deleteGoDaysLock(startDate, endDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl])

    const unlockGoEvent = useCallback((eventId: number, userId: number) => {
        Network.deleteGoEventLock(selectedDate, selectedEndDate, eventId, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl, selectedDate, selectedEndDate])

    const unlockGoEvents = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        Network.deleteGoEventsLock(startDate, endDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [intl])

    const cancelGoRangeExportation = useCallback((userId: number) => {

        Network.cancelGlobalOfficeShift(selectedDate, selectedEndDate, userId).then(
            (response: NetworkResponse<IUserDetail>) => {
                if (response.error === false) {
                    setUserDetail(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while canceling export' }), "error");
            }
        );
    }, [selectedDate, selectedEndDate, intl])
    // Function to route to integrator

    const refreshIntegration = useCallback((force = false) => {
        if (selectedIntegration === 'hotela') {
            refreshHotelaUsers(force);
        } else if (selectedIntegration === 'globaloffice') {
            refreshGoUsers(force);
        }
    }, [selectedIntegration, refreshHotelaUsers, refreshGoUsers]);



    const loadUserDetails = useCallback((userId: number) => {
        if (selectedIntegration === 'hotela') {
            loadHotelaUserDetail(userId);
        } else if (selectedIntegration === 'globaloffice') {
            loadGoUserDetail(userId);
        }
    }, [selectedIntegration, loadHotelaUserDetail, loadGoUserDetail])

    const unlockDay = useCallback((day: Moment, userId: number) => {
        if (selectedIntegration === 'hotela') {
            unlockHotelaDay(day, userId);
        } else if (selectedIntegration === 'globaloffice') {
            unlockGoDay(day, userId);
        }
    }, [selectedIntegration, unlockHotelaDay, unlockGoDay])

    const unlockDays = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        if (selectedIntegration === 'hotela') {
            unlockHotelaDays(startDate, endDate, userId);
        } else if (selectedIntegration === 'globaloffice') {
            unlockGoDays(startDate, endDate, userId);
        }
    }, [selectedIntegration, unlockHotelaDays, unlockGoDays])

    const unlockEvent = useCallback((eventId: number, userId: number) => {
        if (selectedIntegration === 'hotela') {
            unlockHotelaEvent(eventId, userId);
        } else if (selectedIntegration === 'globaloffice') {
            unlockGoEvent(eventId, userId);
        }
    }, [selectedIntegration, unlockHotelaEvent, unlockGoEvent])

    const unlockEvents = useCallback((startDate: Moment, endDate: Moment, userId: number) => {
        if (selectedIntegration === 'hotela') {
            unlockHotelaEvents(startDate, endDate, userId);
        } else if (selectedIntegration === 'globaloffice') {
            unlockGoEvents(startDate, endDate, userId);
        }
    }, [selectedIntegration, unlockHotelaEvents, unlockGoEvents])

    const cancelExportation = useCallback((userId: number) => {
        if (selectedIntegration === 'hotela') {
            cancelHotelaMonthExportation( userId);
        } else if (selectedIntegration === 'globaloffice') {
            cancelGoRangeExportation(userId);
        }
    }, [selectedIntegration, cancelHotelaMonthExportation, cancelGoRangeExportation])

    const changeSelectedUser = useCallback((u: IUser | undefined) => {
        setSelectedUser(u);
        if (u === undefined){
            setUserDetail(undefined)
        }
    }, []);

    useEffect(() => {
        if(selectedDate){
            refreshIntegration()
            if(selectedUser){
                setUserLoading(true)
                loadUserDetails(selectedUser.id)
            }
        }
    }, [selectedDate, refreshIntegration, selectedUser, loadUserDetails, display]);
    

    const tableHeight = useMemo(() => height - 349 < 250 ? 250 : height - 300, [height]);


    const ExportResult = useCallback((props: {
        readyToExport?: boolean
    }) => {
        if (selectedIntegration === 'hotela' && userDetail) {
            return <ExportHotelaResult userData={userDetail.hoMonthData} loading={false}/>
        } else if (selectedIntegration === 'globaloffice') {
        
            return <ExportGlobalOfficeResult userData={props.readyToExport ? userDetail?.goShiftsReadyToExport?.userData : userDetail?.goShiftsAlreadyExported?.userData} loading={false}/>
        }
        return <Spin
            style={{ width: "100%", marginTop: "20px" }}
            size="large"
            spinning
        />
    }, [selectedIntegration, userDetail])

    return (
        <>
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} xl={{ span: 11 }}>
                    <Card
                        size='small'
                        title={allUsers ? <FormattedMessage defaultMessage={'Users'} /> : <FormattedMessage defaultMessage={'Locked users'} />}
                        extra={[
                            <Space key='integration-links'>
                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Display all users' })}>
                                    <CircleButton
                                        icon={<FAIcon prefix='fad' name='user-magnifying-glass' />}
                                        type={allUsers ? 'primary' : 'default'}
                                        onClick={() => setAllUsers(!allUsers)}
                                        size='small'
                                    />
                                </Tooltip>
                            </Space>
                        ]}
                    >
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }}>
                                <VirtualTable
                                    style={{ marginTop: 15, flex: 1, overflow: 'auto' }}
                                    dataSource={filterIntegratedUser(users)}
                                    loading={loading}
                                    rowKey={(e: IIntegrationUsers) => e.id}
                                    onRow={(e: any) => ({
                                        onClick: () => {
                                            if (selectedUser && selectedUser.id === e.id) {
                                                changeSelectedUser(undefined);
                                            } else {
                                                changeSelectedUser(e);
                                            }
                                        },
                                    })}
                                    rowSelection={{
                                        hideSelectAll: true,
                                        onSelect: (user: any) => {
                                            if (selectedUser && selectedUser.id === user.id) {
                                                changeSelectedUser(undefined);
                                            } else {
                                                changeSelectedUser(user);
                                            }
                                        },
                                        selectedRowKeys: selectedUser ? [selectedUser.id] : undefined,
                                        type: 'checkbox'
                                    }}
                                    columns={columns}
                                    scroll={{ x: true, y: tableHeight }}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 13 }}>
                    {userDetail !== undefined ?
                        (selectedIntegration === 'hotela' && userDetail.hoMonthData == null) || (selectedIntegration === 'globaloffice' && userDetail.goShiftsAlreadyExported?.userData == null && userDetail.goShiftsReadyToExport?.userData == null)?
                            <Card 
                                size='small'
                                key={'card-locked'}
                                title={`${intl.formatMessage({ defaultMessage: 'Locks' })} (${userDetail?.lockedDays.length > 0 ?
                                    userDetail.isLockedAllMonth ? display === 'monthly' ? intl.formatMessage({ defaultMessage: 'Month locked' }): intl.formatMessage({ defaultMessage: 'Week locked' }): `${userDetail.lockedDays.filter(l=>!l.temporary).length +" "+intl.formatMessage({ defaultMessage: 'days locked' })}` 
                                    : 
                                    `${userDetail.lockedEvents.length} ${intl.formatMessage({ defaultMessage: 'events locked' })}`
                                })`}
                                extra={
                                    userDetail.lockedDays.length > 0 ? 
                                        <ConfirmButton
                                            icon={<FAIcon prefix='fad' name='unlock' />}
                                            size='small'
                                            title={intl.formatMessage({ defaultMessage: 'Unlock days' })}
                                            onConfirm={() => unlockDays(selectedDate, selectedEndDate, userDetail.userId)}
                                            disabled={userDetail.hoMonthData != null || userDetail.lockedDays.length === 0}
                                            loading={loading}
                                            text={intl.formatMessage({ defaultMessage: 'Are you sure to unlock all days locked' })}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            placement={'left'}
                                            onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                                            primary
                                            buttonPlacement={'left'}
                                        />
                                        :
                                        <ConfirmButton
                                            icon={<FAIcon prefix='fad' name='unlock' />}
                                            size='small'
                                            title={intl.formatMessage({ defaultMessage: 'Unlock events' })}
                                            onConfirm={() => unlockEvents(selectedDate, selectedEndDate, userDetail.userId)}
                                            disabled={userDetail.hoMonthData != null || userDetail.lockedDays.length > 0}
                                            loading={loading}
                                            text={intl.formatMessage({ defaultMessage: 'Are you sure to unlock all events locked' })}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            placement={'left'}
                                            onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                                            primary
                                            buttonPlacement={'left'}
                                        />
                                }
                            >
                                {display == 'monthly' ? 
                                    <Calendar
                                        value={selectedDate}
                                        headerRender={() => <></>}
                                        validRange={[selectedDate, selectedEndDate]} 
                                        dateCellRender={(value: Moment) => {
                                            if (value.isBefore(selectedDate, 'day') || value.isAfter(selectedEndDate, 'day')){
                                                return <></>
                                            }
                                            const findLockedDay = userDetail.lockedDays.find(l => moment(l.date).isSame(value, 'day'))
                                            const eventsOnDay = userDetail.lockedEvents.filter(e=>moment(e.startDate).date() == value.date())
                                            return <CalendarCell autoHeight={false} onUnlockDay={() => unlockDay(value, userDetail.userId)} onUnlockEvent={(eventId: number) => unlockEvent(eventId, userDetail.userId)} canUnlock={userDetail.hoMonthData == null || userDetail.lockedDays.length === 0} locked={findLockedDay !== undefined} eventsOnDay={eventsOnDay} />
                                            ;
                                        }} 
                                    />
                                    :
                                    <WeekCalendar
                                        value={selectedDate}
                                        headerRender={() => <></>}
                                        validRange={[selectedDate, selectedEndDate]} 
                                        dateCellRender={(value: Moment) => {
                                            if (value.isBefore(selectedDate, 'day') || value.isAfter(selectedEndDate, 'day')){
                                                return <></>
                                            }
                                            const findLockedDay = userDetail.lockedDays.find(l => moment(l.date).isSame(value, 'day'))
                                            const eventsOnDay = userDetail.lockedEvents.filter(e=>moment(e.startDate).date() == value.date())
                                            return <CalendarCell autoHeight={true} onUnlockDay={() => unlockDay(value, userDetail.userId)} onUnlockEvent={(eventId: number) => unlockEvent(eventId, userDetail.userId)} canUnlock={userDetail.hoMonthData == null || userDetail.lockedDays.length === 0} locked={findLockedDay !== undefined} eventsOnDay={eventsOnDay} />
                                            ;
                                        }}
                                    />
                                }
                            </Card>
                            :
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                                {(selectedIntegration === 'globaloffice' && userDetail.goShiftsReadyToExport?.userData != null) &&
                            <Card
                                size='small'
                                key={'card-data-ready-to-export'}
                                title={`${intl.formatMessage({ defaultMessage: 'Data ready to export' })}`}
                                extra={
                                    <ConfirmButton
                                        icon={<FAIcon prefix='fad' name='trash-arrow-up' />}
                                        size={'small'}
                                        title={intl.formatMessage({ defaultMessage: 'Cancel exportation' })}
                                        onConfirm={() => cancelExportation(userDetail.userId)}
                                        loading={loading}
                                        text={intl.formatMessage({ defaultMessage: 'Are you sure to cancel exportation' })}
                                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                        placement={'left'}
                                        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                                        primary
                                        buttonPlacement={'left'}
                                    />
                                }
                            >
                                <ExportResult readyToExport/>
                            </Card>}
                                {(selectedIntegration === 'hotela' || (selectedIntegration === 'globaloffice' && userDetail.goShiftsAlreadyExported?.userData != null)) &&
                            <Card
                                size='small'
                                key={'card-data-already-exported'}
                                title={`${intl.formatMessage({ defaultMessage: 'Data already exported' })}`}
                                extra={
                                    selectedIntegration === 'hotela' && <ConfirmButton
                                        icon={<FAIcon prefix='fad' name='trash-arrow-up' />}
                                        size={'small'}
                                        title={intl.formatMessage({ defaultMessage: 'Cancel exportation' })}
                                        onConfirm={() => cancelExportation(userDetail.userId)}
                                        loading={loading}
                                        text={intl.formatMessage({ defaultMessage: 'Are you sure to cancel exportation' })}
                                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                        placement={'left'}
                                        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => e?.stopPropagation()}
                                        primary
                                        buttonPlacement={'left'}
                                    />
                                }
                            >
                                <ExportResult />
                            </Card>}
                                
                            </div>
                                
                        :
                        <Spin
                            style={{ width: "100%", marginTop: "20px" }}
                            size="large"
                            spinning={userLoading}
                        />
                    }
                </Col>
            </Row>
        </>
    );
};
export default forwardRef(UsersManagement);