import { combineReducers } from '@reduxjs/toolkit';
import language from './../features/LanguageSlice';
import customerManagement from './../features/customerManagement';
import integrations from './../features/integrationsSlice';
import news from './../features/newsSlice';
import configurations from './configurations';
import crm from './crm';
import location from './location';
import messages from './messages';
import planning from './planning';
import posts from './posts';
import teamManagement from './teamManagement';
import user from './user';
import window from './window';

const rootReducer = combineReducers({
    user,
    language,
    window,
    planning,
    location,
    messages,
    teamManagement,
    customerManagement,
    configurations,
    posts,
    crm,
    integrations,
    news,
});

export default rootReducer;
