import { Spin } from "antd";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IIntegrationDetailsData, IIntegrationDetailsDisplayCharts, IIntegrationSyncButtons } from "../../../utils/types/integrationTypes";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";
import useIntegration from "../../hooks/useIntegrations";
import IntegrationDetails from "./integrationDetails";

interface IntegrationsDetailsList {
    enabled: boolean;
    details: IIntegrationDetailsData;
    logo: string;
    name: string;
    syncs: IIntegrationSyncButtons[];
    charts: IIntegrationDetailsDisplayCharts[];
}
const chartsColors = ['#badc58', '#686de0', '#ffbe76', '#ff7979', '#e056fd', '#7ed6df', '#95afc0', '#f6e58d'];

const Integrations = () => {
    const intl = useIntl();
    const integrations = useIntegration();

    const integrationsDetailsList: IntegrationsDetailsList[] = useMemo(() => ([
        ...(integrations.enabledIntegrations?.hotelaEnabled && integrations.hotelaDetails ? [{
            enabled: integrations.enabledIntegrations?.hotelaEnabled,
            details: integrations.hotelaDetails, daw: 'awd',
            logo: '/icons/hotela.png',
            name: 'Hotela',
            syncs: [
                // ...(integrations.hotelaDetails?.sync.users ? [{
                //     ...integrations.hotelaDetails.sync.users,
                //     title: <FormattedMessage defaultMessage={'Synchronise users'} />,
                //     onClick: integrations.syncHotelaUsers
                // }] : []),
            ],
            charts: [{
                labels: [intl.formatMessage({ defaultMessage: 'Integrations users' }), intl.formatMessage({ defaultMessage: 'Integrations linked to Sunkhronos' }), intl.formatMessage({ defaultMessage: 'Sunkhronos users only' })],
                data: [integrations.hotelaDetails.charts.users.integrationUsersOnly, integrations.hotelaDetails.charts.users.sunkAndIntegrationUsers, integrations.hotelaDetails.charts.users.sunkUsersOnly],
                colors: chartsColors,
                title: <FormattedMessage defaultMessage={'Synced users'} />,
                display: 'slash'
            }]
        }] : []),
        ...(integrations.enabledIntegrations?.globalOfficeEnabled && integrations.globalOfficeDetails ? [{
            enabled: integrations.enabledIntegrations?.globalOfficeEnabled,
            details: integrations.globalOfficeDetails,
            logo: '/icons/globalOffice.png',
            name: 'Global Office',
            syncs: [
                // ...(integrations.globalOfficeDetails?.sync.users? [{
                //     ...integrations.globalOfficeDetails.sync.users,
                //     title: <FormattedMessage defaultMessage={'Synchronise users'} />,
                //     onClick: integrations.syncGlobalOfficeUsers
                // }] : []),
                // ...(integrations.globalOfficeDetails?.sync.data ? [{
                //     ...integrations.globalOfficeDetails.sync.data,
                //     title: <FormattedMessage defaultMessage={'Synchronise datas'} />,
                //     onClick: integrations.syncGlobalOfficeData
                // }] : []),
                // ...(integrations.globalOfficeDetails?.sync.customers ? [{
                //     ...integrations.globalOfficeDetails.sync.customers,
                //     title: <FormattedMessage defaultMessage={'Synchronise customers'} />,
                //     onClick: integrations.syncGlobalOfficeCustomers
                // }] : []),
                // ...(integrations.globalOfficeDetails?.sync.mandates? [{
                //     ...integrations.globalOfficeDetails.sync.mandates,
                //     title: <FormattedMessage defaultMessage={'Synchronise mandates'} />,
                //     onClick: integrations.syncGlobalOfficeMandates
                // }] : []),
            ],
            charts: [{
                labels: [intl.formatMessage({ defaultMessage: 'Integration users only' }), intl.formatMessage({ defaultMessage: 'Integration users linked to Sunkhronos' }), intl.formatMessage({ defaultMessage: 'Sunkhronos users only' })],
                data: [integrations.globalOfficeDetails.charts.users.integrationUsersOnly, integrations.globalOfficeDetails.charts.users.sunkAndIntegrationUsers, integrations.globalOfficeDetails.charts.users.sunkUsersOnly],
                colors: chartsColors,
                title: <FormattedMessage defaultMessage={'Users'} />,
                display: 'slash'
            },
            ...(integrations.globalOfficeDetails?.charts.shifts ? [{
                labels: [intl.formatMessage({ defaultMessage: 'Shifts errors' }), intl.formatMessage({ defaultMessage: 'Shifts ready to send' }), intl.formatMessage({ defaultMessage: 'Shifts sent' }), intl.formatMessage({ defaultMessage: 'Shifts to validate' })],
                data: [integrations.globalOfficeDetails.charts.shifts?.shiftsError, integrations.globalOfficeDetails.charts.shifts?.shiftsReadyToSend, integrations.globalOfficeDetails.charts.shifts?.shiftsSent, integrations.globalOfficeDetails.charts.shifts?.shiftsToValidate],
                colors: chartsColors,
                title: <FormattedMessage defaultMessage={'Shifts'} />,
                display: 'slash'
            }] : []),
            ...(integrations.globalOfficeDetails?.charts.data ? [{
                labels: [intl.formatMessage({ defaultMessage: 'Integration analytic data' }), intl.formatMessage({ defaultMessage: 'Integration holidays' }), intl.formatMessage({ defaultMessage: 'Integration rubrics' })],
                data: [integrations.globalOfficeDetails.charts.data?.analytics, integrations.globalOfficeDetails.charts.data?.holidays, integrations.globalOfficeDetails.charts.data?.rubrics],
                colors: chartsColors,
                title: <FormattedMessage defaultMessage={'Data'} />,
                display: 'all'
            }] : []),
            ]
        }] : []),
    ]), [integrations, intl]);

    return (
        <Card
            title={<FormattedMessage defaultMessage={'Integrations'} />}
            icon={<FAIcon name='puzzle-piece-simple' prefix='far' />}
            headerElements={[
                <CircleButton
                    small
                    loading={integrations.isLoading}
                    key="team-periods-add-button"
                    icon={<FAIcon prefix='fad' name='rotate' />}
                    title={intl.formatMessage({ defaultMessage: 'Update data' })}
                    onClick={() => {
                        integrations.refreshDetails();
                    }}
                />
            ]}
        >
            <Spin spinning={integrations.isLoading}>
                {
                    integrationsDetailsList.map((integr) => {
                        if (integr.enabled && integr.details)

                            return (
                                <IntegrationDetails
                                    key={`integration-details-${integr.name.toLocaleLowerCase().trim()}`}
                                    logo={integr.logo}
                                    name={integr.name}
                                    displays={integr.charts}
                                    syncButtons={integr.syncs}
                                />
                            );
                    })
                }
            </Spin>
        </Card>
    );
};

export default Integrations;