import { Col, Empty, List, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeOccupancyRates, changeTypesOfDay, loadTypesOfContract } from '../../../../store/actions/configurations';
import { changeTemplates } from '../../../../store/actions/planning';
import { ContractTypeMode } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import { TypeOfContract, TypeOfContractWithUpdate } from '../../../../utils/types/generalTypes';
import { PlanningOccupancyRate, PlanningTemplate, TypeOfDay } from '../../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch } from '../../../../utils/types/storeTypes';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import Card from '../../../common/general/card';
import TypeOfContractItem from './typeOfContractItemV2';

interface IProps {
    typesOfContract: TypeOfContractWithUpdate;
}

type Props = IProps & ConfigurationsDispatchProps & PlanningDispatchProps & IntlProps;

interface State {
    createTypeOfContract: TypeOfContract | undefined;
}

/**
 * Component for the types of contract tab in the configurations page
 */
class TypesOfContractTabV2 extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            createTypeOfContract: undefined,
        };
    }

    componentDidMount(): void {
        this.refresh();
    }

    /**
     * Refresh the types of day
     * @param message a message to display as a success message - optional
     */
    refresh = (forceReload = false) => {
        // get all types of contracts
        this.props.loadTypesOfContract!(forceReload);
    };

    /**
     * Check if the add type Of Contract button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddTypeOfContract = (): boolean => this.state.createTypeOfContract === undefined;

    /**
     * Add a type Of Contract
     */
    addTypeOfContract = () => {
        const createTypeOfContract: TypeOfContract = {
            name: "",
            hoursMin: undefined,
            hoursMax: undefined,
            mode: ContractTypeMode.NORMAL
        };
        this.setState({ createTypeOfContract });
    };

    /**
     * Render a type Of Contract (a list item)
     * @param typeOfContract the item to render
     * @returns the component to render
     */
    renderTypeOfContract = (typeOfContract: TypeOfContract): React.ReactNode => {
        return (
            <>
                <TypeOfContractItem typeOfContract={typeOfContract} stopEdition={() => this.setState({ createTypeOfContract: undefined })} />
            </>
        );
    };

    renderHeader = () => {
        return (
            <div style={{ display: 'flex' }}>
                <Row gutter={[10, 10]} style={{ flex: 1 }}>
                    <Col xs={{ span: 12 }}>
                        <p ><span ><strong><FormattedMessage defaultMessage={'Name'} /></strong></span></p>
                    </Col>
                    {/* <Col xs={{ span: 6 }}>
                        <p>
                            <span ><strong><FormattedMessage defaultMessage={'Min hours'} /></strong></span>
                            <Tooltip title={this.props.intl.formatMessage({ defaultMessage: 'Minimum number of hours to be completed per year.' })}>
                                <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                            </Tooltip>
                        </p>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <p>
                            <span ><strong><FormattedMessage defaultMessage={'Max hours'} /></strong></span>
                            <Tooltip title={this.props.intl.formatMessage({ defaultMessage: 'Maximum number of hours to be completed per year.' })}>
                                <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                            </Tooltip>
                        </p>
                    </Col> */}
                    <Col xs={{ span: 12 }}>
                        <p style={{ marginLeft: '0px', marginBottom: '10px' }}><span ><strong><FormattedMessage defaultMessage={'Type'} /></strong></span></p>
                    </Col>
                </Row>

                {/*
                    //! Dont forget to change the width if you change the action props on the items
                */}
                <div style={{ width: 114 }} />
            </div>
        );
    };

    render() {
        const { createTypeOfContract } = this.state;
        const { intl, typesOfContract } = this.props;
        const parsedTypesOfContract = cloneDeep(typesOfContract);
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                    <Card className="types-of-day-card" icon={<FAIcon prefix='fad' name='file-contract' />} title={<FormattedMessage defaultMessage={'Types of contract'} />} headerElements={[
                        <>
                            <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: parsedTypesOfContract?.updated?.format(getFormat('DATE_AND_ON_TIME')) }} /></p>
                            <CircleButton
                                key="type-of-contract-refresh-button"
                                style={{ marginRight: "5px" }}
                                title={intl.formatMessage({ defaultMessage: 'Force update' })}
                                icon={<FAIcon prefix='fad' name="rotate" />}
                                placement="top"
                                small
                                onClick={() => this.refresh(true)} />
                        </>,
                        <span key={`typeOfContractTab-card-header-separator`} style={{ marginRight: "5px" }}>|</span>,
                        <CircleButton
                            key="type-of-contract-add-button"
                            style={{ marginRight: '8px' }}
                            title={intl.formatMessage({ defaultMessage: 'Add a type of contract' })}
                            onClick={this.addTypeOfContract}
                            icon={<FAIcon prefix='far' name='plus' />}
                            small
                            disabled={!this.showAddTypeOfContract()}
                            placement="top" />
                    ]}>
                        <List
                            className="contract-list"
                            header={this.renderHeader()}
                            dataSource={createTypeOfContract ? parsedTypesOfContract.data.concat([createTypeOfContract]) : parsedTypesOfContract.data}
                            renderItem={this.renderTypeOfContract}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No type of contract'} />} /> }}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    loadTypesOfContract: (fr?: boolean) => dispatch(loadTypesOfContract(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfContract: state.configurations.typesOfContract,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TypesOfContractTabV2));