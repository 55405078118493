import { Button, Modal, Steps } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { FullSMSAccount } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import CreateMessage from './createMessage';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
    createNewMessage: boolean;
    onClose: (reload: boolean) => void;
}
type Props = IProps & ReduxProps & IntlProps;
interface State {
    currentStep: number;
    nextMail: boolean;
    isModified: boolean;
    create: boolean;
    canSend: boolean;
    alerts: { key: string, title: string, description: string, type: 'info' | 'warning' | 'error'; }[];
    segment: 'mail' | 'sms';
}

/**
 * Component for the promotions tab in the CRM page
 */
class ModalCreateMessage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentStep: 0,
            nextMail: false,
            isModified: false,
            create: false,
            canSend: true,
            alerts: [],
            segment: 'sms'
        };
    }
    componentDidMount(): void {
        if (this.props.smsAccount !== undefined) {
            this.checkCanSend(this.props.smsAccount);
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isEqual(prevProps.smsAccount, this.props.smsAccount) && this.props.smsAccount !== undefined) {
            this.checkCanSend(this.props.smsAccount);
        }
    }
    checkCanSend = (smsAccount: FullSMSAccount, nrCreditUsed = 0) => {
        const { intl } = this.props;

        let alerts = cloneDeep(this.state.alerts);
        alerts = alerts.filter(a => !['alert-no-message-warning', 'alert-credit-info', 'alert-credit-error', 'alert-account-warning'].includes(a.key));
        let canSend = true;
        if (nrCreditUsed === 0) {
            alerts.push({
                key: `alert-no-message-warning`,
                title: intl.formatMessage({ defaultMessage: 'Please set a message' }),
                description: ``,
                type: 'warning'
            });
            canSend = false;
        }
        if (!smsAccount) {
            canSend = false;
            alerts.push({
                key: `alert-account-warning`,
                title: intl.formatMessage({ defaultMessage: 'Loading SMS datas' }),
                description: intl.formatMessage({ defaultMessage: 'Your SMS account informations are loading' }),
                type: 'warning'
            });
        }
        if (smsAccount.credit <= nrCreditUsed && smsAccount.unlimited === false) {
            alerts.push({
                key: `alert-credit-error`,
                title: intl.formatMessage({ defaultMessage: "You don't have enough credits" }),
                description: intl.formatMessage({ defaultMessage: '{credits, plural, one {You need more than {credits} credit to send this SMS and you only have {remaining}} other {You need more than {credits} credits to send theses SMS and you only have {remaining}}}' }, { credits: nrCreditUsed, remaining: smsAccount.credit }),
                type: 'error'
            });
            canSend = false;
        } else {
            alerts.push({
                key: `alert-credit-info`,
                title: intl.formatMessage({ defaultMessage: 'Information' }),
                description: intl.formatMessage({ defaultMessage: '{credits, plural, one {To send this SMS you will use {credits} credit} other {To send these SMS you will use {credits} credits}}' }, { credits: nrCreditUsed }),
                type: 'info'
            });

        }


        this.setState({ canSend, alerts });
        return canSend;
    };

    setNextMail = (value: boolean) => {

        this.setState({ isModified: true, nextMail: value });
    };

    closeModalCreateMessage = (reload = false) => {
        this.props.onClose(reload);
        this.setState({ isModified: false, currentStep: 0, nextMail: false, create: false });
    };

    cancelCreate = () => {
        this.setState({ create: false });
    };

    render() {
        const { createNewMessage } = this.props;
        const { nextMail, isModified, create, canSend, segment } = this.state;
        let { currentStep } = this.state;

        return (

            <Modal
                centered={this.props.height < 900 ? true : false}
                style={this.props.height < 900 ? {} : { top: 100 }}
                key="modal-create-promotion"
                destroyOnClose={true}
                className="crm-new-message-create-modal"
                closable={true}
                keyboard={!isModified}
                maskClosable={!isModified}
                onCancel={() => this.closeModalCreateMessage()}
                title={<FormattedMessage defaultMessage={'Create a new targeted or general message'} />}
                open={createNewMessage}
                footer={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button type="dashed" onClick={() => this.closeModalCreateMessage()} key="modal-create-new-message-close">
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <div key="modal-create-new-message-previous-next">
                            <Button type="default" disabled={currentStep === 0} onClick={() => this.setState({ currentStep: currentStep === 0 ? currentStep : currentStep -= 1 })} key="modal-create-new-message-previous">
                                <FormattedMessage defaultMessage={'Previous'} />
                            </Button>
                            <Button disabled={((currentStep === 0 && !nextMail) || (currentStep === 1 && !canSend && segment === 'sms'))} type="primary"
                                onClick={() => {
                                    if (currentStep === 1) {
                                        this.setState({ create: true });
                                    } else {
                                        this.setState({ currentStep: currentStep += 1 });
                                    }
                                }}
                                key="modal-create-new-message-next">
                                {
                                    currentStep === 1 ?
                                        <FormattedMessage defaultMessage={'Send'} />
                                        :
                                        <FormattedMessage defaultMessage={'Next'} />
                                }
                            </Button>
                        </div>
                    </div>
                }
                width="1000px"
            >
                <Steps
                    type="navigation"
                    size="small"
                    current={currentStep}
                    onChange={(current) => {
                        this.setState({ currentStep: current });
                    }}
                    style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                    <Steps.Step title="Clients" key="new-message-modal-step-0" />
                    <Steps.Step disabled={!nextMail} title="Envoi" key="new-message-modal-step-1" />
                </Steps>
                <CreateMessage cancelCreate={this.cancelCreate} finishCreatePromotion={() => this.closeModalCreateMessage(true)} create={create} setNextMail={this.setNextMail} currentStep={currentStep} alerts={this.state.alerts} checkCanSend={this.checkCanSend} segment={segment} setSegment={(segment: 'mail' | 'sms') => this.setState({ segment })} />
            </Modal>

        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    medals: state.crm.medals,
    countries: state.crm.countries,
    height: state.window.height,
    smsAccount: state.messages.smsAccount
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(ModalCreateMessage));