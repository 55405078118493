import { Avatar, Col, Empty, List, Row, Spin, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import '../../../../styles/phone-overview.css';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { showNotification } from '../../../../utils/utils';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import Anticon from '../../../common/general/anticon';
import { tableColumnTextFilterConfig } from '../../../courseManagement/tableSearch';

interface Props {
    type: 'all' | 'mobile' | 'admin' | 'timeclock' | 'SwissSkiSchool' | 'UNKNOWN';
}

interface StandardResponse<T> {
    error: boolean;
    message: string;
    data: T;
}
interface AuthToken {
    id: number;
    created: string;
    expiry: string;
    type: 'N/A' | 'SA' | 'SMB' | 'BC' | 'SMS' | 'SSS' | 'STC' | 'N/A';
}
interface UserWithAuthTokens {
    id: number;
    email: string;
    role: number;
    username: string;
    firstName: string;
    lastName: string;
    authTokens: AuthToken[];
    image: string;
}

export const UserLogedIn = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [userLoading, setUserLoading] = useState<boolean>(false);

    const [usersWithAuthTokens, setUsersWithAuthTokens] = useState<UserWithAuthTokens[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserWithAuthTokens | undefined>(undefined);
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);

    const intl = useIntl();

    const columns: ColumnProps<UserWithAuthTokens>[] = [
        {
            title: <FormattedMessage defaultMessage={'Image'} />,
            dataIndex: 'image',
            key: 'image',
            fixed: true,
            className: '__width_80 __centered-text',
            render: (link: string) => (
                <div className="both-center">
                    <Avatar size="large" src={link} icon={<FAIcon prefix='fad' name='user' />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'firstName',
            key: 'firstName',
            onFilter: (value, record) => {
                return record.firstName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<UserWithAuthTokens>(),
            sorter: (a: UserWithAuthTokens, b: UserWithAuthTokens) => !a.firstName ? -1 : !b.firstName ? 1 : a.firstName < b.firstName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'lastName',
            key: 'lastName',
            onFilter: (value, record) => {
                return record.lastName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<UserWithAuthTokens>(),
            sorter: (a: UserWithAuthTokens, b: UserWithAuthTokens) => !a.lastName ? -1 : !b.lastName ? 1 : a.lastName < b.lastName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'# Devices'} />,
            key: 'loggedInNumbers',
            className: '__width_120',
            defaultSortOrder: 'descend',
            sorter: (a: UserWithAuthTokens, b: UserWithAuthTokens) => (filterAuthTokens(a.authTokens).length <= filterAuthTokens(b.authTokens).length ? -1 : 1),
            render: (user) => <div style={{ width: '100%', textAlign: 'center' }}>{filterAuthTokens(user.authTokens).length}</div>
        },
    ];

    const getIconFromType = (type: 'N/A' | 'SA' | 'SMB' | 'BC' | 'SMS' | 'SSS' | 'STC' | 'N/A') => {
        switch (type) {
            case 'BC':
                return <FAIcon prefix='fad' name='passport' />;
            case 'N/A':
                return <FAIcon prefix='fad' name='question' />;
            case 'SA':
                return <FAIcon prefix='fad' name='display' />;
            case 'SMB':
                return <FAIcon prefix='fad' name='mobile-signal-out' />;
            case 'SMS':
                return <FAIcon prefix='fad' name='mobile' />;
            case 'SSS':
                return <FAIcon prefix='fad' name='skiing' />;
            case 'STC':
                return <FAIcon prefix='fad' name='clock' />;
        }
    };
    const getNameFromType = (type: 'N/A' | 'SA' | 'SMB' | 'BC' | 'SMS' | 'SSS' | 'STC' | 'N/A') => {
        switch (type) {
            case 'BC':
                return intl.formatMessage({ defaultMessage: 'Booking Corner' });
            case 'N/A':
                return intl.formatMessage({ defaultMessage: 'No identification' });
            case 'SA':
                return intl.formatMessage({ defaultMessage: 'Sunkhronos Admin' });
            case 'SMB':
                return intl.formatMessage({ defaultMessage: 'Sunkhronos Mobile Business' });
            case 'SMS':
                return intl.formatMessage({ defaultMessage: 'Sunkhronos Mobile Sport' });
            case 'SSS':
                return intl.formatMessage({ defaultMessage: 'Swiss Ski School' });
            case 'STC':
                return intl.formatMessage({ defaultMessage: 'Sunkhronos Time Clock App' });
        }
    };

    const replaceSelectedUser = useCallback(() => {
        if (selectedUser) {
            setSelectedUser(usersWithAuthTokens.find(u => u.id === selectedUser.id));
        }
    }, [selectedUser, usersWithAuthTokens]);

    const refreshAuthTokens = useCallback((force = false) => {
        setLoading(true);
        Network.getUsersLoggedIn().then(
            (response: StandardResponse<UserWithAuthTokens[]>) => {
                if (response.error === false) {
                    setUsersWithAuthTokens(response.data);
                    force && replaceSelectedUser();
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the connected users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the connected users' }), "error");
            }
        );
    }, [intl, replaceSelectedUser]);

    const deleteAuthToken = useCallback((id: number) => {
        setUserLoading(true);
        Network.deleteAuthToken(id).then(
            (response: StandardResponse<UserWithAuthTokens[]>) => {
                if (response.error === false) {
                    setUsersWithAuthTokens(response.data);
                    replaceSelectedUser();
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the connection' }), "error", response.message);
                }
                setUserLoading(false);
            },
            () => {
                setUserLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the connection' }), "error");
            }
        );
    }, [intl, replaceSelectedUser]);

    const filterAuthTokens = useCallback((authTokens: AuthToken[]) => {
        const type = props.type;
        return authTokens.filter(t => type === 'all' ? true : type === 'mobile' ? (t.type === 'SMB' || t.type === 'SMS') : type === 'admin' ? (t.type === 'SA') : type === 'timeclock' ? (t.type === 'STC') : type === 'SwissSkiSchool' ? (t.type === 'SSS') : t.type === 'N/A');
    }, [props.type]);

    useEffect(() => {
        setLoading(true);
        Network.getUsersLoggedIn().then(
            (response: StandardResponse<UserWithAuthTokens[]>) => {
                if (response.error === false) {
                    setUsersWithAuthTokens(response.data);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the connected users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the connected users' }), "error");
            }
        );
    }, [intl]);



    return (
        <div className="user-logged-in">

            <Spin size='large' spinning={loading}>
                <Row gutter={[30, 10]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xxl={{ span: 10 }} style={{ position: 'relative' }}>
                        <Table
                            style={{ marginTop: 15 }}
                            dataSource={usersWithAuthTokens}
                            rowKey={(e: UserWithAuthTokens) => e.id}
                            onRow={(e) => ({
                                onClick: () => {
                                    if (selectedUser && selectedUser.id === e.id) {
                                        setSelectedUser(undefined);
                                    } else {
                                        setSelectedUser(e);
                                    }
                                },
                            })}
                            rowSelection={{
                                hideSelectAll: true,
                                onSelect: (user) => {
                                    if (selectedUser && selectedUser.id === user.id) {
                                        setSelectedUser(undefined);
                                    } else {
                                        setSelectedUser(user);
                                    }
                                },
                                selectedRowKeys: selectedUser ? [selectedUser.id] : undefined,
                                type: 'checkbox'
                            }}
                            columns={columns}
                            pagination={{
                                showSizeChanger: false,
                                showQuickJumper: false,
                                showLessItems: true,
                                pageSize: 7,
                                hideOnSinglePage: true,
                                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`
                            }}
                        />

                    </Col>
                    <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 1 }} xxl={{ span: 1 }}>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }} xxl={{ span: 13 }} style={{ position: 'relative' }}>
                        <CircleButton
                            size={'small'}
                            style={{
                                border: 'none',
                                position: 'absolute',
                                top: -6,
                                right: 6
                            }}
                            loading={loading || userLoading}
                            key="refresh-auth-tokens-button"
                            icon={<FAIcon prefix='fad' name='rotate' />}
                            title={intl.formatMessage({ defaultMessage: 'Refresh data' })}
                            onClick={() => refreshAuthTokens(true)} />
                        {selectedUser ?
                            <>
                                <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}><h3>{`${selectedUser.firstName} ${selectedUser.lastName} (${selectedUser.username})`}</h3></div>
                                {/* <div style={{display: 'flex', flexDirection: 'column', gap: 14}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <span>{'Afficher l\'équipe'}</span>
                                        <span>test</span>
                                    </div>
                                </div> */}
                                <List
                                    loading={userLoading}
                                    bordered
                                    pagination={{
                                        pageSize: isSmartphone ? 12 : 3,
                                        position: 'bottom',
                                        showSizeChanger: false
                                    }}
                                    itemLayout={'vertical'}
                                    dataSource={filterAuthTokens(selectedUser.authTokens).sort((a, b) => moment(a.created).isSameOrBefore(moment(b.created)) ? 1 : -1)}
                                    renderItem={(authToken) => {
                                        return (
                                            <List.Item
                                                key={`authToken-list-item-${authToken.id}`}
                                                extra={[
                                                    <CircleButton onClick={() => deleteAuthToken(authToken.id)} key={`authToken-logout-button-${authToken.id}`} icon={<Anticon icon={<FAIcon prefix={'far'} name={'right-from-bracket'} />} />} title={intl.formatMessage({ defaultMessage: 'Disconnect device' })} />
                                                ]}
                                            >
                                                <List.Item.Meta
                                                    avatar={getIconFromType(authToken.type)}
                                                    title={
                                                        <div>
                                                            <span>{`(${authToken.id}) ${getNameFromType(authToken.type)}`}</span>
                                                            {
                                                                authToken.type === 'N/A' &&
                                                                <Tooltip title={
                                                                    <div>
                                                                        <p><FormattedMessage defaultMessage={'Unidentified devices are connections made before the update to identify the source of the connection.'} /></p>
                                                                        <p><FormattedMessage defaultMessage={'You can disconnect these devices to force them to reconnect.'} /></p>
                                                                    </div>
                                                                }>
                                                                    <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                                                </Tooltip>}
                                                        </div>
                                                    }
                                                //description={item.email}
                                                />
                                                <div style={{ padding: 10 }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span><FormattedMessage defaultMessage={'Connection made on'} />{':'}</span>
                                                        <span>{moment(authToken.created).format(getFormat('DATE_LONG_AND_ON_TIME_LONG'))}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span><FormattedMessage defaultMessage={'Valid until'} />{':'}</span>
                                                        <span>{moment(authToken.expiry).format(getFormat('DATE_LONG_AND_ON_TIME_LONG'))}</span>
                                                    </div>
                                                </div>
                                            </List.Item>

                                        );
                                    }
                                    }
                                />


                            </>
                            :
                            <Empty description={<FormattedMessage defaultMessage={'No user selected'} />} />
                        }

                    </Col>
                </Row>
            </Spin>
        </div>

    );
};
export default UserLogedIn;