import { Moment } from "moment";
import { CcntTypeErrors, IncreasedHours, ReportTypeErrors } from "../constants";
import { Department, POI, TypeOfContract, TypeOfVehicle, User, UserJobTMP } from "./generalTypes";
import { NetworkSimpleContract, NetworkSimpleEvent } from "./networkTypes";
import { Project, Reminder, TypeOfDay, TypeOfDayOff, UserShort } from "./planningTypes";

//for the Ccnt reports
export interface CcntEventBreaktime {
    dateFrom: Moment;
    dateTo: Moment;
}

export interface CcntEventOvertime {
    dateFrom: Moment;
    dateTo: Moment;
    isNegative: boolean;
}

export interface CcntEvent {
    type: string;
    dateFrom: Moment;
    dateTo: Moment;
    overtime: CcntEventOvertime[];
    breaktime: CcntEventBreaktime[];
}

export interface CcntType {
    weeklyPlannedHours: number;
    lastYearsWorkHours: number;
    lastYearsRest: number;
    lastYearsPublicVacation: number;
    lastYearsVacation: number;
    paidVacationDays: number;
    paidPublicHolidayDays: number;
    paidHours: number[];
    paidRestDays: number;
    events: CcntEvent[];
    company: string;
    employee: string;
    entryDate: Moment;
    exitDate: Moment;
    workHoursPerWeek: number;
    workYear: number;
}

export type CcntReports = { [key: string]: CcntReport; };

export enum CcntErrorType {
    ERROR = 'error',
    CCNT_WARNING = 'ccnt-warning',
    WARNING = 'warning',
    INFO = 'info'
}

export enum CcntErrorCode {
    OVER_24_HOURS = "over24",
    LONG_DAY = "longday",
    NEGATIVE_HOURS = "negativehours",
    TOO_LONG_WORK_ON_HALF_REST_DAY = "overtimeonhalfrestday",
    INPUT_MIX = "inputmix",
    WRONG_INPUT = "wronginput",
    TOO_MANY_DIFFERENT_EVENTS = "toomanydiffevents",
    TOO_MANY_X = "toomanyx",
    TOO_MANY_F = "toomanyf",
    TOO_MANY_M = "toomanym",
    WEEK_OVER_50_HOURS = "weekover50",
    TOO_MANY_WORK_DAYS_IN_A_ROW = "toomanytinarow",
    WEEK_WITHOUT_TWO_REST_DAYS = "weekwithout2rest"
}

export interface CcntReportError {
    type: CcntErrorType,
    code: CcntErrorCode,
    date: string;
}

interface CcntReportTally {
    T: number;
    M: number;
    F: number;
    Mi: number;
    Ma: number;
    X: number;
    V: number;
    A: number;
    D: number;
    filledDaysCount: number;
    daysCount: number;
    paidHours: number;
    theoreticalWorkTime: number;
    workTimeDelta: number;
    finalWorkTimeDelta: number;
    restToGet: number;
    restDelta: number;
    finalRestDelta: number;
    holidayToGet: number;
    holidayDelta: number;
    finalHolidayDelta: number;
    vacationToGet: number;
    vacationDelta: number;
    finalVacationDelta: number;
}

interface CcntReportData {
    employee: string;
    workYear: number;
    company: string;
    entryDate: string;
    exitDate: string;
    weeklyPlannedHours: number;
    lastYearsWorkHours: number;
    lastYearsPublicVacation: number;
    lastYearsVacation: number;
    lastYearsRest: number;
    paidRestDays: number;
    paidVacationDays: number;
    paidHolidayDays: number;
    contractTypeMode: number;
}

export interface CcntReport {
    months: {
        days: string[][];
        tally: CcntReportTally,
    }[],
    tally: CcntReportTally,
    errors: CcntReportError[],
    data: CcntReportData;
}

export interface GroupByDayCcntType {
    date: string;
    events: CcntEvent[];
}

export interface GroupByDayMonthlyReportType {
    date: string;
    events: MonthlyReportEvent[];
}

export interface ReportError {
    date?: Moment;
    dates?: Moment[];
    eventId?: number;
    eventIds?: number[];
    code: string;
    type: ReportTypeErrors; //!
    message: string;
}

export interface CcntError {
    date: string;
    code: string;
    type: CcntTypeErrors;
    message: string;
}

//for the monthly "normal" Reports MonthlyReport
export interface MonthlyReportEventBreaktime {
    dateFrom: Moment;
    dateTo: Moment;
}

export interface MonthlyReportEventOvertime {
    dateFrom: Moment;
    dateTo: Moment;
    isNegative: boolean;
}

export interface BalanceOfTypeOfDaysOff {
    name: string;
    balance: number;
}

export interface MonthlyReportEvent {
    dateFrom: Moment;
    dateTo: Moment;
    overtime: MonthlyReportEventOvertime[];
    breaktime: MonthlyReportEventBreaktime[];
    title: string;
    type_of_day?: string;
    type_of_day_off?: string;
    color: string;
    project: string;
    distance: number;
}


export interface MonthlyReportType {
    workName?: string;
    workRate: number;
    workYear: number;
    workStartDate?: string;
    workEndDate?: string;
    dailyPlannedHours: number;
    balanceHours: number;
    balanceOfTypeOfDaysOff: BalanceOfTypeOfDaysOff[];
    events: MonthlyReportEvent[];
    company: string;
    employee: string;
    workHoursPerWeek: number;
    showDayOfWeek: boolean;
    showDistance: boolean;
    showProject: boolean;
    summaryErrors: ReportSummaryError[];
}
export interface DaysOffEffectiveHours {
    id: number;
    title: string;
    effectiveHours?: number;
    balanceHours?: number;
    reportHours?: number;
    defaultHours?: number;
    initHours?: number;

}
export interface DaysOffReport {
    effectiveTimeByDaysOff?: DaysOffEffectiveHours[];

}

export interface UserEffectiveHoursAbstract {
    userId: number;
    effectiveHours: number;
    weeklyWorkingHours: number;
    nbBusinessDays: number;
    balanceHours: number;
    workRate: number;
    calculatedDaysOff?: DaysOffReport;
}

export interface UserEffectiveHours extends UserEffectiveHoursAbstract {
    endDate: Moment;
    startDate: Moment;
}

export interface DaysOffBalanceType {
    id: number;
    effectiveHours: number;
    balanceHours: number;
    reportHours: number;
    defaultHours: number;
    initHours: number;
}

export interface BalanceYearType {
    startDate: Moment;
    endDate: Moment;
    workRate: number;
    effectiveHours: number;
    weeklyWorkingHours: number;
    nbBusinessDays: number;
    user: UserShort;
    balanceHours: number;
    daysOff: { [key: string]: DaysOffEffectiveHours | undefined | null; };
}


export interface SimpleContract {
    id: number;
    name: string;
    startDate: Moment;
    endDate: Moment;
    typeOfContract?: TypeOfContract;
    department?: Department;
    weeklyWorkingHours: number;
    vacationIncreasedPercentByHour?: number;
    workRate: number;
}

export type SimpleContractCCtSecurity = SimpleContract;

export interface SimpleOvertime {
    dateFrom: Moment;
    dateTo: Moment;
    isNegative: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
}

export interface SimpleBreaktime {
    dateFrom: Moment;
    dateTo: Moment;
    isPaid?: boolean;
}

export interface SimpleEvent {
    id: number;
    title: string;
    dateFrom: Moment;
    dateTo: Moment;
    typeOfDay?: TypeOfDay;
    typeOfDayOff?: TypeOfDayOff;
    overtimes: SimpleOvertime[];
    breakTimes: SimpleBreaktime[];
    reminders?: Reminder[];
}

export interface SimpleEventCctSecurity extends SimpleEvent {
    poi?: POI;
    basePoi?: POI;
    vehicle?: TypeOfVehicle;
    distance?: number;
    project?: Project;
    userRemarks?: string;

    summary?: CctSecurityEventSummary;
}

export interface UserHoursSummaryDataRaw {
    id: number;
    firstName: string;
    lastName: string;
    contracts: NetworkSimpleContract[];
    events: NetworkSimpleEvent[];

}

export interface InitialHoursRaw {
    id: number;
    initialOvertimeHours: number;
    year: number;
    userId: number;
    contractId: number | null;
}

export interface InitialHoursByContractByYear {
    id: number;
    initialHours: number;
    year: number;
    contractId: number;
}

export interface UsersHoursSummariesRaw {
    data: UserHoursSummaryDataRaw[];
    initialHours: InitialHoursRaw[];
}

export interface ContractEvents {
    id: number;
    name: string;
    startDate: Moment;
    endDate: Moment;
    dayWorkingHours: number;
    workRate: number;
    initialOvertimeHours: number;
    withoutContract: boolean;
    events: SimpleEvent[];
    calculatedEvents: UserHoursSummary;
}

export interface PaidHoursBase {
    id?: number;
    year: number;
    month: number;
    hours: number;
}

export interface PaidHours extends PaidHoursBase {
    user: UserShort;
    contract: UserJobTMP;
}

export interface CalculatedUserHoursSummaryData {
    id: number;
    firstName: string;
    lastName: string;
    selectedContractId?: number;
    contractsEvents: ContractEvents[];
}

export interface PaidHoursSummary {
    user: UserShort;
    selectedPaidHoursSummary?: ContractPaidHoursSummary;
    contractsPaidHoursSummary: ContractPaidHoursSummary[];
}

export interface PaidHoursAllMonths {
    ph1?: PaidHoursBase;
    ph2?: PaidHoursBase;
    ph3?: PaidHoursBase;
    ph4?: PaidHoursBase;
    ph5?: PaidHoursBase;
    ph6?: PaidHoursBase;
    ph7?: PaidHoursBase;
    ph8?: PaidHoursBase;
    ph9?: PaidHoursBase;
    ph10?: PaidHoursBase;
    ph11?: PaidHoursBase;
    ph12?: PaidHoursBase;
}

export interface ContractPaidHoursSummary extends UserJobTMP, PaidHoursAllMonths {
}

export interface CalculatedUsersHoursSummaries {
    updated: Moment;
    data: CalculatedUserHoursSummaryData[];
}

export interface UsersHoursSummaries {
    updated: Moment;
    summaries: UserHoursSummary[];
}

export interface UserHoursSummaryByYear {
    startBalance?: number;
    endBalance?: number;
    janHours?: UserHoursSummaryInMonth;
    febHours?: UserHoursSummaryInMonth;
    marHours?: UserHoursSummaryInMonth;
    aprHours?: UserHoursSummaryInMonth;
    mayHours?: UserHoursSummaryInMonth;
    junHours?: UserHoursSummaryInMonth;
    julHours?: UserHoursSummaryInMonth;
    augHours?: UserHoursSummaryInMonth;
    sepHours?: UserHoursSummaryInMonth;
    octHours?: UserHoursSummaryInMonth;
    novHours?: UserHoursSummaryInMonth;
    decHours?: UserHoursSummaryInMonth;
}

export interface EventsByContractByMonth {
    id: number;
    name: string;
    typeOfContract?: TypeOfContract;
    department?: Department;
    startDate: Moment;
    endDate: Moment;
    dayWorkingHours: number;
    workRate: number;
    initialHours: number;
    withoutContract: boolean;
    vacationIncreasedPercentByHour?: number;
    events: SimpleEvent[];
    calculatedEvents?: UserHoursSummaryByYear;
}

export interface UserHoursSummaryInMonth {
    effectiveHours?: number;
    todoHours?: number;
    overtimeUndertimeBalance?: number;
    overtimeBalance?: number;
    undertimeBalance?: number;
    breaktimeBalance?: number;
    totalCalculatedBalance?: number;
    prevBalance?: number;
    nextBalance?: number;
}

export interface ParsedUserHoursSummaryData {
    id: number;
    firstName: string;
    lastName: string;
    selectedContractId?: number;
    contractsEvents: EventsByContractByMonth[];
}

export interface ParsedUsersHoursSummaries {
    updated: Moment;
    data: ParsedUserHoursSummaryData[];
}

export interface UserHoursSummary {
    janHours?: HoursSummary,
    febHours?: HoursSummary,
    marHours?: HoursSummary,
    aprHours?: HoursSummary,
    mayHours?: HoursSummary,
    junHours?: HoursSummary,
    julHours?: HoursSummary,
    augHours?: HoursSummary,
    sepHours?: HoursSummary,
    octHours?: HoursSummary,
    novHours?: HoursSummary,
    decHours?: HoursSummary,
}

export interface HoursSummary {
    effectiveHours?: string;
    todoHours?: string;
    overtimeUndertimeBalance?: string;
    overtimeBalance?: string;
    undertimeBalance?: string;
    breaktimeBalance?: string;
    totalCalculatedBalance?: string;
    prevBalance?: string;
    nextBalance?: string;
}

export interface EventsByMonth {
    janHours?: SimpleEvent[],
    febHours?: SimpleEvent[],
    marHours?: SimpleEvent[],
    aprHours?: SimpleEvent[],
    mayHours?: SimpleEvent[],
    junHours?: SimpleEvent[],
    julHours?: SimpleEvent[],
    augHours?: SimpleEvent[],
    sepHours?: SimpleEvent[],
    octHours?: SimpleEvent[],
    novHours?: SimpleEvent[],
    decHours?: SimpleEvent[],
}

export interface ReportSummaryError {
    code: string;
    message: string;
}

export interface GroupByDayMonthlyReportType {
    date: string;
    events: MonthlyReportEvent[];
}

export interface MonthlyReportError {
    date: string;
    code: string;
    message: string;
}

export interface DayType {
    type: string;
    toGet: number;
    recieved: number;
    effectiveTime: number;
    isNotWorkType: boolean;
}

//! CCT SECURITE
//#region CC

export interface CctSecurityType {
    reportMonth: number;
    lastYearsWorkHours?: number;
    lastYearsVacation?: number;
    events?: CctSecurityRawEvent[];
    company: string;
    employee: string;
    entryDate?: Moment;
    exitDate?: Moment;
    workYear?: number;
}

export interface IncreasedHoursByDay {
    day: IncreasedHours;
    percentage: number;
    allDay: boolean;
    // datetime
    ruleStartDate: Moment;
    ruleEndDate: Moment;
    // only time (moment(component.props.data.value, [moment.ISO_8601, MOMENT_SHORT_TIME_FORMAT]);)
    startTime?: Moment;
    endTime?: Moment;
    // datetime
    created?: Moment;
    modified?: Moment;
}

export interface CctSecurityEventsByContractByMonth extends EventsByContractByMonth {
    cctSecurityCalculatedEvents?: CctSecurityUserHoursSummaryByYear;
    user?: User;
    reportMonth?: number,
}

export interface CctSecurityUserHoursSummaryInMonth /*extends UserHoursSummaryInMonth*/ {
    //*events
    // lstTypeDayOff?: CctSecurityTypeDayOffMonth[];
    summaryByDay?: CctSecurityUserHoursSummaryInDay[];

    //*alerts
    alerts?: ReportError[];

    //*summary
    monthSummary?: CctSecurityMonthlySummary;
    monthOverview?: CctSecurityMonthlyOverview;

    //*for distance calculations
    totEngagementZone?: CctSecurityTravelZoneDistance;
    totEngagementZone2?: CctSecurityTravelZoneDistance;
    totFlatRateZone1?: CctSecurityTravelZoneDistance;
    totFlatRateZone2?: CctSecurityTravelZoneDistance;
    totDynamicRateZone?: CctSecurityTravelZoneDistance;

}

export interface CctSecurityUserHoursSummaryByYear {
    // userFirstname?: string;
    // userLastname?: string;
    // contractType?: string;
    // contractAndNumber?: string;
    // title?: string;
    lstTypeDayOff?: CctSecurityTypeDayOffYear[];
    lstAlerts?: CctSecurityAlerts[];
    startBalance?: number;
    endBalance?: number;
    janHours?: CctSecurityUserHoursSummaryInMonth;
    febHours?: CctSecurityUserHoursSummaryInMonth;
    marHours?: CctSecurityUserHoursSummaryInMonth;
    aprHours?: CctSecurityUserHoursSummaryInMonth;
    mayHours?: CctSecurityUserHoursSummaryInMonth;
    junHours?: CctSecurityUserHoursSummaryInMonth;
    julHours?: CctSecurityUserHoursSummaryInMonth;
    augHours?: CctSecurityUserHoursSummaryInMonth;
    sepHours?: CctSecurityUserHoursSummaryInMonth;
    octHours?: CctSecurityUserHoursSummaryInMonth;
    novHours?: CctSecurityUserHoursSummaryInMonth;
    decHours?: CctSecurityUserHoursSummaryInMonth;
}

//? Vehicle Deplacement
export interface CctSecurityTravelZone {
    id?: number;
    name?: string;
}

export interface TravelSummary {
    distanceTotal?: number;
    distanceTotalCompensated?: number;
    compensationText?: string;
}

export interface CctSecurityDistance {
    id: number;
    distance: number;
}

export interface CctSecurityTravelZoneDistance {
    travelZone?: CctSecurityTravelZone;
    distances?: CctSecurityDistance[];
}



//? Event
export interface CctSecurityUserHoursSummaryInDay {
    date?: Moment;
    events?: SimpleEventCctSecurity[];
    // events?: SimpleEvent[];

    daySummary?: CctSecurityDaySummary[];

}


export interface CctSecurityEventSummary {
    color?: string;
    typeOfDay?: DayType;

    eventHours?: number;
    breaktimesHours?: number;
    breaktimesPaidHours?: number;
    breaktimesNotPaidHours?: number;
    overtimesHours?: number;
    effectiveHours?: number;
    totalHours?: number;


    vacationIncreasedHours?: number;
    increasedHours?: number;

    project?: string;
    projectNumber?: string;
    poiAddress?: string;
    deplacement?: TravelSummary;
    userRemarks?: string;
}

// TODO Peut-être a supprimer ?
export interface CctSecurityEvent extends SimpleEvent {
    color?: string;
    // typeOfDay?: DayType;
    hours?: number;
    totHours?: number;
    vacationMajoration?: number;
    hoursMajoration?: number;
    theProject?: string;
    poiAddress?: string;
    deplacement?: TravelSummary;
    userRemarks?: string;
}

export interface CctSecurityDaySummary {
    date: Moment,
    schedule?: string,
    color: string,
    title?: string,
    typeOfDay: string,
    hoursPerEvent: number,
    overtime: number,
    breaktime: number,
    totHours: number,
    majoration: number,
    project: string;
    address: string,
    startingPoint: string,
    vehicle: string,
    travelZone: string,
    distanceMade: number,
    distanceCompensated: number,
    remarks: string;
}

//? Summary
export interface CctSecurityMonthlySummary {
    todoHours?: number;

    eventHours?: number;
    breaktimePaidBalance?: number;
    breaktimeNotPaidBalance?: number;
    overtimeUndertimeBalance?: number;
    effectiveHours?: number;

    increasedHours?: number;
    vacationIncreasedHours?: number;

    prevTotalHours?: number;
    totalHours?: number;
    nextTotalHours?: number;

    lstTypeDayOff?: CctSecurityTypeDayOffOverview[];
}

export interface CctSecurityMonthlyOverview {
    previousMonthHours?: number;
    actualMonthHours?: number;
    totHours?: number;
    lstTypeDayOff?: CctSecurityTypeDayOffOverview[];
}


//? TypeDayOff
export interface CctSecurityTypeDay {
    id?: number;
    title?: string;
}

export interface CctSecurityTypeDayOffYear {
    typeDayOff?: CctSecurityTypeDay;
    startBalance?: number;
    endBalance?: number;
}

export interface CctSecurityTypeDayOffMonth {
    typeDayOff?: CctSecurityTypeDay;
    count?: number;
}

export interface CctSecurityTypeDayOffOverview {
    typeDayOff?: CctSecurityTypeDay;
    previousBalance?: number;
    actualBalance?: number;
    nextBalance?: number;
}

//? Alerts

export interface CctSecurityAlerts {
    id?: number;
    month?: string;
    lstErrors?: ReportError[];
}

//? Raw Data
export interface CctSecurityTypeRaw {
    workYear: number;
    reportMonth: number;
    company: string;
    employee: string;
    entryDate: Moment;
    exitDate: Moment;
    lastYearsWorkHours: number;
    lastYearsVacation: number;
    events: CctSecurityRawEvent[];
}

export interface CctSecurityRawEvent {
    id?: number;
    dateFrom?: Moment;
    dateTo?: Moment;
    title?: string;
    color?: string;
    typeOfDay?: number;
    typeOfDayOff?: number;
    overtime?: SimpleOvertime[];
    breaktime?: SimpleBreaktime[];
    totHours?: number;
    vehicleType: number;
    theProject?: string;
    poiAddress?: string;
    basePoi: string;
    basePoiId: number;
    basePoiIsPrimary: boolean;
    distance?: number;
    userNote?: string;
}

//? Table fields
export interface CctSecurityDaySummary {
    date: Moment,
    schedule?: string,
    title?: string,
    typeOfDay: string,
    hoursPerEvent: number,
    overtime: number,
    breaktime: number,
    totHours: number,
    majoration: number,
    project: string;
    address: string,
    startingPoint: string,
    vehicle: string,
    travelZone: string,
    distanceMade: number,
    distanceCompensated: number,
    remarks: string;
}



//? 27.01.2023
//? New Workflow
export interface CctSecurityUserEventsByMonthRecap {
    id: number,
    color?: string,
    date: Moment,
    schedule?: string,
    title?: string,
    typeOfDay: string, 
    hoursPerEvent: number,
    overtime: number,
    breaktime: number,
    paidBreaktime: number,
    notPaidBreaktime: number,
    totalHours: number,
    eventHours: number;
    effectiveHours: number;
    increasedHours: number,
    vacationIncreasedHours: number,
    project: string;
    projectNumber: string;
    address: string,
    startingPoint: string,
    vehicle: string,
    travelZone: string,
    distanceMade?: number,
    distanceCompensated?: number,
    remarks: string;
}

export interface CctSecurityUserEventsByMonthSummary {
    id: number,
    name: string,
    value: number,
}

export interface CctSecurityUserEventsByMonthOverview {
    id: number,
    name: string,
    prevMonthBalance: number,
    currentMonthBalance: number,
    nextMonthBalance: number;
}

export interface CctSecurityUserEventsByMonthTravelSummary {
    id: number,
    name: string,
    passengerVehicle: number,
    ownVehicle: number,
    workVehicle: number;
}

export interface CctSecurityUserEventsByMonth {
    user?: User,
    contractName?: string,
    contractType?: TypeOfContract,
    months?: [{
        monthNumber?: number,
        recap?: CctSecurityUserEventsByMonthRecap[],
        summary?: CctSecurityUserEventsByMonthSummary[],
        overview?: CctSecurityUserEventsByMonthOverview[],
        travel?: CctSecurityUserEventsByMonthTravelSummary[];
    }];
}
//#endregion

//#region CctSecurityResponse
export interface InitialDaysOffCctSecurity {
    id: number;
    contractId: number;
    numberOfDays: number;
    typeOfDayOffId: number;
    userId: number;
}

export type InitialHoursCctSecurity = InitialHoursRaw;

export interface CctSecurityData {
    id: number;
    firstName: string;
    lastName: string;
    events: SimpleEventCctSecurity[];
    contracts: SimpleContractCCtSecurity[];
}

export interface CctSecurityResponse {
    data: CctSecurityData[];
    initialDaysOff: InitialDaysOffCctSecurity[];
    initialHours: InitialHoursCctSecurity[];
}
//#endregion