import L, { latLng, LatLngTuple } from "leaflet";
import { default as iconGreenRetina, default as iconRetina } from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import React from 'react';
import { FeatureGroup, GeoJSON, LayersControl, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { connect, useSelector } from 'react-redux';
import iconGreen from '../../../images/marker-icon.png';
import { Course, PoiLive, Sector } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
// import { GeoJSON as GeoJsonObject } from 'geojson';
import { Button, Col, Collapse, Drawer, Row, Spin } from 'antd';
import "leaflet/dist/leaflet.css";
import moment from 'moment';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import Lightbox from 'react-image-lightbox';
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import MarkerClusterGroup from 'react-leaflet-cluster';
import key from 'weak-key';
import '../../../styles/map.css';
import getFormat from "../../../utils/Lang";
import { toggleFullScreen } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../FAIcon";

const markerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconRetinaUrl: iconRetina,

    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [13, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [13, 41],  // the same for the shadow
    popupAnchor: [-1, -41] // point from which the popup should open relative to the iconAnchor
});

const markerIconGreen = L.icon({
    iconUrl: iconGreen,
    shadowUrl: iconShadow,
    iconRetinaUrl: iconGreenRetina,

    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [13, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [13, 41],  // the same for the shadow
    popupAnchor: [-1, -41] // point from which the popup should open relative to the iconAnchor
});

interface IProps {
    refresh: () => void;
    className?: string,
    isEditMode: boolean;
    pois?: PoiLive[];
    sectors?: Sector[];
    courses?: Course[];
    isSmartphone: boolean;
    poiIdToShow?: number;
    loading: boolean;
}

type Props = IProps & IntlProps;

interface State {
    zoom: number;
    center: LatLngTuple;
    isDrawerVisible: boolean;
    poiSelectedId?: number;
    openImage: boolean;
    imageToOpen: string;
    displayOnlyNotComplete: boolean;
}

/**
 * Component that represent a Skeleton (placeholder component when loading element)
 */
class MapLiveLeaflet extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            center: [46.25952238233216, 7.474810746155075],
            zoom: 12,
            isDrawerVisible: false,
            openImage: false,
            imageToOpen: '',
            displayOnlyNotComplete: false,
        };
    }

    componentDidMount() {
        if (this.props.poiIdToShow) {
            this.showPoi(this.props.poiIdToShow);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.poiIdToShow && this.props.poiIdToShow !== prevProps.poiIdToShow) {
            this.showPoi(this.props.poiIdToShow);
        }
    }

    showPoi = (poiId: number | undefined) => {
        if (poiId === undefined) return;
        const poi: number | undefined = this.props.pois?.findIndex(p => p.id === poiId);
        if (poi !== undefined && poi > -1 && this.props.pois && this.props.pois.length >= poi) {
            this.setState({ poiSelectedId: poiId, isDrawerVisible: true });
        }
    };

    /**
     * @description
     * Takes an Array<V>, and a grouping function,
     * and returns a Map of the array grouped by the grouping function.
     *
     * @param list An array of type V.
     * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
     *                  K is generally intended to be a property key of V.
     *
     * @returns Map of the array grouped by the grouping function.
     */
    //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    //    const map = new Map<K, Array<V>>();
    groupBy(list: Array<PoiLive>) {
        const map = new Map();
        list.forEach((item) => {
            const courses: { id: number; }[] | undefined = item.courseIds;
            courses?.forEach(c => {
                const collection = map.get(c.id);
                if (!collection) {
                    map.set(c.id, [item]);
                } else {
                    collection.push(item);
                }
            });
        });
        return map;
    }

    genExtra = (p: number) => {
        if (p === 0) {
            return <FAIcon prefix='fad' name='circle-question' onClick={e => e.stopPropagation()} />;
        } else if (p === -1) {
            return <FAIcon prefix='fad' name='circle-xmark' onClick={e => e.stopPropagation()} />;
        } else {
            return <FAIcon prefix='fad' name='circle-check' onClick={e => e.stopPropagation()} />;

        }
    };

    goUserProfile = (id: number) => {
        const { pathname } = window.location;
        const splited = pathname.split("/");
        return `/${splited[1]}/team-management/user-details/informations?id=${id}`;
    };

    render() {
        const { isEditMode, courses, intl } = this.props;
        const { zoom, center } = this.state;
        const pois = this.props.pois ? [...this.props.pois] : [];
        // const availablePois = pois?.filter(p => p.toDelete !== true);
        const parsedPois = pois ? this.groupBy(pois.filter(p => p.id !== undefined && p.id > 0)) : undefined;
        let poisWithoutCourse = pois?.filter(ap => ap.id !== undefined && ap.id > 0 && (ap.courseIds === undefined || ap.courseIds.length === 0));
        this.state.displayOnlyNotComplete && (poisWithoutCourse = poisWithoutCourse.filter(p => p.events !== undefined && p.events.length > 0 ? p.events.filter(e => !e.isInPlace).length > 0 : false));

        const poiSelected: PoiLive | undefined = this.props.pois?.find(p => p.id === this.state.poiSelectedId);

        // const allInPlaceSelected = poiSelected?.events?.filter(p => p.isInPlace !== true);
        // const isAllInPlaceSelected = allInPlaceSelected !== undefined && allInPlaceSelected.length === 0;

        return (

            <FullScreenContent onlyDisplay={false} refresh={() => this.props.refresh()} changeFilter={() => this.setState({ displayOnlyNotComplete: !this.state.displayOnlyNotComplete })} filter={this.state.displayOnlyNotComplete}>

                <Spin wrapperClassName="__leaflet-map-spinner" spinning={this.props.loading}>
                    <>
                        <MapContainer
                            tap={isMacOs && isSafari ? false : true}
                            preferCanvas={true}
                            className={`leaflet-container-${this.props.isEditMode ? "crosshair" : "grab"}`}
                            key={`map-container-${isEditMode}`}
                            center={center}
                            zoom={zoom}
                            zoomControl={true}
                            dragging={true}
                            keyboard={true}
                            scrollWheelZoom={true}
                            doubleClickZoom={false}
                            style={{ height: "100%" }}>
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name={intl.formatMessage({ defaultMessage: 'Default' })}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Satellite' })}>
                                    <TileLayer
                                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Google maps' })}>
                                    <TileLayer
                                        maxZoom={20}
                                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Black and white' })}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Swiss 1' })}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tile.osm.ch/switzerland/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Swiss 2' })}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                {
                                    pois && parsedPois && Array.from(parsedPois, ([k, value], idx) => {
                                        let course: PoiLive[] = value;
                                        const actualCourse = courses?.find(c => c.id === k);
                                        const actualGeoJson = actualCourse && actualCourse.geoJson;
                                        let parsedGeoJson;
                                        try {
                                            parsedGeoJson = actualGeoJson ? JSON.parse(actualGeoJson) : undefined;
                                        } catch (error) {
                                            parsedGeoJson = undefined;
                                        }

                                        this.state.displayOnlyNotComplete && (course = course.filter(p => p.events !== undefined && p.events.length >= 0 ? p.events.filter(e => !e.isInPlace).length > 0 : false));

                                        return (<LayersControl.Overlay key={`${key(pois)}-${k}-${idx}`} checked name={actualCourse && actualCourse.title ? actualCourse.title : intl.formatMessage({ defaultMessage: 'Unknown name' })}>
                                            <FeatureGroup>
                                                {
                                                    parsedGeoJson &&
                                                    <GeoJSON key={"geojson"} style={{ color: actualCourse?.color, weight: 4, opacity: 0.7 }} attribution={intl.formatMessage({ defaultMessage: 'Credits due to Sunkhronos' })} data={parsedGeoJson} />
                                                }

                                                <MarkerClusterGroup key={`parcours-${k}-${poisWithoutCourse.length}`} maxClusterRadius={(zoom: number) => zoom === 18 ? 10 : 80} chunkedLoading>
                                                    {

                                                        course.length > 0 &&
                                                        course.map((p: PoiLive) => {
                                                            if (p.longitude && p.latitude) {
                                                                const allInPlace = p.events?.filter(p => p.isInPlace !== true);
                                                                const isAllInPlace = allInPlace !== undefined && allInPlace.length === 0;
                                                                return (
                                                                    <Marker
                                                                        icon={isAllInPlace ? markerIconGreen : markerIcon}
                                                                        key={"marker-" + p.id}
                                                                        position={latLng(p.latitude, p.longitude)}
                                                                        eventHandlers={{
                                                                            click: () => {
                                                                                this.showPoi(p.id);
                                                                            },
                                                                        }}>
                                                                        <Tooltip direction="top" offset={[-1, -41]}>{p.title}</Tooltip>
                                                                    </Marker>
                                                                );
                                                            } else { return null; }
                                                        })
                                                    }
                                                </MarkerClusterGroup>
                                            </FeatureGroup>
                                        </LayersControl.Overlay>);
                                    })
                                }
                                {
                                    poisWithoutCourse && poisWithoutCourse.length > 0 &&
                                    <LayersControl.Overlay checked name={intl.formatMessage({ defaultMessage: 'Without route' })}>
                                        <FeatureGroup>
                                            <MarkerClusterGroup key={`sans-parcours-${poisWithoutCourse.length}`} maxClusterRadius={(zoom: number) => zoom === 18 ? 10 : 80} chunkedLoading>
                                                {
                                                    poisWithoutCourse.map(p => {
                                                        if (p.longitude && p.latitude) {
                                                            const allInPlace = p.events?.filter(p => p.isInPlace !== true);
                                                            const isAllInPlace = allInPlace !== undefined && allInPlace.length === 0;
                                                            return (
                                                                <Marker
                                                                    icon={isAllInPlace ? markerIconGreen : markerIcon}
                                                                    key={"marker-" + p.id}
                                                                    position={latLng(p.latitude, p.longitude)}
                                                                    eventHandlers={{
                                                                        click: () => {
                                                                            this.showPoi(p.id);
                                                                        },
                                                                    }}>
                                                                    <Tooltip direction="top" offset={[-1, -41]}>{p.title}</Tooltip>
                                                                </Marker>
                                                            );
                                                        } else { return null; }
                                                    })
                                                }
                                            </MarkerClusterGroup>
                                        </FeatureGroup>
                                    </LayersControl.Overlay>
                                }
                            </LayersControl>
                        </MapContainer>
                        {
                            <Drawer
                                destroyOnClose={true}
                                width={this.props.isSmartphone ? '100%' : '450px'}
                                title={poiSelected ? poiSelected.title : intl.formatMessage({ defaultMessage: 'New point of interest' })}
                                placement="right"
                                onClose={() => this.setState({ isDrawerVisible: false, poiSelectedId: undefined })}
                                visible={this.state.isDrawerVisible}
                                className="__drawerLiveMap"
                                getContainer={false}
                                style={{ position: 'absolute' }}
                            >
                                {
                                    poiSelected &&
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }}>
                                            {
                                                poiSelected.image &&
                                                <img alt="poi security" onClick={() => this.setState({ openImage: true, imageToOpen: `https://storage.googleapis.com/${poiSelected.image}` })} src={`https://storage.googleapis.com/${poiSelected.image}`} style={{ maxWidth: '100%', cursor: 'pointer', marginBottom: "15px", borderRadius: "var(--border-radius)", boxShadow: "var(--box-shadow)" }} />
                                            }
                                            {
                                                poiSelected.events && poiSelected.events.length > 0 ?
                                                    <Collapse
                                                        className="__collapse-background-transparent"
                                                        // bordered={false}
                                                        style={{ textAlign: "initial" }}
                                                        accordion={true}
                                                    >
                                                        {
                                                            poiSelected.events?.map((p, index) => {
                                                                /*
                                                                 * 0  => Position not yet validated.
                                                                 * -1 => The user is not in the right place.
                                                                 * +1 => Position validated
                                                                */
                                                                let userPosition = 0;
                                                                if (p.isInPlace) {
                                                                    userPosition = 1;
                                                                } else if (p.isInPlaceTime) {
                                                                    userPosition = -1;
                                                                } else {
                                                                    userPosition = 0;
                                                                }
                                                                return (
                                                                    <Collapse.Panel
                                                                        className={`__collapse-main ${userPosition === 0 ? "__collapse-normal" : userPosition === -1 ? "__collapse-red" : "__collapse-green"}`}
                                                                        // style={userPosition === 0 ? {border: "1px solid #b1b1b1", borderBottom: '0px'} : userPosition === -1 ? { backgroundColor: "#fff2f0", border: "1px solid #ffccc7", borderBottom: '0px' }: {backgroundColor: "#f6ffed", border: "1px solid #b7eb8f" , borderBottom: '0px'}}
                                                                        header={p.title} key={`collapse-index-${index}`} extra={this.genExtra(userPosition)}>
                                                                        {
                                                                            userPosition === 0 ?
                                                                                <>
                                                                                    {
                                                                                        p.description &&
                                                                                        <>
                                                                                            <p>{p.description}</p>
                                                                                            <hr />
                                                                                        </>
                                                                                    }
                                                                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Users'} />{':'} <a href={p && p.userId ? this.goUserProfile(p.userId) : "#user"}>{`${p.firstName} ${p.lastName}`}</a></p>
                                                                                    {
                                                                                        p.groups && p.groups.length > 0 &&
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                            Groupes :
                                                                                            <span>
                                                                                                {
                                                                                                    p.groups.map((g, index) => {
                                                                                                        return g.groupName + (p.groups && (index + 1 < p.groups.length) ? ", " : "");
                                                                                                    })
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    }
                                                                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Phone number'} />{':'} <span>{p.mobile ? <a href={`tel:${p.mobile}`}>{p.mobile}</a> : "-"}</span></p>
                                                                                    <hr />
                                                                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event start'} />{':'} <span>{`${moment(p.startDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event end'} />{':'} <span>{`${moment(p.endDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                    <p><FormattedMessage defaultMessage={'Position not yet validated'} /></p>
                                                                                </>
                                                                                :
                                                                                userPosition === -1 ?
                                                                                    <>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Users'} /> <a href={p && p.userId ? this.goUserProfile(p.userId) : "#user"}>{`${p.firstName} ${p.lastName}`}</a></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Phone number'} />{':'} <span>{p.mobile ? <a href={`tel:${p.mobile}`}>{p.mobile}</a> : "-"}</span></p>
                                                                                        <hr />
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event start'} />{':'} <span>{`${moment(p.startDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event end'} />{':'} <span>{`${moment(p.endDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                        {/* <p style={{ display: 'flex', justifyContent: 'space-between' }}>Position : <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${p.latitude},${p.longitude}`}>Position de l'utilisateur</a></p> */}
                                                                                        <hr />
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Localization'} />{':'} <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir/${poiSelected.latitude},${poiSelected.longitude}/${p.latitude},${p.longitude}/data=!3m1!4b1`}><FormattedMessage defaultMessage={'Visualize'} /></a></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Localization time'} />{':'} <span>{moment(p.isInPlaceTime).format(getFormat('DATE_AND_TIME_SHORT'))}</span></p>
                                                                                        <p><FormattedMessage defaultMessage={'User is not at the correct location'} /></p>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Users'} />{':'} <a href={p && p.userId ? this.goUserProfile(p.userId) : "#user"}>{`${p.firstName} ${p.lastName}`}</a></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Phone number'} />{':'} <span>{p.mobile ? <a href={`tel:${p.mobile}`}>{p.mobile}</a> : "-"}</span></p>
                                                                                        <hr />
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event start'} />{':'} <span>{`${moment(p.startDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Event end'} />{':'} <span>{`${moment(p.endDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}</span></p>
                                                                                        {/* <p style={{ display: 'flex', justifyContent: 'space-between' }}>Position : <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${p.latitude},${p.longitude}`}>Position de l'utilisateur</a></p> */}
                                                                                        <hr />
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Localization'} />{':'} <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir/${poiSelected.latitude},${poiSelected.longitude}/${p.latitude},${p.longitude}/data=!3m1!4b1`}><FormattedMessage defaultMessage={'Visualize'} /></a></p>
                                                                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}><FormattedMessage defaultMessage={'Localization time'} />{':'} <span>{moment(p.isInPlaceTime).format(getFormat('DATE_AND_TIME_SHORT'))}</span></p>
                                                                                        <p><FormattedMessage defaultMessage={'Position validated'} /></p>
                                                                                    </>
                                                                        }
                                                                    </Collapse.Panel>
                                                                );
                                                            })
                                                        }
                                                    </Collapse>
                                                    :
                                                    <p><FormattedMessage defaultMessage={'No event for this POI'} /></p>
                                            }
                                        </Col>
                                        {
                                            this.state.openImage && (
                                                <Lightbox
                                                    mainSrc={this.state.imageToOpen}
                                                    onCloseRequest={() => this.setState({ openImage: false })}
                                                />
                                            )
                                        }
                                    </Row>
                                }
                            </Drawer>
                        }
                    </>
                </Spin>
            </FullScreenContent>
        );
    }
}

function FullScreenContent(props: { children: any, onlyDisplay: boolean, refresh: any, changeFilter: any, filter: boolean; }) {
    //const handle = useFullScreenHandle();
    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen);
    const intl = useIntl();

    return (
        !isIOS && !props.onlyDisplay ?

            <>
                <Button
                    className="__fullscreen-live-map-button"
                    type={fullscreen ? "primary" : "default"}
                    icon={fullscreen ? <FAIcon prefix='fad' name='minimize' /> : <FAIcon prefix='fad' name='maximize' />}
                    onClick={toggleFullScreen}
                    title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                />
                <Button className="__refresh-map-button" onClick={() => props.refresh()}>
                    <FAIcon prefix={'fad'} name="rotate" />
                </Button>
                <Button title={props.filter ? intl.formatMessage({ defaultMessage: 'Show all POIs' }) : intl.formatMessage({ defaultMessage: 'Show only missing POIs' })} className="__filter-map-button" onClick={() => props.changeFilter()}>
                    {props.filter ? <FAIcon prefix='fad' name='toggle-on' /> : <FAIcon prefix='fad' name='toggle-off' />}
                </Button>
                {props.children}

            </>
            :
            props.children
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(MapLiveLeaflet));
