import { Card, Col, Empty, Row, Spin } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import getFormat from "../../../../utils/Lang";
import { IHOMonthData } from "../../../../utils/types/generalTypes";
import { IUserExportHoursHotelaUserData } from "../../../../utils/types/networkTypes";
import { fixedDecimal } from "../../../../utils/utils";


interface Props {
    loading: boolean;
    userData: IUserExportHoursHotelaUserData | IHOMonthData | undefined | null;
}

const ExportHotelaResult = (props: Props) => {
    const { loading, userData } = props;

    return <Spin spinning={loading}>
        {
            userData == null ?
                <Empty />
                :
                <Row>
                    <Col xs={{ span: 24 }}>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                <Card size='small' title={<strong><FormattedMessage defaultMessage={'Days'} /></strong>} className='integration-data-container'>
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Days off taken'} /><span>{fixedDecimal(userData.dayOff)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Public holidays taken'} /><span>{fixedDecimal(userData.publicDays)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Vacation days taken'} /><span>{fixedDecimal(userData.vacation)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Unpaid days'} /><span>{fixedDecimal(userData.unpaidDays)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid vacations'} /><span>{fixedDecimal(userData.paidDayOff)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid holidays'} /><span>{fixedDecimal(userData.paidPublicDays)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid vacation days'} /><span>{fixedDecimal(userData.paidVacation)}</span></div>
                                    <hr />
                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Military/CP days'} /><span>{fixedDecimal(userData.militaryDays)}</span></div>
                                    <hr />
                                    <br />
                                    <strong><div className='integration-data-item'><FormattedMessage defaultMessage={'Days to pay'} /><span>{fixedDecimal(userData.dayCount)}</span></div></strong>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }}>
                                        <Card size='small' title={<strong><FormattedMessage defaultMessage={'Hours'} /></strong>} className='integration-data-container'>
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Regular overtime'} /><span>{fixedDecimal(userData.regularOvertime)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Iregular overtime'} /><span>{fixedDecimal(userData.irregularOvertime)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Night work (10%)'} /><span>{fixedDecimal(userData.nightBonus10)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Night work (25%)'} /><span>{fixedDecimal(userData.nightBonus25)}</span></div>
                                            <hr />
                                            <br />
                                            <strong><div className='integration-data-item'><FormattedMessage defaultMessage={'Hours to pay'} /><span>{fixedDecimal(userData.hourCount)}</span></div></strong>
                                        </Card>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Card size='small' title={<strong><FormattedMessage defaultMessage={'Balances'} /></strong>} className='integration-data-container'>
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Days off balance'} /><span>{fixedDecimal(userData.extraDaysBalance)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Vacation balance'} /><span>{fixedDecimal(userData.extraHolidaysDaysBalance)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Holiday balance'} /><span>{fixedDecimal(userData.extraPublicDaysBalance)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Overtime balance'} /><span>{fixedDecimal(userData.extraHoursBalance)}</span></div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Card size='small' title={<FormattedMessage defaultMessage={'Periods'} />}>
                                    {
                                        userData.periods.length > 0 ?

                                            userData.periods.map(period => {
                                                return (
                                                    <div className='integration-data-periods-container' key={`period-${period.id}`}>
                                                        <div style={{ width: '100px', display: 'inline-block' }}>
                                                            {
                                                                period.periodType === 'accident' ?
                                                                    <FormattedMessage defaultMessage={'Accident'} />
                                                                    :
                                                                    period.periodType === 'illness' ?
                                                                        <FormattedMessage defaultMessage={'Illness'} />
                                                                        :
                                                                        period.periodType === 'maternity' ?
                                                                            <FormattedMessage defaultMessage={'Maternity'} />
                                                                            :
                                                                            <FormattedMessage defaultMessage={'Absent'} />
                                                            }
                                                        </div>

                                                        <FormattedMessage defaultMessage={'From {start} to {end}'} values={{ start: moment(period.startDate, 'YYYY-MM-DD').format(getFormat('DATE')), end: moment(period.endDate, 'YYYY-MM-DD').format(getFormat('DATE')) }} />
                                                        <span><FormattedMessage defaultMessage={'At {rate}% rate'} values={{ rate: period.rate }} /></span>
                                                        <span><FormattedMessage defaultMessage={'{count, plural, one {{count} day} other {{count} days}}'} values={{ count: moment(period.endDate, 'YYYY-MM-DD').diff(moment(period.startDate, 'YYYY-MM-DD'), 'days') + 1 }} /></span>
                                                    </div>
                                                );
                                            })
                                            :
                                            <p style={{ fontStyle: 'italic', color: '#8c8c8c' }}><FormattedMessage defaultMessage={'No period present for the selected month'} /></p>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        }
    </Spin>
}
export default ExportHotelaResult