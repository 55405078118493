import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, SxProps, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Alert, Checkbox, Col, Divider, Form, FormInstance, Input, InputNumber, message, Radio, Row, Segmented, Select, Space } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from "moment";
import React from "react";
import { defineMessage, FormattedMessage, injectIntl, IntlShape, MessageDescriptor } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RRule } from "rrule";
import { changeUserAvailabilities } from "../../../store/actions/planning";
import { MOMENT_FORMAT_DISPLAY_DATETIME, MOMENT_FORMAT_DISPLAY_TIME, MOMENT_FORMAT_TIME_MIN_GMT } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { UserAvailability, UserAvailabilityEdit } from "../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch } from "../../../utils/types/storeTypes";
import { availabilityRruleToString, colorIsBright, convertUserAvailabilityEditToUserAvailabilityEditNetwork, convertUserAvailabilityNetworkToUserAvailability, showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Bloc from "../../common/general/bloc";
import { AppTheme } from "../../specialRoutes/externalRouteSelector";

// #region Constants
export const enum AvailabilityState {
    Open = 0,
    Close = 1
}

const enum ConstraintsFields {
    TitleMinLen = 4,
    TitleMaxLen = 255,
}

const enum AvailabilityTypes {
    Continue = "continue",
    Periodic = "periodic",
}

const enum DateOrTime {
    StartDateRule = "startdaterule",
    EndDateRule = "enddaterule",
    StartTime = "starttime",
    EndTime = "endtime"
}

export const enum AllDayTimes {
    start = "00:00",
    end = "23:59"
}

enum ProposalsValues {
    EachWeek = "eachweek",
    EachMonthDay = "eachmonthday",
    EachMontFirstX = "eachmontfirstx",
    EachMontLastX = "eachmontlastx",
    // EachMontFirstDate = "eachmontfirstdate",
    // EachMontLastDate = "eachmontlastdate",
}

const proposalsRules: {
    id: ProposalsValues;
    title: (intl: IntlShape, weekday?: MessageDescriptor, monday?: string) => string;
    rrule: (rrule: RRule, startDateRule: Moment | undefined) => RRule;
}[] =
    [
        {
            id: ProposalsValues.EachWeek,
            title: (intl, weekday) => intl.formatMessage({ defaultMessage: 'Every week, on {day}' }, { day: weekday ? intl.formatMessage(weekday) : '' }),
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined
            }),
        },
        {
            id: ProposalsValues.EachMonthDay,
            title: (intl, monthday) => intl.formatMessage({ defaultMessage: 'Every month, {day}' }, { day: monthday ? intl.formatMessage(monthday) : '' }),
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bymonthday: startDateRule?.date()

            }),
        },
        {
            id: ProposalsValues.EachMontFirstX,
            title: (intl, weekday) => intl.formatMessage({ defaultMessage: 'Every month, on the first {day}' }, { day: weekday ? intl.formatMessage(weekday) : '' }),
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },
        {
            id: ProposalsValues.EachMontLastX,
            title: (intl, weekday) => intl.formatMessage({ defaultMessage: 'Every month, on the last {day}' }, { day: weekday ? intl.formatMessage(weekday) : '' }),
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: -1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },
        // {
        //     id: ProposalsValues.EachMontFirstDate,
        //     title: (weekday = "", monthday = "") => `Le dernier jour de chaque mois`,
        //     rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
        //         ...rrule.origOptions,
        //         freq: RRule.MONTHLY,
        //         interval: 1,
        //         bysetpos: 1,

        //     }),
        // },
        // {
        //     id: ProposalsValues.EachMontLastDate,
        //     title: (weekday = "", monthday = "") => `Le premier jour de chaque mois`,
        //     rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
        //         ...rrule.origOptions,
        //         freq: RRule.MONTHLY,
        //         interval: 1,
        //         bysetpos: -1,
        //         byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

        //     }),
        // },

    ];

export const monthlySetPosOptions = [
    {
        name: defineMessage({ defaultMessage: 'First' }),
        bysetpos: 1
    },
    {
        name: defineMessage({ defaultMessage: 'Second' }),
        bysetpos: 2
    },
    {
        name: defineMessage({ defaultMessage: 'Third' }),
        bysetpos: 3
    },
    {
        name: defineMessage({ defaultMessage: 'Fourth' }),
        bysetpos: 4
    },
    {
        name: defineMessage({ defaultMessage: 'Fifth' }),
        bysetpos: 5
    },
    {
        name: defineMessage({ defaultMessage: 'Last' }),
        bysetpos: -1
    },
];

export const weekDaysRRule = [
    {
        weekday: RRule.MO.weekday,
        name: defineMessage({ defaultMessage: 'Monday' }),
        value: RRule.MO.toString()
    },
    {
        weekday: RRule.TU.weekday,
        name: defineMessage({ defaultMessage: 'Tuesday' }),
        value: RRule.TU.toString()
    },
    {
        weekday: RRule.WE.weekday,
        name: defineMessage({ defaultMessage: 'Wednesday' }),
        value: RRule.WE.toString()
    },
    {
        weekday: RRule.TH.weekday,
        name: defineMessage({ defaultMessage: 'Thursday' }),
        value: RRule.TH.toString()
    },
    {
        weekday: RRule.FR.weekday,
        name: defineMessage({ defaultMessage: 'Friday' }),
        value: RRule.FR.toString()
    },
    {
        weekday: RRule.SA.weekday,
        name: defineMessage({ defaultMessage: 'Saturday' }),
        value: RRule.SA.toString()
    },
    {
        weekday: RRule.SU.weekday,
        name: defineMessage({ defaultMessage: 'Sunday' }),
        value: RRule.SU.toString()
    },
];
// #endregion

// #region Typescript
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, IntlProps {
    availabilityState: AvailabilityState;
    availability: UserAvailabilityEdit;
    resetForm?: boolean;
    validateForm?: boolean;
    theme?: AppTheme;

    changeResetForm?: (value: boolean) => void;
    changeValidateForm?: (value: boolean) => void;
    close: () => void;
}

interface State {
    continueForm: {
        endDateDisabled: boolean;
    };
    availability: UserAvailabilityEdit;
    proposal?: ProposalsValues;
    allday: boolean;
}
// #endregion

class AvailabilityEditForm extends React.Component<Props, State> {
    DateTimePickerStyle: SxProps;
    DatePickerStyle: SxProps;
    TimePickerStyle: SxProps;
    formRef = React.createRef<FormInstance<State>>();
    constructor(props: Props) {
        super(props);
        const { theme } = this.props;

        this.state = this.getInitialState();

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);

        this.DateTimePickerStyle = [
            {
                '.MuiFormControl-root': {
                }
            },
            {
                '.MuiInputBase-root fieldset legend': {
                    display: 'block!important',
                    width: 'auto!important',
                    maxWidth: '100%!important',
                    marginBottom: '0.5em!important',
                    padding: '0!important',
                    color: 'inherit!important',
                    fontSize: '1.5em!important',
                    lineHeight: '11px!important',
                    whiteSpace: 'normal!important'
                }
            },
            {
                '.MuiInputBase-root': {
                    borderRadius: '30px 30px 30px 30px',
                }
            },
            {
                '.MuiInputBase-root:hover fieldset': {
                    borderColor: '#c78034',
                }
            },
            {
                '.Mui-focused fieldset': {
                    borderColor: '#c78034!important',
                    borderWidth: '1px!important'
                }
            },
            {
                input: {
                    padding: '0px 10px',
                    height: '33px',
                    color: isDarkMode ? theme.color : '#373737',
                    fontSize: '16px',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                }
            },
            {
                fieldset: {
                    borderColor: 'var(--border-color)',
                }
            },
            {
                width: '100%',
            }
        ];

        this.DatePickerStyle = [
            {
                '.MuiFormControl-root': {
                }
            },
            {
                '.MuiInputBase-root': {
                    borderRadius: '30px 30px 30px 30px',
                }
            },
            {
                '.MuiInputBase-root:hover fieldset': {
                    borderColor: '#c78034',
                }
            },
            {
                '.Mui-focused fieldset': {
                    borderColor: '#c78034!important',
                    borderWidth: '1px!important'
                }
            },
            {
                '.MuiDatePickerToolbar-title': {
                    color: theme?.color
                }
            },
            {
                input: {
                    padding: '0px 10px',
                    height: '33px',
                    color: isDarkMode ? theme.color : '#373737',
                    fontSize: '16px',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                }
            },
            {
                fieldset: {
                    borderColor: 'var(--border-color)',
                }
            },
            {
                width: '100%',
            }
        ];

        this.TimePickerStyle = [
            {
                '.MuiFormControl-root': {
                }
            },
            {
                '.MuiInputBase-root': {
                    borderRadius: '30px',
                }
            },
            {
                '.MuiInputBase-root:hover fieldset': {
                    borderColor: '#c78034',
                }
            },
            {
                '.Mui-focused fieldset': {
                    borderColor: '#c78034!important',
                    borderWidth: '1px!important'
                }
            },
            {
                input: {
                    padding: '0px 10px',
                    height: '33px',
                    color: isDarkMode ? theme.color : '#373737',
                    fontSize: '16px',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                }
            },
            {
                fieldset: {
                    borderColor: 'var(--border-color)',
                }
            },
            {
                width: '100%',
            }
        ];
    }
    // #region React Lifecycle
    componentDidUpdate(prevProps: Props) {
        if (prevProps.resetForm !== this.props.resetForm && this.props.resetForm)
            this.onReset();

        if (prevProps.validateForm !== this.props.validateForm && this.props.validateForm)
            this.onSubmit();
    }
    // #endregion
    getInitialState = () => {
        const availability = cloneDeep(this.props.availability);
        let allday = false;

        if (availability.startTime?.format(MOMENT_FORMAT_DISPLAY_TIME) === AllDayTimes.start && availability.endTime?.format(MOMENT_FORMAT_DISPLAY_TIME) === AllDayTimes.end) {
            allday = true;
        }

        const state: State = {
            continueForm: {
                endDateDisabled: this.props.availability === undefined || this.props.availability.startDateRule === null || this.props.availability.startDateRule === undefined,
            },
            availability: availability,
            proposal: undefined,
            allday: allday,
        };

        return state;
    };

    copyRRuleOnlyLimits = (rrule: RRule | undefined) => {
        return new RRule({
            dtstart: rrule?.origOptions.dtstart,
            until: rrule?.origOptions.until
        });
    };

    onNetworkSave = (availability: UserAvailabilityEdit) => {
        const { teamAvailabilities, availabilityState, intl } = this.props;
        const isEdit = availability?.id !== undefined;

        Network.editUserAvailability(convertUserAvailabilityEditToUserAvailabilityEditNetwork(availability)).then(
            (response) => {
                if (response.error) {
                    if (isEdit) {
                        if (availabilityState === AvailabilityState.Close)
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the availability' }), "success");
                        else
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the unavailability' }), "success");
                    }
                    else {
                        if (availabilityState === AvailabilityState.Close)
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the availability' }), "success");
                        else
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the unavailability' }), "success");
                    }
                } else {
                    const newUserAvailability = convertUserAvailabilityNetworkToUserAvailability(response.data);
                    const userAvailabilitiesData = cloneDeep(teamAvailabilities.find(ta => ta.userId === availability.user.id)?.data);
                    if (userAvailabilitiesData) {
                        const userAvailabilityId = userAvailabilitiesData.findIndex(a => a.id === newUserAvailability.id);

                        if (userAvailabilityId >= 0) {
                            userAvailabilitiesData[userAvailabilityId] = newUserAvailability;
                        } else {
                            userAvailabilitiesData.push(newUserAvailability);
                        }

                        this.props.changeUserAvailabilities(availability.user.id, userAvailabilitiesData);
                        if (isEdit) {
                            if (availabilityState === AvailabilityState.Close)
                                showNotification(intl.formatMessage({ defaultMessage: 'The avaiability has been successfully updated' }), "success");
                            else
                                showNotification(intl.formatMessage({ defaultMessage: 'The unavaiability has been successfully updated' }), "success");
                        }
                        else {
                            if (availabilityState === AvailabilityState.Close)
                                showNotification(intl.formatMessage({ defaultMessage: 'The avaiability has been successfully created' }), "success");
                            else
                                showNotification(intl.formatMessage({ defaultMessage: 'The unavaiability has been successfully created' }), "success");
                        }
                        this.props.close();
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'Corrupted data, please reload the page' }), "error");
                    }
                }
            },
            () => {
                if (isEdit) {
                    if (availabilityState === AvailabilityState.Close)
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the availability' }), "success");
                    else
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the unavailability' }), "success");
                }
                else {
                    if (availabilityState === AvailabilityState.Close)
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the availability' }), "success");
                    else
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the unavailability' }), "success");
                }
            }
        );
    };

    onFinish = (values: any) => {
        const availability: UserAvailabilityEdit = {
            id: this.state.availability.id,
            user: this.state.availability.user,
            isContinue: true,
            title: values.title,
            startDateRule: values.startDateRule?.clone(),
            endDateRule: values.endDateRule?.clone(),
            startTime: values.startDateRule?.clone(),
            endTime: values.endDateRule?.clone()

        };

        this.onNetworkSave(availability);
    };

    onSubmit = () => {
        const { availability } = this.state;

        this.props.changeValidateForm && this.props.changeValidateForm(false);

        if (availability?.isContinue) {
            this.formRef.current?.submit();
        } else {
            if (availability.rrule) {
                this.onNetworkSave(availability);
            } else {
                message.error(this.props.intl.formatMessage({ defaultMessage: 'Please fill in all required information' }));
            }
        }
    };

    onReset = () => {
        const { availability } = this.props;

        if (availability?.isContinue) {
            this.formRef.current?.resetFields();
        } else {
            this.setState({ ...this.getInitialState() });
        }


        this.props.changeResetForm && this.props.changeResetForm(false);
    };

    onChangeContinue = (val: AvailabilityTypes) => {
        const { availability } = this.state;

        if (val === AvailabilityTypes.Continue) {
            availability.isContinue = true;
        } else {
            availability.isContinue = false;
        }

        this.setState({ availability });
    };
    // #endregion

    // #region Continue code
    continueFormCheckEndDate = (_: any, value: any) => {
        return new Promise<void>((resolve, reject) => {
            const startDate: undefined | Moment = this.formRef.current?.getFieldValue('startDateRule');
            if (startDate === undefined || startDate === null || startDate.isSameOrBefore((value as Moment), "days")) {
                resolve();
            } else {
                reject(this.props.intl.formatMessage({ defaultMessage: 'End date is before the start date' }));
            }
        });
    };

    validateDate = (_: any, value: any) => {
        return new Promise<void>((resolve, reject) => {
            const isValidDate = moment(value, MOMENT_FORMAT_DISPLAY_DATETIME, true).isValid();
            if (isValidDate) {
                resolve();
            } else {
                reject(this.props.intl.formatMessage({ defaultMessage: 'Please select a valid date' }));
            }
        });
    };

    renderContinue = () => {
        const { availability, availabilityState, isSmartphone, theme, intl } = this.props;
        const { continueForm } = this.state;
        const CustomDateTimePicker = isSmartphone ? MobileDateTimePicker : DateTimePicker;

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);
        const startDateRule = availability?.startDateRule?.clone();
        const endDateRule = availability?.endDateRule?.clone();

        if (startDateRule && availability?.startTime) {
            startDateRule.set({ hours: availability.startTime.hours(), minutes: availability.startTime.minutes(), seconds: availability.startTime.seconds() });
        }
        if (endDateRule && availability?.endTime) {
            endDateRule.set({ hours: availability.endTime.hours(), minutes: availability.endTime.minutes(), seconds: availability.endTime.seconds() });
        }

        return (
            <>
                <Form
                    ref={this.formRef}
                    name="editAvailability"
                    className={`__form-with-animation`}
                    initialValues={{
                        title: availability?.title,
                        startDateRule: startDateRule,
                        endDateRule: endDateRule,
                    }}
                    layout="vertical"
                    onFinish={this.onFinish}
                    requiredMark="optional"
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Bloc title={<FormattedMessage defaultMessage={'Title'} />} textStyle={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color } : {}}>
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[
                                        { required: true, message: availabilityState === AvailabilityState.Close ? intl.formatMessage({ defaultMessage: 'Please enter the title of the availability' }) : intl.formatMessage({ defaultMessage: 'Please enter the title of the unavailability' }) },
                                        { type: 'string' },
                                        { min: ConstraintsFields.TitleMinLen, message: intl.formatMessage({ defaultMessage: 'The minimum length is {length}' }, { length: ConstraintsFields.TitleMinLen }) },
                                        { max: ConstraintsFields.TitleMaxLen, message: intl.formatMessage({ defaultMessage: 'The maximum length is {length}' }, { length: ConstraintsFields.TitleMaxLen }) },
                                        { whitespace: true, message: intl.formatMessage({ defaultMessage: 'There are only seized spaces' }) }
                                    ]}
                                >
                                    <Input showCount maxLength={ConstraintsFields.TitleMaxLen} />
                                </Form.Item>
                            </Bloc>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Bloc title={availabilityState === AvailabilityState.Close ? <FormattedMessage defaultMessage={'Start and end of the availability'} /> : <FormattedMessage defaultMessage={'Start and end of the unavailability'} />} textStyle={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color } : {}}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '5px' }}>
                                    <Form.Item
                                        hasFeedback
                                        name={"startDateRule"}
                                        rules={[
                                            { required: true, message: intl.formatMessage({ defaultMessage: 'Please enter the start date' }) },
                                            { validator: this.validateDate }
                                        ]}
                                        style={{ minWidth: '160px', width: '100%', flex: '1' }}
                                    >
                                        <CustomDateTimePicker
                                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                            label={null}
                                            format={getFormat('DATE_AND_TIME_SHORT')}
                                            onChange={() => {
                                                continueForm.endDateDisabled = false;
                                                this.setState({ continueForm });
                                            }}
                                            // placeholder={`${moment().format(MOMENT_FORMAT_DISPLAY_DATE)} 00:00`}
                                            sx={this.DateTimePickerStyle}
                                        />
                                    </Form.Item>
                                    {isSmartphone ? undefined : < div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '33px', width: '20px' }}>-</div>}
                                    <Form.Item
                                        dependencies={["startDateRule"]}
                                        hasFeedback
                                        name={"endDateRule"}
                                        rules={[
                                            { required: true, message: intl.formatMessage({ defaultMessage: 'Please enter the end date' }) },
                                            { validator: this.validateDate },
                                            { validator: this.continueFormCheckEndDate },
                                        ]}
                                        style={{ minWidth: '160px', width: '100%', flex: '1' }}
                                    >
                                        <CustomDateTimePicker
                                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                            disabled={continueForm.endDateDisabled}
                                            format={getFormat('DATE_AND_TIME_SHORT')}
                                            // placeholder={`${moment().add(1, "day").format(MOMENT_FORMAT_DISPLAY_DATE)} 00:00`}
                                            sx={this.DateTimePickerStyle}
                                        />
                                    </Form.Item>
                                </div>
                            </Bloc>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    };
    // #endregion

    // #region Recurring code


    getProposalsText = (val: ProposalsValues) => {
        const { availability } = this.state;
        const proposalFound = proposalsRules.find(p => p.id === val);
        if (proposalFound) {
            const weekday = weekDaysRRule.find(d => availability.startDateRule && ((d.weekday + 1) === availability.startDateRule.isoWeekday()))?.name;
            const monthday = availability.startDateRule?.format("D");
            return proposalFound.title(this.props.intl, weekday, monthday);
        } else {
            return '';
        }
    };

    onChangeTitle = (title: string) => {
        const { availability } = this.state;
        availability.title = title;
        this.setState({ availability });
    };

    onChangeDateOrTime = (val: Moment | null, field: DateOrTime) => {
        const { availability } = this.state;
        let rrule = availability.rrule;
        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }
        if (val === null) return;
        switch (field) {
            case DateOrTime.StartDateRule:
                availability.startDateRule = val?.clone();
                if (val.year() > 1970 && availability.endDateRule) {
                    rrule = new RRule({
                        ...rrule?.origOptions,
                        dtstart: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                        until: val ? new Date(availability.endDateRule.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                    });
                }
                break;
            case DateOrTime.EndDateRule:
                availability.endDateRule = val?.clone();
                if (val.year() > 1970 && availability.startDateRule) {
                    rrule = new RRule({
                        ...rrule?.origOptions,
                        dtstart: val ? new Date(availability.startDateRule.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                        until: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                    });
                }

                break;
            case DateOrTime.StartTime:
                availability.startTime = val?.clone();
                break;
            case DateOrTime.EndTime:
                availability.endTime = val?.clone();
                break;
            default:
                return;
        }

        availability.rrule = rrule;
        this.setState({ availability });
    };

    onChangeAllDay = (checked: boolean) => {
        const { availability } = this.state;
        if (checked) {
            availability.startTime = moment(AllDayTimes.start, MOMENT_FORMAT_DISPLAY_TIME);
            availability.endTime = moment(AllDayTimes.end, MOMENT_FORMAT_DISPLAY_TIME);
        }

        this.setState({ allday: checked, availability });
    };

    onChangeProposal = (val: ProposalsValues) => {
        const { availability } = this.state;
        let rrule = this.copyRRuleOnlyLimits(availability?.rrule);

        const proposalFound = proposalsRules.find(p => p.id === val);
        if (proposalFound) {
            rrule = new RRule({ ...proposalFound.rrule(rrule, availability.startDateRule).origOptions });
            availability.rrule = rrule;
            this.setState({ proposal: val, availability });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the proposal' }), "error");
            this.setState({ proposal: undefined });
        }
    };

    onChangeFrequency = (val: number | string) => {
        const { availability } = this.state;
        let rrule = this.copyRRuleOnlyLimits(this.state.availability?.rrule);

        val = toFinite(val);
        switch (val) {
            case RRule.DAILY:
                rrule = new RRule({
                    ...rrule.origOptions,
                    freq: toFinite(val),
                    interval: 1
                });
                break;
            case RRule.WEEKLY:
                rrule = new RRule({
                    ...rrule.origOptions,
                    freq: toFinite(val),
                    byweekday: [RRule.MO],
                    interval: 1
                });
                break;
            case RRule.MONTHLY:
            default:
                rrule = new RRule({
                    ...rrule.origOptions,
                    freq: toFinite(val),
                    bymonthday: 1,
                    interval: 1
                });
                break;
        }
        availability.rrule = rrule;

        this.setState({ availability, proposal: undefined });
    };

    onChangeWeekDay = (val: number | number[]) => {
        const { availability } = this.state;
        let rrule = availability?.rrule;
        let weekdays = rrule?.options.byweekday;

        if (isArray(val)) val = val[0];
        if (weekdays) {
            if (weekdays.includes(val)) {
                const filteredWeekdays = weekdays.filter(wd => wd !== val);
                weekdays = filteredWeekdays.length > 0 ? filteredWeekdays : weekdays;
            } else {
                weekdays = weekdays.concat(val);
            }
        } else {
            weekdays = [val];
        }

        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        rrule = new RRule({
            ...rrule?.origOptions,
            byweekday: weekdays
        });
        availability.rrule = rrule;

        this.setState({ availability, proposal: undefined });
    };

    onChangeMonthType = (isDate: boolean) => {
        const { availability } = this.state;
        let rrule = availability?.rrule;
        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        if (isDate) {
            rrule = new RRule({
                ...rrule?.origOptions,
                bymonthday: 1,
                byweekday: undefined,
                bysetpos: undefined

            });
        } else {
            rrule = new RRule({
                ...rrule?.origOptions,
                bysetpos: 1,
                bymonthday: undefined,
                byweekday: [RRule.MO]
            });
        }

        availability.rrule = rrule;
        this.setState({ availability });
    };

    onChangeMonthDay = (monthDay: number | null) => {
        const { availability } = this.state;
        let rrule = availability?.rrule;

        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        rrule = new RRule({
            ...rrule?.origOptions,
            bymonthday: monthDay
        });

        availability.rrule = rrule;
        this.setState({ availability });

    };

    onChangeSetPos = (val: number | number[]) => {
        const { availability } = this.state;
        let rrule = availability?.rrule;

        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        if (isArray(val)) val = val[0];

        const monthlyOptions = monthlySetPosOptions.find(m => m.bysetpos === val);
        if (monthlyOptions) {
            rrule = new RRule({
                ...rrule?.origOptions,
                bysetpos: monthlyOptions.bysetpos
            });


            availability.rrule = rrule;
            this.setState({ availability });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Invalid option' }), "error");
        }
    };

    onIncreaseInterval = () => {
        const { availability } = this.state;
        let rrule = availability?.rrule;

        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        rrule = new RRule({
            ...rrule?.origOptions,
            interval: rrule ? ++rrule.options.interval : 1
        });

        availability.rrule = rrule;
        this.setState({ availability });
    };

    onDecreaseInterval = () => {
        const { availability } = this.state;
        let rrule = availability?.rrule;

        if (!rrule) {
            rrule = new RRule({
                freq: RRule.DAILY
            });
        }

        rrule = new RRule({
            ...rrule?.origOptions,
            interval: rrule ? --rrule.options.interval : 1
        });

        availability.rrule = rrule;
        this.setState({ availability });
    };

    renderRecurringDaily = () => {
        const { theme, intl } = this.props;
        const { availability } = this.state;
        const rrule = availability?.rrule;

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);

        return (
            <Col xs={{ span: 24 }} md={{ span: 24 }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CircleButton
                        style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                        small
                        disabled={rrule && rrule.options.interval <= 1}
                        icon={<FAIcon prefix='far' name='minus' />}
                        title={intl.formatMessage({ defaultMessage: 'Reduce the number of days' })}
                        placement="top"
                        onClick={this.onDecreaseInterval}
                    />
                    <p style={{ flex: '1', textAlign: 'center' }}>
                        {
                            !rrule || rrule.options.interval === 1 ?
                                <span><FormattedMessage defaultMessage={'Repeats every day'} /></span>
                                :
                                <span><FormattedMessage defaultMessage={'Repeat every {count} days'} values={{ count: rrule.options.interval }} /></span>
                        }
                    </p>
                    <CircleButton
                        style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                        small
                        icon={<FAIcon prefix='far' name='plus' />}
                        title={intl.formatMessage({ defaultMessage: 'Increase the number of days' })}
                        placement="top"
                        onClick={this.onIncreaseInterval}
                    />
                </div>
            </Col>
        );
    };

    renderRecurringWeekly = () => {
        const { isSmartphone, theme, intl } = this.props;
        const { availability } = this.state;
        const rrule = availability?.rrule;

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);

        return (
            <>
                <Col xs={{ span: 24 }} md={{ span: 24 }} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CircleButton
                            style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                            small
                            disabled={rrule && rrule.options.interval <= 1}
                            icon={<FAIcon prefix='far' name='minus' />}
                            title={intl.formatMessage({ defaultMessage: 'Reduce the number of weeks' })}
                            placement="top"
                            onClick={this.onDecreaseInterval}
                        />
                        <p style={{ flex: '1', textAlign: 'center' }}>
                            {
                                !rrule || rrule.options.interval === 1 ?
                                    <span><FormattedMessage defaultMessage={'Repeat every week'} /></span>
                                    :
                                    <span><FormattedMessage defaultMessage={'Repeats every {count} weeks'} values={{ count: rrule.options.interval }} /></span>
                            }
                        </p>
                        <CircleButton
                            style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                            small
                            icon={<FAIcon prefix='far' name='plus' />}
                            title={intl.formatMessage({ defaultMessage: 'Increase the number of weeks' })}
                            placement="top"
                            onClick={this.onIncreaseInterval}
                        />
                    </div>
                </Col>
                <Col xs={{ span: 24 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {
                            weekDaysRRule.map((day) => {
                                return (
                                    <CircleButton
                                        className="__weekdays-buttons"
                                        style={rrule?.options.byweekday.includes(day.weekday) ? { color: theme && colorIsBright(theme.button) ? 'white' : 'dark', backgroundColor: theme?.button } : {}}
                                        key={`weekdays-${day.value}`}
                                        value={day.value}
                                        type={rrule?.options.byweekday.includes(day.weekday) ? 'primary' : 'default'}
                                        onClick={() => {
                                            this.onChangeWeekDay(day.weekday);
                                        }}
                                        icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{intl.formatMessage(day.name).substring(0, isSmartphone ? 1 : 2)}</div>}
                                        title={intl.formatMessage(day.name)}
                                    />
                                );
                            })
                        }
                    </div>
                </Col>
            </>
        );
    };

    renderRecurringMonthly = () => {
        const { availability } = this.state;
        const { theme, intl } = this.props;
        const rrule = availability?.rrule;
        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);

        return (
            <>
                <Col xs={{ span: 24 }} md={{ span: 24 }} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CircleButton
                            style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                            small
                            disabled={rrule && rrule.options.interval <= 1}
                            icon={<FAIcon prefix='far' name='minus' />}
                            title={intl.formatMessage({ defaultMessage: 'Reduce the number of months' })}
                            placement="top"
                            onClick={this.onDecreaseInterval}
                        />
                        <p style={{ flex: '1', textAlign: 'center' }}>
                            {
                                !rrule || rrule.options.interval === 1 ?
                                    <span><FormattedMessage defaultMessage={'Repeats every month'} /></span>
                                    :
                                    <span><FormattedMessage defaultMessage={'Repeat every {count} months'} values={{ count: rrule.options.interval }} /></span>
                            }
                        </p>
                        <CircleButton
                            style={isDarkMode ? { backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color } : {}}
                            small
                            icon={<FAIcon prefix='far' name='plus' />}
                            title={intl.formatMessage({ defaultMessage: 'Increase the number of months' })}
                            placement="top"
                            onClick={this.onIncreaseInterval}
                        />
                    </div>
                </Col>
                <Col xs={{ span: 24 }}>
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '10px', borderRadius: 'var(--border-radius)' }}>
                        <Radio.Group
                            value={rrule && rrule.options.bymonthday.length > 0}
                            onChange={(val) => this.onChangeMonthType(val.target.value)}>
                            <Space direction="vertical">
                                <Space>
                                    <div>
                                        <Radio value={true}><FormattedMessage defaultMessage={'Day n°'} /></Radio>
                                    </div>
                                    <div>
                                        <InputNumber
                                            disabled={rrule && rrule.options.bymonthday.length === 0}
                                            value={rrule && rrule.options.bymonthday[0]}
                                            onChange={this.onChangeMonthDay}
                                            min={1}
                                            max={31}
                                            style={{ width: '60px', minWidth: '60px' }}
                                        />
                                    </div>
                                </Space>
                                <Space>
                                    <Radio value={false}><FormattedMessage defaultMessage={'On the'} /></Radio>
                                    <Select
                                        disabled={rrule && rrule.options.bymonthday.length > 0}
                                        value={rrule?.options.bysetpos}
                                        onChange={this.onChangeSetPos}
                                        style={{ width: '110px' }}
                                    >
                                        {monthlySetPosOptions.map(o => <Select.Option key={`monthly-set-pos-${o.name}`} value={o.bysetpos}>{intl.formatMessage(o.name).toLocaleLowerCase()}</Select.Option>)}
                                    </Select>
                                    <Select
                                        disabled={rrule && rrule.options.bymonthday.length > 0}
                                        value={rrule?.options.byweekday}
                                        onChange={this.onChangeWeekDay}
                                        style={{ width: '100px' }}
                                    >
                                        {weekDaysRRule.map(p => <Select.Option key={`weekday-${p.value}`} value={p.weekday}>{intl.formatMessage(p.name).toLocaleLowerCase()}</Select.Option>)}
                                    </Select>
                                </Space>
                            </Space>
                        </Radio.Group>
                    </div>
                </Col>
            </>
        );

    };

    renderRecurringYearly = () => {
        return <p></p>;
    };

    renderRecurring = () => {
        const { availability, proposal, allday } = this.state;
        const { isSmartphone, theme } = this.props;
        const rrule = availability?.rrule;
        const CustomTimePicker = isSmartphone ? MobileTimePicker : TimePicker;
        const CustomDatePicker = isSmartphone ? MobileDatePicker : DesktopDatePicker;

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);

        const endDateBeforeStartDate = availability.endDateRule && availability.startDateRule && availability.endDateRule.isBefore(availability.startDateRule);
        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Bloc title={<FormattedMessage defaultMessage={'Title'} />} textStyle={isDarkMode ? { backgroundColor: theme?.backgroundColor, color: theme?.color } : {}}>
                                <Input
                                    showCount
                                    value={availability.title}
                                    onChange={e => this.onChangeTitle(e.target.value)}
                                    maxLength={ConstraintsFields.TitleMaxLen} />
                            </Bloc>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Bloc title={<FormattedMessage defaultMessage={'Active period between'} />} textStyle={isDarkMode ? { backgroundColor: theme?.backgroundColor, color: theme?.color } : {}}>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 20px 1fr' }}>
                                    <div>
                                        <CustomDatePicker
                                            label={null}
                                            // placeholder="Date de début"
                                            value={availability?.startDateRule}
                                            onChange={val => this.onChangeDateOrTime(val, DateOrTime.StartDateRule)}
                                            format={getFormat('DATE')}
                                            sx={this.DatePickerStyle}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>-</div>
                                    <div>
                                        <CustomDatePicker
                                            label={null}
                                            // placeholder="Date de fin"
                                            value={availability?.endDateRule}
                                            onChange={val => this.onChangeDateOrTime(val, DateOrTime.EndDateRule)}
                                            format={getFormat('DATE')}
                                            sx={this.DatePickerStyle}
                                        />
                                    </div>
                                </div>
                                {
                                    endDateBeforeStartDate &&
                                    <Col xs={{ span: 24 }} style={{ marginTop: 10 }}>
                                        <Alert type="error" showIcon
                                            style={{ fontSize: "80%", fontStyle: 'italic' }}
                                            message={<FormattedMessage defaultMessage={'The start date is later than the end date'} />} />
                                    </Col>
                                }
                            </Bloc>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Bloc title={<FormattedMessage defaultMessage={'Start and end per active day'} />} textStyle={isDarkMode ? { backgroundColor: theme?.backgroundColor, color: theme?.color } : {}}>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 20px 1fr' }}>
                                    <div style={{ display: 'flex' }}>
                                        <CustomTimePicker
                                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                            label={null}
                                            // placeholder="Heure de début"
                                            disabled={allday}
                                            value={availability?.startTime}
                                            onChange={val => this.onChangeDateOrTime(val, DateOrTime.StartTime)}
                                            format={getFormat('TIME_SHORT')}
                                            views={['hours', 'minutes']}
                                            sx={this.TimePickerStyle}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>-</div>
                                    <div style={{ display: 'flex' }}>
                                        <CustomTimePicker
                                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                            label={null}
                                            // placeholder="Heure de fin"
                                            disabled={allday}
                                            value={availability?.endTime}
                                            onChange={val => this.onChangeDateOrTime(val, DateOrTime.EndTime)}
                                            format={getFormat('TIME_SHORT')}
                                            views={['hours', 'minutes']}
                                            sx={this.TimePickerStyle}
                                        />
                                    </div>
                                </div>
                                <Checkbox
                                    checked={allday}
                                    onChange={e => this.onChangeAllDay(e.target.checked)}>
                                    <FormattedMessage defaultMessage={'All day'} />
                                </Checkbox>
                            </Bloc>
                        </Col>
                    </Row>
                </Col>
                < Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Bloc title={<FormattedMessage defaultMessage={'Period repeat'} />} textStyle={isDarkMode ? { backgroundColor: theme?.backgroundColor, color: theme?.color } : {}}>
                        <Row gutter={[10, 10]}>
                            {
                                availability && availability.startDateRule && availability.endDateRule && availability.startTime && availability.endTime ?
                                    <>
                                        <Col xs={{ span: 24 }} style={{ paddingTop: '5px', display: 'flex', gap: '5px' }}>
                                            <Select
                                                style={{ flex: '1', overflow: 'hidden' }}
                                                placeholder={<FormattedMessage defaultMessage={'Load from a proposal'} />}
                                                value={proposal}
                                                onChange={this.onChangeProposal}
                                            >
                                                {Object.values(ProposalsValues).map(p => <Select.Option key={`proposal-value-${p}`} value={p}>{this.getProposalsText(p)}</Select.Option>)}
                                            </Select>
                                        </Col>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <Col xs={{ span: 24 }}>
                                            <Row gutter={[20, 20]}>
                                                <Col xs={{ span: 24 }} md={{ span: 24 }} >
                                                    <Segmented block
                                                        style={isDarkMode ? { color: theme?.color, backgroundColor: theme?.backgroundAccent } : {}}
                                                        options={[
                                                            {
                                                                label: <FormattedMessage defaultMessage={'Day'} />,
                                                                value: RRule.DAILY.toString(),
                                                            },
                                                            {
                                                                label: <FormattedMessage defaultMessage={'Week'} />,
                                                                value: RRule.WEEKLY.toString(),
                                                            },
                                                            {
                                                                label: <FormattedMessage defaultMessage={'Month'} />,
                                                                value: RRule.MONTHLY.toString(),

                                                            }
                                                        ]}
                                                        value={rrule?.options.freq.toString()}
                                                        onChange={(val) => this.onChangeFrequency(val.valueOf())} />
                                                </Col>
                                                <Col xs={{ span: 24 }}>
                                                    <Row gutter={[20, 20]}>
                                                        {
                                                            rrule?.options.freq === RRule.DAILY ?
                                                                this.renderRecurringDaily()
                                                                : rrule?.options.freq === RRule.WEEKLY ?
                                                                    this.renderRecurringWeekly()
                                                                    : rrule?.options.freq === RRule.MONTHLY ?
                                                                        this.renderRecurringMonthly()
                                                                        : rrule?.options.freq === RRule.YEARLY ?
                                                                            this.renderRecurringYearly()
                                                                            : null
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                    :
                                    <Col xs={{ span: 24 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', padding: '30px 0' }}>
                                            <p><FormattedMessage defaultMessage={'Please enter the initial configuration...'} /></p>
                                        </div>
                                    </Col>
                            }
                        </Row>
                    </Bloc>
                </Col>

                < Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Alert message={
                        rrule?.options.freq ?
                            availabilityRruleToString(this.props.intl, availability, allday)
                            :
                            <FormattedMessage defaultMessage={'Waiting for repeat configuration...'} />
                    } type="info" showIcon />
                    {/* <Alert style={{ marginTop: '10px' }} message={rrule?.toString()} type="info" showIcon /> */}
                </Col>
            </Row >
        );
    };
    // #endregion

    render() {
        const { availability } = this.state;
        const { theme } = this.props;
        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);
        const themePicker = createTheme({
            palette: {
                mode: isDarkMode ? 'dark' : 'light',
            },
        });
        return (
            <Row gutter={[20, 30]}>
                <Col xs={{ span: 24 }}>
                    <Segmented block
                        style={isDarkMode ? { color: theme?.color, backgroundColor: theme?.backgroundAccent } : {}}
                        options={
                            [{
                                label: <FormattedMessage defaultMessage={'Continue'} description={'Period'} />,
                                value: AvailabilityTypes.Continue,
                                icon: <FAIcon prefix='fad' name='calendar-days' />
                            }, {
                                label: <FormattedMessage defaultMessage={'Periodic'} />,
                                value: AvailabilityTypes.Periodic,
                                icon: <FAIcon prefix='fad' name='calendar-range' />

                            }]}
                        value={availability.isContinue ? AvailabilityTypes.Continue : AvailabilityTypes.Periodic}
                        onChange={(val) => this.onChangeContinue(val.toString() as AvailabilityTypes)} />
                </Col>
                <Col xs={{ span: 24 }}>
                    <ThemeProvider theme={themePicker}>
                        <CssBaseline />
                        <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            {
                                availability.isContinue ?
                                    this.renderContinue()
                                    :
                                    this.renderRecurring()
                            }
                        </LocalizationProvider>
                    </ThemeProvider>
                </Col>
            </Row>
        );
    }
}

// #region Redux connect
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUserAvailabilities: (userId: number, userAvailabilities: UserAvailability[]) => dispatch(changeUserAvailabilities(userId, userAvailabilities)),
});
const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    teamAvailabilities: state.planning.teamAvailabilities
});
// #endregion

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AvailabilityEditForm));