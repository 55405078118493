import { Divider, InputRef, List, message, Modal, Popover, Tooltip } from "antd";
import { cloneDeep } from "lodash";
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import isEqual from "react-fast-compare";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeSettings } from "../../../store/actions/planning";
import Network from "../../../utils/network";
import { NetworkSettings } from "../../../utils/types/networkTypes";
import { PlanningColor } from "../../../utils/types/planningTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { convertNetworkSettingsToPlanningSettings, convertPlanningSettingsToNetworkSettings, findSimilarColors, isNullOrEmpty, searchIgnoreCaseAndAccentInsensitive, showNotification } from "../../../utils/utils";
import FAIcon from "../FAIcon";
import InputField from "./inputField";

const colorStyle: CSSProperties = {
    width: '60px',
    height: '23px',
    borderRadius: 'var(--border-radius)',
};

const swatchStyle: CSSProperties = {
    padding: '5px',
    background: '#fff',
    borderRadius: 'var(--border-radius)',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
};


const popoverStyle2: CSSProperties = {
    position: 'absolute',
    zIndex: '2',
    top: '35px',
    left: '215px'
};

const coverStyle: CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
};

interface Props {
    activeColor?: PlanningColor;
    colors?: PlanningColor[];
    onChangeColor?(color?: PlanningColor): void;
    children?: ReactNode;

}

export const ColorChooser = (props: Props) => {

    const [displayColorSearchPicker, setDisplayColorSearchPicker] = useState<boolean>(false);
    const [displayNewColorName, setDisplayNewColorName] = useState<boolean>(false);
    const [colorSearch, setColorSearch] = useState<string | undefined>(undefined);
    const [titleSearch, setTitleSearch] = useState<string | undefined>(undefined);
    const [newColor, setNewColor] = useState<PlanningColor | undefined>(undefined);
    const settings = useSelector((state: ApplicationState) => state.planning.settings);
    const windowWidth = useSelector((state: ApplicationState) => state.window.width);
    const createInputRef = useRef<InputRef>(null);
    const dispatch = useDispatch();
    const { onChangeColor } = props;
    const intl = useIntl();

    const getFilteredColors = useCallback((searchTitle?: string, searchColor?: string) => {

        let filteredColors = cloneDeep(props.colors);
        if (isNullOrEmpty(filteredColors)) {
            return undefined;
        }

        if (searchTitle && searchTitle.length > 0) {
            filteredColors = filteredColors?.filter(color => searchIgnoreCaseAndAccentInsensitive(color.title, searchTitle));
        }

        if (searchColor && searchColor.length > 0) {
            const listOfSimilarColorsIdx: number[] = findSimilarColors(searchColor, filteredColors.map(color => color.color), 80, true) as number[];
            filteredColors = listOfSimilarColorsIdx.map(c => {
                return filteredColors![c];
            });
        }

        if (searchColor && searchColor.length > 0 && filteredColors.find(c => c.color === searchColor) === undefined) {
            let newNewColor = newColor;
            if (newNewColor === undefined || newNewColor.color !== searchColor) {
                newNewColor = {
                    id: -1,
                    color: searchColor,
                    title: intl.formatMessage({ defaultMessage: 'Create color' }),
                    notSetYet: true
                };
                setNewColor(newNewColor);
            }
            filteredColors.push(newNewColor);

        }
        filteredColors.unshift({
            id: undefined,
            color: 'var(--primary-color)',
            title: intl.formatMessage({ defaultMessage: 'Default color' }),
        });
        return filteredColors;

    }, [props.colors, intl, newColor]);

    const saveColor = useCallback(() => {
        const editColor = newColor;
        const newSettings = cloneDeep(settings);

        if (newSettings !== undefined && editColor !== undefined && editColor.title !== undefined && editColor.title !== '' && editColor.color !== undefined && editColor.color !== '') {

            if (editColor.id !== undefined && editColor.id !== -1 && newSettings.colors) {
                newSettings.colors = newSettings.colors?.filter(c => c.id !== editColor.id);
            }
            if (newSettings.colors === undefined) {
                newSettings.colors = [editColor];
            } else {
                newSettings.colors.push(editColor);
            }
            const networkSettings: NetworkSettings = convertPlanningSettingsToNetworkSettings(newSettings);

            // update settings
            Network.updateSettings(networkSettings).then(
                response => {
                    const newSettings = convertNetworkSettingsToPlanningSettings(response);
                    !isEqual(settings, newSettings) && dispatch(changeSettings(newSettings));
                    message.success(intl.formatMessage({ defaultMessage: "The planning colors successfully updated" }));
                    if (newSettings && newSettings.colors && newColor) {
                        const selectedColor = newSettings.colors.find(c => c.color === newColor.color);
                        onChangeColor && onChangeColor(selectedColor);
                        setNewColor(undefined);
                    }

                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating colors' }), "warning")
            );
        } else {
            message.warning(intl.formatMessage({ defaultMessage: 'Please fill in all fields' }));
        }
    }, [newColor, settings, dispatch, intl, onChangeColor]);

    const openCreateColorModal = useCallback(() => {
        setDisplayNewColorName(true);
        createInputRef.current?.focus();
    }, []);
    useEffect(() => {
        if (displayNewColorName)
            createInputRef.current?.focus();
    }, [displayNewColorName]);

    const handleKeyUp = useCallback((event) => {
        // Enter
        if (event.keyCode === 13) {
            saveColor();
        }
    }, [saveColor]);

    return (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <span
                style={{
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    width: 200,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}>{props.activeColor?.title}</span>
            <Popover
                arrowPointAtCenter
                key={`color-popover-primary`}
                title={<FormattedMessage defaultMessage={'Select a color'} />}
                trigger={'click'}
                placement='topRight'
                overlayInnerStyle={{ width: 290, height: 330 }}
                destroyTooltipOnHide
                content={
                    <>
                        <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                            <InputField
                                autoFocus
                                style={{ flex: 1 }}
                                placeholder={intl.formatMessage({ defaultMessage: 'Search' })}
                                value={titleSearch}
                                onChange={e => setTitleSearch(e.target.value)} />
                            {windowWidth > 850 && <div style={swatchStyle} onClick={() => setDisplayColorSearchPicker(true)}>
                                {
                                    colorSearch ?
                                        <div style={{ ...colorStyle, background: `${colorSearch}` }} />
                                        :
                                        <div title={intl.formatMessage({ defaultMessage: 'Search for a color' })} style={{ ...colorStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <FAIcon prefix='fad' name='eye-dropper-half' />
                                        </div>
                                }
                            </div>}
                        </div>

                        <div style={displayColorSearchPicker ? popoverStyle2 : { display: 'none' }}>
                            <div
                                style={coverStyle}
                                onClick={() => {
                                    setDisplayColorSearchPicker(false);
                                }
                                } />
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '227px',
                                padding: '6px 12px',
                                border: 'var(--border)',
                                borderBottom: 'none',
                                borderTopLeftRadius: 'var(--border-radius)',
                                borderTopRightRadius: 'var(--border-radius)',
                                backgroundColor: 'white',
                                zIndex: '999'
                            }}>
                                <p style={{
                                    zIndex: '999'
                                }}><FormattedMessage defaultMessage={'Search for a color'} /></p>
                                <div
                                    title={intl.formatMessage({ defaultMessage: 'Delete' })}
                                    style={{ cursor: 'pointer', zIndex: '999' }}
                                    onClick={() => setColorSearch(undefined)}
                                >
                                    <FAIcon prefix='fas' name='xmark' />
                                </div>
                            </div>
                            <ChromePicker
                                disableAlpha={true}
                                color={colorSearch}
                                onChange={(c: ColorResult) => setColorSearch(c.hex)}
                            />
                        </div>
                        <Divider style={{ margin: '12px 0' }} />
                        <List
                            className='colors-list'
                            style={{ marginTop: 8, width: '100%', height: '200px', overflowY: 'scroll' }}
                            itemLayout="horizontal"
                            dataSource={getFilteredColors(titleSearch, colorSearch)}
                            renderItem={(color) => (
                                <List.Item
                                    className={`colors-list-item ${(props.activeColor && color.color === props.activeColor.color || color.color === colorSearch) ? 'colors-list-item-active' : ''}`}
                                    onClick={() => (color.id && color.id < 0) ? openCreateColorModal() : props.onChangeColor && props.onChangeColor(color)}>
                                    <List.Item.Meta
                                        avatar={<div style={{ cursor: 'initial', marginRight: '5px', height: '20px', width: '20px', borderRadius: '5px', backgroundColor: color?.color ? color?.color : 'var(--primary-color)' }} />}
                                        description={<Tooltip mouseEnterDelay={0.4} placement="right" title={color.title}><span style={{
                                            display: 'inline-block',
                                            textOverflow: 'ellipsis',
                                            width: 140,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            color: 'black'
                                        }}>{color.title}</span></Tooltip>}
                                    />
                                </List.Item>
                            )}
                        />
                        {props.children}
                    </>
                }
            >
                <div>

                    <div
                        style={swatchStyle}
                    >
                        <div style={{ ...colorStyle, background: `${props.activeColor?.color || 'var(--primary-color)'}` }} />
                    </div>
                </div>

            </Popover>
            <Modal
                title={<FormattedMessage defaultMessage={'Create color'} />}
                closable={false}
                onCancel={() => setDisplayNewColorName(false)}
                width={'300px'}
                onOk={() => saveColor()}
                open={displayNewColorName && newColor !== undefined} style={{ zIndex: 1000 }}>
                <InputField
                    inputRef={createInputRef}
                    onKeyUp={handleKeyUp}
                    autoFocus
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    value={newColor && !newColor.notSetYet ? newColor.title : ''}
                    onChange={(e) => {
                        const newNewColor = cloneDeep(newColor);
                        if (newNewColor) {
                            newNewColor.title = e.target.value;
                            newNewColor.notSetYet = false;
                            setNewColor(newNewColor);
                        }
                    }}
                />
            </Modal>
        </div>
    );
};
export default ColorChooser;