import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { injectIntl } from "react-intl";
import getFormat from "../../../utils/Lang";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../FAIcon";
import CircleButton from "./circleButton";

interface CustomDatePickerProps extends IntlProps {
    onChange: (value: Moment | null) => void;
    date: Moment;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    isSmartphone?: boolean;
}

interface CustomDatePickerState { }

/**
 * Component that represent the vacations tab for the report page
 */
class DatePickerSelect extends React.Component<CustomDatePickerProps, CustomDatePickerState> {
    render() {
        const { onChange, date, disabled, loading, className, isSmartphone, intl } = this.props;
        return (
            <Space>
                <CircleButton
                    small
                    icon={<FAIcon prefix='far' name='chevron-left' />}
                    title={intl.formatMessage({ defaultMessage: 'Previous year' })}
                    onClick={() => onChange(date.clone().add(-1, "year"))}
                    loading={loading} />
                <div key={`custom-date-picker-div-${date.format('YYYYMMDD')}`} className={className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
                    <DatePicker.YearPicker
                        key={`month-picker-${date.format("MM")}`}
                        suffixIcon={null}
                        className={`__monthly-planning-month-picker ${className}-picker`}
                        value={date}
                        onChange={onChange}
                        placement={'bottomLeft'}
                        popupClassName={`${className}-picker-popup`}
                        size='large'
                        bordered={false}
                        inputReadOnly
                        format={getFormat('YEAR')}
                        disabled={disabled}
                        allowClear={false}
                    />
                </div>
                <CircleButton
                    small
                    icon={<FAIcon prefix='far' name='chevron-right' />}
                    title={intl.formatMessage({ defaultMessage: 'Next year' })}
                    onClick={() => onChange(date.clone().add(1, "year"))}
                    loading={loading}
                />
                <CircleButton
                    small
                    icon={<FAIcon prefix='fad' name='house' />}
                    title={intl.formatMessage({ defaultMessage: 'Today' })}
                    onClick={() => onChange(moment())}
                    disabled={loading}
                />
            </Space>
        );
    }
}


export default injectIntl(DatePickerSelect);