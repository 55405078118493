import { Col, Collapse, DatePicker, Divider, Empty, Row, Select, Spin, Tree } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { DataNode } from "antd/lib/tree";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { Key, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Rules } from "../../../rbacRules";
import { BLUE_COLOR, GREEN_COLOR, MOMENT_MONTH_FORMAT, MOMENT_SHORT_TIME_FORMAT, RED_COLOR } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { IMissionStatus } from "../../../utils/types/customerTypes";
import { DictionaryNumber, DictionaryString, PlanningUserPerf } from "../../../utils/types/generalTypes";
import { NetworkMonthlyPlanningRow, NetworkMonthlyPlanningRowPerfAddons } from "../../../utils/types/networkTypes";
import { MonthlyPlanningByUserByDay } from "../../../utils/types/planningTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { colorIsBright, disabledDate, showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Can from "../../common/general/can";
import useCustomerManagement from "../../hooks/useCustomerManagement";

interface Props {
    startDate: Moment;
    endDate: Moment;
    refresh?: boolean;
    endRefresh?: () => void;
    newEvent?: (ruleId: number, date: Moment, templateId?: number) => void;
    showEvent?: (eventId: number) => void;
    eventsV2?: DictionaryNumber<PlanningUserPerf>;
    events?: MonthlyPlanningByUserByDay[];
    mode?: 'weekly' | 'monthly';
}

type MissionControlStatus = 'satisfied' | 'error' | 'tooMuch';

const MissionsControlV2 = (props: Props) => {

    const { startDate, endDate, newEvent, showEvent, eventsV2, events, mode } = props;

    const customerManagement = useCustomerManagement();
    const users = useSelector((state: ApplicationState) => state.teamManagement.users);
    const pois = useSelector((state: ApplicationState) => state.location.pois?.data);
    const colors = useSelector((state: ApplicationState) => state.planning.settings.colors);

    const oldStartDate = useRef(moment());
    const oldEndDate = useRef(moment());

    const intl = useIntl();

    const [loading, setLoading] = useState(false);
    const [missionsStatus, setMissionsStatus] = useState<{ loaded: boolean, data: IMissionStatus[]; }>({ loaded: false, data: [] });
    const [organizedMissions, setOrganizedMissions] = useState<DictionaryString<DictionaryString<DictionaryString<IMissionStatus[]>>>>({});

    const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);
    const [selectedMandates, setSelectedMandates] = useState<number[]>([]);
    const [selectedPois, setSelectedPois] = useState<number[]>([]);
    const [selectedDates, setSelectedDates] = useState<[string, string]>([startDate.clone().format(MOMENT_MONTH_FORMAT), endDate.clone().endOf(mode === 'monthly' ? 'month' : 'week').format(MOMENT_MONTH_FORMAT)]);
    const [selectedMissionStatus, setSelectedMissionStatus] = useState<MissionControlStatus[]>([]);
    const [selectedColors, setSelectedColors] = useState<number[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

    const processedEventsV2 = useMemo(() => eventsV2 ? Object.keys(eventsV2).map((i: string) => eventsV2[parseInt(i)]) : [], [eventsV2]);

    const generateMissions = useCallback(() => {
        const missions = missionsStatus.data;
        const newFilteredMissions: DictionaryString<DictionaryString<DictionaryString<IMissionStatus[]>>> = {};

        missions.forEach(mission => {
            const tmpStartDate = moment(mission.startDate).format(MOMENT_MONTH_FORMAT);
            if (!newFilteredMissions[tmpStartDate])
                newFilteredMissions[tmpStartDate] = {};

            if (!newFilteredMissions[tmpStartDate][mission.customerId])
                newFilteredMissions[tmpStartDate][mission.customerId] = {};

            if (!newFilteredMissions[tmpStartDate][mission.customerId][mission.mandateId])
                newFilteredMissions[tmpStartDate][mission.customerId][mission.mandateId] = [];
            newFilteredMissions[tmpStartDate][mission.customerId][mission.mandateId].push(mission);
        });

        setOrganizedMissions(newFilteredMissions);
    }, [missionsStatus]);

    const getMissionsStatus = useCallback((force?: boolean, clearKeys = true) => {
        if ((oldEndDate.current && !oldEndDate.current.isSame(endDate, 'date') || !oldStartDate.current.isSame(startDate, 'date')) || force) {

            oldEndDate.current = endDate;
            oldStartDate.current = startDate;

            if (clearKeys)
                setExpandedKeys([]);

            setLoading(true);
            setMissionsStatus({ loaded: false, data: [] });
            setOrganizedMissions({});

            Network.getMissionStatus(startDate, endDate).then(
                response => {
                    setMissionsStatus({ loaded: true, data: response.data });
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the missions states' }), "error");
                }
            ).finally(() => {
                setLoading(false);
                props.endRefresh?.();
            });
        }
    }, [endDate, intl, props, startDate]);

    const generateMissionDetails = useCallback((mission: IMissionStatus) => {
        const remainingSeconds = mission.remainingTimeToDo;
        const remainingHumans = mission.remainingHumanToHave;

        return (

            <div>
                <div className='mission-control-indicator-line'>
                    {
                        remainingSeconds === 0 ?
                            <FAIcon name='circle-check' prefix='fad' color={GREEN_COLOR} />
                            :
                            remainingSeconds < 0 ?
                                <FAIcon name='circle-check' prefix='fad' color={BLUE_COLOR} />
                                :
                                <FAIcon name='circle-xmark' prefix='fad' color={RED_COLOR} />

                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
                        <span><FormattedMessage defaultMessage={'Hours'} />:</span>
                        <span>{`${moment.duration(mission.totalizedEventsTotalDuration, 'seconds').format(MOMENT_SHORT_TIME_FORMAT)} / ${moment.duration(mission.timeToDo, 'seconds').format(MOMENT_SHORT_TIME_FORMAT)}`}</span>
                    </div>
                </div>
                {
                    mission.humanToHave > 0 &&
                    <div className='mission-control-indicator-line'>
                        {
                            remainingHumans === 0 ?
                                <FAIcon name='circle-check' prefix='fad' color={GREEN_COLOR} />
                                :
                                remainingHumans < 0 ?
                                    <FAIcon name='circle-check' prefix='fad' color={BLUE_COLOR} />
                                    :
                                    <FAIcon name='circle-xmark' prefix='fad' color={RED_COLOR} />

                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
                            <span><FormattedMessage defaultMessage={'Users'} />:</span>
                            <span>{`${mission.totalizedEventsTotalHumans} / ${mission.humanToHave}`}</span>
                        </div>
                    </div>
                }
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                    {
                        mission.eventUserIds.map((user_id) => {
                            const user = users.find((u) => u.id === user_id);
                            if (!user)
                                return;

                            let tmpEvents: NetworkMonthlyPlanningRowPerfAddons[] | NetworkMonthlyPlanningRow[] = [];
                            if (processedEventsV2.length > 0) {
                                const userEvents = processedEventsV2.find(userEvent => userEvent.id === user.id);
                                tmpEvents = userEvents ? Object.values(userEvents.days).map(day => day.events).flat() : [];
                            }
                            else if (events) {
                                const userEvents = events.find(userEvent => userEvent.userId === user.id);
                                tmpEvents = userEvents?.allEvents ? userEvents.allEvents : [];
                            }

                            return tmpEvents.filter(event => mission.eventsIds.includes(event.id) && event.userId == user_id && moment(event.startDate).isSame(moment(mission.startDate), 'date')).map(event => {
                                const eventColor = event.color || event.colorHex || 'var(--primary-color)';
                                return (
                                    <div key={`event-${event.id}`} className='mission-control-user-event' style={{ background: eventColor, color: colorIsBright(eventColor) ? 'var(--dark-color)' : 'white' }} onClick={() => showEvent && showEvent(event.id)}>
                                        {user?.first_name} {user?.last_name}
                                        <div style={{ fontSize: 12 }}>{moment.utc(event.startDate).format(getFormat('TIME_SHORT'))} {'-'} {moment.utc(event.endDate).format(getFormat('TIME_SHORT'))}</div>
                                    </div>
                                );
                            });
                        })
                    }
                    <div className='mission-control-event-create' onClick={() => newEvent && newEvent(mission.ruleId, moment(mission.startDate), mission.templateId)}>
                        <FAIcon name='plus' prefix='fas' color={colorIsBright('#bea156') ? 'black' : 'white'} />
                    </div>
                </div>
            </div>
        );
    }, [events, newEvent, processedEventsV2, showEvent, users]);

    const getIconColor = useCallback((missions: IMissionStatus[]) => {
        const remainingSeconds = missions.reduce((e, val) => e + val.remainingTimeToDo, 0);
        const remainingHumans = missions.reduce((e, val) => e + val.remainingHumanToHave, 0);

        if (remainingSeconds === 0 && remainingHumans === 0)
            return GREEN_COLOR;
        if (remainingSeconds > 0 || remainingHumans > 0)
            return RED_COLOR;
        if (remainingSeconds <= 0 && remainingHumans <= 0)
            return BLUE_COLOR;

        return '#9b59b6';
    }, []);

    const renderTreeView = useCallback((obj: any, keys: string[] = [], title = '', level = 0): any[] => {
        // Base case: when the object is an array (list of missions)
        if (Array.isArray(obj)) {
            return obj.map((mission: IMissionStatus, index): DataNode => {
                const iconColor = getIconColor([mission]);
                return ({
                    switcherIcon: <FAIcon name="flag" prefix="fad" color={iconColor} />,
                    icon: <></>,
                    // title: [...keys, index].join('_'),
                    title: mission.missionTitle,
                    key: [...keys, index].join('_'), // Unique key for each mission node
                    children: [
                        {
                            icon: <></>,
                            title: generateMissionDetails(mission),
                            // title: [...keys, index, 'details'].join('_'),
                            key: [...keys, index, 'details'].join('_'),
                            isLeaf: true,
                            selectable: false,
                            className: 'mission-control-list-not-selectable'
                        }
                    ]
                });
            });
        }

        // Recursive case: when the object is an object (for date, customer, mandate levels)
        return Object.keys(obj).map((key): DataNode => {
            let nextTitle = title;
            let iconColor = 'black';
            const nextKeys = [...keys, key]; // Update keys with the current key for the next recursive call

            // Assign the relevant title based on the level
            if (level === 0) {
                // Level 0 is the date level
                nextTitle = moment(key).format(getFormat('DATE'));
                const customers = Object.values(obj[key]).map(customer => customer);
                const mandates = Object.values(customers).map((customer: any) => Object.values(customer).map(mandate => mandate).flat());
                const missions = Object.values(mandates).map((mandate: any) => Object.values(mandate).map(mission => mission)).flat() as IMissionStatus[];
                iconColor = getIconColor(missions);
            } else if (level === 1) {
                // Level 1 is the customer level
                const missions = Object.values(obj[key])[0] as IMissionStatus[];
                iconColor = getIconColor(missions);
                if (Array.isArray(missions)) {
                    if (missions[0] && missions[0].customerTitle) {
                        nextTitle = missions[0].customerTitle;
                    }
                }
            } else if (level === 2) {
                // Level 2 is the mandate level
                const missions = Object.values(obj[key]) as IMissionStatus[];
                iconColor = getIconColor(missions);
                const firstMission: IMissionStatus = missions[0];
                if (firstMission && firstMission.mandateTitle) {
                    nextTitle = firstMission.mandateTitle;
                }
            }

            // nextTitle = nextKeys.join('_');

            // Create a node for the current level (date, customer, or mandate)
            return {
                icon: <></>,
                switcherIcon:
                    level === 0 ? <FAIcon name="folder-closed" prefix="fad" color={iconColor} />
                        :
                        level === 1 ? <FAIcon name="user-tie-hair" prefix="fad" color={iconColor} />
                            : level === 2 ? <FAIcon name="folder-tree" prefix="fad" color={iconColor} />
                                : undefined,
                title:
                    level === 0 ? nextTitle
                        : level === 1 ? nextTitle
                            : level === 2 ? nextTitle
                                : undefined,
                key: nextKeys.join('_'), // Unique key for the node
                className:
                    level === 0 ? 'filter-item-day' : level === 1 ? 'filter-item-customer' : level === 2 ? 'filter-item-mandate' : level === 3 ? 'filter-item-mission' : '',
                children: renderTreeView(obj[key], nextKeys, nextTitle, level + 1), // Recursive call to build child nodes
            };
        });
    }, [generateMissionDetails, getIconColor]);

    const filteredMissions = useMemo(() => {
        const tmpMissions = cloneDeep(organizedMissions);
        const [start, end] = selectedDates; // Destructure the selected date range

        const startDate = moment(start);
        const endDate = moment(end);
        const filteredData: DictionaryString<DictionaryString<DictionaryString<IMissionStatus[]>>> = {};

        // Loop through each date in the data
        Object.keys(tmpMissions).forEach(dateKey => {
            const date = moment(dateKey); // Convert the date string to a moment object
            // Check if the date is within the selected date range
            if (date.isBetween(startDate, endDate, null, '[]')) {
                const customers = tmpMissions[dateKey];

                // Filter the customers
                const filteredCustomers: DictionaryString<DictionaryString<IMissionStatus[]>> = {};

                Object.keys(customers).forEach(customerId => {
                    const customerIdNum = Number(customerId);

                    // Check if the customerId is in the selectedCustomers array (or if no customers are selected)
                    if (selectedCustomers.length === 0 || selectedCustomers.includes(customerIdNum)) {
                        const mandates = customers[customerIdNum];

                        // Filter the mandates
                        const filteredMandates: DictionaryString<IMissionStatus[]> = {};

                        Object.keys(mandates).forEach(mandateId => {
                            const mandateIdNum = Number(mandateId);

                            // Filter the missions
                            let filteredMissions: IMissionStatus[] = [];

                            if (selectedPois.length === 0 && selectedMissionStatus.length === 0 && selectedColors.length === 0) {
                                filteredMissions = mandates[mandateIdNum];
                            } else {
                                mandates[mandateIdNum].forEach(mission => {
                                    if (selectedMissionStatus.includes('satisfied') && mission.remainingTimeToDo === 0 && mission.remainingHumanToHave === 0) {
                                        filteredMissions.push(mission);
                                    }
                                    if (selectedMissionStatus.includes('error') && mission.remainingTimeToDo > 0 && mission.remainingHumanToHave > 0) {
                                        filteredMissions.push(mission);
                                    }
                                    if (selectedMissionStatus.includes('tooMuch') && mission.remainingTimeToDo < 0 && mission.remainingHumanToHave < 0) {
                                        filteredMissions.push(mission);
                                    }
                                    if (mission.poiId && selectedPois.includes(mission.poiId)) {
                                        filteredMissions.push(mission);
                                    }
                                    if (mission.colorId && selectedColors.includes(mission.colorId)) {
                                        filteredMissions.push(mission);
                                    }
                                });
                            }

                            // If there are missions left after filtering by POIs, keep the mandate
                            if (filteredMissions.length > 0) {
                                // Only keep mandates with non-empty missions
                                mandates[mandateIdNum] = filteredMissions;

                                // Check if the mandateId is in the selectedMandates array (or if no mandates are selected)
                                if (selectedMandates.length === 0 || selectedMandates.includes(mandateIdNum)) {
                                    filteredMandates[mandateIdNum] = mandates[mandateIdNum];
                                }
                            }
                        });

                        // If there are any mandates with missions left, keep the customer
                        if (Object.keys(filteredMandates).length > 0) {
                            filteredCustomers[customerIdNum] = filteredMandates;
                        }
                    }
                });

                // If there are any customers with mandates left, keep the date
                if (Object.keys(filteredCustomers).length > 0) {
                    filteredData[dateKey] = filteredCustomers;
                }
            }
        });



        return filteredData;
    }, [organizedMissions, selectedDates, selectedCustomers, selectedPois, selectedMissionStatus, selectedColors, selectedMandates]);

    const treeData = useMemo(() => renderTreeView(filteredMissions), [filteredMissions, renderTreeView]);

    const customersOptions = useMemo((): DefaultOptionType[] => customerManagement.customers.data ? customerManagement.customers.data.map<DefaultOptionType>(customer => ({ label: customer.title, value: customer.id })) : [], [customerManagement.customers.data]);
    const mandatesOptions = useMemo((): DefaultOptionType[] => customerManagement.mandates.data ? customerManagement.mandates.data.filter(mandate => selectedCustomers.length > 0 ? selectedCustomers.includes(mandate.customerId) : true).map<DefaultOptionType>(mandate => ({ label: mandate.title, value: mandate.id })) : [], [customerManagement.mandates.data, selectedCustomers]);
    const poisOptions = useMemo((): DefaultOptionType[] => pois ? pois.filter(poi => poi.customerIds && poi.customerIds.some(customerId => selectedCustomers.includes(customerId))).map<DefaultOptionType>(poi => ({ label: poi.title, value: poi.id })) : [], [pois, selectedCustomers]);
    const colorsOptions = useMemo((): DefaultOptionType[] => colors ? colors.map<DefaultOptionType>(color => ({ label: (<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}><div style={{ minHeight: '20px', maxHeight: '20px', minWidth: '20px', maxWidth: '20px', background: color.color, borderRadius: '100%' }} />{color.title}</div>), value: color.id })) : [], [colors]);
    const missionStatusOptions = useMemo((): DefaultOptionType[] => {
        return [
            { value: 'satisfied', label: (<div><FAIcon name='circle-check' prefix='far' color='var(--icon-green)' /> <FormattedMessage defaultMessage={'Complete'} /></div>) },
            { value: 'error', label: (<div><FAIcon name='circle-xmark' prefix='far' color='var(--icon-red)' /> <FormattedMessage defaultMessage={'Incomplete'} /></div>) },
            { value: 'tooMuch', label: (<div><FAIcon name='circle-check' prefix='far' color='var(--icon-blue)' /> <FormattedMessage defaultMessage={'Overplanned'} /></div>) },
        ];
    }, []);

    const renderFilters = useCallback(() => {
        return (
            <div className='filters-container'>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} style={{ marginTop: 10 }}>
                        {
                            mode === 'monthly' ?
                                <DatePicker.RangePicker
                                    format={getFormat('DATE')}
                                    showTime={false}
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                    value={[moment(selectedDates[0]), moment(selectedDates[1])]}
                                    disabledDate={(current) => disabledDate(current, startDate, endDate)}
                                    onChange={(e) => {
                                        if (e && e[0] && e[1])
                                            setSelectedDates([e[0].format(MOMENT_MONTH_FORMAT), e[1].format(MOMENT_MONTH_FORMAT)]);
                                    }}
                                />
                                :
                                undefined

                        }
                    </Col>
                    <Col xs={{ span: 24 }}>
                        <Collapse collapsible='header' ghost style={{ borderBottom: 'none' }}>
                            <Collapse.Panel header={<FormattedMessage defaultMessage={'Filters'} />} key='filters'>
                                <Divider style={{ margin: '0px 0px 16px 0px' }} />
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }}>
                                        <Select
                                            placeholder={<FormattedMessage defaultMessage={'Filter by customer'} />}
                                            style={{ width: '100%' }}
                                            options={customersOptions}
                                            value={selectedCustomers}
                                            loading={customerManagement.customers.loading}
                                            allowClear
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                            onChange={(e) => {
                                                setSelectedCustomers(e);
                                                if (e.length === 0)
                                                    setSelectedMandates([]);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Select
                                            placeholder={<FormattedMessage defaultMessage={'Filter by mandate'} />}
                                            style={{ width: '100%' }}
                                            options={mandatesOptions}
                                            value={selectedMandates}
                                            allowClear
                                            loading={customerManagement.mandates.loading}
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                            onChange={(e) => {
                                                setSelectedMandates(e);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Select
                                            placeholder={<FormattedMessage defaultMessage={'Filter by working place'} />}
                                            style={{ width: '100%' }}
                                            options={poisOptions}
                                            value={selectedPois}
                                            allowClear
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                            onChange={(e) => {
                                                setSelectedPois(e);
                                            }}
                                        />
                                    </Col>
                                    <Divider style={{ margin: '12px 0px' }} />
                                    <Col xs={{ span: 24 }}>
                                        <Select
                                            placeholder={<FormattedMessage defaultMessage={'Filter by color'} />}
                                            style={{ width: '100%' }}
                                            options={colorsOptions}
                                            value={selectedColors}
                                            allowClear
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label?.toString() ?? '')?.includes(input)}
                                            onChange={(e) => {
                                                setSelectedColors(e);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Select
                                            placeholder={<FormattedMessage defaultMessage={'Filter by state'} />}
                                            style={{ width: '100%' }}
                                            options={missionStatusOptions}
                                            value={selectedMissionStatus}
                                            allowClear
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                            onChange={(e) => {
                                                setSelectedMissionStatus(e);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Divider style={{ margin: '12px 0px' }} />
            </div>
        );
    }, [mode, selectedDates, customersOptions, selectedCustomers, customerManagement.customers.loading, customerManagement.mandates.loading, mandatesOptions, selectedMandates, poisOptions, selectedPois, colorsOptions, selectedColors, missionStatusOptions, selectedMissionStatus, startDate, endDate]);

    useEffect(() => {
        if (props.refresh && missionsStatus.loaded)
            getMissionsStatus(true, false);
    }, [getMissionsStatus, missionsStatus.loaded, props.refresh]);


    useEffect(() => {
        if (!missionsStatus.loaded)
            getMissionsStatus();

        if (missionsStatus.loaded)
            generateMissions();
    }, [generateMissions, getMissionsStatus, missionsStatus.loaded]);

    useEffect(() => {
        const isDateSame = oldStartDate.current.isSame(startDate) || oldEndDate.current.isSame(endDate);
        if (!isDateSame) {
            if (moment().isBetween(startDate, endDate)) {
                setSelectedDates([moment().format(MOMENT_MONTH_FORMAT), moment().endOf(mode === 'monthly' ? 'month' : 'week').format(MOMENT_MONTH_FORMAT)]);
            }
            else
                setSelectedDates([startDate.format(MOMENT_MONTH_FORMAT), mode === 'monthly' ? endDate.format(MOMENT_MONTH_FORMAT) : startDate.clone().endOf('week').format(MOMENT_MONTH_FORMAT)]);

            getMissionsStatus();
        }
    }, [endDate, getMissionsStatus, mode, startDate]);

    return (
        <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
            <div className='mission-control-container'>
                {renderFilters()}
                {
                    loading ?
                        <Spin spinning />
                        :
                        treeData.length != 0 ?
                            <Tree.DirectoryTree
                                className='mission-control-list'
                                multiple
                                treeData={treeData}
                                expandedKeys={expandedKeys} // Pass the expanded keys
                                onExpand={(keys) => setExpandedKeys(keys)} // Update expanded keys on user action
                                checkStrictly={true}
                                autoExpandParent={false}
                            />
                            :
                            <Empty />
                }
            </div>
            <div className="reset-filters-button">
                <CircleButton
                    className='__monthly-planning-btn-filter'
                    small={true}
                    type={"default"}
                    onClick={() => getMissionsStatus(true)}
                    icon={<FAIcon prefix="fal" name="rotate" color="dark" />}
                    title={intl.formatMessage({ defaultMessage: 'Refresh' })} />
            </div>
        </Can>
    );
};

export default MissionsControlV2;