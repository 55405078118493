import { Badge, Col, DatePicker, DatePickerProps, Divider, Row, Segmented, Space, Spin } from "antd";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { cloneMomentWithLocale, isNullOrEmpty, toggleFullScreen } from "../../../utils/utils";
import Filters from "../../planningPerf/tabs/common/filters";
import FAIcon from "../FAIcon";
import CircleButton from "../fields/circleButton";
import Card from "./card";

export interface DataByDate {
    date: Moment,
    nbUnprocessedEvents: number;
}
interface Props {
    tableRender: JSX.Element;
    isEmpty: boolean;
    isEmptyText: string;
    display: 'day' | 'week' | 'month' | 'year';
    loading: 'idle' | 'loading' | 'success' | 'error';
    dataByDate: DataByDate[];
    headerRender?: JSX.Element;
    refresh(force: boolean): void;
    changeDate(startDate: Moment, endDate: Moment): void;
    changeDisplay(display: 'day' | 'week' | 'month' | 'year'): void;
    changeSelectedDay(selectedDay: Moment): void;
    selectedDay: Moment;
    icon: JSX.Element;
    displayTypes?: { value: string | number, label: string | JSX.Element; }[];
    filters?: ControlTimeFilters;
    setFilters?: (filters: Partial<ControlTimeFilters>) => void;
}
const TimeClockShowType = [
    {
        value: 'daily',
        label: <FormattedMessage defaultMessage={'Daily'} />
    },
    {
        value: 'weekly',
        label: <FormattedMessage defaultMessage={'Weekly'} />
    },
    {
        value: 'monthly',
        label: <FormattedMessage defaultMessage={'Monthly'} />
    },
    {
        value: 'yearly',
        label: <FormattedMessage defaultMessage={'Yearly'} />
    }
];

export interface ControlTimeFilters {
    users: number[];
    groups: number[];
    usersToExclude: number[];
    eventClockedStatus: number[];
    selectedProjects: number[];
}

export const ControlTime = (props: Props) => {
    const { filters, setFilters } = props;
    const [dataByDate, setDataByDate] = useState<DataByDate[]>(props.dataByDate);
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const width = useSelector((state: ApplicationState) => state.window.width);

    const [sidePanels, setSidePanels] = useState<{ showFilters?: boolean; }>({});

    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen);
    const { display, selectedDay, loading, changeDate, changeSelectedDay } = props;
    const intl = useIntl();

    const generateDaysOfSelectedWeek = useCallback(() => {
        const dataByDate: DataByDate[] = [];
        for (let day = selectedDay.clone().startOf('week').startOf('day'); day.isSameOrBefore(selectedDay.clone().endOf('week').endOf('day')); day.add(1, 'day')) {
            const data = props.dataByDate.find(dBD => dBD.date.isSame(day, 'day'));

            dataByDate.push({
                date: cloneMomentWithLocale(day.clone()),
                nbUnprocessedEvents: data ? data.nbUnprocessedEvents : 0,
            });
        }
        setDataByDate(dataByDate);
    }, [selectedDay, props.dataByDate]);

    useEffect(() => {
        if (props.dataByDate.length > 0) {
            if (display === 'day') {
                generateDaysOfSelectedWeek();
            } else if (display === 'week') {
                setDataByDate(props.dataByDate);
            } else {
                setDataByDate(props.dataByDate);
            }
        }
    }, [props.dataByDate, display, generateDaysOfSelectedWeek]);



    const hasFiltersActive = () => {
        return false;
    };

    const enableSidePanel = useCallback((key: keyof typeof sidePanels) => {
        setSidePanels((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    }, [setSidePanels]);


    const hasSider = Object.values(sidePanels).find(v => v);

    const onChange = useCallback((sD: Moment | null) => {
        if (sD != null && sD !== selectedDay) {
            //this.setState({ selectedEventWithEventClockeds: [] });
            if (display === 'year')
                changeDate(sD.clone().startOf('year').startOf('day'), sD.clone().endOf('year').endOf('day'));
            else if (display === 'month')
                changeDate(sD.clone().startOf('month').startOf('day'), sD.clone().endOf('month').endOf('day'));
            else
                changeDate(sD.clone().startOf('week').startOf('day'), sD.clone().endOf('week').endOf('day'));
            changeSelectedDay(sD);
        }
    }, [selectedDay, display, changeDate, changeSelectedDay]);

    const onNext = useCallback(() => {
        let next: Moment;
        if (display === 'year') {
            next = selectedDay.clone().endOf('year').add(1, 'day');

        } if (display === 'month') {
            next = selectedDay.clone().endOf('month').add(1, 'day');

        } else
            next = selectedDay.clone().endOf('week').add(1, 'day');

        onChange(next);
    }, [selectedDay, display, onChange]);

    const onLast = useCallback(() => {
        let last: Moment;
        if (display === 'year')
            last = selectedDay.clone().startOf('year').subtract(1, 'day');
        else if (display === 'month')
            last = selectedDay.clone().startOf('month').subtract(1, 'day');
        else
            last = selectedDay.clone().startOf('week').subtract(1, 'day');

        onChange(last);
    }, [selectedDay, display, onChange]);

    const customWeekStartEndFormat: DatePickerProps['format'] = useCallback((value: any) => `${moment(value).startOf('week').format(getFormat('DAY_SHORT_AND_MONTH_HALF'))} - ${moment(value).endOf('week').format(getFormat('DAY_SHORT_AND_MONTH_HALF'))}`, []);
    const customYearStartEndFormat: DatePickerProps['format'] = useCallback((value: any) => `${moment(value).startOf('year').format(getFormat('YEAR'))}`, []);
    const customMonthStartEndFormat: DatePickerProps['format'] = useCallback((value: any) => `${moment(value).startOf('month').format(getFormat('MONTH'))}`, []);

    const CustomDatePicker = useCallback((cprops: { className?: string; }) => {
        return (
            <div key={`custom-date-picker-div-${selectedDay.format('YYYYMMDD')}`} className={cprops.className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
                {
                    display === 'day' || display === 'week' ?
                        <DatePicker.WeekPicker
                            className={`__weekly-planning-month-picker ${cprops.className}-picker`}
                            key={`week-picker-${selectedDay.format("YY")}-${selectedDay.week()}`}
                            //picker='week'
                            suffixIcon={null}
                            placement={'bottomLeft'}
                            size='large'
                            bordered={false}
                            format={customWeekStartEndFormat}
                            value={selectedDay}
                            onChange={onChange}
                            allowClear={false}
                            inputReadOnly
                            style={{
                                textAlign: 'center'
                            }}
                            disabled={loading === 'loading'}
                        />
                        : display === 'year' ?
                            <DatePicker.YearPicker
                                className={`__weekly-planning-month-picker ${cprops.className}-picker`}
                                key={`year-picker-${selectedDay.format("YY")}-${selectedDay.year()}`}
                                //picker='week'
                                suffixIcon={null}
                                placement={'bottomLeft'}
                                size='large'
                                bordered={false}
                                format={customYearStartEndFormat}
                                value={selectedDay}
                                onChange={onChange}
                                allowClear={false}
                                inputReadOnly
                                style={{
                                    textAlign: 'center'
                                }}
                                disabled={loading === 'loading'}
                            />
                            :
                            <DatePicker
                                className={`__weekly-planning-month-picker ${cprops.className}-picker`}
                                key={`year-picker-${selectedDay.format("YY-MM")}-${selectedDay.month()}`}
                                //picker='week'
                                suffixIcon={null}
                                placement={'bottomLeft'}
                                size='large'
                                picker={'month'}
                                bordered={false}
                                format={customMonthStartEndFormat}
                                value={selectedDay}
                                onChange={onChange}
                                allowClear={false}
                                inputReadOnly
                                style={{
                                    textAlign: 'center'
                                }}
                                disabled={loading === 'loading'}
                            />
                }
            </div>


        );
    }, [selectedDay, loading, display, isSmartphone, customMonthStartEndFormat, customWeekStartEndFormat, customYearStartEndFormat, onChange]);

    return (
        <Card
            icon={props.icon}
            title={
                <Space>
                    <div style={{ display: 'flex' }}>
                        <CircleButton small icon={<FAIcon prefix='fal' name='chevron-left' />} onClick={() => onLast()} disabled={props.loading === 'loading'} />
                        <CustomDatePicker className='work_control-datepicker-input-container' />
                        <CircleButton small icon={<FAIcon prefix='fal' name='chevron-right' />} onClick={() => onNext()} disabled={props.loading === 'loading'} />
                    </div>
                    {
                        width > 470 ?
                            <CircleButton
                                small
                                icon={<FAIcon prefix='fad' name='house' />}
                                title={intl.formatMessage({ defaultMessage: 'Today' })}
                                onClick={() => onChange(moment())}
                                disabled={props.loading === 'loading' || props.selectedDay.isSame(moment(), 'day')}
                            />
                            : null
                    }
                    {
                        !isSmartphone && width > 1190 ?
                            <>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                <CircleButton
                                    small
                                    title={intl.formatMessage({ defaultMessage: 'Force reload' })}
                                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                                    onClick={() => props.refresh(true)}
                                    disabled={props.loading === 'loading'} />
                                {
                                    !isSmartphone &&
                                    <CircleButton
                                        small
                                        type={fullscreen ? 'primary' : 'default'}
                                        title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                                        icon={fullscreen ? <FAIcon prefix='fad' name='minimize' /> : <FAIcon prefix='fad' name='maximize' />}
                                        onClick={toggleFullScreen}
                                    />
                                }
                            </>
                            : null
                    }
                </Space>
            }
            headerElements={
                !isSmartphone ?
                    [
                        <Space key={'timeclock-show-options'}>
                            {
                                width > 1500 ?
                                    <>
                                        <Segmented
                                            disabled={props.loading === 'loading'}
                                            key={'work_control-show-options'}
                                            value={props.display === 'day' ? 'daily' : props.display === 'week' ? 'weekly' : props.display === 'year' ? 'yearly' : 'monthly'}
                                            options={props.displayTypes ?? TimeClockShowType}
                                            onChange={(value) => {
                                                if (value === 'yearly') {
                                                    props.changeDate(props.selectedDay.clone().startOf('year').startOf('day'), props.selectedDay.clone().endOf('year').endOf('day'));
                                                } if (value === 'monthly') {
                                                    props.changeDate(props.selectedDay.clone().startOf('month').startOf('day'), props.selectedDay.clone().endOf('month').endOf('day'));
                                                } else {
                                                    props.changeDate(props.selectedDay.clone().startOf('week').startOf('day'), props.selectedDay.clone().endOf('week').endOf('day'));
                                                }
                                                props.changeDisplay(value === 'daily' ? 'day' : value === 'weekly' ? 'week' : value === 'yearly' ? 'year' : 'month');
                                            }}
                                        />
                                    </>
                                    : null
                            }

                            {
                                props.headerRender ?
                                    <>

                                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                        {props.headerRender}
                                    </>
                                    : null
                            }
                            {
                                props.setFilters && props.filters ?
                                    <>
                                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                        <CircleButton
                                            className='__monthly-planning-btn-filter'
                                            small={true}
                                            type={sidePanels.showFilters ? "primary" : "default"}
                                            icon={
                                                <Badge dot={hasFiltersActive()}>
                                                    <FAIcon prefix='fad' name='filters' />
                                                </Badge>}
                                            onClick={() => enableSidePanel("showFilters")}
                                            title={hasFiltersActive() ? intl.formatMessage({ defaultMessage: 'Active filters' }) : intl.formatMessage({ defaultMessage: 'No active filters' })} />
                                    </>
                                    : null
                            }


                        </Space>
                    ]
                    : []
            }>
            <div className="__time-clock-content">
                <div className={`__time-clock-filters ${isSmartphone || props.display !== 'day' ? 'hidden-sidebar' : ''}`} >
                    <Spin spinning={props.loading === 'loading'} indicator={<FAIcon prefix='fas' name='spinner-third' spin />}>
                        <Row gutter={[5, 5]} >
                            {
                                props.selectedDay && props.display === 'day' ?
                                    dataByDate.map((dBD, key) => {
                                        const hideBadge = props.loading === 'loading' || dBD.date.isAfter(moment(), 'day');
                                        const badgeTitle = `${intl.formatMessage({ defaultMessage: 'Total events : {total}' }, { total: dBD.nbUnprocessedEvents })}`;
                                        let bagdeColor = GREEN_COLOR;
                                        let countBadge = hideBadge ? 0 : <FAIcon prefix='fad' name='circle-check' fontSize={20} color={GREEN_COLOR} />;
                                        let dotBadge = false;

                                        if (!hideBadge && dBD.nbUnprocessedEvents > 0) {
                                            bagdeColor = RED_COLOR;
                                            countBadge = dBD.nbUnprocessedEvents;
                                            dotBadge = false;
                                        }

                                        return (
                                            <Col
                                                xs={{ span: 24 }}
                                                key={`day-${key}`}
                                                onClick={() => onChange(dBD.date)}
                                            >
                                                <div className={`__time-clock-filter-day${dBD.date.isSame(props.selectedDay, 'day') ? '-selected' : ''}`}>
                                                    <Badge
                                                        dot={dotBadge}
                                                        offset={dBD.nbUnprocessedEvents < 10 ? [7, -1] : dBD.nbUnprocessedEvents < 100 ? [11, -1] : [16, -1]}
                                                        count={countBadge}
                                                        color={bagdeColor}
                                                        title={badgeTitle}
                                                        overflowCount={999}
                                                    >
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '40px 1fr', gridColumnGap: '3px' }}>
                                                            <p style={{ marginTop: 'auto', marginBottom: 'auto', textTransform: 'capitalize' }}>{dBD.date.format(getFormat('DAY_MEDIUM'))}</p>
                                                            <p>{dBD.date.format(getFormat('DAY_SHORT'))}</p>
                                                        </div>
                                                    </Badge>
                                                </div>
                                            </Col>
                                        );
                                    })
                                    : null
                            }
                        </Row>
                    </Spin>
                </div>
                <div className="__time-clock-data">
                    {
                        props.loading === 'loading' ?
                            <div style={{ textAlign: 'center', margin: 'auto' }}>
                                <Spin size='large' />
                            </div>
                            : props.isEmpty ?
                                <div style={{ textAlign: 'center', margin: 'auto' }}>
                                    {
                                        filters && (!isNullOrEmpty(filters.users) || !isNullOrEmpty(filters.groups)) ?
                                            <>
                                                <FAIcon prefix='fad' name='circle-check' color={ORANGE_COLOR} fontSize={isSmartphone ? '40px' : '60px'} style={{ marginBottom: 10 }} />
                                                <p style={{ fontSize: isSmartphone ? '20px' : '30px', color: 'gray' }}><FormattedMessage defaultMessage={'{text} with selected filters'} values={{ text: props.isEmptyText }} /></p>
                                            </>
                                            :
                                            <>
                                                <FAIcon prefix='fad' name='circle-check' color={GREEN_COLOR} fontSize={isSmartphone ? '40px' : '60px'} style={{ marginBottom: 10 }} />
                                                <p style={{ fontSize: isSmartphone ? '20px' : '30px', color: 'gray' }}>{`${props.isEmptyText}`}</p>
                                            </>
                                    }
                                    {
                                        !isSmartphone && props.display === 'day' ?
                                            < p style={{ fontSize: '20px', color: 'gray', textTransform: 'capitalize' }}>{props.selectedDay.format(getFormat('DATE_LONG'))}</p>
                                            :
                                            < p style={{ fontSize: isSmartphone ? '15px' : '20px', color: 'gray', textTransform: 'capitalize' }}>{`${props.selectedDay.clone().startOf('week').format(getFormat('DATE_MEDIUM'))} - ${props.selectedDay.clone().endOf('week').format(getFormat('DATE_MEDIUM'))}`}</p>
                                    }
                                </div>
                                :
                                props.tableRender
                    }
                </div>
                <div className={`__time-clock-filters2 ${hasSider ? '' : 'hidden-sidebar'}`}>
                    <p className='__mp-sider-title'>
                        {sidePanels.showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                    </p>
                    <div className='__mp-main-siders-content'>
                        {
                            sidePanels.showFilters && filters && setFilters ?
                                <Filters
                                    reset={() => setFilters({ users: [], groups: [], usersToExclude: [], eventClockedStatus: [], selectedProjects: [] })}
                                    users={{
                                        selectedUsers: filters.users,
                                        changeUsers: (val) => setFilters({ users: val }),
                                    }}
                                    groups={{
                                        usersToExclude: filters.usersToExclude,
                                        selectedGroups: filters.groups,
                                        changeGroups: (groups, toExclude) => setFilters({ groups, usersToExclude: toExclude })
                                    }}
                                    eventClockedStatus={{
                                        eventClockedStatus: filters.eventClockedStatus,
                                        changeEventClockedStatus: (val) => setFilters({ eventClockedStatus: val }),
                                    }}
                                    projects={{
                                        selectedProjects: filters.selectedProjects,
                                        changeProjects: (val) => setFilters({ selectedProjects: val }),
                                    }}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </div >
        </Card>
    );
};
export default ControlTime;