import { Card as CardA, Col, Row, Spin } from 'antd';
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Network from "../../../utils/network";
import { DashboardStats } from "../../../utils/types/generalTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";
import Clock from "../../common/general/clock";

export const General = () => {
    const [stats, setStats] = useState<DashboardStats | undefined>(undefined);
    const [statsLoading, setStatsLoading] = useState<boolean>(false);
    const statsLoaded = useRef<boolean>(false);

    const company = useSelector((state: ApplicationState) => state.user.company);

    const intl = useIntl();



    const loadDashboardStats = useCallback((force = false) => {
        if (force || (!statsLoading && !statsLoaded.current)) {
            setStatsLoading(true);
            Network.getDashboardFastStatistics().then(
                response => {
                    statsLoaded.current = true;
                    setStatsLoading(false);
                    setStats(response);
                },
                () => {
                    setStatsLoading(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the statistics' }), "warning");
                }
            );
        }
    }, [intl, statsLoading]);

    useEffect(() => {
        if (!statsLoading && !statsLoaded.current) {
            loadDashboardStats();
        }
    }, [loadDashboardStats, statsLoading]);



    return (
        <Card
            className="dashboard-card"
            title={<FormattedMessage defaultMessage={'General'} />}
            icon={<FAIcon prefix='fad' name='info' />}
            headerElements={[
                <CircleButton
                    small
                    loading={statsLoading}
                    disabled={statsLoaded.current === false}
                    key="team-periods-add-button"
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    title={intl.formatMessage({ defaultMessage: 'Refresh' })}
                    onClick={() => loadDashboardStats(true)} />
            ]}
        >
            <Row gutter={[12, 8]} style={{ marginBottom: '15px' }}>
                <Col xs={{ span: 24 }}>
                    <img alt='' className="dashboard-company-logo" src={company?.logo} />
                </Col>
                <Col xs={{ span: 24 }}>
                    <Clock />
                </Col>
            </Row>
            {
                stats === undefined ?
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                    : <Row gutter={[10, 10]} >
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='user' /> <FormattedMessage defaultMessage={'Users'} /></span>}>
                                <p>{stats.nbUsers}</p>
                            </CardA>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='users' /> <FormattedMessage defaultMessage={'Groups'} /></span>}>
                                <p>{stats.nbGroups}</p>
                            </CardA>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='calendar-days' /> <FormattedMessage defaultMessage={'Events'} /></span>}>
                                <p>{stats.nbEvents}</p>
                            </CardA>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='newspaper' /> <FormattedMessage defaultMessage={'News'} /></span>}>
                                <p>{stats.nbNews}</p>
                            </CardA>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='messages' /> <FormattedMessage defaultMessage={'Messages'} /></span>}>
                                <p>{stats.nbMessages}</p>
                            </CardA>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }}>
                            <CardA size="small" title={<span><FAIcon prefix='fad' name='clipboard-list' /> <FormattedMessage defaultMessage={'Requirements'} /></span>}>
                                <p>{stats.nbOccupancyRates}</p>
                            </CardA>
                        </Col>
                    </Row>
            }

        </Card>
    );

};
export default General;