import { Space, Tabs, Tooltip } from 'antd';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { changeTypesOfDay, changeTypesOfDayOff, reset } from '../../store/actions/configurations';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { TypeOfDay } from '../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { alert } from '../../utils/utils';
import Can from '../common/general/can';
import Container from '../common/navigations/container';
import GeneralTab from './general/generalTab';
import HolidaysTab from './holidays/holidaysTab';
import OccupancyRateTab from './occupancyRate/occupancyRateTab';
import TypesOfContractTab from './typeOfContract/typesOfContractTab';
import TypesOfDayTab from './typesOfDay/typesOfDayTab';

import TravelAndVehicleTab from './travelAndVehicle/travelAndVehicleTab';

import { FormattedMessage, injectIntl } from 'react-intl';
import { CompanyTypes } from '../../utils/constants';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import MajorationTab from './majoration/majorationTab';
import TypesOfContractTabV2 from './typeOfContract/v2/typesOfContractTabV2';

//Keys for the different tabs
enum TabKeys {
    General = "g",
    TypeOfDay = "t",
    OccupancyRate = "o",
    Holidays = "h",
    TypeOfContract = "c",
    TravelAndVehicle = "v",
    Majoration = "m"
}

//Links for the different tabs
enum TabLink {
    General = "general",
    TypeOfDay = "type-of-day",
    OccupancyRate = "occupancy-rate",
    Holidays = "holidays",
    TypeOfContract = "type-of-contract",
    TravelAndVehicle = "travel-and-vehicle",
    Majoration = "majoration"
}

interface IProps {

}

type ReduxProps = ConnectedProps<typeof connector>;


type Props = IProps & RouterProps & ConfigurationsDispatchProps & IntlProps & ReduxProps;

interface State {

}

/**
 * Component for the Configurations page
 */
class Configurations extends React.Component<Props, State> {

    componentDidMount() {
        // get types of day
        Network.getTypeOfDay().then(
            response => this.props.changeTypesOfDay!(response),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the day types' }), "warning")
        );
        // get types of day
        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff!(response),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the day types' }), "warning")
        );
    }

    // componentWillUnmount() {
    // reset all stored values
    // this.props.reset!();
    // }

    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        switch (this.props.match.params.tab) {
            case TabLink.OccupancyRate:
                return TabKeys.OccupancyRate;
            case TabLink.TypeOfDay:
                return TabKeys.TypeOfDay;
            case TabLink.Holidays:
                return TabKeys.Holidays;
            case TabLink.General:
                return TabKeys.General;
            case TabLink.TypeOfContract:
                return TabKeys.TypeOfContract;
            case TabLink.TravelAndVehicle:
                return TabKeys.TravelAndVehicle;
            case TabLink.Majoration:
                return TabKeys.Majoration;
            default:
                return TabKeys.General;
        }
    };

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (key) {
            case TabKeys.TypeOfDay:
                link = TabLink.TypeOfDay;
                break;
            case TabKeys.OccupancyRate:
                link = TabLink.OccupancyRate;
                break;
            case TabKeys.Holidays:
                link = TabLink.Holidays;
                break;
            case TabKeys.General:
                link = TabLink.General;
                break;
            case TabKeys.TypeOfContract:
                link = TabLink.TypeOfContract;
                break;
            case TabKeys.TravelAndVehicle:
                link = TabLink.TravelAndVehicle;
                break;
            case TabKeys.Majoration:
                link = TabLink.Majoration;
                break;
            default:
                link = TabLink.General;
                break;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/configurations/${link}`);
    };

    render() {
        const currentTab = this.getCurrentTab();
        const { intl } = this.props;
        return (
            <Can rule={Rules.Planning.Settings} redirect="/dashboard">
                <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Settings' }), link: "/configurations" }]}>
                    <Tabs animated activeKey={currentTab} onChange={this.onChangeTab}>
                        <Tabs.TabPane forceRender key={TabKeys.General} tab={
                            <Space>
                                <FAIcon prefix='fad' name='gear' />
                                <FormattedMessage defaultMessage={'General'} />
                            </Space>
                        }>
                            <GeneralTab />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.OccupancyRate} tab={
                            <Space>
                                <FAIcon prefix='fad' name='clipboard-list' />
                                <p>
                                    <FormattedMessage defaultMessage={'Requirements'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Rules applicable for managing the mission of day types.' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>
                        }>
                            <OccupancyRateTab />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.TypeOfDay} tab={
                            <Space>
                                <FAIcon prefix='fad' name='calendar-days' />
                                <p>
                                    <FormattedMessage defaultMessage={'Day types'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Types that can be attached to events' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>

                        }>
                            <TypesOfDayTab />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.Holidays} tab={
                            <Space>
                                <FAIcon prefix='fad' name='calendar-xmark' />
                                <p>
                                    <FormattedMessage defaultMessage={'Types of day off'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Management of vacations, leaves, and public holidays' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>
                        }>
                            <HolidaysTab />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.TypeOfContract} tab={
                            <Space>
                                <FAIcon prefix='fad' name='file-contract' />
                                <p>
                                    <FormattedMessage defaultMessage={'Types of contrat'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Management of contract types' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>
                        }>
                            {
                                this.props.company?.type === CompanyTypes.CCNT ?
                                    <TypesOfContractTabV2 />
                                    :
                                    <TypesOfContractTab />
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.TravelAndVehicle} tab={
                            <Space>
                                <FAIcon prefix='fad' name='route' />
                                <p>
                                    <FormattedMessage defaultMessage={'Routes & vehicles'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Management of vehicles and other parameters related to journeys' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>
                        }>
                            <TravelAndVehicleTab vehicle={undefined} stopEdition={() => console.log("TODO")} />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.Majoration} tab={
                            <Space>
                                <FAIcon prefix='fad' name='percent' />
                                <p>
                                    <FormattedMessage defaultMessage={'Increased days'} />
                                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Management of overtime rates' })}>
                                        <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                    </Tooltip>
                                </p>
                            </Space>
                        }>
                            <MajorationTab />
                        </Tabs.TabPane>
                    </Tabs>
                </Container>
            </Can>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    reset: () => dispatch(reset()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Configurations));