import { Badge, DatePicker, Divider, Select, Space, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useUsers from "../../../hooks/useUsers";
import { useAppSelector } from "../../../store/store";
import { ContractTypeMode, GREEN_COLOR, RED_COLOR } from "../../../utils/constants";
import Network from "../../../utils/network";
import { DictionaryNumber, User, UserJobTMP } from "../../../utils/types/generalTypes";
import { CcntReport } from "../../../utils/types/reportTypes";
import { showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";
import VirtualTable from "../../common/general/virtualTable";
import Filters from "../../planningPerf/tabs/common/filters";
import DrawerCcntReport from "../ccnt/drawerCcntReport";

interface CcntBalancesByUserId {
    hours: number;
    rests: number;
    vacations: number;
    holidays: number;
    contractTypeMode: number;
}

interface Filters {
    users: number[];
    groups: number[];
    usersToExclude: number[];
}

const ReportBalancesCCNT = () => {
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [selectedContracts, setSelectedContracts] = useState<DictionaryNumber<number | undefined>>({});
    const [ccntBalances, setCcntBalances] = useState<DictionaryNumber<CcntBalancesByUserId>>({});
    const [loading, setLoading] = useState(false);
    const [loadingCcnt, setLoadingCcnt] = useState(false);
    const [ccnt, setCcnt] = useState<undefined | CcntReport>(undefined);
    const [filters, setFilters] = useState<Filters>({ users: [], groups: [], usersToExclude: [] });
    const [showFilters, setShowFilters] = useState(false);
    const selectedMonthRef = useRef<Moment | undefined>(undefined);
    const users = useUsers();
    const intl = useIntl();
    const height = useAppSelector(state => state.window.height);

    const availableContracts = useCallback((month: Moment, contracts?: UserJobTMP[]) => {
        return contracts ? contracts.filter(c => c.id !== undefined && moment(c.contract_expiry_date, 'YYYY-MM-DD').isSameOrAfter(month, 'dates') && moment(c.date_in_report, 'YYYY-MM-DD').isSameOrBefore(month, 'dates')) : [];
    }, []);

    const getAllCcntData = useCallback((month: Moment) => {
        const userIds = Object.keys(selectedContracts).map(idx => parseInt(idx));
        const jobIds = Object.keys(selectedContracts).map(idx => selectedContracts[parseInt(idx)]).filter(i => i !== undefined) as number[];
        setLoading(true);
        Network.generateReportsCcntV2(month.format("YYYY-01-01"), month.clone().endOf('month').format("YYYY-MM-DD"), userIds, jobIds).then(
            (response) => {
                setCcntBalances(() => Object.fromEntries(
                    users.filter(u => availableContracts(month, u.job).length > 0).map((u) => {
                        const values = response[u.id] ? response[u.id].tally : { finalWorkTime: 0, finalRestDelta: 0, finalVacationDelta: 0, finalHolidayDelta: 0 };
                        return [u.id, { contractTypeMode: response[u.id].data.contractTypeMode, hours: values.finalWorkTimeDelta.toFixed(2), rests: values.finalRestDelta.toFixed(2), vacations: values.finalVacationDelta.toFixed(2), holidays: values.finalHolidayDelta.toFixed(2) }];
                    })
                ));
                setLoading(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                setLoading(false);
            }
        );
    }, [availableContracts, intl, selectedContracts, users]);

    useEffect(() => {
        setSelectedContracts(() => Object.fromEntries(
            users.map((u) => [u.id, u.job && availableContracts(selectedMonth, u.job).length > 0 ? availableContracts(selectedMonth, u.job)[0].id : undefined])
        ));
    }, [availableContracts, selectedMonth, users]);

    useEffect(() => {
        if (Object.keys(selectedContracts).length > 0 && (!selectedMonthRef.current || selectedMonthRef.current.format('YYYY-MM-DD') !== selectedMonth.format('YYYY-MM-DD'))) {
            getAllCcntData(selectedMonth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContracts]);

    const renderTag = useCallback((value: number, inverted = false) => {
        const color = value < 0 ? (inverted ? GREEN_COLOR : RED_COLOR) : (inverted ? RED_COLOR : GREEN_COLOR);

        // By @Axel
        return <span style={{ background: color + "33", padding: '5px 10px', color: color, borderRadius: 999 }}>{value}</span>;
    }, []);

    const isFixed = useCallback((contractTypeMode: number) => {
        return [ContractTypeMode.NORMAL].includes(contractTypeMode);
    }, []);

    const getCcntReport = useCallback((userIds: number[], jobIds: number[]) => {
        setLoadingCcnt(true);
        Network.generateReportsCcntV2(selectedMonth.format("YYYY-MM-01"), selectedMonth.format("YYYY-MM-DD"), userIds, jobIds).then(
            (networkCCnt) => {
                const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                setLoadingCcnt(false);
                setCcnt(ccnt);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), 'warning');
                setLoadingCcnt(false);
            }
        );
    }, [intl, selectedMonth]);

    const columns = useMemo((): ColumnsType<User> => ([
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            key: 'lastname',
            dataIndex: 'last_name',
            className: '__width_300',
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            key: 'firstname',
            dataIndex: 'first_name',
            className: '__width_300',
        },
        {
            title: <FormattedMessage defaultMessage={'Contract'} />,
            key: 'contract',
            className: '__width_200',
            width: '200px',
            render: (_, record) => {
                const contracts = availableContracts(selectedMonth, record.job);
                return (
                    <>
                        {
                            contracts.length > 0 ?
                                <Select
                                    value={selectedContracts[record.id]}
                                    options={contracts.map(j => ({ value: j.id, label: j.name }))}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setSelectedContracts((oldContracts) => {
                                            const newContracts = cloneDeep(oldContracts);
                                            newContracts[record.id] = e;
                                            return newContracts;
                                        });
                                    }}
                                />
                                :
                                <FormattedMessage defaultMessage={'No contract available'} />
                        }
                    </>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Balances'} />,
            key: 'balance',
            className: '__width_480',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Hours'} />,
                    key: 'balance-hours',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].hours - ccntBalances[a.id].hours),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 && isFixed(ccntBalances[record.id].contractTypeMode) ?
                            ccntBalances[record.id].hours ?
                                renderTag(ccntBalances[record.id].hours, true)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Rest'} />,
                    key: 'balance-rests',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].rests - ccntBalances[b.id].rests),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].rests ?
                                renderTag(ccntBalances[record.id].rests)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Vacations'} />,
                    key: 'balance-vacations',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].vacations - ccntBalances[b.id].vacations),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].vacations ?
                                renderTag(ccntBalances[record.id].vacations)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Holidays'} />,
                    key: 'balance-holidays',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].holidays - ccntBalances[b.id].holidays),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].holidays ?
                                renderTag(ccntBalances[record.id].holidays)
                                : 0
                            : '-'
                    )
                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Action'} />,
            key: 'action',
            className: '__width_80',
            width: '80px',
            align: 'center',
            render: (_, record) => {
                return (
                    <CircleButton
                        icon={<FAIcon prefix='fad' name='eye' />}
                        small
                        title="Show report"
                        disabled={selectedContracts[record.id] === undefined}
                        onClick={() => {
                            const contractId = selectedContracts[record.id];
                            if (contractId !== undefined)
                                getCcntReport([record.id], [contractId]);
                        }}
                    />
                );
            }
        },
    ]
    ), [availableContracts, selectedMonth, selectedContracts, ccntBalances, isFixed, renderTag, getCcntReport]);

    const resetFilters = useCallback(() => {
        setFilters({
            groups: [],
            users: [],
            usersToExclude: []
        });
    }, []);

    const hasActiveFilters = () => {
        return filters.users.length > 0 || filters.groups.length > 0;
    };

    const tableHeight = useMemo(() => {
        const newHeight = height - 270;
        return newHeight < 250 ? 250 : newHeight;
    }, [height]);

    const filteredUsers = useMemo(() => {
        let newUsers = cloneDeep(users).filter(u => u.visible);
        if (filters.users.length > 0)
            newUsers = newUsers.filter(u => filters.users.includes(u.id));
        if (filters.groups.length > 0)
            newUsers = newUsers.filter(u => u.group_users?.some(g => filters.groups.includes(g.group)));
        return newUsers;
    }, [filters.groups, filters.users, users]);

    return (
        <Card
            title={
                <Space>
                    <FormattedMessage defaultMessage={'Balances N-CLA'} />
                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='rotate' />}
                        onClick={() => getAllCcntData(selectedMonth)}
                    />
                </Space>
            }
            icon={<FAIcon prefix='fad' name='calculator' />}
            headerElements={[
                <>
                    <DatePicker.MonthPicker
                        key='balance-ccnt-month-picker'
                        format={"MM/YYYY"}
                        style={{ width: '110px' }}
                        allowClear={false}
                        value={selectedMonth}
                        onChange={(e) => {
                            if (e) {
                                setSelectedMonth(e);
                            }
                        }}
                    />
                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                    <CircleButton small type={showFilters ? 'primary' : 'default'} icon={<Badge dot={hasActiveFilters()}><FAIcon prefix='fad' name='filters' color={showFilters ? 'white' : 'black'} /></Badge>} onClick={() => setShowFilters(!showFilters)} />
                </>
            ]}
        >
            <Spin spinning={loading}>
                <div style={{ display: 'flex', gap: 10 }}>
                    <VirtualTable
                        dataSource={filteredUsers}
                        columns={columns}
                        style={{ flex: 1, overflow: 'auto' }}
                        pagination={false}
                        scroll={{ x: true, y: tableHeight }}
                    />
                    {
                        showFilters &&
                        <div className={`__time-clock-filters2 ${showFilters ? '' : 'hidden-sidebar'}`}>
                            <p className='__mp-sider-title'>
                                {showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                            </p>
                            <div className='__mp-main-siders-content'>
                                {
                                    showFilters && filters ?
                                        <Filters
                                            reset={resetFilters}
                                            users={{
                                                selectedUsers: filters.users,
                                                changeUsers: (val) => setFilters({ ...filters, users: val })
                                            }}
                                            groups={{
                                                selectedGroups: filters.groups,
                                                usersToExclude: filters.usersToExclude,
                                                changeGroups: (groups, usersToExclude) => setFilters({ ...filters, groups, usersToExclude })
                                            }}
                                        />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Spin>
            <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={ccnt !== undefined} close={() => setCcnt(undefined)} year={selectedMonth} />
        </Card>
    );
};

export default ReportBalancesCCNT;