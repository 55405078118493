import { DashOutlined, MoreOutlined } from "@ant-design/icons";
import { Col, Empty, Popover, Row, Space, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { cloneDeep } from "lodash";
import moment from "moment";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { changeTypesOfDay, changeTypesOfDayOff } from "../../../store/actions/configurations";
import { changeTemplates } from "../../../store/actions/planning";
import { BreaktimeCalculatedTypes } from "../../../utils/constants";
import { ProjectNameProps, withProjectName } from "../../../utils/customIntl/withProjectName";
import Network from "../../../utils/network";
import { UserEventsData } from "../../../utils/objects/cct/userEventsData";
import { POI, StaffType } from "../../../utils/types/generalTypes";
import { NetworkEvent } from "../../../utils/types/networkTypes";
import { PlanningTemplate, TypeOfDay } from "../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch } from "../../../utils/types/storeTypes";
import { convertNetworkEventToPlanningEvent, convertNetworkEventsToPlanningEvents, convertNetworkEventsToPlanningEventsV2, convertPlanningEventToSimpleEvent, isNullOrEmpty, momentDurationFormat, showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import DeleteButton from "../../common/fields/deleteButton";
import SpeedDial from "../../common/fields/speedDial";
import Card from "../../common/general/card";
import Event, { EventSource } from "../../common/general/event";
import VirtualTable from "../../common/general/virtualTable";
import { tableColumnTextFilterConfig } from "../../courseManagement/tableSearch";
import TemplateModal from "../../planning/templateModal";
import Filters from "./common/filters";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, IntlProps, ProjectNameProps { }

export interface TemplateFilters {
    projects: number[];
    typeOfDays: number[];
    typeOfDaysOff: number[];
    pois: number[];
    staffTypes: number[];
}

interface State {
    editTemplateId?: number;
    dupeTemplateId?: number;
    archivedOnly: boolean;
    archivedTemplates: PlanningTemplate[];
    loadingArchived: boolean;
    loading: boolean;
    showFilters: boolean;
    filters: TemplateFilters;
}

class Template extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            archivedOnly: false,
            archivedTemplates: [],
            loadingArchived: false,
            loading: false,
            showFilters: false,
            filters: {
                projects: [],
                pois: [],
                staffTypes: [],
                typeOfDays: [],
                typeOfDaysOff: [],
            }
        };
    }

    templateColumnsActions = (record: PlanningTemplate) => {
        return [
            <Space key={`template-edit-space-${record.id}`} >
                <CircleButton
                    key={`template-edit-${record.id}`}
                    icon={<FAIcon prefix='fad' name='pencil' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                    withoutTooltip
                    small
                    onClick={() => this.setState({ editTemplateId: record.id })}
                />
                <CircleButton
                    key={`template-duplicate-${record.id}`}
                    icon={<FAIcon prefix='fad' name='clone' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Duplicate' })}
                    withoutTooltip
                    small
                    onClick={() => this.setState({ dupeTemplateId: record.id })}
                />
                <CircleButton
                    key={`template-archiv-${record.id}`}
                    icon={record.active ? <FAIcon prefix='fad' name='box-archive' /> : <FAIcon prefix='far' name='check' />}
                    title={record.active ? this.props.intl.formatMessage({ defaultMessage: 'Archive' }) : this.props.intl.formatMessage({ defaultMessage: 'Activate' })}
                    withoutTooltip
                    small
                    onClick={() => this.toggleTemplateArchivState(record)}
                />
                <DeleteButton
                    text={<FormattedMessage defaultMessage={'Do you want to delete this template?'} />}
                    key={`template-delete-${record.id}`}
                    onConfirm={() => this.onDeleteTemplate(record)}
                    placement='left'
                    small
                    withoutTooltip
                />
            </Space>
        ];
    };
    templateColumns: ColumnProps<PlanningTemplate>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: "title",
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            render: (record: PlanningTemplate) => {
                const totalHoursBreakTime = record.breakTimes ? record.breakTimes.reduce((value: number, b) => value += b.totalHours, 0) : 0;
                return <Event event={{ ...record, totalHoursBreakTime }} displayTimes displayIcons displayOverlay source={EventSource.TEMPLATE} />;
            },
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,

        },
        {
            title: <FormattedMessage defaultMessage={'Duration'} />,
            key: "duration",
            className: '__width_100 __centered-text',
            render: (record: PlanningTemplate) => {
                const simpleEvent = convertPlanningEventToSimpleEvent({
                    ...record,
                    startDate: moment(record.startDate),
                    endDate: moment(record.endDate),
                });

                const eventEffective = moment.duration(UserEventsData.eventDuration(simpleEvent, true, true, undefined, undefined, true), "seconds");
                const eventEffectiveString = momentDurationFormat(eventEffective);

                const paidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.PAID, undefined, undefined, true), "seconds");
                const paidBreaktimeDurationStr = momentDurationFormat(paidBreaktimeDuration);

                const notPaidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.NOTPAID, undefined, undefined, true), "seconds");
                const notPaidBreaktimeDurationStr = momentDurationFormat(notPaidBreaktimeDuration);
                return (
                    <Popover
                        overlayInnerStyle={{ width: '350px' }}
                        mouseEnterDelay={0.5}
                        title={<FormattedMessage defaultMessage={'Hours details'} />}
                        content={
                            <>
                                {
                                    paidBreaktimeDuration.asMinutes() !== 0 ?
                                        <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                            <p><FormattedMessage defaultMessage={'Paid breaks'} />{':'}</p>
                                            <p style={{ fontStyle: 'italic', opacity: '0.9', textDecoration: 'line-through' }}>{paidBreaktimeDurationStr}</p>
                                        </div>
                                        : null
                                }
                                {
                                    notPaidBreaktimeDuration.asMinutes() !== 0 ?
                                        <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                            <p><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</p>
                                            <p>-{notPaidBreaktimeDurationStr}</p>
                                        </div>
                                        : null
                                }
                                <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                    <p><FormattedMessage defaultMessage={'Effective time'} />{':'}</p>
                                    <p>{eventEffectiveString}</p>
                                </div>
                            </>
                        }
                    >
                        <span>{eventEffectiveString}</span>
                    </Popover >
                );
            },
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => {
                const simpleEventA = convertPlanningEventToSimpleEvent({
                    ...a,
                    startDate: moment(a.startDate),
                    endDate: moment(a.endDate),
                });
                const simpleEventB = convertPlanningEventToSimpleEvent({
                    ...b,
                    startDate: moment(b.startDate),
                    endDate: moment(b.endDate),
                });
                const durationA = UserEventsData.eventDuration(simpleEventA, true, true, undefined, undefined, true);
                const durationB = UserEventsData.eventDuration(simpleEventB, true, true, undefined, undefined, true);

                return durationA - durationB;
            },

        },
        {
            title: this.props.projectName,
            dataIndex: "project",
            key: "project",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record.project ?
                    record
                        .project.title!.toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) : false;
            },
            render: (typeOfDay: TypeOfDay) => typeOfDay?.title,
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.project?.title ? -1 : !b.project?.title ? 1 : a.project?.title < b.project?.title ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Type of day'} />,
            dataIndex: "typeOfDay",
            key: "typeOfDay",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record.typeOfDay ?
                    record
                        .typeOfDay.title!.toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) : false;
            },
            render: (typeOfDay: TypeOfDay) => typeOfDay?.title,
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.typeOfDay?.title ? -1 : !b.typeOfDay?.title ? 1 : a.typeOfDay?.title < b.typeOfDay?.title ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Type of vacation'} />,
            dataIndex: "typeOfDayOff",
            key: "typeOfDayOff",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record.typeOfDayOff ?
                    record
                        .typeOfDayOff.title!.toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) : false;
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            render: (typeOfDayOff: TypeOfDay) => typeOfDayOff?.title,
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.typeOfDayOff?.title ? -1 : !b.typeOfDayOff?.title ? 1 : a.typeOfDayOff?.title < b.typeOfDayOff?.title ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Point of interest'} />,
            dataIndex: "poi",
            key: "poi",
            className: '__min-width-170',
            render: (poi: POI) => poi?.title,
            onFilter: (value, record) => {
                return record.poi ?
                    record
                        .poi.title!.toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) : false;
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.poi?.title ? -1 : !b.poi?.title ? 1 : a.poi?.title < b.poi?.title ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Abilities'} />,
            dataIndex: "staffType",
            key: "staffType",
            className: '__min-width-170',
            render: (staffType: StaffType) => {
                if (staffType) {
                    return (
                        <Tag className="__report-groups-users-tags" color="#f5f5f5">
                            {staffType.name}
                        </Tag>
                    );
                } else {
                    return null;
                }
            },
            onFilter: (value, record) => {
                return record.staffType ?
                    record
                        .staffType.name!.toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) : false;
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.staffType?.name ? -1 : !b.staffType?.name ? 1 : a.staffType?.name < b.staffType?.name ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: "actions",
            className: '__width_180 __centered-text',
            render: (record: PlanningTemplate) => {
                return this.props.isSmartphone ? (
                    <SpeedDial
                        key="poi-actions-speeddial"
                        title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        buttons={this.templateColumnsActions(record)}
                    />
                ) : (
                    this.templateColumnsActions(record)
                );
            },
        },
    ];

    templateColumnsMobile: ColumnProps<PlanningTemplate>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: "title",
            key: "title",
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: "actions",
            width: "90px",
            //className: "__poi-actions",
            render: (record: PlanningTemplate) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SpeedDial
                            key="poi-actions-speeddial"
                            title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            buttons={this.templateColumnsActions(record)}
                        />
                    </div>
                );
            },
        },
    ];

    componentDidMount() {
        if (isNullOrEmpty(this.props.templates)) {
            this.refreshTemplates();
        }
        Network.getTypeOfDay().then(
            response => this.props.changeTypesOfDay(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
        // get types of day
        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
    }

    toggleTemplateArchivState = (template: PlanningTemplate) => {
        const { intl } = this.props;
        let templates = this.props.templates ? cloneDeep(this.props.templates) : [];
        let archivedTemplates = cloneDeep(this.state.archivedTemplates);
        const newTemplateActive = !template.active;
        if (template && template.id) {
            Network.archivTemplate(template.id, newTemplateActive).then(
                (response: NetworkEvent) => {
                    const newTemplate = convertNetworkEventToPlanningEvent(response);
                    if (newTemplateActive) {
                        templates.push(newTemplate);
                        archivedTemplates = archivedTemplates.filter(t => t.id !== newTemplate.id);
                    } else {
                        templates = templates?.filter(t => t.id !== newTemplate.id);
                        archivedTemplates.push(newTemplate);
                    }

                    templates = templates.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));
                    archivedTemplates = archivedTemplates.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));

                    this.setState({ archivedTemplates });
                    this.props.changeTemplates(templates);

                    showNotification(template.active ? intl.formatMessage({ defaultMessage: 'The template has been successfully activated' }) : intl.formatMessage({ defaultMessage: 'The template has been successfully archived' }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the template' }), "warning")
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the template' }), "warning");
        }

    };

    refreshTemplates = () => {
        this.setState({ loading: true });
        Network.getTemplates().then(
            response => {
                if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning");
                else {
                    this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning")
        ).finally(() => this.setState({ loading: false }));
    };

    refreshArchivedTemplated = () => {
        this.setState({ loadingArchived: true });
        Network.getArchivedTemplates().then(
            (response) => this.setState({ archivedTemplates: convertNetworkEventsToPlanningEvents(response) }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the archived templates' }), "warning")
        ).finally(() => this.setState({ loadingArchived: false }));
    };

    onDeleteTemplate = (template: PlanningTemplate) => {
        Network.deleteTemplate(template.id!).then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The template has been successfully deleted' }), "success");
                this.refreshTemplates();
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the templates' }), "warning"),
        );
    };

    filteredTemplates = () => {
        const { archivedTemplates, archivedOnly, filters } = this.state;
        const { templates } = this.props;
        let tmpTemplates = archivedOnly ? archivedTemplates : templates;

        if (filters.typeOfDays.length > 0)
            tmpTemplates = tmpTemplates?.filter(t => t.typeOfDay?.id ? filters.typeOfDays.includes(t.typeOfDay.id) : false);
        if (filters.typeOfDaysOff.length > 0)
            tmpTemplates = tmpTemplates?.filter(t => t.typeOfDayOff?.id ? filters.typeOfDaysOff.includes(t.typeOfDayOff.id) : false);
        if (filters.pois.length > 0)
            tmpTemplates = tmpTemplates?.filter(t => t.poi?.id ? filters.pois.includes(t.poi.id) : false);
        if (filters.projects.length > 0)
            tmpTemplates = tmpTemplates?.filter(t => t.project?.id ? filters.projects.includes(t.project.id) : false);
        if (filters.staffTypes.length > 0)
            tmpTemplates = tmpTemplates?.filter(t => t.staffType?.id ? filters.staffTypes.includes(t.staffType.id) : false);

        return tmpTemplates?.sort((a, b) => !a.title ? -1 : !b.title ? 1 : a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ? -1 : 1);
    };

    render() {
        const { archivedOnly, loading, loadingArchived, archivedTemplates, showFilters, filters } = this.state;
        const { templates, intl } = this.props;
        let tableHeight = this.props.height - 242;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <Card
                className="team-card"
                icon={<FAIcon prefix='fad' name='layer-group' />}
                title={<FormattedMessage defaultMessage={'Templates'} />}
                headerElements={[
                    <Space key="planning-template-headers">
                        <CircleButton
                            small
                            onClick={() => this.setState(prevState => {
                                const archivedOnly = !prevState.archivedOnly;
                                if (archivedOnly) {
                                    this.refreshArchivedTemplated();
                                }
                                return ({ archivedOnly });
                            })}
                            type={archivedOnly ? "primary" : "default"}
                            icon={<FAIcon prefix='fad' name='box-archive' />}
                            title={archivedOnly ? intl.formatMessage({ defaultMessage: 'Show active templates' }) : intl.formatMessage({ defaultMessage: 'Show archived templates' })}
                            withoutTooltip
                        />
                        <CircleButton
                            small
                            onClick={() => this.setState({ editTemplateId: -1 })}
                            icon={<FAIcon prefix='far' name='plus' />}
                            title={intl.formatMessage({ defaultMessage: 'Add a template' })}
                            placement="topLeft"
                        />
                        <CircleButton
                            small
                            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                            icon={<FAIcon prefix='fad' name='filters' />}
                            title={intl.formatMessage({ defaultMessage: 'Filters' })}
                            placement="topLeft"
                        />
                    </Space>
                ]}
            >
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} style={{ display: 'flex', gap: 10 }}>
                        <VirtualTable
                            rowKey={(p: any) => p.id}
                            dataSource={this.filteredTemplates()}
                            columns={
                                this.props.isSmartphone
                                    ? this.templateColumnsMobile
                                    : this.templateColumns
                            }
                            loading={archivedOnly ? loadingArchived : loading}
                            pagination={false}
                            style={{ flex: 1, minWidth: 0 }}
                            scroll={(archivedOnly ? archivedTemplates.length === 0 : (templates === undefined || templates.length === 0)) ? {} : { x: true, y: tableHeight }}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No template'} />} /> }}
                        />
                        <div className={`__time-clock-filters2 ${showFilters ? '' : 'hidden-sidebar'}`}>
                            <p className='__mp-sider-title'>
                                {showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                            </p>
                            <div className='__mp-main-siders-content'>
                                {
                                    showFilters && filters ?
                                        <Filters
                                            reset={() => this.setState({ filters: { pois: [], projects: [], staffTypes: [], typeOfDays: [], typeOfDaysOff: [] } })}
                                            projects={{
                                                selectedProjects: filters.projects,
                                                changeProjects: (val) => this.setState({ filters: { ...filters, projects: val } })
                                            }}
                                            typeOfDays={{
                                                selectedTypeOfDays: filters.typeOfDays,
                                                changeTypeOfDays: (val) => this.setState({ filters: { ...filters, typeOfDays: val } })
                                            }}
                                            typeOfDaysOff={{
                                                selectedTypeOfDaysOff: filters.typeOfDaysOff,
                                                changeTypeOfDaysOff: (val) => this.setState({ filters: { ...filters, typeOfDaysOff: val } })
                                            }}
                                            POIs={{
                                                selectedPOIs: filters.pois,
                                                changePOIs: (val) => this.setState({ filters: { ...filters, pois: val } })
                                            }}
                                            staffTypes={{
                                                selectedStaffTypes: filters.staffTypes,
                                                changeStaffTypes: (val) => this.setState({ filters: { ...filters, staffTypes: val } })
                                            }}
                                        />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    this.state.editTemplateId !== undefined ?
                        <TemplateModal
                            visible={this.state.editTemplateId !== undefined}
                            templateId={this.state.editTemplateId}
                            isEdit
                            onClose={() => this.setState({ editTemplateId: undefined })}
                        />
                        : null
                }
                {
                    this.state.dupeTemplateId !== undefined ?
                        <TemplateModal
                            visible={this.state.dupeTemplateId !== undefined}
                            templateId={this.state.dupeTemplateId}
                            isDuplicate
                            onClose={() => this.setState({ dupeTemplateId: undefined })}
                        />
                        : null
                }
            </Card>
        );
    }
}


const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    templates: state.planning.templates,
    isSmartphone: state.window.isSmartphone,
    height: state.window.height

});


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(withProjectName(Template)));