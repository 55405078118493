import { Empty, Input, message, Segmented, Space, Spin, Tag } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { changeNews } from '../../store/actions/posts';
import { changeUsers } from '../../store/actions/teamManagement';
import '../../styles/newsfeed.css';
import Network from '../../utils/network';
import { News, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { checkRBACRule, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import Skeleton from '../common/general/skeleton';
import Container from '../common/navigations/container';
import injectIntlHOC from '../common/wrapper/injectIntlHOC';
import NewsCard from './newsCard';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & ReduxProps & WrappedComponentProps & IntlProps;

interface State {
    currentPage: number;
    loading: boolean;
    scrollElement: HTMLElement | null;
    hasMoreNews: boolean;
    filter: Filters;
    searchValue: string;
    searchValueApplied: string[];
    openLikeDetail: number | undefined;
    selectedFeed: 'userfeed' | 'newsfeed' | 'customers';
}

enum Filters {
    All, Staff, Clients, NewsFeed, UserFeed
}

/**
 * Page that display the list of news
 */
class NewsList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            openLikeDetail: undefined,
            currentPage: -1,
            loading: false,
            scrollElement: null,
            hasMoreNews: true,
            searchValue: '',
            searchValueApplied: [],
            filter: checkRBACRule(Rules.Newsfeed.Filter) ? Filters.All : Filters.Staff,
            selectedFeed: 'newsfeed'
        };
    }

    componentDidMount() {
        //fetch the news
        this.getNews();

        //display alert message if needed
        if (this.props.location && this.props.location.state && this.props.location.state.successMessage) {
            message.success(this.props.location.state.successMessage);
            this.props.history.replace({ state: undefined });
        }
        !(this.props.users && this.props.users.length > 0) && this.getUsers();

    }

    getUsers = () => {
        //get all users
        Network.getAllUsers().then(
            response => this.props.changeUsers(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
        );
    };
    /**
     * Fetch the news
     */
    getNews = () => {
        this.setState({ loading: true });
        let { currentPage } = this.state;
        const { selectedFeed } = this.state;
        let { news } = this.props;
        ++currentPage;

        if (currentPage === 0) {
            news = [];
        }
        Network.getNewsList(currentPage, selectedFeed)
            .then(
                response => {
                    news = news.concat(response) as News[];
                    this.setState({ currentPage, loading: false, hasMoreNews: response.length > 0 });
                    this.props.changeNews(news);

                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the news' }), "warning");
                    this.setState({ loading: false });
                    this.props.changeNews(news);
                }
            );
    };
    changeNews = (id: number, newNews: News) => {
        const news = cloneDeep(this.props.news);
        const foundIndex = news.findIndex(n => n.id === id);
        if (foundIndex !== -1) {

            news[foundIndex] = newNews;
            this.props.changeNews(news);
        }

    };

    onChangeSelectedFeed = (selectedFeed: 'userfeed' | 'newsfeed' | 'customers') => {
        this.setState({ selectedFeed, currentPage: -1 }, () => {
            this.getNews();
        });
    };

    /**
     * Handle the suppression of a news
     * @param content the news content
     */
    handleDelete = (content: News) => {
        Network.deleteNews(content).then(
            () => {
                //add a animation
                const parent = document.getElementById(`newscard-${content.id}`);
                if (parent) {
                    parent.className += " newsfeed-delete";
                }
                setTimeout(() => this.removeNews(content.id as number), 2000);
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The news has been successfully deleted' }), "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the news' }), "error")
        );
    };

    /**
     * Remove a news from state
     * @param newsId the news' id of the new that has been deleted
     */
    removeNews = (newsId: number) => {
        let { news } = this.props;
        news = news.filter(n => n.id !== newsId);
        this.props.changeNews(news);
    };

    /**
     * Fired every time the user scroll
     * check if more news must be loaded
     */
    onScroll = (): void => {
        //if there is no more message or it is loading, return
        if (this.state.hasMoreNews && !this.state.loading) {
            this.getNews();
        }
    };

    /**
     * Get a news' destinator
     * @param news the news
     * @returns the destinator
     */
    getDestinator = (news: News): string => {
        if (news.is_instructor && news.is_client) return "Global";
        else if (!news.is_instructor && news.is_client) return "Clients";
        else if (news.groups && news.groups.length > 0) return `Groupe${news.groups.length > 1 ? 's' : ''}: ${news.groups.map(g => g.name).join(', ')}`;
        else return "Staff";
    };

    /**
     * Filter the news according to the choosen filters
     * @returns the visible news
     */
    filterNews = (news: News[]): News[] => {
        if (this.state.searchValueApplied.length > 0) {
            news = news.filter(n => (
                this.state.searchValueApplied.some(searchValue => n.user_name.toLowerCase().includes(searchValue.toLowerCase())) ||
                this.state.searchValueApplied.some(searchValue => n.text.toLowerCase().includes(searchValue.toLowerCase())) ||
                this.state.searchValueApplied.some(searchValue => n.title.toLowerCase().includes(searchValue.toLowerCase())) ||
                n.user_title && this.state.searchValueApplied.some(searchValue => n.user_title && n.user_title.toLowerCase().includes(searchValue.toLowerCase()))
            ));
        }
        return news;
    };

    render() {
        const { intl, isSmartphone, displayFilter, currentUser } = this.props;
        const { selectedFeed } = this.state;
        //if (this.props.isSmartphone) actions.unshift(<CircleButton onClick={() => this.props.history.push(`/${this.props.match.params.lang}/newsfeed/create`)} title={"Envoyer une news"} icon={<PlusOutlined />} placement="bottomLeft" />);
        console.log('LIKE 0', this.props.news, this.state.searchValueApplied);


        return (
            <Container
                backTop
                breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'News' }), link: "/newsfeed/list" }, { title: intl.formatMessage({ defaultMessage: 'List' }), link: "/newsfeed/list" }]}
            >

                <Input
                    style={{ width: this.props.width <= 1300 ? '85%' : '60%', marginLeft: 47, marginBottom: 5 }}
                    placeholder={intl.formatMessage({ defaultMessage: 'Search for a news' })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (this.state.searchValue != null && this.state.searchValue !== '') {
                                const searchValueApplied = cloneDeep(this.state.searchValueApplied);
                                searchValueApplied.push(this.state.searchValue);
                                this.setState({ searchValueApplied: searchValueApplied, searchValue: '' });

                            }
                        }
                    }
                    }
                    value={this.state.searchValue}
                    onChange={(value) => this.setState({ searchValue: value.target.value })}
                    suffix={
                        <CircleButton
                            title={this.props.intl.formatMessage({ defaultMessage: 'Search' })}
                            style={{ border: 'none', height: 27, backgroundColor: 'transparent', right: -10 }}
                            onClick={() => {
                                if (this.state.searchValue != null && this.state.searchValue !== '') {
                                    const searchValueApplied = cloneDeep(this.state.searchValueApplied);
                                    searchValueApplied.push(this.state.searchValue);
                                    this.setState({ searchValueApplied: searchValueApplied, searchValue: '' });
                                }
                            }}
                            icon={<FAIcon prefix='fad' name='magnifying-glass' />}
                        />
                    }
                />
                {
                    <Space className="newslist-add-button" style={{ top: '18px', left: this.props.width <= 1300 ? 'calc( 85% + 4px + 47px )' : 'calc(60% + 47px + 33px)' }}>
                        {currentUser && (selectedFeed === 'userfeed' || (selectedFeed === 'newsfeed' && currentUser.role !== 1) || (selectedFeed === 'customers' && currentUser.role !== 1)) &&
                            <CircleButton
                                onClick={() => this.props.history.push({
                                    pathname: `/${this.props.match.params.lang}/newsfeed/create`, state: {
                                        targetFeed: selectedFeed
                                    }
                                })}
                                title={this.props.intl.formatMessage({ defaultMessage: "Send a post" })}
                                icon={<FAIcon prefix='far' name='plus' />}
                                placement="bottomLeft"
                                small
                            />
                        }
                    </Space>
                }

                <div style={{ width: this.props.width <= 1300 ? '85%' : '60%', display: 'flex', flexDirection: 'row', gap: 0, marginLeft: 47, marginBottom: 5 }}>
                    {this.state.searchValueApplied.length > 0 ?
                        this.state.searchValueApplied.map((searchValue, idx) => {
                            return (
                                <Tag
                                    style={{
                                        fontSize: '18px',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4
                                    }}
                                    key={`search-value-${searchValue}-${idx}`} closable onClose={() => {
                                        let searchValueApplied = cloneDeep(this.state.searchValueApplied);
                                        searchValueApplied = searchValueApplied.filter(sV => sV !== searchValue);
                                        this.setState({ searchValueApplied: searchValueApplied });
                                    }}>{searchValue}</Tag>
                            );
                        })
                        :
                        null

                    }
                </div>
                <Segmented
                    block
                    size={isSmartphone ? 'middle' : 'large'}
                    style={{ width: this.props.width <= 1300 ? '85%' : '60%', marginLeft: 47, marginTop: 15, marginBottom: 15 }}
                    key={'timeclock-show-options'}
                    value={selectedFeed}
                    options={[
                        {
                            value: 'newsfeed',
                            label: intl.formatMessage({ defaultMessage: 'Newsfeed' })
                        },
                        {
                            value: 'userfeed',
                            label: intl.formatMessage({ defaultMessage: 'UserFeed' })
                        },
                        {
                            value: 'customers',
                            label: intl.formatMessage({ defaultMessage: 'Customers' })
                        },
                    ].filter(opt => ((displayFilter || !displayFilter && opt.value !== 'customers') && (currentUser && currentUser.role === 1 ? opt.value !== 'customers' : true)))}
                    onChange={(selectedFeed) => this.onChangeSelectedFeed(selectedFeed as ('userfeed' | 'newsfeed' | 'customers'))}
                />
                {
                    !this.state.loading && this.props.news.length === 0 ?
                        <div className="newslist-empty">
                            <Empty description={this.props.intl.formatMessage({ defaultMessage: "No news" })} />
                        </div>
                        :
                        <div className="newslist-content">

                            <div className="newslist-vertical-line" />
                            <Skeleton className="newslist-skeleton" loading={this.state.loading} repeat={3} avatar title paragraph={{ rows: 3 }} />
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={this.onScroll}
                                hasMore={this.state.hasMoreNews}>
                                {
                                    this.filterNews(this.props.news).map((news: News) => (
                                        <NewsCard news={news}
                                            content={
                                                {
                                                    ...news,
                                                    id: news.id,
                                                    title: news.title,
                                                    titleFr: news.translations?.find(t => t.lang_key === "fr")?.title,
                                                    titleDe: news.translations?.find(t => t.lang_key === "de")?.title,
                                                    titleIt: news.translations?.find(t => t.lang_key === "it")?.title,
                                                    text: news.text,
                                                    textFr: news.translations?.find(t => t.lang_key === "fr")?.desc,
                                                    textDe: news.translations?.find(t => t.lang_key === "de")?.desc,
                                                    textIt: news.translations?.find(t => t.lang_key === "it")?.desc,
                                                    avatar: news.user_image,
                                                    image: news.res_url,
                                                    files: news.files,
                                                    created: news.created,
                                                    can_delete: news.can_delete,
                                                    creator: news.user_name,
                                                    destinator: this.getDestinator(news),
                                                }
                                            }
                                            handleDelete={this.handleDelete} changeNews={this.changeNews} key={`newslist-card-${news.id}`}
                                            changeOpenLikeDetail={(newsId: number | undefined) => (this.state.openLikeDetail !== newsId) && this.setState({ openLikeDetail: newsId })}
                                            openLikeDetail={this.state.openLikeDetail}
                                        />))
                                }
                            </InfiniteScroll>
                            {
                                this.state.loading ?
                                    <Spin style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }} size="large" spinning={this.state.loading} />
                                    : null
                            }
                        </div>
                }
            </Container>
        );
    }
}
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeNews: (news: News[]) => dispatch(changeNews(news)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});
const mapStateToProps = (state: ApplicationState) => ({
    news: state.posts.news,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    displayFilter: state.user.currentUser?.company_detail?.ext,
    users: state.teamManagement.users,
    currentUser: state.user.currentUser
});
const connector = connect(mapStateToProps, mapDispatchToProps);


export default injectIntlHOC(connector(injectIntl(NewsList)));