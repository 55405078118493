import { Button } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeSignature } from '../../store/actions/crm';
import { ApplicationState, CRMDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Card from '../common/general/card';

interface IProps {
    signature: string | undefined;
}

type Props = IProps & CRMDispatchProps & IntlProps;

interface State {
    signature: string;
}

/**
 * Component for the signature tab of the CRM page
 */
class SignatureTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            signature: "",
        };
    }

    saveSignature = () => {
        this.props.changeSignature!(this.state.signature);
    };

    render() {
        const { signature } = this.state;
        return (
            <div>
                <Card title={<FormattedMessage defaultMessage={'Signature'} />} icon={<FAIcon prefix='fad' name='signature' className="anticon" />} className="crm-promotions-card">
                    <div>
                        <p className="crm-section-title"><FormattedMessage defaultMessage={'Select a signature that will appear at the end of emails sent with messages and promotions.'} /></p>
                        <InputField
                            className="crm-textarea"
                            type="textarea"
                            autoSize={{ minRows: 2 }}
                            value={signature}
                            placeholder={this.props.intl.formatMessage({ defaultMessage: 'Signature' })}
                            onChange={(e: InputFieldOnChangeEvent) => this.setState({ signature: e.target.value })}
                            allowClear />
                    </div>
                </Card>
                {
                    signature.trim() &&
                    <Button
                        className="crm-button"
                        type="primary"
                        onClick={this.saveSignature}>
                        <FormattedMessage defaultMessage={'Save'} />
                    </Button>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSignature: (s: string) => dispatch(changeSignature(s)),
});

const mapStateToProps = (state: ApplicationState) => ({
    signature: state.crm.signature,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignatureTab));