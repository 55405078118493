import { Button, Spin } from 'antd';
import React, { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { GOOGLE_STORAGE_URL } from '../../../utils/constants';
import { GenericFile, ValidateFile } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../FAIcon';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps {

    onUploaded(file: (File | string | GenericFile | ValidateFile | undefined)): void;
    file?: (File | string | GenericFile | ValidateFile);
    accept?: string;
    className?: string;
    containerStyle?: CSSProperties;
    editText?: string;
    addText?: string;
    deleteTextMaxWidth?: number;
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    divider?: boolean;

}

interface State {
    fileName?: string;
}

/**
 * Component that represent an image uploader field
 */
class FileUploader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }
    /**
     * Check that the file is less than 2MB
     * @param file the uploaded file
     * @return true if the file is less than 2MB
     */
    componentDidMount(): void {
        this.setFileFromProps();
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isEqual(prevProps.file, this.props.file)) {
            this.setFileFromProps();
        }
    }
    checkFile = (file: File): boolean => {
        const isLessThan2MB = file.size / 1024 / 1024 <= 20;

        if (!isLessThan2MB) alert(this.props.intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB.' }, { size: 20 }), "warning");

        return isLessThan2MB;
    };

    setFileFromProps = () => {
        let fileName: string | undefined = undefined;
        const { file } = this.props;
        if (file && typeof file === 'string' && file.startsWith('sunkhronos_admin/')) {

            const splittedFile = file.split('/');
            if (splittedFile.length > 1) {
                fileName = splittedFile[1];
            }
        } else if (file && typeof file !== 'string' && !(file instanceof File) && ['ValidateFile', 'GenericFile'].includes(file.type)) {
            fileName = file.file_name;
        } else if (file && typeof file !== 'string' && (file instanceof File)) {
            fileName = file.name;
        }
        this.setState({ fileName });
    };

    /**
     * Call after that the image is uploadeGenericFiled
     * @param event event the triggered event
     */
    uploadImage = (event: any): void => {
        const { files } = event.target;
        if (files && files.length > 0 && this.checkFile(files[0])) {
            this.props.onUploaded(files[0]);
        }
    };

    /**
     * Called when the user drag the file over the component
     * @param event event the triggered event
     */
    onDragOver = (event: any): void => {
        event.preventDefault();
        event.stopPropagation();

        const div = document.getElementById("file-uploader")!;
        if (div.className.includes("image-uploader-drag")) return;
        div.className += " image-uploader-drag";
    };

    uploadFile = (event: any): void => {
        const files: File[] = Array.from(event.target.files);

        if (files && files.length > 0 && files.every((file: File) => this.checkFile(file))) {

            this.props.onUploaded(files[0]);
        }
    };

    /**
     * Called when the user drop the file (Drag and Drop)
     * @param event event the triggered event
     */
    onDrop = (event: any): void => {
        const div = document.getElementById("file-uploader")!;
        div.className = div.className.replace("image-uploader-drag", "");
        this.uploadImage(event);
    };

    render() {
        console.log(this.props.file);
        const { file, disabled } = this.props;
        const fileSrc = (file && typeof file === 'string') ? file.startsWith("http") ? file : GOOGLE_STORAGE_URL + file : (file && typeof file !== 'string' && !(file instanceof File)) ? file.type === 'GenericFile' ? file.auth_url : file.file_name : undefined;
        return (
            <div style={this.props.containerStyle}>
                {this.props.loading ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}><Spin size={'large'} spinning={this.props.loading} /></div>

                    : <div id="file-uploader" onDragOver={this.onDragOver} onDrop={this.onDrop} className={`file-uploader-parent${disabled ? '-disabled' : ''} ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
                        <input disabled={disabled} style={disabled ? { cursor: 'default' } : {}} className="image-uploader-input" type="file" multiple accept={this.props.accept} onChange={this.uploadFile} />
                        <div className="file-uploader-content file-uploader-button">
                            <FAIcon prefix='fad' name='file-arrow-up' /> {this.props.file !== undefined ? this.props.editText ?? <FormattedMessage defaultMessage={'Edit file'} /> : this.props.addText ?? <FormattedMessage defaultMessage={'Add file'} />}
                        </div>
                    </div>}
                {
                    (this.props.divider === undefined || this.props.divider) &&
                    <div className="file-uploader-divider" style={{ marginBottom: 10, marginTop: 10 }} />
                }

                {((typeof file !== 'string' && file instanceof File) && file?.name || (typeof file !== 'string' && !(file instanceof File)) && file?.type === 'ValidateFile') ?
                    <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p title={this.state.fileName} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: this.props.deleteTextMaxWidth }} >{this.state.fileName}</p>
                        <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(undefined)} />
                    </div>
                    :
                    (typeof file !== 'string' && !(file instanceof File)) && file?.type === 'GenericFile' ?
                        <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p title={this.state.fileName} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: this.props.deleteTextMaxWidth }} >{this.state.fileName}</p>
                            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                <a target={'_blank'} href={fileSrc} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                                <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(undefined)} />
                            </div>
                        </div>
                        :
                        file && typeof file === "string" &&
                        <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <a target={'_blank'} href={fileSrc} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                            <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(undefined)} />
                        </div>
                }
            </div>
        );
    }
}




const mapStateToProps = (state: ApplicationState) => ({
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(FileUploader));